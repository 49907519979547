import React from "react";

import { Input, NumericInput, Select, TextArea } from "design-system";
import { useNow } from "lib/date";
import { FormController } from "app/lib/FormController";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";

import { Schema } from "../../../CreateAndEdit/Schema";
import { AdditionalTermFlyover } from "./AdditionalTermFlyover";
import { useGetProServiceProductsQuery } from "./data.graphql";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { USD_CREDIT_TYPE } from "app/lib/credits";

const useProServiceCtrl = FormController.createHook(Schema.ProService, {
  init(props: Props) {
    return props.edit ?? {};
  },
});

interface Props {
  edit?: Schema.Types.ProService;
  onSave: (override: Schema.Types.ProService) => void;
  onCancel: () => void;
  onDelete?: () => void;
  options?: {
    netsuiteEnabled?: boolean;
  };
}

export const ProServiceFlyover: React.FC<Props> = (props) => {
  const ctrl = useProServiceCtrl(props);
  const now = useNow();
  const proServiceProducts = useGetProServiceProductsQuery();

  return (
    <AdditionalTermFlyover
      {...props}
      ctrl={ctrl}
      title={
        props.edit
          ? "Edit professional service term"
          : "Add a professional service term"
      }
    >
      <Select
        {...ctrl.props.Select("productId", {
          name: "Product",
          placeholder: "Select a product",
          loading: proServiceProducts.loading,
          disabled: proServiceProducts.loading,
          autoFocus: true,
          options:
            proServiceProducts.data?.contract_pricing.products.map((p) => ({
              value: p.id,
              label: ProductListItem.getName(p, now),
            })) ?? [],
        })}
      />

      <TextArea
        {...ctrl.props.TextArea("description", {
          name: "Description (optional)",
          placeholder: "Internal description of professional service",
        })}
      />

      {props.options?.netsuiteEnabled && (
        <Input
          {...ctrl.props.Input("netSuiteSalesOrderId", {
            name: "NetSuite Sales Order ID (optional)",
            placeholder: "Enter ID",
          })}
        />
      )}
      <div className="grid grid-cols-3 gap-12">
        <NumericInput
          {...ctrl.props.NumericInput("quantity", {
            placeholder: "1",
            name: "Quantity",
          })}
        />
        <DeprecatedCreditInput
          {...ctrl.props.CreditInput("unitPrice", {
            name: "Unit price",
            creditType: USD_CREDIT_TYPE,
            placeholder: "123.45",
            allowZeroAmounts: true,
          })}
        />
        <DeprecatedCreditInput
          {...ctrl.props.CreditInput("maxAmount", {
            name: "Max amount",
            creditType: USD_CREDIT_TYPE,
            placeholder: "123.45",
            allowZeroAmounts: true,
          })}
        />
      </div>
    </AdditionalTermFlyover>
  );
};
