import { useCallback, useEffect, useMemo, useState } from "react";
import { Schema } from "app/pages/Contracts/Pricing/Schema";
import { useGetAllCreditTypesQuery } from "app/pages/Contracts/Pricing/CreateAndEditRateCard/data.graphql";
import { filterAndSortCreditTypes } from "app/pages/Contracts/lib/CreditTypes";
import { createContainer } from "unstated-next";
import { useProductsQuery } from "./data.graphql";
import { USD_CREDIT_ID, USD_CREDIT_TYPE } from "app/lib/credits";
import { FiatCreditType } from "app/types/credit-types";
import {
  categorizeProducts,
  getDefaultRates,
  ProductListItem,
} from "./contextUtils";
import { deepEqual } from "fast-equals";
import { useRateCardQuery } from "app/pages/Contracts/Pricing/RateCardsDetails/data.graphql";

type DimensionalProduct =
  Schema.Types.UnifiedRateCardInput["dimensionalProducts"][0];

export type RateProductEnum =
  | "usageRates"
  | "subscriptionRates"
  | "compositeRates";

type InitialState = {
  rateCardId?: string;
};

type FormState = {
  name: string;
  description?: string;
  aliases: Schema.Types.RateCardAlias[];
  creditTypeConversions: Schema.Types.CreditTypeConversion[];
  fiatCreditType: FiatCreditType;
  usageRates: Schema.Types.Rate[];
  subscriptionRates: Schema.Types.Rate[];
  compositeRates: Schema.Types.Rate[];
  selectedProducts: string[];
  dimensionalProducts: DimensionalProduct[];
};

const DEFAULT_FORM_STATE_VALUES = {
  name: "",
  description: "",
  aliases: [],
  creditTypeConversions: [],
  fiatCreditType: USD_CREDIT_TYPE,
  selectedProducts: [],
  usageRates: [],
  subscriptionRates: [],
  compositeRates: [],
  dimensionalProducts: [],
};

function useRateCardContext({ rateCardId }: InitialState = {}) {
  const isEdit = !!rateCardId;
  const { data, loading: loadingCreditTypes } = useGetAllCreditTypesQuery();
  const { fiatCreditTypes, customCreditTypes } = useMemo(
    () => filterAndSortCreditTypes(data?.CreditType ?? []),
    [data?.CreditType],
  );
  const {
    data: products,
    loading: loadingProducts,
    error: productDataError,
  } = useProductsQuery();

  /**
   * Only contains products which show up on the rate card form.
   * For now, this removes professional services and fix products.
   */
  const filteredProductsByType = useMemo(() => {
    if (products) {
      const filteredProducts = products.contract_pricing.products.filter(
        (product) => {
          return (
            product.__typename === "UsageProductListItem" ||
            product.__typename === "SubscriptionProductListItem" ||
            product.__typename === "CompositeProductListItem"
          );
        },
      );
      return filteredProducts;
    } else {
      return [];
    }
  }, [products]);

  const snapshotKey = isEdit
    ? `rate-card-edit-v3-${rateCardId}`
    : "rate-card-create-v3";

  const rateCardReq = useRateCardQuery({
    variables: { id: rateCardId ?? "" },
    skip: !isEdit,
  });

  const getInitialState = () => {
    const stored = sessionStorage.getItem(snapshotKey);
    if (stored) {
      try {
        const parsed = JSON.parse(stored);
        return { ...DEFAULT_FORM_STATE_VALUES, ...parsed };
      } catch {
        return DEFAULT_FORM_STATE_VALUES;
      }
    }
    return DEFAULT_FORM_STATE_VALUES;
  };

  const [formState, setFormStateInternal] =
    useState<FormState>(getInitialState());

  /**
   * Updates the form state and saves a snapshot to session storage
   * so that form state can be restored if the user changes steps
   * in the rate card creation flow or refreshes the page.
   */
  const updateForm = useCallback(
    (updater: (prev: FormState) => FormState) => {
      setFormStateInternal((prev) => {
        const newFormState = updater(prev);
        sessionStorage.setItem(snapshotKey, JSON.stringify(newFormState));
        return newFormState;
      });
    },
    [snapshotKey, setFormStateInternal],
  );

  /**
   * Sets the fiat credit type, updates the credit type
   * on all existing rates, and clears out credit type conversions.
   */
  const setFiatCreditType = useCallback(
    (fiatCreditType: FiatCreditType) => {
      updateForm((prevState) => {
        const usageRates =
          prevState.usageRates?.map((rate) => ({
            ...rate,
            creditType: fiatCreditType,
          })) ?? [];

        const subscriptionRates =
          prevState.subscriptionRates?.map((rate) => ({
            ...rate,
            creditType: fiatCreditType,
          })) ?? [];

        const compositeRates =
          prevState.compositeRates?.map((rate) => ({
            ...rate,
            creditType: fiatCreditType,
          })) ?? [];

        const newState = {
          ...prevState,
          fiatCreditType,
          usageRates,
          subscriptionRates,
          compositeRates,
          creditTypeConversions: [],
        };
        return newState;
      });
    },
    [updateForm],
  );

  useEffect(() => {
    // We could consider refactoring all the existing rate card loading outside this context.
    // We likely need this useEffect call because we're fetching data from outside React (rateCardReq), or
    // at least some mechanism to update state when rateCardReq finishes loading.
    if (isEdit && !rateCardReq.loading) {
      const rateCard = rateCardReq.data?.contract_pricing.rate_card;
      if (rateCard === undefined) {
        return;
      }

      const creditTypeConversions =
        rateCard.credit_type_conversions?.map((c) => ({
          custom_credit_type_id: c.custom_credit_type.id,
          custom_credit_type_name: c.custom_credit_type.name,
          fiat_per_custom_credit:
            rateCard?.fiat_credit_type.id === USD_CREDIT_ID
              ? Number(c.fiat_per_custom_credit) / 100.0
              : Number(c.fiat_per_custom_credit),
        })) ?? [];

      updateForm((prevState) => ({
        ...prevState,
        name: rateCard.name,
        description: rateCard.description ?? undefined,
        // This is ok because you can't edit aliases when you're adding a rate to an existing rate card.
        aliases: [],
        creditTypeConversions,
      }));

      setFiatCreditType({
        id: rateCard.fiat_credit_type.id,
        name: rateCard.fiat_credit_type.name,
        client_id: null,
        environment_type: null,
      });
    }
  }, [rateCardReq]);

  function clearSnapshot() {
    sessionStorage.removeItem(snapshotKey);
  }

  const productsMap = useMemo(() => {
    const data = new Map<string, ProductListItem>();
    return (
      Array.from(filteredProductsByType.values()).reduce((acc, product) => {
        acc.set(product.id, product);
        return acc;
      }, data) ?? data
    );
  }, [filteredProductsByType]);

  const conversionRateChange = useCallback(
    (conversion: Schema.Types.CreditTypeConversion) => {
      updateForm((prevState) => {
        const conversions = prevState.creditTypeConversions;
        const conversionIndex = conversions.findIndex(
          (c) => c.custom_credit_type_id === conversion.custom_credit_type_id,
        );
        if (conversionIndex >= 0) {
          return {
            ...prevState,
            creditTypeConversions: [
              ...conversions.slice(0, conversionIndex),
              conversion,
              ...conversions.slice(conversionIndex + 1),
            ],
          };
        } else {
          return {
            ...prevState,
            creditTypeConversions: [...conversions, conversion],
          };
        }
      });
    },
    [updateForm],
  );

  const setRateStartingAtDate = useCallback(
    (rateType: RateProductEnum, date?: Date) => {
      if (date) {
        updateForm((prevState) => {
          return {
            ...prevState,
            [rateType]: prevState[rateType].map((rate) => ({
              ...rate,
              startingAt: date.toISOString() ?? undefined,
            })),
          };
        });
      }
    },
    [updateForm],
  );

  const setRateEndingBeforeDate = useCallback(
    (type: RateProductEnum, date?: Date) => {
      updateForm((prevState) => {
        return {
          ...prevState,
          [type]: prevState[type].map((rate) => ({
            ...rate,
            endingBefore: date?.toISOString() ?? undefined,
          })),
        };
      });
    },
    [updateForm],
  );
  /**
   * Updates rates by merging partial object. Only the rates selected by the rate selector
   * will be updated
   */
  const patchProductRates = useCallback(
    (
      type: RateProductEnum,
      rateSelector: {
        productId: string;
        pricingGroupValues?: Record<string, string>;
      },
      update: Partial<NonNullable<Schema.Types.AddRateInput["rates"]>[number]>,
    ) => {
      updateForm((prevState) => {
        return {
          ...prevState,
          [type]: prevState[type].map((rate) => {
            if (
              rateSelector.productId === rate.productId &&
              (!rateSelector.pricingGroupValues ||
                deepEqual(
                  rateSelector.pricingGroupValues,
                  rate.pricingGroupValues,
                ))
            ) {
              return {
                ...rate,
                ...update,
              };
            }
            return rate;
          }),
        };
      });
    },
    [updateForm],
  );

  const editRate = useCallback(
    (
      type: RateProductEnum,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      updateForm((prevFormState) => {
        const rates = prevFormState[type];
        // TODO(ekaragiannis) - we need to be able to updates the credit type for all ones in the product
        const originalIndex = rates.findIndex((r) => r.id === update.id);
        if (originalIndex >= 0) {
          // if the change was to the credit type, then we need to update all subrates for that product
          const currentRate = rates[originalIndex];
          if (currentRate.creditType?.id !== update.creditType?.id) {
            return {
              ...prevFormState,
              [type]: rates.map((r, index) => {
                if (index === originalIndex) {
                  return update;
                } else if (r.productId === update.productId) {
                  return {
                    ...r,
                    creditType: update.creditType,
                  };
                } else {
                  return r;
                }
              }),
            };
          } else {
            return {
              ...prevFormState,
              [type]: [
                ...rates.slice(0, originalIndex),
                update,
                ...rates.slice(originalIndex + 1),
              ],
            };
          }
        } else {
          return prevFormState;
        }
      });
    },
    [updateForm],
  );

  const addRateOverride = useCallback(
    (
      type: RateProductEnum,
      previousRate: Schema.Types.Rate,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      updateForm((prevFormState) => {
        const rates = prevFormState[type];
        const originalIndex = rates.findLastIndex(
          (r) =>
            r.productId === previousRate.productId &&
            deepEqual(r.pricingGroupValues, previousRate.pricingGroupValues),
        );
        return {
          ...prevFormState,
          [type]: [
            ...rates.slice(0, originalIndex + 1),
            update,
            ...rates.slice(originalIndex + 1),
          ],
        };
      });
    },
    [updateForm],
  );

  const removeRateOverride = useCallback(
    (
      type: RateProductEnum,
      update: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      updateForm((prevFormState) => {
        const rates = prevFormState[type];
        const originalIndex = rates.findIndex((r) => r.id === update.id);
        if (originalIndex >= 0) {
          return {
            ...prevFormState,
            [type]: [
              ...rates.slice(0, originalIndex),
              ...rates.slice(originalIndex + 1),
            ],
          };
        }

        return prevFormState;
      });
    },
    [updateForm],
  );

  const addCommitRate = useCallback(
    (
      type: RateProductEnum,
      rate: Schema.Types.Rate,
      commitRate: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      updateForm((prevFormState) => {
        const rates = prevFormState[type];
        const originalIndex = rates.findLastIndex(
          (r) => r.productId === rate.productId && r.id === rate.id,
        );
        return {
          ...prevFormState,
          [type]: [
            ...rates.slice(0, originalIndex),
            {
              ...rates[originalIndex],
              hasCommitRate: true,
            },
            { ...commitRate, isCommitRate: true, hasCommitRate: false },
            ...rates.slice(originalIndex + 1),
          ],
        };
      });
    },
    [updateForm],
  );

  const removeCommitRate = useCallback(
    (
      type: RateProductEnum,
      commitRate: NonNullable<Schema.Types.AddRateInput["rates"]>[number],
    ) => {
      updateForm((prevFormState) => {
        const rates = prevFormState[type];
        let originalIndex = rates.findIndex((r) => r.id === commitRate.id);
        if (!rates[originalIndex].isCommitPrice) {
          originalIndex += 1;
        }
        if (originalIndex >= 1) {
          return {
            ...prevFormState,
            [type]: [
              ...rates.slice(0, originalIndex - 1),
              {
                ...rates[originalIndex - 1],
                hasCommitRate: false,
              },
              ...rates.slice(originalIndex + 1),
            ],
          };
        }

        return prevFormState;
      });
    },
    [updateForm],
  );

  /**
   * Takes the old form state and new selected products.
   * Returns the new form state with the new selected products,
   * rates, and dimensional products updated.
   */
  const computeNewFormStateForProducts = useCallback(
    (prevState: FormState, newSelectedProducts: string[]) => {
      const currentDimensionalProducts = prevState.dimensionalProducts;
      const {
        dimensionalProducts: newDimensionalProducts,
        nonDimensionalProducts,
      } = categorizeProducts(newSelectedProducts, productsMap);

      const finalDimensionalProducts = newDimensionalProducts.map((dp) => {
        const currentProduct = currentDimensionalProducts.find(
          (cdp) =>
            cdp.id === dp.id &&
            dp.pricingGroupKeyValues.every((pair) =>
              cdp.pricingGroupKeyValues.some((cpair) => cpair.key === pair.key),
            ),
        );
        if (currentProduct) {
          return currentProduct;
        } else {
          return dp;
        }
      });

      const { usageRates, subscriptionRates, compositeRates } = getDefaultRates(
        prevState.usageRates,
        prevState.subscriptionRates,
        prevState.compositeRates,
        nonDimensionalProducts,
        finalDimensionalProducts,
        prevState.fiatCreditType,
      );

      return {
        ...prevState,
        selectedProducts: newSelectedProducts,
        dimensionalProducts: finalDimensionalProducts,
        usageRates,
        subscriptionRates,
        compositeRates,
      };
    },
    [productsMap, categorizeProducts, getDefaultRates],
  );

  const setSelectedProducts = useCallback(
    (selectedProducts: string[]) => {
      updateForm((prev) => {
        return computeNewFormStateForProducts(prev, selectedProducts);
      });
    },
    [updateForm, computeNewFormStateForProducts],
  );

  const removeProduct = useCallback(
    (productId: string) => {
      updateForm((prevState) => {
        const currentSelected = prevState.selectedProducts;
        const newSelected: string[] = currentSelected.filter(
          (p) => p !== productId,
        );
        return computeNewFormStateForProducts(prevState, newSelected);
      });
    },
    [updateForm, computeNewFormStateForProducts],
  );

  const hasValidRateCardInput = useMemo(() => {
    // Check if the form state is valid against the expected rate card schema
    // TODO: expose the validation issues
    const rateCardSchemaValidation = Schema.UnifiedRateCardInput.safeParse({
      ...formState,
      fiatCreditTypeId: formState.fiatCreditType.id,
      fiatCreditTypeName: formState.fiatCreditType.name,
    });
    return (
      rateCardSchemaValidation.success &&
      (!formState.dimensionalProducts ||
        formState.dimensionalProducts.every((dp) =>
          dp.pricingGroupKeyValues.every((pgv) =>
            pgv.values.some((v) => v.length > 0),
          ),
        ))
    );
  }, [formState]);

  return {
    loading: loadingCreditTypes || loadingProducts,
    fiatCreditTypes: fiatCreditTypes as FiatCreditType[],
    customCreditTypes,
    clearSnapshot,
    products: filteredProductsByType,
    productsMap,
    productDataError,
    fiatCreditType: formState.fiatCreditType,
    editRate,
    addRateOverride,
    removeRateOverride,
    addCommitRate,
    removeCommitRate,
    removeProduct,
    setFiatCreditType,
    conversionRateChange,
    setRateStartingAtDate,
    setRateEndingBeforeDate,
    aliases: formState.aliases,
    setAliases: (aliases: Schema.Types.RateCardAlias[]) => {
      updateForm((prev) => ({ ...prev, aliases }));
    },
    name: formState.name,
    setName: (name: string) => {
      updateForm((prev) => ({ ...prev, name }));
    },
    updateForm: updateForm,
    description: formState.description,
    setDescription: (description: string) => {
      updateForm((prev) => ({ ...prev, description }));
    },
    dimensionalProducts: formState.dimensionalProducts,
    hasValidRateCardInput,
    selectedProducts: formState.selectedProducts,
    rates: {
      usageRates: formState.usageRates,
      subscriptionRates: formState.subscriptionRates,
      compositeRates: formState.compositeRates,
    },
    creditTypeConversions: formState.creditTypeConversions,
    setSelectedProducts,
    patchProductRates,
    setDimensionalProductKeyValues: (
      values: Array<{ productId: string; key: string; values: string[] }>,
    ) => {
      updateForm((prevState) => {
        const dimensionalProducts = prevState.dimensionalProducts;
        const updatedDimensionalProducts = dimensionalProducts.map((dp) => {
          const product = values.find((v) => v.productId === dp.id);
          if (product) {
            return {
              ...dp,
              pricingGroupKeyValues: dp.pricingGroupKeyValues.map((pair) => {
                if (pair.key === product.key) {
                  return {
                    ...pair,
                    values: product.values,
                  };
                }

                return pair;
              }),
            };
          } else {
            return dp;
          }
        });

        const { nonDimensionalProducts } = categorizeProducts(
          prevState.selectedProducts,
          productsMap,
        );
        const { usageRates, subscriptionRates, compositeRates } =
          getDefaultRates(
            prevState.usageRates ?? [],
            prevState.subscriptionRates ?? [],
            prevState.compositeRates ?? [],
            nonDimensionalProducts,
            updatedDimensionalProducts,
            prevState.fiatCreditType,
          );

        return {
          ...prevState,
          dimensionalProducts: updatedDimensionalProducts,
          usageRates,
          subscriptionRates,
          compositeRates,
        };
      });
    },
  };
}

export const RateCardContext = createContainer(useRateCardContext);
