/* d0c8e585546277b7c1479b038806ab28f0d1a053
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveProductMutationVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
}>;


export type ArchiveProductMutation = { __typename?: 'Mutation', archive_product: { __typename?: 'Product', id: string, deprecated_at: string | null } };


export const ArchiveProductDocument = gql`
    mutation ArchiveProduct($product_id: uuid!) {
  archive_product(product_id: $product_id) {
    id
    deprecated_at
    __environment_type: environment_type
  }
}
    `;
export type ArchiveProductMutationFn = Apollo.MutationFunction<ArchiveProductMutation, ArchiveProductMutationVariables>;

/**
 * __useArchiveProductMutation__
 *
 * To run a mutation, you first call `useArchiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProductMutation, { data, loading, error }] = useArchiveProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useArchiveProductMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProductMutation, ArchiveProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProductMutation, ArchiveProductMutationVariables>(ArchiveProductDocument, options);
      }
export type ArchiveProductMutationHookResult = ReturnType<typeof useArchiveProductMutation>;
export type ArchiveProductMutationResult = Apollo.MutationResult<ArchiveProductMutation>;
export type ArchiveProductMutationOptions = Apollo.BaseMutationOptions<ArchiveProductMutation, ArchiveProductMutationVariables>;