import {
  CustomerPlanNameFragment,
  CustomerPlanRoutePathFragment,
  CustomerPlanStatusFragment,
  CustomerPlanBillingProviderFragment,
} from "./fragments.graphql";

export type {
  CustomerPlanNameFragment as NameFragment,
  CustomerPlanActivityFragment as ActivityFragment,
  CustomerPlanRoutePathFragment as RoutePathFragment,
  CustomerPlanStatusFragment as StatusFragment,
  CustomerPlanBillingProviderFragment as BillingProviderFragment,
} from "./fragments.graphql";

import { toDayjs, Dayjs, distanceFrom } from "lib/date";
import { formatBillingProvider } from "app/lib/billingProvider/formatBillingProvider";

export function getName(plan: CustomerPlanNameFragment): string {
  return plan.Plan.name;
}

export function getRoutePath(plan: CustomerPlanRoutePathFragment): string {
  return `/customers/${plan.Customer.id}/plans/${plan.id}`;
}

export function getStatus(plan: CustomerPlanStatusFragment, now: Dayjs) {
  const start = distanceFrom(now, toDayjs(plan.start_date));
  const end = plan.cancellation_date
    ? distanceFrom(now, toDayjs(plan.cancellation_date))
    : null;

  if (!start.isInPast) {
    return start.distDays >= 30 ? "upcoming" : "active-soon";
  }

  if (end?.isInPast) {
    return end.distDays <= 7 ? "recently-ended" : "ended";
  }

  return end && end?.distDays <= 30 ? "ending-soon" : "active";
}

export function getBillingProvider(
  customerPlan: CustomerPlanBillingProviderFragment,
) {
  return formatBillingProvider(customerPlan?.Plan?.billing_provider);
}
