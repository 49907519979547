import classnames from "classnames";
import Decimal from "decimal.js";
import React from "react";

import { NumericInput } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { Tooltip } from "design-system";
import { Subtitle } from "design-system";
import { CreditType } from "app/types/credit-types";
import { CompositeChargeTypeEnum_Enum } from "types/generated-graphql/__types__";

const CREDIT_PLACEHOLDER = "123.45";

interface UsageFeeRateCellProps {
  canBeTiered: boolean;
  isTiered: boolean;
  value: string;
  setValue: (v: number | null) => void;
  creditType: CreditType;
  disabled: boolean;
}

export const UsageFeeRateCell: React.FC<UsageFeeRateCellProps> = (props) => {
  return (
    <div className="flex items-center justify-between">
      {!props.isTiered && (
        <DeprecatedCreditInput
          className="w-[280px]"
          creditType={props.creditType}
          placeholder={CREDIT_PLACEHOLDER}
          initialValue={props.value}
          allowZeroAmounts
          success={props.value !== ""}
          onChange={props.setValue}
          disabled={props.disabled}
        />
      )}
    </div>
  );
};

interface TieredFeeRateCellV2Props {
  metricMinimum: number | undefined;
  nextMetricMinimum: number | undefined;
  canEditNextMetricMinimum: boolean;
  setNextMetricMinimum: (v: number | null) => void;
  price: string;
  setPrice: (v: number | null) => void;
  creditType: CreditType;
  disabled: boolean;
  tierIndex: number;
  numTiers: number;
  removeTier: () => void;
}

export const TieredFeeRateCellV2: React.FC<TieredFeeRateCellV2Props> = (
  props,
) => {
  let minErrorString = "";
  if (props.nextMetricMinimum !== undefined) {
    if (!Number.isInteger(props.nextMetricMinimum)) {
      minErrorString = "Must be an integer";
    }
    if (props.nextMetricMinimum < 0) {
      minErrorString = "Must be positive";
    } else if (
      props.metricMinimum !== undefined &&
      props.metricMinimum >= props.nextMetricMinimum
    ) {
      minErrorString = "Too small";
    }
  }
  return (
    <div className="relative flex w-full justify-end gap-24">
      {props.tierIndex < props.numTiers - 1 && (
        <NumericInput
          placeholder={`${(props.nextMetricMinimum ?? 0) + 1}`}
          value={props.nextMetricMinimum}
          onChange={props.setNextMetricMinimum}
          disabled={!props.canEditNextMetricMinimum}
          error={minErrorString}
          className="mt-4 w-[128px]"
          prefix="≤"
        />
      )}
      <DeprecatedCreditInput
        className="mt-4 h-full w-[124px]"
        creditType={props.creditType}
        placeholder={CREDIT_PLACEHOLDER}
        initialValue={props.price}
        allowZeroAmounts
        success={props.price !== ""}
        onChange={props.setPrice}
        disabled={props.disabled}
      />
      {props.tierIndex > 0 && props.canEditNextMetricMinimum && (
        <IconButton
          className="absolute right-[-40px]"
          onClick={props.removeTier}
          theme="tertiary"
          icon="xCircle"
        />
      )}
    </div>
  );
};

interface SeatRateCellProps {
  creditType: CreditType;
  value: string;
  setValue: (v: number | null) => void;
  initialQuantity?: Decimal;
  setInitialQuantity: (v: Decimal | null) => void;
  disabled: boolean;
  hasCustomPricing?: boolean;
  isNewProduct: boolean;
  rampStart: number;
}

export const SeatRateCell: React.FC<SeatRateCellProps> = (props) => {
  let quantityErrorString = "";
  if (props.initialQuantity !== undefined) {
    if (!props.initialQuantity.isInteger()) {
      quantityErrorString = "Initial quantity must be an integer";
    } else if (props.initialQuantity.lessThan(0)) {
      quantityErrorString = "Initial quantity must be positive";
    }
  }

  return (
    <div className="flex items-center justify-between">
      <Tooltip
        content="Customers on this plan have price adjustments enabled. You may not update this existing quantity."
        disabled={!props.hasCustomPricing || props.isNewProduct}
      >
        <NumericInput
          placeholder=""
          value={
            props.initialQuantity ? Number(props.initialQuantity) : undefined
          }
          onChange={(v) => {
            props.setInitialQuantity(v || v === 0 ? new Decimal(v) : null);
          }}
          disabled={
            props.disabled || (props.hasCustomPricing && !props.isNewProduct)
          }
          className={classnames(
            "w-[128px] [&_input]:!pl-[68px] [&_span]:font-default [&_span]:text-gray-600",
            {
              "[&_input]:text-deprecated-gray-300 [&_span]:text-deprecated-gray-300":
                props.disabled,
            },
          )}
          prefix="Quantity:"
          success={props.initialQuantity && !quantityErrorString}
          warning={quantityErrorString}
        />
      </Tooltip>
      <>
        <Subtitle className="mt-2 mx-8 mb-4 w-12 font-default text-gray-600">
          ×
        </Subtitle>
        <DeprecatedCreditInput
          creditType={props.creditType}
          placeholder={CREDIT_PLACEHOLDER}
          initialValue={props.value}
          allowZeroAmounts
          success={props.value !== ""}
          onChange={props.setValue}
          disabled={props.disabled}
          className="h-full w-[124px]"
        />
      </>
    </div>
  );
};

interface FlatFeeRateCellProps {
  creditType: CreditType;
  value: string;
  setValue: (v: number | null) => void;
  quantity?: Decimal;
  setQuantity: (v: Decimal | null) => void;
  isTiered: boolean;
  disabled: boolean;
  hasCustomPricing?: boolean;
  isNewProduct: boolean;
  rampStart: number;
}

export const FlatFeeRateCell: React.FC<FlatFeeRateCellProps> = (props) => {
  return (
    <div className="flex items-center justify-between">
      <Tooltip
        content="Customers on this plan have price adjustments enabled. You may not update this existing quantity."
        disabled={!props.hasCustomPricing || props.isNewProduct}
      >
        <NumericInput
          placeholder=""
          value={props.quantity ? Number(props.quantity) : undefined}
          onChange={(v) => {
            props.setQuantity(v || v === 0 ? new Decimal(v) : null);
          }}
          disabled={
            props.disabled || (props.hasCustomPricing && !props.isNewProduct)
          }
          className={classnames(
            "w-[128px] [&_input]:!pl-[68px] [&_span]:font-default [&_span]:text-gray-600",
            {
              "[&_input]:text-deprecated-gray-300 [&_span]:text-deprecated-gray-300":
                props.disabled,
            },
          )}
          prefix="Quantity:"
          success={
            props.quantity &&
            new Decimal(props.quantity).greaterThanOrEqualTo(0)
          }
          warning={
            props.quantity &&
            new Decimal(props.quantity).lessThan(0) &&
            "Quantity must be greater than or equal to 0"
          }
        />
      </Tooltip>
      {!props.isTiered && (
        <>
          <Subtitle className="mt-2 mx-8 mb-4 w-12 font-default text-gray-600">
            ×
          </Subtitle>
          <DeprecatedCreditInput
            creditType={props.creditType}
            placeholder={CREDIT_PLACEHOLDER}
            initialValue={props.value}
            allowZeroAmounts
            success={props.value !== ""}
            onChange={props.setValue}
            disabled={props.disabled}
            className="h-full w-[124px]"
          />
        </>
      )}
    </div>
  );
};

interface CompositeChargeRateCellProps {
  value: Decimal | undefined;
  setValue: (v: Decimal | null) => void;
  quantity?: Decimal;
  setQuantity: (v: Decimal | null) => void;
  disabled: boolean;
  hasCustomPricing?: boolean;
  isNewProduct: boolean;
  type: CompositeChargeTypeEnum_Enum | undefined;
  creditType?: CreditType;
}

export const CompositeChargeRateCell: React.FC<CompositeChargeRateCellProps> = (
  props,
) => {
  const quantity = props.quantity ? new Decimal(props.quantity) : undefined;
  const quantityIsValid = quantity?.eq(1) || quantity?.eq(0);
  return (
    <div className="flex items-center justify-between">
      <Tooltip
        content="Customers on this plan have price adjustments enabled. You may not update this existing quantity."
        disabled={!props.hasCustomPricing || props.isNewProduct}
      >
        <NumericInput
          placeholder=""
          value={quantity?.toNumber()}
          onChange={(v) => {
            props.setQuantity(v || v === 0 ? new Decimal(v) : null);
          }}
          disabled={
            props.disabled || (props.hasCustomPricing && !props.isNewProduct)
          }
          className={classnames(
            "w-[128px] [&_input]:!pl-[68px] [&_span]:font-default [&_span]:text-gray-600",
            {
              "[&_input]:text-deprecated-gray-300 [&_span]:text-deprecated-gray-300":
                props.disabled,
            },
          )}
          prefix="Quantity:"
          success={quantityIsValid}
          warning={
            props.quantity && !quantityIsValid
              ? "Quantity must be equal to zero or one"
              : null
          }
        />
      </Tooltip>
      <Subtitle className="mt-2 mx-8 mb-4 w-12 font-default text-gray-600">
        ×
      </Subtitle>
      {!props.type || props.type === CompositeChargeTypeEnum_Enum.Percentage ? (
        <NumericInput
          className="h-full w-[124px]"
          placeholder={CREDIT_PLACEHOLDER}
          onChange={(v) =>
            props.setValue(v != null ? new Decimal(v).div(100) : v)
          }
          disabled={props.disabled || !props.type}
          value={
            props.value
              ? new Decimal(props.value).mul(100).toNumber()
              : undefined
          }
          success={props.value && props.value.greaterThanOrEqualTo(-100)}
          warning={
            props.value && new Decimal(props.value).lessThan(-100)
              ? "Percentage must be greater than or equal to -100%"
              : undefined
          }
          suffix="%"
        />
      ) : (
        <DeprecatedCreditInput
          placeholder={CREDIT_PLACEHOLDER}
          creditType={
            props.creditType ?? {
              client_id: null,
              name: "",
              id: "",
              environment_type: null,
            }
          }
          disabled={props.disabled}
          initialValue={props.value?.toString()}
          onChange={(v) => {
            props.setValue(v != null ? new Decimal(v) : v);
          }}
          success={props.value && props.value.greaterThan(0)}
        />
      )}
    </div>
  );
};
