import React from "react";

import { EventDetailsQuery } from "../../queries.graphql";

import { Subtitle, Icon, IconName } from "design-system";
import classnames from "classnames";
import { renderDateTimeInUTC } from "lib/time";

type Event = EventDetailsQuery["mri_events"][0];

type SummaryProps = {
  event: Event;
};

export const Summary: React.FC<SummaryProps> = ({ event }) => {
  const hasBillableMetrics =
    !!event?.billable_metrics?.length || !!event?.seat_metrics.length;

  let summaryStateClass = "text-deprecated-success-500";
  let summaryIcon: IconName = "checkmarkCircle";

  const processedAtSummary = event.processedAt
    ? `It was processed on ${renderDateTimeInUTC(
        new Date(event.processedAt),
        false, // render as string
        false, // show UTC label
      )}`
    : "";

  let summaryText = (
    <>
      This event was ingested, but it didn’t match a billable metric or
      customer. {processedAtSummary}
    </>
  );

  if (event.is_duplicate) {
    summaryText = (
      <>
        This event was ingested, but the <code>transaction_id</code> was a
        duplicate of a previously ingested event.
      </>
    );
    summaryStateClass = "text-deprecated-error-500";
    summaryIcon = "warning";
  } else if (event?.customer) {
    if (hasBillableMetrics) {
      summaryText = (
        <>
          This event was ingested and matched a billable metric.{" "}
          {processedAtSummary}
        </>
      );
    } else {
      summaryText = (
        <>
          This event was ingested but didn't match a billable metric.{" "}
          {processedAtSummary}
        </>
      );
      summaryStateClass = "text-deprecated-error-500";
      summaryIcon = "warning";
    }
  } else {
    if (hasBillableMetrics) {
      summaryText = (
        <>
          This event was ingested but didn't match a customer_id.{" "}
          {processedAtSummary}
        </>
      );
      summaryStateClass = "text-deprecated-error-500";
      summaryIcon = "warning";
    } else {
      summaryStateClass = "text-deprecated-error-500";
      summaryIcon = "warning";
    }
  }
  return (
    <div className="rounded-xs border border-deprecated-gray-100 p-12">
      <Subtitle
        level={2}
        className="flex flex-row items-center pl-[6px] font-default font-normal"
      >
        <Icon
          className={classnames(
            "mr-8 min-h-[20px] min-w-[20px] ",
            summaryStateClass,
          )}
          icon={summaryIcon}
        />
        <div>{summaryText}</div>
      </Subtitle>
    </div>
  );
};
