import React, { useEffect, useState } from "react";
import { ButtonDropdown } from "design-system";

import { useRequiredParam } from "app/lib/routes/params";

import { ActivityList } from "app/pages/Contracts/components/ActivityList";
import { useNow, toDayjs, Dayjs } from "lib/date";
import { useApolloResp } from "app/pages/Contracts/lib/ApolloResp";
import { ContractProductFlyover } from "app/pages/Contracts/Pricing/ContractProductFlyover";

import { CustomerContractLayout } from "../CustomerContractLayout";
import { useAsyncCommitUsage } from "../Usage";
import { TopLevelDetails } from "./TopLevelDetails";
import { CommitsOverview } from "./CommitsOverview";
import { AdditionalTermsTable } from "../../../components/AdditionalTermsTable";
import { getTerms, getRowsFromTerms } from "./AdditionalTermRows";
import {
  DiscountTermFlyover,
  ProServiceTermFlyover,
  ResellerRoyaltyTermFlyover,
  ScheduledChargeTermFlyover,
} from "./ContractTermFlyover";

import {
  ArchiveContractDocument,
  ContractMetadataFragment,
  ContractOverviewQuery,
  DiscountTermFlyoverFragment,
  EditContractEndDateDocument,
  ProServiceTermFlyoverFragment,
  ScheduledChargeTermFlyoverFragment,
  useContractOverviewQuery,
} from "./data.graphql";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { EditContractEndDateFlyover } from "./EditContractEndDateFlyover";
import { ContractMetadataFlyover } from "./ContractMetadataFlyover";
import { ButtonDropdownItem } from "design-system/components/ButtonDropdown";
import { ArchiveContractModal } from "./ArchiveContractModal";
import { RateSchedulePanel } from "app/pages/Contracts/components/RateSchedulePanel";
import { describeOverride } from "app/pages/Contracts/lib/Override";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { ContractRatesDownloadModal } from "./ContractRatesDownloadModal";
import { useAuthCheck } from "app/lib/useAuthCheck";
import { AmendContractDocument } from "../Create/data.graphql";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { EditContractDocument } from "../Edit/data.graphql";
import { ContractRecurringCommits } from "../../../lib/RecurringCommit/ContractRecurringCommits";

export const CustomerContractOverviewPage: React.FC = () => {
  const now = useNow();
  const contractId = useRequiredParam("contractId");
  const customerId = useRequiredParam("customerId");
  const canEditContract = useFeatureFlag<boolean>(
    "allow-contract-editing",
    false,
  );
  const showAmendmentEndpoints = useFeatureFlag<boolean>(
    "show-amendment-endpoints",
    false,
  );
  const contractQuery = useContractOverviewQuery({
    variables: {
      contractId,
      customerId,
    },
  });
  const { refetch } = contractQuery;
  const req = useApolloResp(contractQuery);

  const [showArchiveContractModal, setShowArchiveContractModal] =
    useState(false);

  const [showDownloadRatesModal, setShowDownloadRatesModal] = useState(false);
  const [showEditContractEndDateFlyover, setShowEditContractEndDateFlyover] =
    useState(false);
  const [showContractMetadataFlyover, setShowContractMetadataFlyover] =
    useState(false);
  const routePath = Contract.getRoutePath({
    id: contractId,
    customer: { id: customerId },
  });
  const amendAllowed = useAuthCheck(AmendContractDocument, true).allowed;
  const editAllowed = useAuthCheck(EditContractDocument, true).allowed;
  const editEndDateAllowed = useAuthCheck(
    EditContractEndDateDocument,
    true,
  ).allowed;
  const archiveAllowed = useAuthCheck(ArchiveContractDocument, true).allowed;

  const dropdownItems: ButtonDropdownItem[] = [];
  if (req.state === "success") {
    dropdownItems.push({
      label: "View metadata...",
      onClick: () => {
        setShowContractMetadataFlyover(true);
      },
    });
    dropdownItems.push({
      label: "Manage custom fields...",
      routePath: `/custom-fields/contract/${contractId}`,
    });
    // TODO: do the disabled conditions still apply here?
    if (canEditContract) {
      dropdownItems.push({
        label: "Edit contract...",
        routePath: `${routePath}/edit`,
        disabled:
          !!(
            req.customer.contract?.ending_before &&
            toDayjs(req.customer.contract.ending_before).isSameOrBefore(now)
          ) || !editAllowed,
        disabledTooltip: !editAllowed
          ? "You are not authorized to perform this action"
          : undefined,
      });
    }

    if (showAmendmentEndpoints) {
      dropdownItems.push({
        label: "Add an amendment...",
        routePath: `${routePath}/amend`,
        disabled:
          !!(
            req.customer.contract?.ending_before &&
            toDayjs(req.customer.contract.ending_before).isSameOrBefore(now)
          ) ||
          !amendAllowed ||
          req.customer.contract?.has_edits,
        disabledTooltip: !amendAllowed
          ? "You are not authorized to perform this action"
          : req.customer.contract?.has_edits
            ? "This contract has already been edited"
            : undefined,
      });
    }
    dropdownItems.push({
      label: "Edit contract end date...",
      onClick: () => {
        setShowEditContractEndDateFlyover(true);
      },
      disabled: !editEndDateAllowed,
      disabledTooltip: "You are not authorized to perform this action",
    });
    dropdownItems.push({
      label: "Download rates...",
      onClick() {
        setShowDownloadRatesModal(true);
      },
    });
    if (!req.customer.contract?.archived_at) {
      const activeNextContract = req.customer.contract?.transitions.find(
        (transition) =>
          req.customer.contract?.id == transition.from_contract.id &&
          transition.to_contract.archived_at == null,
      )?.to_contract;
      dropdownItems.push({
        label: "Archive contract...",
        onClick: async () => {
          setShowArchiveContractModal(true);
        },
        disabled: activeNextContract != null || !archiveAllowed,
        disabledTooltip:
          archiveAllowed && activeNextContract
            ? `Transition contract ${activeNextContract.name} must be archived before this contract may be archived`
            : "You are not authorized to perform this action",
      });
    }
  }

  return (
    <CustomerContractLayout
      rootReq={req}
      tabsAction={
        <ButtonDropdown
          buttonTheme="gray"
          buttonType="fill"
          icon="ellipsisVertical"
          disabled={req.state !== "success"}
          items={dropdownItems}
          align="end"
        />
      }
      content={({ contract }) => (
        <>
          {showEditContractEndDateFlyover && (
            <EditContractEndDateFlyover
              onSuccess={() => {
                refetch().catch(console.error);
                setShowEditContractEndDateFlyover(false);
              }}
              onCancel={() => setShowEditContractEndDateFlyover(false)}
              contract={contract}
              customerId={customerId}
            />
          )}
          {showDownloadRatesModal && (
            <ContractRatesDownloadModal
              onComplete={() => {
                refetch().catch(console.error);
                setShowDownloadRatesModal(false);
              }}
              onCancel={() => setShowDownloadRatesModal(false)}
              onStartDownload={() => setShowDownloadRatesModal(false)}
              onError={() => {}}
              contractId={contract.id}
              customerId={customerId}
              at={now}
            />
          )}
          {showArchiveContractModal && (
            <ArchiveContractModal
              onSuccess={() => {
                setShowArchiveContractModal(false);
                refetch().catch(console.error);
              }}
              onCancel={() => setShowArchiveContractModal(false)}
              contract_id={contract.id}
              customer_id={customerId}
            ></ArchiveContractModal>
          )}
          <CustomerContractOverview
            contract={contract}
            customerId={customerId}
            showActivityList={!canEditContract}
            showAmendments
            showContractMetadataFlyover={showContractMetadataFlyover}
            setShowContractMetadataFlyover={setShowContractMetadataFlyover}
          />
        </>
      )}
    />
  );
};

export function CustomerContractOverview({
  contract,
  customerId,
  showContractMetadataFlyover = false,
  setShowContractMetadataFlyover = () => {},
  showActivityList = false,
  showAmendments = false,
  asOfDate = undefined,
}: {
  contract: Exclude<
    Exclude<ContractOverviewQuery["customer"], null>["contract"],
    null
  >;

  customerId: string;
  showContractMetadataFlyover?: boolean;
  setShowContractMetadataFlyover?: (show: boolean) => void;
  showActivityList?: boolean;
  showAmendments?: boolean;
  asOfDate?: Dayjs;
}) {
  const now = useNow();
  const usage = useAsyncCommitUsage({
    customerId,
    contractId: contract.id,
  });

  const [flyoverTarget, setFlyoverTarget] = React.useState<
    | { type: "product"; id: string }
    | { type: "discount"; term: DiscountTermFlyoverFragment }
    | { type: "scheduled_charge"; term: ScheduledChargeTermFlyoverFragment }
    | { type: "pro_service"; term: ProServiceTermFlyoverFragment }
    | { type: "reseller_royalty"; term: Contract.ResellerRoyaltyState }
    | { type: "contract"; term: ContractMetadataFragment }
  >();

  useEffect(() => {
    if (showContractMetadataFlyover) {
      setFlyoverTarget({ type: "contract", term: contract });
    } else {
      setFlyoverTarget(undefined);
    }
  }, [showContractMetadataFlyover]);

  const { commits, credits } = Commit.splitCommitsAndCredits(
    showAmendments
      ? contract.v2_fields?.commits_union ?? []
      : contract.v2_fields?.commits_union.filter((c) => !c.amendment_id) ?? [],
  );

  const termRows = getRowsFromTerms(
    getTerms(contract, now, { includeAmendments: showAmendments }),
    now,
    (term) => {
      switch (term.__typename) {
        case "Discount":
          setFlyoverTarget({ type: "discount", term });
          break;
        case "ScheduledCharge":
          setFlyoverTarget({ type: "scheduled_charge", term });
          break;
        case "ProService":
          setFlyoverTarget({ type: "pro_service", term });
          break;
        case "AWSRoyalty":
        case "AWSProServiceRoyalty":
        case "GCPRoyalty":
        case "GCPProServiceRoyalty":
          setFlyoverTarget({ type: "reseller_royalty", term });
          break;
        case "AWSRoyaltyUpdate":
        case "AWSProServiceRoyaltyUpdate":
        case "GCPRoyaltyUpdate":
        case "GCPProServiceRoyaltyUpdate":
          return;
        default:
          term satisfies never;
      }
    },
  );

  return (
    <div className="flex flex-col gap-y-4xl">
      {/* Flyover */}
      {((): React.ReactElement | null => {
        if (!flyoverTarget) {
          return null;
        }

        const onRequestClose = () => setFlyoverTarget(undefined);

        switch (flyoverTarget.type) {
          case "product":
            return (
              <ContractProductFlyover
                productId={flyoverTarget.id}
                onRequestClose={onRequestClose}
              />
            );
          case "discount":
            return (
              <DiscountTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "scheduled_charge":
            return (
              <ScheduledChargeTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "pro_service":
            return (
              <ProServiceTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "reseller_royalty":
            return (
              <ResellerRoyaltyTermFlyover
                term={flyoverTarget.term}
                onRequestClose={onRequestClose}
              />
            );
          case "contract":
            return (
              <ContractMetadataFlyover
                contract={flyoverTarget.term}
                onRequestClose={() => setShowContractMetadataFlyover(false)}
                contractName={Contract.getName(contract)}
              />
            );
        }
      })()}

      {!showActivityList && <TopLevelDetails contract={contract} />}

      {showActivityList && (
        <div className="mt-12 flex flex-row gap-12">
          <div className="flex w-3/4 flex-col gap-12">
            <TopLevelDetails contract={contract} />
          </div>

          <div className="w-1/4">
            <ActivityList
              loading={false}
              title="Recent lifecycle activity"
              subtitle="Activity across this contract"
              hideContractOrPlanName
              activity={Contract.getActivityItems(contract, now, true)}
              itemCount={3}
            />
          </div>
        </div>
      )}

      {commits.length > 0 && (
        <CommitsOverview
          commits={commits}
          asyncUsage={usage}
          contracts={[contract]}
          asOfDate={asOfDate}
        />
      )}

      {credits.length > 0 && (
        <CommitsOverview
          commits={credits}
          asyncUsage={usage}
          asCredit
          contracts={[contract]}
        />
      )}

      {contract.recurring_commits.length > 0 && (
        <ContractRecurringCommits contract={contract} />
      )}

      <AdditionalTermsTable rows={termRows} renderT10Table />

      {contract.rate_card && (
        <RateSchedulePanel
          rateCardId={contract.rate_card.id}
          title={contract.rate_card.name}
          startingAt={new Date(contract.starting_at)}
          endingBefore={
            contract.ending_before ? new Date(contract.ending_before) : null
          }
          onRowClick={(row) =>
            setFlyoverTarget({ type: "product", id: row.product.id })
          }
          overrides={(showAmendments
            ? contract.v2_fields?.overrides ?? []
            : contract.v2_fields?.overrides.filter((o) => !o.amendment_id) ?? []
          ).map((o) => describeOverride(now, o))}
          multiplierOverridePrioritization={
            contract.multiplier_override_prioritization ?? undefined
          }
          showOverridesTable
          renderT10Table
        />
      )}
    </div>
  );
}
