import React, { useContext } from "react";
import { CurrencyInput } from "app/pages/Offering/tabs/RateCards/CurrencyInput";
import {
  USD_CREDIT_TYPE,
  creditsFromInput,
  valueStringFromCredits,
} from "app/lib/credits";
import { CreditTypeContext } from "../Sections/Commits/CommitTakeover";
import { CreditType } from "app/types/credit-types";

export const CommitPriceInput: React.FC<{
  unitPrice?: number;
  creditType?: CreditType;
  onPriceChange: (price: number | undefined) => void;
  onCreditTypeChange: (creditType: CreditType) => void;
  disabled?: boolean;
  disableChangingCreditTypes?: boolean;
  isInvalid?: boolean;
  hintText?: string;
}> = (props) => {
  const creditTypeContext = useContext(CreditTypeContext);

  const additionalFiatCreditTypes = creditTypeContext.fiatCreditTypes.filter(
    (ct) => ct.id !== creditTypeContext.rateCardFiatCreditType.id,
  );

  const creditType = props.creditType ?? USD_CREDIT_TYPE;

  function onChange(price: number | undefined) {
    const convertedPrice = creditsFromInput(price, creditType).toNumber();
    props.onPriceChange(convertedPrice);
  }

  function convertUnitPrice(unitPrice: number | undefined) {
    return unitPrice
      ? Number(valueStringFromCredits(unitPrice, creditType.id))
      : undefined;
  }
  return (
    <CurrencyInput
      placeholder="100.00"
      price={convertUnitPrice(props.unitPrice)}
      onPriceChange={onChange}
      additionalFiatCreditTypes={additionalFiatCreditTypes}
      creditTypeConversions={creditTypeContext.creditTypeConversions}
      customCreditTypes={creditTypeContext.customCreditTypes}
      onCreditTypeChange={props.onCreditTypeChange}
      rateCardFiatCreditType={creditTypeContext.rateCardFiatCreditType}
      rateCreditType={creditType}
      allowEditCreditConversions={true}
      disabled={props.disabled}
      disableChangingCreditTypes={props.disableChangingCreditTypes ?? false}
      isInvalid={props.isInvalid}
      hintText={props.hintText}
    />
  );
};
