/* 0b0eee3bbf512aa3aa21f206dea25ec94c124010
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientBillingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ClientBillingsQuery = { __typename?: 'Query', client_overview: { __typename?: 'ClientOverview', total_billings: { __typename?: 'Billings', ending_before: string, results: Array<{ __typename?: 'Billings_Result', amount: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } }> } | null, current_period_billings: { __typename?: 'BillingsWithMoMChange', ending_before: string, results: Array<{ __typename?: 'BillingsWithMoMChange_Result', amount: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } }> } | null } };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type TopClientProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TopClientProductsQuery = { __typename?: 'Query', client_overview: { __typename?: 'ClientOverview', top_products: { __typename?: 'TopProducts', ending_before: string, results: Array<{ __typename?: 'TopProducts_Result', amount_billed: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, product: { __typename?: 'TopProducts_Result_Product', id: string, name: string | null }, month_over_month_change: { __typename?: 'MonthOverMonthChange', percent_change: string | null, previous_period_total_billed: string | null, previous_period_ending_before: string } }> } | null } };

export type TopClientPlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TopClientPlansQuery = { __typename?: 'Query', client_overview: { __typename?: 'ClientOverview', top_plans: { __typename?: 'TopPlans', ending_before: string, results: Array<{ __typename?: 'TopPlans_Result', amount_billed: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, plan: { __typename?: 'TopPlans_Result_Plan', id: string, name: string | null }, month_over_month_change: { __typename?: 'MonthOverMonthChange', percent_change: string | null, previous_period_total_billed: string | null, previous_period_ending_before: string } }> } | null } };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const ClientBillingsDocument = gql`
    query ClientBillings {
  client_overview {
    total_billings {
      ending_before
      results {
        credit_type {
          ...CreditType
          __environment_type: environment_type
        }
        amount
      }
    }
    current_period_billings {
      ending_before
      results {
        credit_type {
          ...CreditType
          __environment_type: environment_type
        }
        amount
      }
    }
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useClientBillingsQuery__
 *
 * To run a query within a React component, call `useClientBillingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientBillingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientBillingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientBillingsQuery(baseOptions?: Apollo.QueryHookOptions<ClientBillingsQuery, ClientBillingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientBillingsQuery, ClientBillingsQueryVariables>(ClientBillingsDocument, options);
      }
export function useClientBillingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientBillingsQuery, ClientBillingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientBillingsQuery, ClientBillingsQueryVariables>(ClientBillingsDocument, options);
        }
export type ClientBillingsQueryHookResult = ReturnType<typeof useClientBillingsQuery>;
export type ClientBillingsLazyQueryHookResult = ReturnType<typeof useClientBillingsLazyQuery>;
export type ClientBillingsQueryResult = Apollo.QueryResult<ClientBillingsQuery, ClientBillingsQueryVariables>;
export const TopClientProductsDocument = gql`
    query TopClientProducts {
  client_overview {
    top_products {
      results {
        credit_type {
          ...CreditType
          __environment_type: environment_type
        }
        product {
          id
          name
        }
        amount_billed
        month_over_month_change {
          percent_change
          previous_period_total_billed
          previous_period_ending_before
        }
      }
      ending_before
    }
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useTopClientProductsQuery__
 *
 * To run a query within a React component, call `useTopClientProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopClientProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopClientProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopClientProductsQuery(baseOptions?: Apollo.QueryHookOptions<TopClientProductsQuery, TopClientProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopClientProductsQuery, TopClientProductsQueryVariables>(TopClientProductsDocument, options);
      }
export function useTopClientProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopClientProductsQuery, TopClientProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopClientProductsQuery, TopClientProductsQueryVariables>(TopClientProductsDocument, options);
        }
export type TopClientProductsQueryHookResult = ReturnType<typeof useTopClientProductsQuery>;
export type TopClientProductsLazyQueryHookResult = ReturnType<typeof useTopClientProductsLazyQuery>;
export type TopClientProductsQueryResult = Apollo.QueryResult<TopClientProductsQuery, TopClientProductsQueryVariables>;
export const TopClientPlansDocument = gql`
    query TopClientPlans {
  client_overview {
    top_plans {
      results {
        credit_type {
          ...CreditType
          __environment_type: environment_type
        }
        plan {
          id
          name
        }
        amount_billed
        month_over_month_change {
          percent_change
          previous_period_total_billed
          previous_period_ending_before
        }
      }
      ending_before
    }
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useTopClientPlansQuery__
 *
 * To run a query within a React component, call `useTopClientPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopClientPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopClientPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopClientPlansQuery(baseOptions?: Apollo.QueryHookOptions<TopClientPlansQuery, TopClientPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopClientPlansQuery, TopClientPlansQueryVariables>(TopClientPlansDocument, options);
      }
export function useTopClientPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopClientPlansQuery, TopClientPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopClientPlansQuery, TopClientPlansQueryVariables>(TopClientPlansDocument, options);
        }
export type TopClientPlansQueryHookResult = ReturnType<typeof useTopClientPlansQuery>;
export type TopClientPlansLazyQueryHookResult = ReturnType<typeof useTopClientPlansLazyQuery>;
export type TopClientPlansQueryResult = Apollo.QueryResult<TopClientPlansQuery, TopClientPlansQueryVariables>;