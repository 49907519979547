/* f7467a0a1ee5174b6c2e03c9c191badab5dbe910
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillableMetricProductsQueryVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
}>;


export type BillableMetricProductsQuery = { __typename?: 'Query', BillableMetric: { __typename?: 'BillableMetric', id: string, products: Array<{ __typename?: 'Product', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, active_plan_count: number, updated_at: string, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null }>, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, Plan: { __typename?: 'Plan', id: string, name: string } }>, ProductPricingFactors_aggregate: { __typename?: 'ProductPricingFactor_aggregate', aggregate: { __typename?: 'ProductPricingFactor_aggregate_fields', count: number } | null } }> } | null };

export type ProductCardDetailsFragment = { __typename?: 'Product', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, active_plan_count: number, updated_at: string, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null }>, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, Plan: { __typename?: 'Plan', id: string, name: string } }>, ProductPricingFactors_aggregate: { __typename?: 'ProductPricingFactor_aggregate', aggregate: { __typename?: 'ProductPricingFactor_aggregate_fields', count: number } | null } };

export type SeatMetricProductsQueryVariables = Types.Exact<{
  seat_metric_id: Types.Scalars['uuid'];
}>;


export type SeatMetricProductsQuery = { __typename?: 'Query', seat_metric: { __typename?: 'SeatMetric', id: string, products: Array<{ __typename?: 'Product', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, active_plan_count: number, updated_at: string, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null }>, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, Plan: { __typename?: 'Plan', id: string, name: string } }>, ProductPricingFactors_aggregate: { __typename?: 'ProductPricingFactor_aggregate', aggregate: { __typename?: 'ProductPricingFactor_aggregate_fields', count: number } | null } }> } };

export const ProductCardDetailsFragmentDoc = gql`
    fragment ProductCardDetails on Product {
  id
  name
  description
  created_at
  deprecated_at
  active_plan_count
  updated_at
  Actor {
    name
    id
    deprecated_at
  }
  ProductPricingFactors {
    id
    name
    BillableMetric {
      id
      name
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
  PricedProducts {
    id
    Plan {
      id
      name
      __environment_type: environment_type
    }
  }
  ProductPricingFactors_aggregate {
    aggregate {
      count
    }
  }
  __environment_type: environment_type
}
    `;
export const BillableMetricProductsDocument = gql`
    query BillableMetricProducts($billable_metric_id: uuid!) {
  BillableMetric: BillableMetric_by_pk(id: $billable_metric_id) {
    id
    products(types: [ACTIVE]) {
      id
      ...ProductCardDetails
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    ${ProductCardDetailsFragmentDoc}`;

/**
 * __useBillableMetricProductsQuery__
 *
 * To run a query within a React component, call `useBillableMetricProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricProductsQuery({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *   },
 * });
 */
export function useBillableMetricProductsQuery(baseOptions: Apollo.QueryHookOptions<BillableMetricProductsQuery, BillableMetricProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricProductsQuery, BillableMetricProductsQueryVariables>(BillableMetricProductsDocument, options);
      }
export function useBillableMetricProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricProductsQuery, BillableMetricProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricProductsQuery, BillableMetricProductsQueryVariables>(BillableMetricProductsDocument, options);
        }
export type BillableMetricProductsQueryHookResult = ReturnType<typeof useBillableMetricProductsQuery>;
export type BillableMetricProductsLazyQueryHookResult = ReturnType<typeof useBillableMetricProductsLazyQuery>;
export type BillableMetricProductsQueryResult = Apollo.QueryResult<BillableMetricProductsQuery, BillableMetricProductsQueryVariables>;
export const SeatMetricProductsDocument = gql`
    query SeatMetricProducts($seat_metric_id: uuid!) {
  seat_metric(seat_metric_id: $seat_metric_id) {
    id
    products {
      id
      ...ProductCardDetails
      __environment_type: environment_type
    }
  }
}
    ${ProductCardDetailsFragmentDoc}`;

/**
 * __useSeatMetricProductsQuery__
 *
 * To run a query within a React component, call `useSeatMetricProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatMetricProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatMetricProductsQuery({
 *   variables: {
 *      seat_metric_id: // value for 'seat_metric_id'
 *   },
 * });
 */
export function useSeatMetricProductsQuery(baseOptions: Apollo.QueryHookOptions<SeatMetricProductsQuery, SeatMetricProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeatMetricProductsQuery, SeatMetricProductsQueryVariables>(SeatMetricProductsDocument, options);
      }
export function useSeatMetricProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeatMetricProductsQuery, SeatMetricProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeatMetricProductsQuery, SeatMetricProductsQueryVariables>(SeatMetricProductsDocument, options);
        }
export type SeatMetricProductsQueryHookResult = ReturnType<typeof useSeatMetricProductsQuery>;
export type SeatMetricProductsLazyQueryHookResult = ReturnType<typeof useSeatMetricProductsLazyQuery>;
export type SeatMetricProductsQueryResult = Apollo.QueryResult<SeatMetricProductsQuery, SeatMetricProductsQueryVariables>;