/* e0d3883fc67bf1455cad1dcc6c3f7526208ee43d
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCommitRateForCustomerQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetCommitRateForCustomerQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, contracts: Array<{ __typename?: 'Contract', id: string, archived_at: string | null, rate_card: { __typename?: 'RateCard', id: string, product_rates_metadata: Array<{ __typename?: 'ProductRatesMetadata', has_commit_rate: boolean }> } | null }> } | null };

export type GetCommitRateForRateCardQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetCommitRateForRateCardQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_card: { __typename?: 'RateCardMetadata', id: string, product_rates_metadata: Array<{ __typename?: 'ProductRatesMetadata', has_commit_rate: boolean }> } } };


export const GetCommitRateForCustomerDocument = gql`
    query GetCommitRateForCustomer($id: uuid!) {
  customer: Customer_by_pk(id: $id) {
    id
    contracts {
      id
      rate_card {
        id
        product_rates_metadata {
          has_commit_rate
        }
      }
      archived_at
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCommitRateForCustomerQuery__
 *
 * To run a query within a React component, call `useGetCommitRateForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommitRateForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommitRateForCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommitRateForCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>(GetCommitRateForCustomerDocument, options);
      }
export function useGetCommitRateForCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>(GetCommitRateForCustomerDocument, options);
        }
export type GetCommitRateForCustomerQueryHookResult = ReturnType<typeof useGetCommitRateForCustomerQuery>;
export type GetCommitRateForCustomerLazyQueryHookResult = ReturnType<typeof useGetCommitRateForCustomerLazyQuery>;
export type GetCommitRateForCustomerQueryResult = Apollo.QueryResult<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>;
export const GetCommitRateForRateCardDocument = gql`
    query GetCommitRateForRateCard($id: uuid!) {
  contract_pricing {
    rate_card(id: $id) {
      id
      product_rates_metadata {
        has_commit_rate
      }
    }
  }
}
    `;

/**
 * __useGetCommitRateForRateCardQuery__
 *
 * To run a query within a React component, call `useGetCommitRateForRateCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommitRateForRateCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommitRateForRateCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommitRateForRateCardQuery(baseOptions: Apollo.QueryHookOptions<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>(GetCommitRateForRateCardDocument, options);
      }
export function useGetCommitRateForRateCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>(GetCommitRateForRateCardDocument, options);
        }
export type GetCommitRateForRateCardQueryHookResult = ReturnType<typeof useGetCommitRateForRateCardQuery>;
export type GetCommitRateForRateCardLazyQueryHookResult = ReturnType<typeof useGetCommitRateForRateCardLazyQuery>;
export type GetCommitRateForRateCardQueryResult = Apollo.QueryResult<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>;