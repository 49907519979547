/* 272aad33451c540791e65249f28f2d14b82833ce
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductEditingInfoQueryVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
}>;


export type ProductEditingInfoQuery = { __typename?: 'Query', Product_by_pk: { __typename?: 'Product', id: string, name: string, description: string, group_key: string | null, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, ordering: number | null, charge_type_enum: Types.ChargeTypeEnum_Enum, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string, group_keys: object | null } | null }> } | null };

export type EditProductMutationVariables = Types.Exact<{
  product_id: Types.Scalars['uuid'];
  name: Types.Scalars['String'];
  description: Types.Scalars['String'];
  pricing_factors: Array<Types.PricingFactorInput> | Types.PricingFactorInput;
}>;


export type EditProductMutation = { __typename?: 'Mutation', edit_product: { __typename?: 'Product', id: string, name: string, description: string, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null }> } };


export const ProductEditingInfoDocument = gql`
    query ProductEditingInfo($product_id: uuid!) {
  Product_by_pk(id: $product_id) {
    id
    name
    description
    group_key
    ProductPricingFactors {
      id
      name
      ordering
      charge_type_enum
      BillableMetric {
        id
        name
        group_keys
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useProductEditingInfoQuery__
 *
 * To run a query within a React component, call `useProductEditingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductEditingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductEditingInfoQuery({
 *   variables: {
 *      product_id: // value for 'product_id'
 *   },
 * });
 */
export function useProductEditingInfoQuery(baseOptions: Apollo.QueryHookOptions<ProductEditingInfoQuery, ProductEditingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductEditingInfoQuery, ProductEditingInfoQueryVariables>(ProductEditingInfoDocument, options);
      }
export function useProductEditingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductEditingInfoQuery, ProductEditingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductEditingInfoQuery, ProductEditingInfoQueryVariables>(ProductEditingInfoDocument, options);
        }
export type ProductEditingInfoQueryHookResult = ReturnType<typeof useProductEditingInfoQuery>;
export type ProductEditingInfoLazyQueryHookResult = ReturnType<typeof useProductEditingInfoLazyQuery>;
export type ProductEditingInfoQueryResult = Apollo.QueryResult<ProductEditingInfoQuery, ProductEditingInfoQueryVariables>;
export const EditProductDocument = gql`
    mutation EditProduct($product_id: uuid!, $name: String!, $description: String!, $pricing_factors: [PricingFactorInput!]!) {
  edit_product(
    product_id: $product_id
    name: $name
    description: $description
    pricing_factors: $pricing_factors
  ) {
    id
    name
    description
    ProductPricingFactors {
      id
      name
      BillableMetric {
        id
        name
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;
export type EditProductMutationFn = Apollo.MutationFunction<EditProductMutation, EditProductMutationVariables>;

/**
 * __useEditProductMutation__
 *
 * To run a mutation, you first call `useEditProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductMutation, { data, loading, error }] = useEditProductMutation({
 *   variables: {
 *      product_id: // value for 'product_id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      pricing_factors: // value for 'pricing_factors'
 *   },
 * });
 */
export function useEditProductMutation(baseOptions?: Apollo.MutationHookOptions<EditProductMutation, EditProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProductMutation, EditProductMutationVariables>(EditProductDocument, options);
      }
export type EditProductMutationHookResult = ReturnType<typeof useEditProductMutation>;
export type EditProductMutationResult = Apollo.MutationResult<EditProductMutation>;
export type EditProductMutationOptions = Apollo.BaseMutationOptions<EditProductMutation, EditProductMutationVariables>;