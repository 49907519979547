import React, { useState } from "react";

import { Badge, Icon, Subtitle, Tooltip } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import { DeprecatedSimpleTable } from "components/deprecated/SimpleTable";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { dayjs } from "lib/dayjs";
import { useNow } from "lib/date";

import { renderDate } from "lib/time";
import { ContractUsageFilterHistoryTableFragment } from "./fragments.graphql";
import pluralize from "pluralize";

export const ContractUsageFilterHistoryTable: React.FC<{
  usageFilter: ContractUsageFilterHistoryTableFragment;
}> = ({ usageFilter }) => {
  const now = useNow();
  const [usageFilterHistoryPaneOpen, setUsageFilterHistoryPaneOpen] =
    useState<boolean>(false);

  const usageFilterHistory =
    usageFilter && usageFilter.initial
      ? [
          ...usageFilter.updates
            .map((update) => ({
              id: update.id,
              groupKey: update.group_key,
              groupValues: update.group_values,
              effectiveAt: update.effective_at,
            }))
            .reverse(),
          {
            id: usageFilter.initial.id,
            groupKey: usageFilter.initial.group_key,
            groupValues: usageFilter.initial.group_values,
            effectiveAt: usageFilter.initial.effective_at,
          },
        ]
      : [];

  // the update history is in reverse order, so the first item we
  // find that is in the past is the current usage filter
  const current = usageFilterHistory.find(
    (f) => f.effectiveAt === null || now.isSameOrAfter(f.effectiveAt),
  );

  return (
    <div className="flex items-center">
      <div>
        {current ? current.groupValues.length : "0"}{" "}
        {pluralize("value", current?.groupValues.length ?? 0)}
      </div>
      <div onClick={() => setUsageFilterHistoryPaneOpen(true)}>
        <Tooltip content="View history">
          <Icon
            icon="calendar"
            className="ml-4 h-[14px] w-[14px] cursor-pointer text-deprecated-primary-600"
          />
        </Tooltip>
      </div>
      <DeprecatedRightPane
        isOpen={usageFilterHistoryPaneOpen}
        onRequestClose={() => setUsageFilterHistoryPaneOpen(false)}
        size="lg"
      >
        <header className="mb-12 flex flex-row items-center">
          {/* left */}
          <div className="grow">
            <Subtitle className="font-medium text-gray-800">
              Usage filter history
            </Subtitle>
          </div>
          {/* right */}
          <div className="grow-0">
            <IconButton
              onClick={() => setUsageFilterHistoryPaneOpen(false)}
              theme="secondary"
              icon="xClose"
            />
          </div>
        </header>
        <DeprecatedSimpleTable
          className="rounded-xs border border-deprecated-gray-100 [&>table]:m-0 [&>table]:min-w-full"
          data={usageFilterHistory}
          columns={[
            {
              header: "Group key",
              render: (usageFilter) =>
                current && usageFilter.id === current.id ? (
                  <div>
                    {usageFilter.groupKey}
                    <Badge theme="primary" type="light" className="ml-8">
                      CURRENT
                    </Badge>
                  </div>
                ) : (
                  usageFilter.groupKey
                ),
            },
            {
              header: "Group values",
              alignment: "right",
              render: (usageFilter) => (
                <div className="flex flex-col">
                  {usageFilter.groupValues.map((value, i) => (
                    <DeprecatedCopyableID
                      id={value}
                      label="usage filter group value"
                      key={i}
                      className="ml-auto"
                    />
                  ))}
                </div>
              ),
            },
            {
              header: "Effective date (UTC)",
              alignment: "right",
              render: (usageFilter) =>
                usageFilter.effectiveAt
                  ? renderDate(dayjs(usageFilter.effectiveAt).toDate(), {
                      isUtc: true,
                      excludeUtcLabel: true,
                    })
                  : "--",
            },
          ]}
          noBottomBorder={true}
        />
      </DeprecatedRightPane>
    </div>
  );
};
