import React from "react";

import { FullScreenPage } from "components/AppShell";
import { Body, Headline, Subtitle, Icon, IconName } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedWizardFooter } from "components/deprecated/Wizard";
import { useNavigate } from "app/lib/useNavigate";
import { MetronomeLogo } from "components/MetronomeLogo";
import { DeprecatedInternalLink } from "components/deprecated/Typography";

type InterstitialProps = {
  onClose: () => void;
  onContinue: () => void;
  existingPlanName?: string;
};

interface PlanWizardInfoBoxProps {
  icon: IconName;
  title: string;
  body: string;
}
const PlanWizardInfoBox: React.FC<PlanWizardInfoBoxProps> = (props) => {
  return (
    <div className="mb-[28px] flex">
      <div className="flex h-[30px] w-[30px] items-center justify-center rounded-[30px] bg-deprecated-primary-500 text-white">
        <Icon icon={props.icon} />
      </div>
      <div className="ml-12 flex w-[420px] flex-col items-start">
        <Subtitle level={1}>{props.title}</Subtitle>
        <Body level={1} className="mt-8 text-left">
          {props.body}
        </Body>
      </div>
    </div>
  );
};
export const Interstitial: React.FC<InterstitialProps> = (props) => {
  const navigate = useNavigate();
  return (
    <FullScreenPage title="New plan">
      <div className="relative flex grow flex-col items-center justify-center bg-gray-50 text-center">
        <DeprecatedInternalLink
          routePath="/"
          className="absolute left-20 top-20"
        >
          <MetronomeLogo theme="greyscale" badgeOnly />
        </DeprecatedInternalLink>
        <IconButton
          onClick={props.onClose}
          className="absolute right-12 top-12"
          theme="secondary"
          icon="xClose"
        />
        <div className="my-24 flex h-[80px] w-[80px] items-center justify-center rounded-[40px] bg-deprecated-primary-500 text-2xl text-white">
          <Icon icon="receipt" />
        </div>
        <Headline level={4}>{`Welcome ${
          props.existingPlanName ? "back " : ""
        }to the Metronome plans creator`}</Headline>
        {props.existingPlanName ? (
          <Body className="mb-12 ml-12 mr-20 mt-8 max-w-[850px] text-deprecated-gray-700">
            Continue building <b>{props.existingPlanName}</b>.<br />
            We'll save your progress at each step, so you don’t have to worry
            about completing your new plan in one sitting.
          </Body>
        ) : (
          <>
            <Body className="mb-12 ml-12 mr-20 mt-8 max-w-[850px] text-deprecated-gray-700">
              A plan specifies what products a customer on that plan has access
              to, the prices that are paid, and the invoicing cadence. Once
              you've added a name and description, we’ll continually save your
              progress so you don’t have to worry about completing your new plan
              in one session.
            </Body>
            <hr className="mb-[42px] w-[100px] border-b border-b-black" />
            <PlanWizardInfoBox
              icon="documents"
              title="Set plan terms"
              body="Determine the plan’s length, how often customers are invoiced, and add any applicable pricing ramps."
            />
            <PlanWizardInfoBox
              icon="cart"
              title="Add products"
              body="View all your existing products and add the ones applicable to this plan."
            />
            <PlanWizardInfoBox
              icon="logoUsd"
              title="Price products"
              body="Add pricing to all the charges in each of your selected products."
            />
          </>
        )}
      </div>
      <DeprecatedWizardFooter
        onClickBack={() => navigate("/plans")}
        backLabel="Cancel"
        onClickContinue={props.onContinue}
        continueLabel="Continue"
      />
    </FullScreenPage>
  );
};
