import React from "react";
import { Schema } from "../../../CreateAndEdit/Schema";

import { Button } from "components/Button";
import { Pricing } from "../types";
import { Section } from "../../components/Section";
import { Commit } from "types/generated-graphql/__types__";
import { CommitsTable } from ".";
import { CreditType } from "app/types/credit-types";

interface Props {
  ctrl: Schema.Types.CreateSharedCtrl;
  pricing: Pricing;
  onAddCredit(): void;
  onEditCredit(id: string): void;
  creditTypes: CreditType[];
  commits?: Commit[];
}

export const CreditsSection: React.FC<Props> = (props) => {
  const credits = props.ctrl.get("credits") ?? [];

  return (
    <Section
      title="Credits"
      actions={
        <Button
          onClick={() => props.onAddCredit()}
          text="Add a credit"
          theme="primary"
          leadingIcon="plus"
        />
      }
      description="Add credit terms."
    >
      {credits.length === 0 ? (
        <div className="flex h-[120px] items-center justify-center rounded-medium border border-deprecated-gray-100 bg-gray-50 text-sm text-gray-600">
          No credits
        </div>
      ) : (
        <CommitsTable
          title="Credits"
          rateRows={credits}
          pricing={props.pricing}
          onSelectRow={(row) => props.onEditCredit(row.id)}
          asCredit
          creditTypes={props.creditTypes}
        />
      )}
    </Section>
  );
};
