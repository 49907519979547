import React from "react";
import { DateInput, NumericInput, Tooltip } from "design-system";

import { IconButton } from "components/IconButton";
import { Button } from "components/Button";

import { v4 as uuid } from "uuid";
import { DeprecatedCreditInput } from "components/deprecated/Input";

import { Schema } from "../../../CreateAndEdit/Schema";
import { RootCtrl, useRootCtrl } from "./RootCtrl";
import { InvoicePreview } from "./InvoicePreview";
import { DefaultTimeframe } from "../../../CreateAndEdit/lib/DefaultTimeframe";
import { upsertById } from "../../lib/upsertById";
import { DateSequence } from "../../lib/DateSequence";
import { useMode } from "./mode";
import { CreditType } from "app/types/credit-types";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";
import {
  CommitInvoiceScheduleItemEditability,
  CommitInvoiceScheduleItemRemovability,
  ScheduledChargeScheduleItemEditability,
} from "types/generated-graphql/__types__";
import {
  getEditabilityMessage,
  getRemovabilityMessage,
} from "../../../Edit/utils";

const useCustomScheduleCtrl = useRootCtrl.child(Schema.FixedSchedule, {
  read: (parent) => {
    const schedule = parent.get("schedule");
    return schedule?.type === "fixed" ? schedule : { type: "fixed" };
  },
  write: (child) => ({ schedule: child.getUnvalidatedInputs() }),
  debugName: "CustomSchedule",
});

export const CustomSchedule: React.FC<{
  parent: RootCtrl;
  creditType: CreditType;
  allowEmpty: boolean;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useCustomScheduleCtrl(props.parent);
  const items = ctrl.get("items") ?? [];
  const mode = useMode();
  const seq = DateSequence.create(
    items.map((i) => i.date),
    timeframe,
  );

  return (
    <>
      <div className="col-span-3 flex flex-col gap-12">
        {items.map((item, i) => {
          return (
            <DateSequence.Provider seq={seq} index={i} key={item.id ?? `${i}`}>
              <CustomScheduleItem
                id={item.id}
                parent={ctrl}
                creditType={props.creditType}
                onDelete={
                  items.length > (props.allowEmpty ? 0 : 1)
                    ? () =>
                        ctrl.update({
                          items: ctrl
                            .get("items")
                            ?.filter((i) => i.id !== item.id),
                        })
                    : undefined
                }
              />
            </DateSequence.Provider>
          );
        })}
      </div>
      <div>
        <Button
          onClick={() =>
            ctrl.update({
              items: [
                ...(ctrl.get("items") || []),
                {
                  id: uuid(),
                },
              ],
            })
          }
          text={
            mode === "invoice"
              ? "Add another segment"
              : "Add discount to another invoice"
          }
          theme="secondary"
          leadingIcon="plus"
        />
      </div>
      <div className="col-span-3">
        <InvoicePreview
          schedule={ctrl.getValid()}
          creditType={props.creditType}
        />
      </div>
    </>
  );
};

const useScheduleItemCtrl = useCustomScheduleCtrl.child(
  Schema.FixedScheduleItem,
  {
    read(parent, id: string, timeframe?: DefaultTimeframe) {
      const existing = parent.get("items")?.find((i) => i.id === id);

      return {
        ...existing,
        date: existing?.date ?? timeframe?.startingAt,
      };
    },
    write(child, parent) {
      return {
        items: upsertById(
          parent.get("items") || [],
          child.getUnvalidatedInputs(),
        ),
      };
    },
  },
);

interface ScheduleItemProps {
  parent: ReturnType<typeof useCustomScheduleCtrl>;
  id: string;
  creditType: CreditType;
  onDelete?: () => void;
}
const CustomScheduleItem: React.FC<ScheduleItemProps> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useScheduleItemCtrl(props.parent, props.id, timeframe);
  const mode = useMode();

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "unitPrice",
  );

  const editability =
    ctrl.get("editability") ?? CommitInvoiceScheduleItemEditability.Editable;
  const isEditable =
    editability === CommitInvoiceScheduleItemEditability.Editable ||
    editability === ScheduledChargeScheduleItemEditability.Editable;

  const removability =
    ctrl.get("removability") ?? CommitInvoiceScheduleItemRemovability.Removable;

  return (
    <div className="col-span-3 grid grid-cols-3 gap-12">
      <Tooltip
        content={getEditabilityMessage(editability)}
        disabled={isEditable}
      >
        <div className="w-full">
          <DateInput
            {...ctrl.props.DateInput("date", {
              name: mode === "invoice" ? "Invoice at" : "Apply to invoice on",
              disabled: !isEditable,
            })}
          />
        </div>
      </Tooltip>
      <Tooltip
        content={getEditabilityMessage(editability)}
        disabled={isEditable}
      >
        <div className="w-full">
          <NumericInput
            {...ctrl.props.NumericInput("quantity", {
              name: "Quantity",
              placeholder: "1",
              disabled: !isEditable,
            })}
          />
        </div>
      </Tooltip>
      <div className="flex gap-12">
        <div className="grow">
          <Tooltip
            content={getEditabilityMessage(editability)}
            disabled={isEditable}
          >
            <div className="w-full">
              <DeprecatedCreditInput
                {...ctrl.props.CreditInput("unitPrice", {
                  name: mode === "invoice" ? "Unit price" : "Discount",
                  placeholder: "123.45",
                  creditType: props.creditType,
                  allowZeroAmounts: true,
                  disabled: !isEditable,
                })}
              />
            </div>
          </Tooltip>
        </div>
        <Tooltip
          content={getRemovabilityMessage(removability)}
          disabled={
            removability === CommitInvoiceScheduleItemRemovability.Removable
          }
        >
          <IconButton
            className="mt-[18px]"
            onClick={props.onDelete}
            disabled={
              !props.onDelete ||
              removability !== CommitInvoiceScheduleItemRemovability.Removable
            }
            theme="tertiary"
            icon="xCircle"
          />
        </Tooltip>
      </div>
    </div>
  );
};
