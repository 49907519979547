import React from "react";
import classNames from "classnames";
import { Body, Subtitle } from "design-system";
import { printDate, Dayjs } from "lib/date";
import { DeprecatedInternalLink } from "components/deprecated/Typography";

import { useRoutePathMatches } from "app/lib/routes/useRoutePathMatches";
import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";

type LoadingActivityItem = {
  displayType: "loading";
};

export interface ActivityItem {
  id: string;
  type: "start" | "amendment" | "edit" | "end";
  displayType?: "primary" | "secondary" | "warning";
  time: Dayjs;
  /**
   * When specified, the plan/contract name will be displayed in the activity
   * otherwise the name is expected to be in implicit in the context of the list
   */
  contractOrPlanName?: string;
  content: string;
  routePath?: string;
  createdAt: Dayjs;
  amendmentId?: string; // Amendment ID for amendments upversioned to edits
}

interface Props {
  loading: boolean;
  title: string;
  subtitle?: string;
  activity: ActivityItem[];
  className?: string;
  /**
   * Defaults to 3
   */
  itemCount?: number;
  /**
   * When set to true, the name of the contract or plan will not be displayed
   * for the activity items
   */
  hideContractOrPlanName?: boolean;
}

export const ActivityItem: React.FC<{
  item: ActivityItem | LoadingActivityItem;
  hideContractOrPlanName?: boolean;
}> = ({ item, hideContractOrPlanName }) => {
  const routePath =
    "routePath" in item && item.routePath ? item.routePath : undefined;
  const urlMatches = useRoutePathMatches(routePath ? [routePath] : []);

  const props = { className: "flex" };
  const children = [
    <div
      className={classNames("min-h-[40px] w-4 grow-0 rounded-large", {
        "bg-deprecated-green-light":
          !item.displayType || item.displayType === "primary",
        "bg-deprecated-gray-lightest":
          item.displayType === "loading" || item.displayType === "secondary",
        "bg-warning-200": item.displayType === "warning",
      })}
    ></div>,
    <div className="ml-8 grow text-sm font-light leading-3">
      {item.displayType === "loading" ? (
        <div className="w-3/4">
          <DeprecatedTextSkeleton />
          <DeprecatedTextSkeleton />
          <DeprecatedTextSkeleton />
        </div>
      ) : (
        <>
          <Subtitle level={4} className="mb-4 font-semibold text-gray-800">
            {printDate(item.time)}
          </Subtitle>
          <Body level={2} className="mb-4 leading-1 text-gray-800">
            {item.content}
          </Body>
          {hideContractOrPlanName ? null : (
            <Body level={2} className="mb-2 leading-1 text-gray-800">
              {item.contractOrPlanName}
            </Body>
          )}
        </>
      )}
    </div>,
  ];

  return (
    <li className="mt-8 first:mt-0">
      {item.displayType !== "loading" && routePath && !urlMatches
        ? React.createElement(
            DeprecatedInternalLink,
            { ...props, routePath },
            ...children,
          )
        : React.createElement("div", props, ...children)}
    </li>
  );
};

export const ActivityList: React.FC<Props> = ({
  loading,
  title,
  subtitle,
  activity,
  className,
  itemCount = 3,
  hideContractOrPlanName,
}) => {
  const sortedActivity = React.useMemo(
    () => activity.slice(0, itemCount),
    [activity, itemCount],
  );

  return (
    <div className={className}>
      {loading ? (
        <>
          <DeprecatedTextSkeleton className="!mb-12 !w-[175px]" />
          {subtitle && (
            <DeprecatedTextSkeleton
              size="small"
              className="!mb-12 !w-[175px]"
            />
          )}
          <ol>
            <ActivityItem item={{ displayType: "loading" }} />
            <ActivityItem item={{ displayType: "loading" }} />
          </ol>
        </>
      ) : (
        <>
          <Subtitle level={1} className="font-semibold text-gray-800">
            {title}
          </Subtitle>
          {subtitle && (
            <Body level={2} className="mb-8 text-gray-800">
              {subtitle}
            </Body>
          )}
          <ol>
            {sortedActivity.map((item) => (
              <ActivityItem
                key={item.id}
                item={item}
                hideContractOrPlanName={hideContractOrPlanName}
              />
            ))}
          </ol>
        </>
      )}
    </div>
  );
};
