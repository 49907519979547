import { useSnackbar } from "components/deprecated/Snackbar";
import { reportToSentry } from "app/lib/errors/sentry";
import { useNavigate } from "app/lib/useNavigate";
import { useUpdateRateCardMutation } from "app/pages/Contracts/Pricing/CreateAndEditRateCard/data.graphql";
import { useGetRateCardProductCreditTypesQuery } from "./data.graphql";
import { RateCardContext } from "./RateCardContext";
import { RatesTable } from "./RatesTable";
import { formRatesAsGraphqlRates, getBadgeLabel } from "./utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "components/Button";
import { DropdownItem } from "components/Dropdown";
import { EmptyState } from "components/EmptyState";
import { TextInput } from "components/Input";
import { InputDropdown } from "components/InputDropdown";
import { SectionHeader } from "components/SectionHeader";
import { Takeover } from "components/Takeover";
import { useDocsLink } from "app/lib/docs-link";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";

function AddRatePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const docsLink = useDocsLink({
    contractsPath: "pricing-packaging/create-manage-rate-cards/",
  });

  const {
    loading,
    products,
    selectedProducts,
    setSelectedProducts,
    setDimensionalProductKeyValues,
    dimensionalProducts,
    rates,
    hasValidRateCardInput,
    clearSnapshot,
    name,
    editRate,
    newSubscriptions,
  } = RateCardContext.useContainer();
  const [productQuery, setProductQuery] = useState<string>("");
  const selectedProductToRate = useMemo(() => {
    if (selectedProducts.length === 1) {
      return products.find((p) => p.id === selectedProducts[0]) ?? null;
    } else {
      return null;
    }
  }, [selectedProducts, products]);

  const handleBack = useCallback(() => {
    clearSnapshot();
    navigate(`/offering/rate-cards/${id}`);
  }, [navigate, id, clearSnapshot]);

  const [updateRateCardMutation, updateResult] = useUpdateRateCardMutation();

  const pushMessage = useSnackbar();
  const onSubmit = async () => {
    try {
      const allRates = [
        ...rates.usageRates,
        ...rates.subscriptionRates,
        ...rates.compositeRates,
      ];
      const result = await updateRateCardMutation({
        variables: {
          rateCardId: id ?? "",
          name,
          additionalRates: formRatesAsGraphqlRates(allRates, newSubscriptions),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
          cache.evict({ fieldName: "contract_pricing" });
          cache.evict({ id: `RateCardMetadata:${id}` });
          cache.evict({ id: `RateCard:${id}` });
        },
      });
      const updatedId = result.data?.update_rate_card?.id;

      if (updatedId) {
        handleBack();
        pushMessage({
          type: "success",
          content: "Rate card updated",
        });
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to edit rate card: ${e}`,
        type: "error",
      });
    }
  };

  const dimensionalProductText = useMemo(() => {
    if (dimensionalProducts.length === 1) {
      const pricingGroupKeyValues =
        dimensionalProducts[0].pricingGroupKeyValues;
      if (pricingGroupKeyValues.length == 1) {
        return pricingGroupKeyValues[0].key;
      } else if (pricingGroupKeyValues.length === 2) {
        return `${pricingGroupKeyValues[0].key} and ${pricingGroupKeyValues[1].key}`;
      } else if (pricingGroupKeyValues.length > 2) {
        return `${pricingGroupKeyValues
          .slice(0, -1)
          .map((p) => p.key)
          .join(
            ", ",
          )}, and ${pricingGroupKeyValues[pricingGroupKeyValues.length - 1].key}`;
      }
    }

    return "";
  }, [dimensionalProducts]);

  /**
   * Current credit types on the rate card product.
   * if the product isnt on the rate card currently, clients can
   * select a credit type from the list of available credit types.
   * Else the credit type is locked to the current credit type for the product
   */
  const currentCreditTypes = useGetRateCardProductCreditTypesQuery({
    variables: {
      rateCardId: id ?? "",
      selectors: [
        {
          product_id: selectedProductToRate?.id,
        },
      ],
    },
    skip: !selectedProductToRate,
  });

  /**
   * Sets the default rate credit type to the current credit type on the product
   */
  useEffect(() => {
    if (currentCreditTypes.data) {
      const currentCreditType =
        currentCreditTypes.data.contract_pricing.rate_card.rate_schedule
          .credit_types_on_segments?.[0];

      if (
        rates.usageRates.length > 0 &&
        currentCreditType &&
        currentCreditType.id !== rates.usageRates[0].creditType?.id
      ) {
        editRate("usageRates", {
          ...rates.usageRates[0],
          creditType: currentCreditType,
        });
      }

      if (
        rates.subscriptionRates.length > 0 &&
        currentCreditType &&
        currentCreditType.id !== rates.subscriptionRates[0].creditType?.id
      ) {
        editRate("subscriptionRates", {
          ...rates.subscriptionRates[0],
          creditType: currentCreditType,
        });
      }
    }
  }, [currentCreditTypes.data, rates]);

  return (
    <Takeover
      isOpen={true}
      onClose={handleBack}
      title="Add a rate"
      maxContainerWidth="max-w-[1400px]"
      headerButtons={[
        <Button
          text="View docs"
          isExternalLink={true}
          linkTo={docsLink}
          theme="secondary"
          leadingIcon="share03"
        />,
      ]}
      footerLeadingButton={
        <Button text="Back" theme="secondary" onClick={handleBack} />
      }
      footerTrailingButtons={[
        <Button
          text="Save"
          theme="primary"
          disabled={!hasValidRateCardInput || updateResult.loading}
          loading={updateResult.loading}
          onClick={async () => {
            await onSubmit();
          }}
        />,
      ]}
    >
      <div className="flex flex-col gap-32">
        <SectionHeader
          title="Select a product to rate"
          subtitle="Rates define the prices you charge for your products. Rates can be changed on a schedule."
          bottomBorder={false}
        />
        {loading ? (
          <DeprecatedParagraphSkeleton numLines={3} />
        ) : (
          <InputDropdown
            disabled={loading}
            fullWidth
            hintText="Search for new products to add or existing ones to schedule a rate change"
            placeholder="Select a product"
            leadingIcon="searchSm"
            tagsVariant={false}
            value={selectedProductToRate?.current.name}
            onChangeText={(val) => {
              if (val.value === "") {
                // if the user has cleared the input, clear the selected products
                setSelectedProducts([]);
              }
              setProductQuery(val.value);
            }}
            onClearText={() => {
              setSelectedProducts([]);
            }}
            key="selectedProduct"
          >
            {(productQuery === ""
              ? products
              : products.filter((p) =>
                  p.current.name
                    .toLowerCase()
                    .includes(productQuery.toLowerCase()),
                )
            ).map((product) => (
              <DropdownItem
                label={product.current.name}
                key={product.id}
                selected={selectedProductToRate?.id === product.id}
                value={product.id}
                onClick={(data) => {
                  setSelectedProducts([product.id]);
                }}
                subtext={getBadgeLabel(product.__typename)}
              />
            ))}
          </InputDropdown>
        )}
        {!selectedProductToRate ? (
          <EmptyState
            className="mx-auto"
            icon="cube03"
            mainText="Search for a product to add to your rate card"
            supportingText="You’ll then be able to rate and preview it."
          />
        ) : (
          <>
            {dimensionalProducts.length > 0 && (
              <div className="flex flex-col gap-12">
                <SectionHeader
                  title="Specify dimensional pricing values for this rate"
                  subtitle={`Choose the specific values of ${dimensionalProductText} corresponding to the rate. These values can be new, or already exist on the rate card.`}
                  bottomBorder={false}
                />
                <div
                  key={selectedProductToRate.id}
                  className="flex min-w-[500px] flex-col gap-16 overflow-auto rounded-xl p-3xl shadow-sm"
                >
                  {dimensionalProducts[0].pricingGroupKeyValues.map((pair) => (
                    <div key={pair.key} className="flex flex-col gap-12">
                      <span className="text-gray-700 truncate text-sm font-semibold">
                        {pair.key}
                      </span>
                      <TextInput
                        fullWidth
                        value={pair.values[0]}
                        onChange={({ value }) => {
                          setDimensionalProductKeyValues([
                            {
                              productId: selectedProductToRate.id,
                              key: pair.key,
                              values: [value],
                            },
                          ]);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-col gap-3xl">
              <SectionHeader
                title="Rate your product"
                subtitle=""
                bottomBorder={false}
              />
              <RatesTable
                rates={
                  selectedProductToRate.__typename === "UsageProductListItem"
                    ? rates.usageRates
                    : selectedProductToRate.__typename ===
                        "SubscriptionProductListItem"
                      ? rates.subscriptionRates
                      : rates.compositeRates
                }
                title=""
                type={
                  selectedProductToRate.__typename === "UsageProductListItem"
                    ? "usageRates"
                    : selectedProductToRate.__typename ===
                        "SubscriptionProductListItem"
                      ? "subscriptionRates"
                      : "compositeRates"
                }
                allowEditCreditConversions={false}
                disableChangingCreditTypes={
                  (currentCreditTypes.data?.contract_pricing.rate_card
                    .rate_schedule.credit_types_on_segments?.length ?? 0) > 0
                }
              />
            </div>
          </>
        )}
      </div>
    </Takeover>
  );
}

export default function AddRatePageWrapper() {
  const { id } = useParams();
  return (
    <RateCardContext.Provider initialState={{ rateCardId: id }}>
      <AddRatePage />
    </RateCardContext.Provider>
  );
}
