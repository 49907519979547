import {
  ContractNameFragment,
  ContractRoutePathFragment,
  ContractStatusFragment,
  ContractActivityFragment,
  ContractResellerRoyaltiesFragment,
  ContractBillingProviderFragment,
  ContractTransitionsFragment,
} from "./fragments.graphql";

import { TransitionTypeEnum } from "types/generated-graphql/__types__";

import { ActivityItem } from "../../components/ActivityList";
import { printDate, toDayjs, Dayjs, distanceFrom } from "lib/date";
import { compact } from "../array";
import type { ContractOrPlan } from "../ContractOrPlan";
import { formatBillingProvider } from "app/lib/billingProvider/formatBillingProvider";
import { dayjs } from "lib/dayjs";

export type {
  ContractNameFragment as NameFragment,
  ContractRoutePathFragment as RoutePathFragment,
  ContractStatusFragment as StatusFragment,
  ContractActivityFragment as ActivityFragment,
  ContractBillingProviderFragment as BillingProviderFragment,
} from "./fragments.graphql";

export function makeName(
  startingAt: string,
  contractName: string | null,
  rateCard?: { name: string | null } | null,
): string {
  const date = printDate(toDayjs(startingAt));
  return contractName ?? `${rateCard?.name ?? "--"} (${date})`;
}

/**
 * Get the name of a contract. Defaults to the rate card name and includes the starting date of the contract
 */
export function getName(contract: ContractNameFragment): string {
  return makeName(contract.starting_at, contract.name, contract.rate_card);
}

/**
 * Get the "route path" for the contract, this path does not include the env type, that is
 * added automatically by elements that produce links.
 */
export function getRoutePath(contract: ContractRoutePathFragment): string {
  return `/customers/${contract.customer.id}/contracts/${contract.id}`;
}

/**
 * Get all the activity items for this contract.
 */
export function getActivityItems(
  contract: ContractActivityFragment,
  now: Dayjs,
  sortByRecency?: boolean,
): ActivityItem[] {
  const getEndString = () => {
    const transition = getTransition(contract);

    let endString = "Contract end";

    if (transition?.type === TransitionTypeEnum.Supersede) {
      endString += " (superseded)";
    } else if (transition?.type === TransitionTypeEnum.Renewal) {
      endString += " (renewed)";
    }

    return endString;
  };

  const startedId = "start";
  const endedId = "end";

  const activityItems = compact<ActivityItem>(
    {
      id: startedId,
      type: "start",
      displayType: "primary",
      time: toDayjs(contract.starting_at),
      routePath: `${getRoutePath(contract)}/lifecycle/${startedId}`,
      contractOrPlanName: getName(contract),
      content: "Contract start",
      createdAt: toDayjs(contract.created_at),
    },

    contract.edit_history.map((a): ActivityItem => {
      return {
        id: a.id,
        type: a.amendment_id ? "amendment" : "edit",
        displayType: "primary",
        time: toDayjs(a.timestamp),
        routePath: `${getRoutePath(contract)}/lifecycle/${a.id}`,
        contractOrPlanName: getName(contract),
        content: a.amendment_id ? "Contract amendment" : "Contract edit",
        createdAt: toDayjs(a.timestamp),
        amendmentId: a.amendment_id ?? undefined,
      };
    }),

    contract.ending_before && now.isSameOrAfter(contract.ending_before)
      ? {
          id: endedId,
          type: "end",
          displayType: "warning",
          time: toDayjs(contract.ending_before),
          routePath: `${getRoutePath(contract)}/lifecycle/${endedId}`,
          contractOrPlanName: getName(contract),
          content: getEndString(),
          createdAt: toDayjs(contract.ending_before),
        }
      : null,
  );

  if (sortByRecency) {
    return activityItems.sort((a, b) => {
      const timeDiff = b.time.diff(a.time);
      if (
        timeDiff !== 0 ||
        a.createdAt === undefined ||
        b.createdAt === undefined
      ) {
        return timeDiff;
      }
      return b.createdAt.diff(a.createdAt);
    });
  }

  return activityItems;
}

/**
 * Describe the status of a contract at a specific time.
 */
export function getStatus(
  contract: ContractStatusFragment,
  now: Dayjs,
): ContractOrPlan.Status {
  if (contract.archived_at) {
    return "archived";
  }
  const start = distanceFrom(now, toDayjs(contract.starting_at));
  const end = contract.ending_before
    ? distanceFrom(now, toDayjs(contract.ending_before))
    : null;

  if (!start.isInPast) {
    return start.distDays >= 30 ? "upcoming" : "active-soon";
  }

  if (end?.isInPast) {
    const transitionOut = getTransition(contract);
    if (transitionOut !== undefined) {
      return transitionOut.type === TransitionTypeEnum.Supersede
        ? "inactive (superseded)"
        : "inactive (renewed)";
    } else {
      return end.distDays <= 7 ? "recently-ended" : "ended";
    }
  }

  return end && end?.distDays <= 30 ? "ending-soon" : "active";
}

export type ResellerRoyaltyState =
  ContractResellerRoyaltiesFragment["reseller_royalties"][0];

/**
 * Get the current state of a contract's reseller royalties.
 */
export function getResellerRoyaltiesStates(
  contract: ContractResellerRoyaltiesFragment,
  showAmendments: boolean,
  now: Dayjs,
): ResellerRoyaltyState[] {
  const resellerRoyaltiesSchedule =
    contract.v2_fields?.reseller_royalties_schedule;
  const currentFromSchedule = new Map<string, ResellerRoyaltyState>();

  for (const rr of resellerRoyaltiesSchedule ?? []) {
    const currentSegment = rr.segments.find(
      (s) =>
        toDayjs(s.starting_at).isSameOrBefore(now) &&
        (!s.ending_before || toDayjs(s.ending_before).isAfter(now)),
    );
    if (currentSegment) {
      currentFromSchedule.set(rr.type, currentSegment);
    }
  }
  return Array.from(currentFromSchedule.values());
}

export function getBillingProvider(contract: ContractBillingProviderFragment) {
  return formatBillingProvider(
    contract?.customer_billing_provider_configuration?.billing_provider,
  );
}

export function getTransition(contract: ContractTransitionsFragment) {
  return contract.transitions.find(
    (t) => t.from_contract.id === contract.id && !t.to_contract.archived_at,
  );
}

type ContractDates = {
  startingAt: string;
  endingBefore: string | null;
};
export function doContractsOverlap(
  firstContractDates: ContractDates,
  secondContractDates: ContractDates,
): boolean {
  const newStart = dayjs.utc(firstContractDates.startingAt);
  const newEnd = firstContractDates.endingBefore
    ? dayjs.utc(firstContractDates.endingBefore)
    : null;

  const existingStart = dayjs.utc(secondContractDates.startingAt);
  const existingEnd = secondContractDates.endingBefore
    ? dayjs.utc(secondContractDates.endingBefore)
    : null;

  // This overlap calculation may seem deceptively simple, given that a contract's end date can be null.
  // The logic works by leveraging the behavior of dayjs.utc(null), which returns the current time instead
  // of throwing an error. When an end date is null, we can disregard the result of the isBefore check,
  // as it is effectively handled by the === null check.
  return (
    (newStart.isBefore(existingEnd) || existingEnd === null) &&
    (existingStart.isBefore(newEnd) || newEnd === null)
  );
}
