/* a53ea1200b30904a0a8cccf6546b3cd7e6df8ad1
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListCustomerQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  order_by: Array<Types.Customer_Order_By> | Types.Customer_Order_By;
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
  filter?: Types.InputMaybe<Types.Customer_Bool_Exp>;
}>;


export type ListCustomerQuery = { __typename?: 'Query', totalNonArchivedCustomers: Array<{ __typename?: 'Client', id: string, customer_count: { __typename?: 'CustomerCount', count: number, estimated: boolean } }>, totalArchivedCustomers: Array<{ __typename?: 'Client', id: string, customer_count: { __typename?: 'CustomerCount', count: number, estimated: boolean } }>, Customer: Array<{ __typename?: 'Customer', name: string, id: string, archived_at: string | null, created_at: string, contract_status: Array<{ __typename?: 'CustomerContractStatus', starting_at: string, ending_before: string | null, archived_at: string | null, transition_type: string | null }>, plan_status: Array<{ __typename?: 'CustomerPlanStatus', starting_at: string, ending_before: string | null }> }> };

export type CustomerRoutePathFragment = { __typename?: 'Customer', id: string };

export type CustomerStatusFragment = { __typename?: 'Customer', id: string, archived_at: string | null, created_at: string, contract_status: Array<{ __typename?: 'CustomerContractStatus', starting_at: string, ending_before: string | null, archived_at: string | null, transition_type: string | null }>, plan_status: Array<{ __typename?: 'CustomerPlanStatus', starting_at: string, ending_before: string | null }> };

export type CustomerListContractStatusFragment = { __typename?: 'CustomerContractStatus', starting_at: string, ending_before: string | null, archived_at: string | null, transition_type: string | null };

export type CustomerListPlanStatusFragment = { __typename?: 'CustomerPlanStatus', starting_at: string, ending_before: string | null };

export type SearchCustomersQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  query: Types.Scalars['String'];
  archived: Types.ArchivedFilter;
}>;


export type SearchCustomersQuery = { __typename?: 'Query', searchCustomers: Array<{ __typename?: 'Customer', name: string, id: string, archived_at: string | null, created_at: string, contract_status: Array<{ __typename?: 'CustomerContractStatus', starting_at: string, ending_before: string | null, archived_at: string | null, transition_type: string | null }>, plan_status: Array<{ __typename?: 'CustomerPlanStatus', starting_at: string, ending_before: string | null }> }> };

export type CustomerTotalBillingsQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type CustomerTotalBillingsQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, revenue_overview: { __typename?: 'CustomerRevenueOverview', total_billings: { __typename?: 'Billings', results: Array<{ __typename?: 'Billings_Result', amount: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } }> } } } | null };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export const CustomerRoutePathFragmentDoc = gql`
    fragment CustomerRoutePath on Customer {
  id
  __environment_type: environment_type
}
    `;
export const CustomerListContractStatusFragmentDoc = gql`
    fragment CustomerListContractStatus on CustomerContractStatus {
  starting_at
  ending_before
  archived_at
  transition_type
}
    `;
export const CustomerListPlanStatusFragmentDoc = gql`
    fragment CustomerListPlanStatus on CustomerPlanStatus {
  starting_at
  ending_before
}
    `;
export const CustomerStatusFragmentDoc = gql`
    fragment CustomerStatus on Customer {
  id
  archived_at
  created_at
  contract_status {
    ...CustomerListContractStatus
  }
  plan_status {
    ...CustomerListPlanStatus
  }
  __environment_type: environment_type
}
    ${CustomerListContractStatusFragmentDoc}
${CustomerListPlanStatusFragmentDoc}`;
export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const ListCustomerDocument = gql`
    query ListCustomer($environment_type: EnvironmentTypeEnum_enum!, $order_by: [Customer_order_by!]!, $offset: Int!, $limit: Int!, $filter: Customer_bool_exp) {
  totalNonArchivedCustomers: Client {
    id
    customer_count(environment_type: $environment_type, is_archived: false) {
      count
      estimated
    }
  }
  totalArchivedCustomers: Client {
    id
    customer_count(environment_type: $environment_type, is_archived: true) {
      count
      estimated
    }
  }
  Customer(limit: $limit, offset: $offset, order_by: $order_by, where: $filter) {
    name
    ...CustomerRoutePath
    ...CustomerStatus
    __environment_type: environment_type
  }
}
    ${CustomerRoutePathFragmentDoc}
${CustomerStatusFragmentDoc}`;

/**
 * __useListCustomerQuery__
 *
 * To run a query within a React component, call `useListCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCustomerQuery(baseOptions: Apollo.QueryHookOptions<ListCustomerQuery, ListCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCustomerQuery, ListCustomerQueryVariables>(ListCustomerDocument, options);
      }
export function useListCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCustomerQuery, ListCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCustomerQuery, ListCustomerQueryVariables>(ListCustomerDocument, options);
        }
export type ListCustomerQueryHookResult = ReturnType<typeof useListCustomerQuery>;
export type ListCustomerLazyQueryHookResult = ReturnType<typeof useListCustomerLazyQuery>;
export type ListCustomerQueryResult = Apollo.QueryResult<ListCustomerQuery, ListCustomerQueryVariables>;
export const SearchCustomersDocument = gql`
    query SearchCustomers($environment_type: EnvironmentTypeEnum_enum!, $query: String!, $archived: ArchivedFilter!) {
  searchCustomers(
    environment_type: $environment_type
    query: $query
    archived: $archived
  ) {
    name
    ...CustomerRoutePath
    ...CustomerStatus
    __environment_type: environment_type
  }
}
    ${CustomerRoutePathFragmentDoc}
${CustomerStatusFragmentDoc}`;

/**
 * __useSearchCustomersQuery__
 *
 * To run a query within a React component, call `useSearchCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCustomersQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      query: // value for 'query'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useSearchCustomersQuery(baseOptions: Apollo.QueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
      }
export function useSearchCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCustomersQuery, SearchCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCustomersQuery, SearchCustomersQueryVariables>(SearchCustomersDocument, options);
        }
export type SearchCustomersQueryHookResult = ReturnType<typeof useSearchCustomersQuery>;
export type SearchCustomersLazyQueryHookResult = ReturnType<typeof useSearchCustomersLazyQuery>;
export type SearchCustomersQueryResult = Apollo.QueryResult<SearchCustomersQuery, SearchCustomersQueryVariables>;
export const CustomerTotalBillingsDocument = gql`
    query CustomerTotalBillings($customer_id: uuid!) {
  Customer_by_pk(id: $customer_id) {
    id
    revenue_overview {
      total_billings {
        results {
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
          amount
        }
      }
    }
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useCustomerTotalBillingsQuery__
 *
 * To run a query within a React component, call `useCustomerTotalBillingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTotalBillingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTotalBillingsQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useCustomerTotalBillingsQuery(baseOptions: Apollo.QueryHookOptions<CustomerTotalBillingsQuery, CustomerTotalBillingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerTotalBillingsQuery, CustomerTotalBillingsQueryVariables>(CustomerTotalBillingsDocument, options);
      }
export function useCustomerTotalBillingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerTotalBillingsQuery, CustomerTotalBillingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerTotalBillingsQuery, CustomerTotalBillingsQueryVariables>(CustomerTotalBillingsDocument, options);
        }
export type CustomerTotalBillingsQueryHookResult = ReturnType<typeof useCustomerTotalBillingsQuery>;
export type CustomerTotalBillingsLazyQueryHookResult = ReturnType<typeof useCustomerTotalBillingsLazyQuery>;
export type CustomerTotalBillingsQueryResult = Apollo.QueryResult<CustomerTotalBillingsQuery, CustomerTotalBillingsQueryVariables>;