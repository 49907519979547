import React from "react";
import { Input } from "design-system";
import { Schema } from "../../CreateAndEdit/Schema";

interface Props {
  ctrl: Schema.Types.CreateSharedCtrl;
  options?: {
    salesforceEnabled?: boolean;
    netsuiteEnabled?: boolean;
  };
}

export const getIntegrationsDescription = (options?: {
  salesforceEnabled?: boolean;
  netsuiteEnabled?: boolean;
}) => {
  const { salesforceEnabled, netsuiteEnabled } = options ?? {};
  if (!salesforceEnabled && !netsuiteEnabled) {
    return "";
  }

  if (salesforceEnabled && netsuiteEnabled) {
    return "Connect to Salesforce and NetSuite.";
  }

  if (salesforceEnabled) {
    return "Connect to Salesforce.";
  }
  if (netsuiteEnabled) {
    return "Connect to NetSuite.";
  }
  return "";
};

export const ContractIntegrations: React.FC<Props> = (props) => {
  const { salesforceEnabled, netsuiteEnabled } = props.options ?? {};
  return (
    <>
      {salesforceEnabled && (
        <Input
          {...props.ctrl.props.Input("salesforceOpportunityId", {
            name: "Salesforce Opportunity ID (optional)",
            placeholder: "Enter ID",
          })}
        />
      )}
      {netsuiteEnabled && (
        <Input
          {...props.ctrl.props.Input("netsuiteSalesOrderId", {
            name: "NetSuite Sales Order ID (optional)",
            placeholder: "Enter ID",
          })}
        />
      )}
    </>
  );
};
