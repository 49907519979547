import React from "react";

import { OverrideFlyover } from "../../Sections/Overrides";
import {
  ScheduledChargeFlyover,
  ResellerRoyaltyFlyover,
  DiscountFlyover,
} from "../../Sections/AdditionalTerms";
import { CommitFlyover } from "../../Sections/Commits";
import { upsertById } from "../upsertById";

import { Schema } from "../../../CreateAndEdit/Schema";
import { ViewOverridesFlyover } from "../../Sections/Overrides/ViewOverrideFlyover";
import {
  DescribeFragment,
  describeOverride,
} from "app/pages/Contracts/lib/Override";
import { useNow } from "lib/date";
import { describeNewOverride } from "../Override";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
import { ProServiceFlyover } from "../../Sections/AdditionalTerms/ProServiceFlyover";
import { CreditType } from "app/types/credit-types";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { CommitTakeover } from "../../../CreateAndEdit/Sections/Commits/CommitTakeover";

type FlyoverObjType =
  | "override"
  | "commit"
  | "credit"
  | "recurring_commit"
  | "recurring_credit"
  | Schema.Types.AdditionalTermType;
type TermKey =
  | "commits"
  | "credits"
  | "recurringCommits"
  | "recurringCredits"
  | "overrides"
  | "scheduledCharges"
  | "discounts"
  | "resellerRoyalties"
  | "proServices";

export type Flyover =
  | {
      type: `new_override`;
      productId: string | undefined;
      creditType: CreditType | undefined;
      rateCardId: string | undefined;
    }
  | {
      type: `new_${Extract<FlyoverObjType, "commit" | "credit">}`;
      fiatCreditTypes: CreditType[];
      customCreditTypes: CreditType[];
      rateCardId: string | undefined;
    }
  | {
      type: `new_${Extract<FlyoverObjType, "scheduled_charge" | "discount">}`;
      fiatCreditTypes: CreditType[];
    }
  | {
      type: `new_${Exclude<FlyoverObjType, "override" | "commit" | "recurring_commit" | "recurring_credit" | "credit" | "scheduled_charge" | "discount">}`;
    }
  | {
      type: `edit_${Extract<FlyoverObjType, "commit" | "credit" | "recurring_commit" | "recurring_credit">}`;
      id: string;
      fiatCreditTypes: CreditType[];
      customCreditTypes: CreditType[];
      rateCardId: string | undefined;
    }
  | {
      type: `edit_${Extract<FlyoverObjType, "scheduled_charge" | "discount">}`;
      id: string;
      fiatCreditTypes: CreditType[];
    }
  | {
      type: `edit_${Extract<FlyoverObjType, "override">}`;
      id: string;
      creditType: CreditType | undefined;
      rateCardId: string | undefined;
    }
  | {
      type: `edit_${Exclude<FlyoverObjType, "commit" | "recurring_commit" | "override" | "credit" | "recurring_credit" | "scheduled_charge" | "discount">}`;
      id: string;
      creditType?: CreditType;
    }
  | { type: "view_overrides"; rateCardId: string | undefined };

interface Props {
  ctrl: Schema.Types.CreateSharedCtrl;
  allProducts: Array<
    ProductListItem.IdFragment &
      ProductListItem.NameFragment &
      ProductListItem.TypeFragment
  >;
  contract?: {
    starting_at: string | null;
    ending_before: string | null;
    multiplier_override_prioritization: string | null;
    overrides?: DescribeFragment[];
    amendments?: Array<{
      overrides: DescribeFragment[];
    }>;
    v2_fields?: {
      overrides: DescribeFragment[];
    };
  };
  options?: {
    netsuiteEnabled?: boolean;
    level?: "contract" | "customer";
  };
}

export function useFlyovers({ ctrl, allProducts, contract, options }: Props): {
  flyoverElement: React.ReactNode | null;
  setFlyover: (flyover: Flyover | undefined) => void;
  closeFlyover: () => void;
} {
  const [flyover, setFlyover] = React.useState<Flyover>();
  const closeFlyover = () => setFlyover(undefined);
  const now = useNow();
  const useNewCommitUi =
    useFeatureFlag<boolean>("use-new-commit-ui", false) || false;
  function termSaveAndCloseHandler<K extends TermKey>(key: K) {
    return (
      update: NonNullable<Schema.Types.CreateContractInput[typeof key]>[number],
    ) => {
      const current: any[] = ctrl.get(key) ?? [];
      ctrl.update({
        [key]: Array.isArray(current) ? upsertById(current, update) : [update],
      });
      setFlyover(undefined);
    };
  }

  function termSaveHandler<K extends TermKey>(key: K) {
    return (
      update: NonNullable<Schema.Types.CreateContractInput[typeof key]>[number],
    ) => {
      const current: any[] = ctrl.get(key) ?? [];
      ctrl.update({
        [key]: Array.isArray(current) ? upsertById(current, update) : [update],
      });
    };
  }

  function termDeleteHandler<K extends TermKey>(key: K, id: string) {
    return () => {
      ctrl.update({
        [key]: (ctrl.get(key) ?? []).filter((t) =>
          key === "resellerRoyalties" && "rateType" in t
            ? t.rateType !== id
            : t.id !== id,
        ),
      });
      setFlyover(undefined);
    };
  }

  const flyoverElement = ((): React.ReactElement | null => {
    switch (flyover?.type) {
      case "new_override":
        return !flyover.rateCardId || !contract ? null : (
          <OverrideFlyover
            contract={contract}
            overrides={[
              ("v2_fields" in contract
                ? contract.v2_fields?.overrides ?? []
                : contract.overrides ?? []
              ).map((o) => describeOverride(now, o)),
              ("v2_fields" in contract
                ? []
                : contract.amendments ?? []
              ).flatMap((a) =>
                a.overrides.map((o) => describeOverride(now, o)),
              ),
              (ctrl.get("overrides") ?? []).map((o) =>
                describeNewOverride(now, allProducts, o),
              ),
            ].flat()}
            rateCardId={flyover.rateCardId}
            defaultProductId={flyover.productId}
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("overrides")}
            creditType={flyover.creditType}
            newCommits={ctrl?.get("commits") ?? []}
          />
        );
      case "edit_override":
        return !flyover.rateCardId || !contract ? null : (
          <OverrideFlyover
            contract={contract}
            overrides={[
              ("v2_fields" in contract
                ? contract.v2_fields?.overrides ?? []
                : contract.overrides ?? []
              ).map((o) => describeOverride(now, o)),
              ("v2_fields" in contract
                ? []
                : contract.amendments ?? []
              ).flatMap((a) =>
                a.overrides.map((o) => describeOverride(now, o)),
              ),
              (ctrl.get("overrides") ?? []).map((o) =>
                describeNewOverride(now, allProducts, o),
              ),
            ].flat()}
            rateCardId={flyover.rateCardId}
            edit={(ctrl.get("overrides") ?? []).find(
              (o) => o.id === flyover.id,
            )}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("overrides", flyover.id)}
            onSave={termSaveAndCloseHandler("overrides")}
            creditType={flyover.creditType}
            newCommits={ctrl?.get("commits") ?? []}
          />
        );
      case "view_overrides":
        return !flyover.rateCardId ? null : (
          <ViewOverridesFlyover
            rateCardId={flyover.rateCardId}
            overrides={ctrl.get("overrides") ?? []}
            onRequestClose={closeFlyover}
          />
        );

      case "new_scheduled_charge":
        return (
          <ScheduledChargeFlyover
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("scheduledCharges")}
            fiatCreditTypes={flyover.fiatCreditTypes}
          />
        );
      case "edit_scheduled_charge":
        const isExistingScheduledCharge =
          ctrl.get("existingScheduledChargeIds")?.includes(flyover.id) ?? false;
        return (
          <ScheduledChargeFlyover
            edit={(ctrl.get("scheduledCharges") ?? []).find(
              (o) => o.id === flyover.id,
            )}
            onCancel={closeFlyover}
            onDelete={
              isExistingScheduledCharge
                ? undefined
                : termDeleteHandler("scheduledCharges", flyover.id)
            }
            onSave={termSaveAndCloseHandler("scheduledCharges")}
            fiatCreditTypes={flyover.fiatCreditTypes}
            isExistingScheduledCharge={isExistingScheduledCharge}
          />
        );

      case "new_reseller_royalty":
        return (
          <ResellerRoyaltyFlyover
            resellerRoyalties={ctrl.get("resellerRoyalties")}
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("resellerRoyalties")}
          />
        );
      case "edit_reseller_royalty":
        return (
          <ResellerRoyaltyFlyover
            edit={(ctrl.get("resellerRoyalties") ?? []).find(
              (rr) => rr.type === flyover.id,
            )}
            resellerRoyalties={ctrl.get("resellerRoyalties")}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("resellerRoyalties", flyover.id)}
            onSave={termSaveAndCloseHandler("resellerRoyalties")}
          />
        );

      case "new_commit":
        if (useNewCommitUi) {
          return (
            <CommitTakeover
              edit={undefined}
              recurringCommitEdit={undefined}
              onClose={closeFlyover}
              onSave={termSaveHandler("commits")}
              onRecurringSave={termSaveAndCloseHandler("recurringCommits")}
              options={options}
              fiatCreditTypes={flyover.fiatCreditTypes}
              customCreditTypes={flyover.customCreditTypes}
              defaultCreditType={flyover.fiatCreditTypes[0]}
              rateCardId={flyover.rateCardId}
              isExistingCommit={false}
              isOpen={true}
            />
          );
        }
        return (
          <CommitFlyover
            edit={undefined}
            onClose={closeFlyover}
            onSave={termSaveHandler("commits")}
            options={options}
            fiatCreditTypes={flyover.fiatCreditTypes}
            customCreditTypes={flyover.customCreditTypes}
            defaultCreditType={flyover.fiatCreditTypes[0]}
            rateCardId={flyover.rateCardId}
            isExistingCommit={false}
          />
        );
      case "edit_commit":
        const isExistingCommit =
          ctrl.get("existingCommitIds")?.includes(flyover.id) ?? false;
        if (useNewCommitUi) {
          return (
            <CommitTakeover
              edit={(ctrl.get("commits") ?? []).find(
                (c) => c.id === flyover.id,
              )}
              recurringCommitEdit={undefined}
              onClose={closeFlyover}
              onDelete={
                isExistingCommit
                  ? undefined
                  : termDeleteHandler("commits", flyover.id)
              }
              onSave={termSaveAndCloseHandler("commits")}
              options={options}
              fiatCreditTypes={flyover.fiatCreditTypes}
              customCreditTypes={flyover.customCreditTypes}
              defaultCreditType={flyover.fiatCreditTypes[0]}
              rateCardId={flyover.rateCardId}
              isExistingCommit={isExistingCommit}
              isOpen={true}
            />
          );
        }
        return (
          <CommitFlyover
            edit={(ctrl.get("commits") ?? []).find((c) => c.id === flyover.id)}
            onClose={closeFlyover}
            onDelete={
              isExistingCommit
                ? undefined
                : termDeleteHandler("commits", flyover.id)
            }
            onSave={termSaveAndCloseHandler("commits")}
            options={options}
            fiatCreditTypes={flyover.fiatCreditTypes}
            customCreditTypes={flyover.customCreditTypes}
            defaultCreditType={flyover.fiatCreditTypes[0]}
            rateCardId={flyover.rateCardId}
            isExistingCommit={isExistingCommit}
          />
        );
      case "edit_recurring_commit":
        const isExistinRecurringCommit =
          ctrl.get("existingRecurringCommitIds")?.includes(flyover.id) ?? false;
        return (
          <CommitTakeover
            recurringCommitEdit={(ctrl.get("recurringCommits") ?? []).find(
              (c) => c.id === flyover.id,
            )}
            edit={undefined}
            onClose={closeFlyover}
            onDelete={
              isExistinRecurringCommit
                ? undefined
                : termDeleteHandler("recurringCommits", flyover.id)
            }
            onRecurringSave={termSaveAndCloseHandler("recurringCommits")}
            options={options}
            fiatCreditTypes={flyover.fiatCreditTypes}
            customCreditTypes={flyover.customCreditTypes}
            defaultCreditType={flyover.fiatCreditTypes[0]}
            rateCardId={flyover.rateCardId}
            isExistingCommit={isExistinRecurringCommit}
            isOpen={true}
          />
        );
      case "new_credit":
        if (useNewCommitUi) {
          return (
            <CommitTakeover
              edit={undefined}
              recurringCommitEdit={undefined}
              onClose={closeFlyover}
              onSave={termSaveHandler("credits")}
              onRecurringSave={termSaveAndCloseHandler("recurringCredits")}
              options={{ ...options, asCredit: true }}
              fiatCreditTypes={flyover.fiatCreditTypes}
              customCreditTypes={flyover.customCreditTypes}
              defaultCreditType={flyover.fiatCreditTypes[0]}
              rateCardId={flyover.rateCardId}
              isExistingCommit={false}
              isOpen={true}
            />
          );
        }
        return (
          <CommitFlyover
            edit={undefined}
            onClose={closeFlyover}
            onSave={termSaveHandler("credits")}
            options={{ ...options, asCredit: true }}
            fiatCreditTypes={flyover.fiatCreditTypes}
            customCreditTypes={flyover.customCreditTypes}
            defaultCreditType={flyover.fiatCreditTypes[0]}
            rateCardId={flyover.rateCardId}
            isExistingCommit={false}
          />
        );
      case "edit_credit":
        const isExistingCredit =
          ctrl.get("existingCreditIds")?.includes(flyover.id) ?? false;
        if (useNewCommitUi) {
          return (
            <CommitTakeover
              edit={(ctrl.get("credits") ?? []).find(
                (c) => c.id === flyover.id,
              )}
              recurringCommitEdit={undefined}
              onClose={closeFlyover}
              onDelete={
                isExistingCredit
                  ? undefined
                  : termDeleteHandler("credits", flyover.id)
              }
              onSave={termSaveAndCloseHandler("credits")}
              options={{ ...options, asCredit: true }}
              fiatCreditTypes={flyover.fiatCreditTypes}
              customCreditTypes={flyover.customCreditTypes}
              defaultCreditType={flyover.fiatCreditTypes[0]}
              rateCardId={flyover.rateCardId}
              isExistingCommit={isExistingCredit}
              isOpen={true}
            />
          );
        }
        return (
          <CommitFlyover
            edit={(ctrl.get("credits") ?? []).find((c) => c.id === flyover.id)}
            onClose={closeFlyover}
            onDelete={
              isExistingCredit
                ? undefined
                : termDeleteHandler("credits", flyover.id)
            }
            onSave={termSaveAndCloseHandler("credits")}
            options={{ ...options, asCredit: true }}
            fiatCreditTypes={flyover.fiatCreditTypes}
            customCreditTypes={flyover.customCreditTypes}
            defaultCreditType={flyover.fiatCreditTypes[0]}
            rateCardId={flyover.rateCardId}
            isExistingCommit={isExistingCredit}
          />
        );
      case "edit_recurring_credit":
        const isExistingRecurringCredit =
          ctrl.get("existingRecurringCreditIds")?.includes(flyover.id) ?? false;
        return (
          <CommitTakeover
            edit={undefined}
            recurringCommitEdit={(ctrl.get("recurringCredits") ?? []).find(
              (c) => c.id === flyover.id,
            )}
            onClose={closeFlyover}
            onDelete={
              isExistingRecurringCredit
                ? undefined
                : termDeleteHandler("recurringCredits", flyover.id)
            }
            onRecurringSave={termSaveAndCloseHandler("recurringCredits")}
            options={{ ...options, asCredit: true }}
            fiatCreditTypes={flyover.fiatCreditTypes}
            customCreditTypes={flyover.customCreditTypes}
            defaultCreditType={flyover.fiatCreditTypes[0]}
            rateCardId={flyover.rateCardId}
            isExistingCommit={isExistingRecurringCredit}
            isOpen={true}
          />
        );
      case "new_discount":
        return (
          <DiscountFlyover
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("discounts")}
            fiatCreditTypes={flyover.fiatCreditTypes}
          />
        );
      case "edit_discount":
        return (
          <DiscountFlyover
            edit={ctrl.get("discounts")?.find((d) => d.id === flyover.id)}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("discounts", flyover.id)}
            onSave={termSaveAndCloseHandler("discounts")}
            fiatCreditTypes={flyover.fiatCreditTypes}
          />
        );
      case "new_pro_service":
        return (
          <ProServiceFlyover
            onCancel={closeFlyover}
            onSave={termSaveAndCloseHandler("proServices")}
            options={options}
          />
        );

      case "edit_pro_service":
        return (
          <ProServiceFlyover
            edit={ctrl.get("proServices")?.find((ps) => ps.id === flyover.id)}
            onCancel={closeFlyover}
            onDelete={termDeleteHandler("proServices", flyover.id)}
            onSave={termSaveAndCloseHandler("proServices")}
          />
        );

      case undefined:
        return null;
    }
  })();

  return { flyoverElement, setFlyover: setFlyover, closeFlyover };
}
