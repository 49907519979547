import React, { useState } from "react";
import pluralize from "pluralize";
import { FullScreenPage } from "components/AppShell";
import { Input, Body, Headline, Icon } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedWizardFooter } from "components/deprecated/Wizard";
import {
  useProductActiveAndFutureCustomersQuery,
  useProductArbitraryCustomerQuery,
} from "./data/queries.graphql";
import { MetronomeLogo } from "components/MetronomeLogo";
import { DeprecatedInternalLink } from "components/deprecated/Typography";

const getConfirmationText = (
  activePlans: number | undefined,
  activeCustomers: number | undefined,
  singleCustomerName: string | undefined,
) => {
  if (activeCustomers === 0) {
    return null;
  } else if (activeCustomers === 1 && singleCustomerName) {
    return {
      body: (
        <>
          This product is currently on a plan associated with{" "}
          <b>{singleCustomerName}</b>. Any edits will be reflected in their
          current bill cycle. Are you sure you want to make edits? If so, enter{" "}
          <b>{singleCustomerName}</b> below:
        </>
      ),
      value: singleCustomerName,
    };
  } else if (activePlans === undefined || activeCustomers === undefined) {
    // If there was an error in retrieving the number of active plans or customers,
    // use a more generic phrasing.
    return {
      body: (
        <>
          This product is currently associated with some customers. Any edits
          will be reflected in their current bill cycle. Are you sure you want
          to make edits? If so, enter <b>yes</b> below:
        </>
      ),
      value: "yes",
    };
  } else {
    const currentPlansString = pluralize("active plan", activePlans, true);
    const currentCustomersString = pluralize("customer", activeCustomers, true);

    return {
      body: (
        <>
          This product is currently on <b>{currentPlansString}</b> and
          associated with <b>{currentCustomersString}</b>. Any edits will be
          reflected in their current bill cycle. Are you sure you want to make
          edits? If so, enter <b>{currentCustomersString}</b>.
        </>
      ),
      value: currentCustomersString,
    };
  }
};

type EditInterstitialProps = {
  onClose: () => void;
  onContinue: () => void;
  productId: string;
};

export const EditInterstitial: React.FC<EditInterstitialProps> = ({
  onClose,
  onContinue,
  productId,
}) => {
  const [confirmationInput, setConfirmationInput] = useState("");
  const { data, loading } = useProductActiveAndFutureCustomersQuery({
    variables: { product_id: productId },
  });
  const { data: customerData, loading: customerLoading } =
    useProductArbitraryCustomerQuery({
      skip: data?.Product_by_pk?.active_customers_count !== 1,
      variables: { product_id: productId },
    });

  if (loading || customerLoading) {
    return null;
  }

  const confirmText = getConfirmationText(
    data?.Product_by_pk?.active_customers_plan_count,
    data?.Product_by_pk?.active_customers_count,
    customerData?.arbitrary_customer[0]?.name,
  );
  return (
    <FullScreenPage title="Edit product">
      <div className="relative flex grow flex-col items-center justify-center bg-gray-50 text-center">
        <DeprecatedInternalLink
          routePath="/"
          className="absolute left-20 top-20"
        >
          <MetronomeLogo theme="greyscale" badgeOnly />
        </DeprecatedInternalLink>
        <IconButton
          onClick={onClose}
          className="absolute right-12 top-12"
          theme="secondary"
          icon="xClose"
        />
        <div className="mb-24 h-[80px] w-[80px] items-center justify-center rounded-[40px] bg-deprecated-primary-500 text-2xl text-white">
          <Icon icon="receipt" />
        </div>
        <Headline level={4}>Product editing</Headline>
        <Body className="mx-20 mb-24 mt-8 max-w-[850px] text-deprecated-gray-700">
          Edit the name of your product and charges. New charges can be added,
          but existing charges cannot be removed from an existing product. Any
          new charge(s) added to the product will appear as zero dollar charges
          on existing plans.
        </Body>
        {confirmText && (
          <>
            <hr className="mb-[42px] w-[100px] border-b border-b-black" />
            <Body className="mx-20 mb-24 mt-8 max-w-[850px] text-deprecated-gray-700">
              {confirmText?.body}
            </Body>
            <Input
              placeholder={`Enter "${confirmText.value}"`}
              value={confirmationInput}
              onChange={setConfirmationInput}
            />
          </>
        )}
      </div>
      <DeprecatedWizardFooter
        onClickBack={onClose}
        backLabel="Cancel"
        onClickContinue={onContinue}
        continueLabel="Continue"
        continueDisabled={
          !!(confirmText && confirmationInput !== confirmText.value)
        }
      />
    </FullScreenPage>
  );
};
