import { useState, useEffect, useRef } from "react";

export type UseIntersectionObserverOptions = IntersectionObserverInit;

/**
 * Hook interface to the browser's IntersectionObserver API.
 */
export function useIntersectionObserver<T extends Element>(
  options?: UseIntersectionObserverOptions,
): [observedElement: React.RefObject<T>, isIntersecting: boolean] {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          return;
        }
      }
      setIsIntersecting(false);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref.current, options]);

  return [ref, isIntersecting];
}
