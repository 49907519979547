/* 1222e6bc50e1174f607ebe90ce893651efc059cc
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAutoTopupsSettingsQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type GetAutoTopupsSettingsQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, recharge_settings: Array<{ __typename?: 'RechargeSettings', id: string, name: string, enabled: boolean, auto_recharge_threshold: string, auto_recharge_to_amount: string, billing_provider: Types.BillingProviderEnum_Enum | null, credit_grant_type: string | null, grant_duration: string, priority: string, reason: string | null, status: string | null, status_metadata: object | null, billing_provider_config: { __typename?: 'RechargeSettingsBillingProviderConfig', stripe_config: { __typename?: 'RechargeSettingsStripeConfig', automatic_tax: boolean | null, invoice_custom_fields: object | null, invoice_metadata: object | null } | null } | null, recharge_alert_history: Array<{ __typename: 'RechargeAlertHistory', created_at: string, customer_alert_id: string, evaluation_id: string | null, id: string, status: Types.CustomerAlertStatusEnum_Enum }> }>, BillingProviderCustomers: Array<{ __typename?: 'BillingProviderCustomer', billing_provider: Types.BillingProviderEnum_Enum, billing_provider_customer_id: string }> } | null };


export const GetAutoTopupsSettingsDocument = gql`
    query GetAutoTopupsSettings($customer_id: uuid!) {
  Customer_by_pk(id: $customer_id) {
    id
    recharge_settings {
      id
      name
      enabled
      auto_recharge_threshold
      auto_recharge_to_amount
      billing_provider
      billing_provider_config {
        stripe_config {
          automatic_tax
          invoice_custom_fields
          invoice_metadata
        }
      }
      credit_grant_type
      grant_duration
      priority
      reason
      status
      status_metadata
      recharge_alert_history {
        created_at
        customer_alert_id
        evaluation_id
        id
        status
        __typename
      }
    }
    BillingProviderCustomers {
      billing_provider
      billing_provider_customer_id
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetAutoTopupsSettingsQuery__
 *
 * To run a query within a React component, call `useGetAutoTopupsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoTopupsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoTopupsSettingsQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useGetAutoTopupsSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetAutoTopupsSettingsQuery, GetAutoTopupsSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoTopupsSettingsQuery, GetAutoTopupsSettingsQueryVariables>(GetAutoTopupsSettingsDocument, options);
      }
export function useGetAutoTopupsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoTopupsSettingsQuery, GetAutoTopupsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoTopupsSettingsQuery, GetAutoTopupsSettingsQueryVariables>(GetAutoTopupsSettingsDocument, options);
        }
export type GetAutoTopupsSettingsQueryHookResult = ReturnType<typeof useGetAutoTopupsSettingsQuery>;
export type GetAutoTopupsSettingsLazyQueryHookResult = ReturnType<typeof useGetAutoTopupsSettingsLazyQuery>;
export type GetAutoTopupsSettingsQueryResult = Apollo.QueryResult<GetAutoTopupsSettingsQuery, GetAutoTopupsSettingsQueryVariables>;