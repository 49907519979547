import React, { useCallback, useEffect } from "react";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { NumericInput, Select } from "design-system";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { OverrideCtrl, useOverrideCtrl } from "./OverrideCtrl";
import { Schema } from "../../../CreateAndEdit/Schema";
import { CreditType } from "app/types/credit-types";

const useTieredRateController = useOverrideCtrl.child(Schema.TieredRate, {
  read(parent, creditType: CreditType) {
    const rate = parent.get("rate");
    return rate?.type === "overwrite" && rate.newRate.type === "tiered"
      ? {
          ...rate.newRate,
          creditType,
        }
      : undefined;
  },
  write: (child) => ({
    rate: {
      type: "overwrite",
      newRate: child.getUnvalidatedInputs(),
    },
  }),
});

interface Props {
  parent: OverrideCtrl;
  creditType?: CreditType;
}

export const OverwriteTieredRate: React.FC<Props> = (props) => {
  const rateCreditType = props.creditType ?? USD_CREDIT_TYPE;
  const tieredCtrl = useTieredRateController(props.parent, rateCreditType);
  let tiers = tieredCtrl.get("tiers") || [];

  useEffect(() => {
    // Initialize with a default empty tier if no tiers exist
    if (tiers.length === 0) {
      const initialTiers = [
        { lastUnit: undefined, unitPrice: 0 },
        { lastUnit: undefined, unitPrice: 0 },
      ];
      tieredCtrl.update({ tiers: initialTiers });
      // Last tier must be unbounded
    } else if (tiers[tiers.length - 1].lastUnit !== undefined) {
      tieredCtrl.update({
        tiers: [...tiers, { lastUnit: undefined, unitPrice: 0 }],
      });
    }
  }, [tieredCtrl, tiers]);

  const addTier = useCallback(() => {
    const newTiers = [...tiers, { lastUnit: undefined, unitPrice: 0 }];
    tieredCtrl.update({ tiers: newTiers });
  }, [tieredCtrl, tiers]);

  const removeTier = useCallback(
    (index: number) => {
      const updatedTiers = [...tiers];

      // Transfer the unitPrice to the next tier if the removed tier is not the last one.
      // Deals with a limitation of the credit input component and follows tiers plans behavior.
      if (index < updatedTiers.length - 1) {
        updatedTiers[index + 1].unitPrice = updatedTiers[index].unitPrice;
      }

      // If the removed tier is not the first one, and there's a tier following the removed one,
      // adjust the previous tier's lastUnit to match the next tier's lastUnit (if it exists).
      if (index > 0 && index < updatedTiers.length - 1) {
        updatedTiers[index - 1].lastUnit = updatedTiers[index].lastUnit;
      }
      updatedTiers.splice(index, 1);

      tieredCtrl.update({ tiers: updatedTiers });
    },
    [tieredCtrl, tiers],
  );

  return (
    <div>
      {tiers.map((tier, index) => (
        <TierInput
          key={index}
          index={index}
          parent={tieredCtrl}
          creditType={rateCreditType}
          addTier={addTier}
          onDelete={() => removeTier(index)}
        />
      ))}
      <Select
        {...tieredCtrl.props.Select("creditType", {
          placeholder: "",
          className: "hidden",
          options: [
            {
              label: rateCreditType.name,
              value: rateCreditType,
            },
          ],
        })}
      />
    </div>
  );
};

const useTierController = useTieredRateController.child(Schema.TierForm, {
  read(parent, index) {
    const tiers = parent.get("tiers");
    const tier = tiers?.[index];
    const prevLastUnit = tiers?.[index - 1]?.lastUnit;
    const isLastTier = tiers && index === tiers.length - 1;
    return { ...tier, prevLastUnit: prevLastUnit ?? undefined, isLastTier };
  },

  write(child, parent, index) {
    const existingTiers = parent.get("tiers") || [];
    // If the lastUnit is empty, set it to undefined (otherwise null causes form errors)
    let { lastUnit, unitPrice } = child.getUnvalidatedInputs();
    if (!lastUnit) {
      lastUnit = undefined;
    }

    const updatedTiers = existingTiers.map((tier, i) =>
      i === index ? { lastUnit, unitPrice } : tier,
    );

    return { tiers: updatedTiers };
  },
});

interface TierInputProps {
  index: number;
  parent: ReturnType<typeof useTieredRateController>;
  creditType: CreditType;
  onDelete: () => void;
  addTier?: () => void;
}
const TierInput: React.FC<TierInputProps> = ({
  index,
  parent,
  creditType,
  addTier,
  onDelete,
}) => {
  const tierCtrl = useTierController(parent, index);
  const firstUnitPlaceholder =
    index === 0 ? "> 0" : `> ${parent.get("tiers")?.[index - 1].lastUnit ?? 0}`;
  const addButton = index === 0 && (
    <Button
      onClick={addTier}
      text="Add another tier"
      theme="primary"
      leadingIcon="plus"
    />
  );
  const deleteButton = index !== 0 && (
    <IconButton
      className="-ml-[10px]"
      onClick={onDelete}
      theme="tertiary"
      icon="xCircle"
    />
  );
  return (
    <div key={index} className="mb-8 grid grid-cols-3 items-end gap-x-12">
      <div className="col-span-1 grid grid-cols-2 gap-12 text-gray-600">
        <NumericInput
          name="First unit"
          placeholder={firstUnitPlaceholder}
          disabled={true}
        />
        <NumericInput
          {...tierCtrl.props.NumericInput("lastUnit", {
            name: "Last unit",
            placeholder: "∞",
          })}
        />
      </div>

      <DeprecatedCreditInput
        {...tierCtrl.props.CreditInput("unitPrice", {
          name: "Rate",
          placeholder: "123.45",
          creditType,
          allowZeroAmounts: true,
        })}
      />

      <div className="justify-self-left mt-[16px]">
        {addButton}
        {deleteButton}
      </div>
    </div>
  );
};
