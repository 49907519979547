import React from "react";
import { Modal } from "components/Modal";
import { useUpdateIngestAliasesMutation } from "../../../../../deprecated/Customer/tabs/Settings/sections/Identifiers/queries.graphql";
import { Button } from "components/Button";
import { TextInput } from "components/Input";
import { useCheckCustomerIngestAliasQuery } from "../../queries.graphql";
import { useSnackbar } from "components/deprecated/Snackbar";
import { Link } from "react-router-dom";
interface Props {
  onClose: () => void;
  customerId: string;
  existingAliases: string[];
}
export const SetIngestAliasesModal: React.FC<Props> = ({
  onClose,
  customerId,
  existingAliases,
}) => {
  const pushMessage = useSnackbar();
  const [ingestAlias, setIngestAlias] = React.useState<string | undefined>();
  const [setIngestAliases, setIngestAliasResults] =
    useUpdateIngestAliasesMutation();
  const { data, loading } = useCheckCustomerIngestAliasQuery({
    skip: ingestAlias == null || existingAliases.includes(ingestAlias),
    variables: {
      ingest_alias: ingestAlias ?? "",
    },
  });
  const aliasOnThisCustomer =
    ingestAlias && existingAliases.includes(ingestAlias);
  const existingCustomer = !!data?.search_customer_ingest_aliases.length
    ? data.search_customer_ingest_aliases[0].Customer
    : null;

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      icon="checkCircle"
      title="Add ingest alias"
      supportingText="Add an ingest alias to associate usage events with this customer."
      modalButtons={[
        <Button
          size="lg"
          showFullWidth={true}
          disabled={
            !!(
              !ingestAlias ||
              setIngestAliasResults.loading ||
              loading ||
              existingCustomer ||
              aliasOnThisCustomer
            )
          }
          text="Add"
          onClick={async () => {
            if (!ingestAlias || existingAliases.includes(ingestAlias)) {
              return;
            }
            try {
              await setIngestAliases({
                variables: {
                  customer_id: customerId,
                  ingest_aliases: [...existingAliases, ingestAlias],
                },
                update(cache) {
                  cache.evict({
                    fieldName: "CustomerIngestAliases",
                  });
                },
              });
              pushMessage({
                content: "Ingest alias added",
                type: "success",
              });
              onClose();
            } catch (e) {
              pushMessage({
                content: "Failed to add ingest alias",
                type: "error",
              });
            }
          }}
        />,
        <Button
          size="lg"
          showFullWidth={true}
          text="Cancel"
          onClick={() => {
            onClose();
          }}
          theme="secondary"
        />,
      ]}
    >
      <TextInput
        hintText={
          ingestAlias === "" ? (
            "Ingest alias cannot be empty"
          ) : aliasOnThisCustomer ? (
            "This ingest alias is already associated with this customer"
          ) : existingCustomer ? (
            <span>
              This ingest alias is already associated with another customer:{" "}
              <Link to={`/customers/${existingCustomer.id}/settings`}>
                <span className="underline">{existingCustomer.name}</span>
              </Link>
            </span>
          ) : (
            "An ingest alias must be unique to this customer"
          )
        }
        label="Ingest alias"
        placeholder="my-ingest-alias"
        value={ingestAlias}
        onChange={(meta) => setIngestAlias(meta.value)}
        isInvalid={
          !!existingCustomer || aliasOnThisCustomer || ingestAlias === ""
        }
      />
    </Modal>
  );
};
