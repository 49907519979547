/* f0af7c3052a55d9a120a5c18d54801b7102e114a
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsertCreditTypeMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type InsertCreditTypeMutation = { __typename?: 'Mutation', insert_CreditType_one: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } | null };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type CreditTypesQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type CreditTypesQuery = { __typename?: 'Query', CreditType: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }> };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const InsertCreditTypeDocument = gql`
    mutation InsertCreditType($name: String!) {
  insert_CreditType_one(object: {name: $name}) {
    ...CreditType
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;
export type InsertCreditTypeMutationFn = Apollo.MutationFunction<InsertCreditTypeMutation, InsertCreditTypeMutationVariables>;

/**
 * __useInsertCreditTypeMutation__
 *
 * To run a mutation, you first call `useInsertCreditTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCreditTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCreditTypeMutation, { data, loading, error }] = useInsertCreditTypeMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertCreditTypeMutation(baseOptions?: Apollo.MutationHookOptions<InsertCreditTypeMutation, InsertCreditTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCreditTypeMutation, InsertCreditTypeMutationVariables>(InsertCreditTypeDocument, options);
      }
export type InsertCreditTypeMutationHookResult = ReturnType<typeof useInsertCreditTypeMutation>;
export type InsertCreditTypeMutationResult = Apollo.MutationResult<InsertCreditTypeMutation>;
export type InsertCreditTypeMutationOptions = Apollo.BaseMutationOptions<InsertCreditTypeMutation, InsertCreditTypeMutationVariables>;
export const CreditTypesDocument = gql`
    query CreditTypes($environment_type: EnvironmentTypeEnum_enum!) {
  CreditType(
    where: {archived_at: {_is_null: true}, _or: [{environment_type: {_eq: $environment_type}}, {environment_type: {_is_null: true}}]}
  ) {
    ...CreditType
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useCreditTypesQuery__
 *
 * To run a query within a React component, call `useCreditTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditTypesQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useCreditTypesQuery(baseOptions: Apollo.QueryHookOptions<CreditTypesQuery, CreditTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditTypesQuery, CreditTypesQueryVariables>(CreditTypesDocument, options);
      }
export function useCreditTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditTypesQuery, CreditTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditTypesQuery, CreditTypesQueryVariables>(CreditTypesDocument, options);
        }
export type CreditTypesQueryHookResult = ReturnType<typeof useCreditTypesQuery>;
export type CreditTypesLazyQueryHookResult = ReturnType<typeof useCreditTypesLazyQuery>;
export type CreditTypesQueryResult = Apollo.QueryResult<CreditTypesQuery, CreditTypesQueryVariables>;