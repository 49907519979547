import { FormController } from "app/lib/FormController";
import { Schema } from "../../../CreateAndEdit/Schema";
import type { OverrideFlyoverProps } from "./OverrideFlyover";
import { DefaultTimeframe } from "../../../CreateAndEdit/lib/DefaultTimeframe";
import { toDayjs } from "lib/date";
import { Override } from "@metronome-industries/schedule-utils";

const latestOverrideBoundary = (
  overrides: Override.Description[],
  productId: string,
) =>
  overrides
    // if an override applies to this product capture the largest date
    // from that override
    ?.flatMap(
      (o) =>
        o.appliesTo?.flatMap((specifier) =>
          specifier.productId === productId
            ? o.endingBefore ?? o.startingAt
            : [],
        ) ?? [],
    )
    // sort the dates in descending order
    .sort((a, b) => {
      if (a != null && b != null) return toDayjs(b).diff(a);
      if (a != null) return -1; // sort a first
      if (b != null) return 1; // sort b first
      return 0;
    })
    // take the largest date
    .at(0);

export type OverrideCtrl = ReturnType<typeof useOverrideCtrl>;
export const useOverrideCtrl = FormController.createHook(Schema.Override, {
  init(props: OverrideFlyoverProps, timeframe: DefaultTimeframe) {
    if (props.edit) {
      return props.edit;
    }

    return {
      productId: props.defaultProductId,
      startingAt:
        (props.overrides &&
          props.defaultProductId &&
          latestOverrideBoundary(props.overrides, props.defaultProductId)) ||
        timeframe.startingAt,
    };
  },
});
