import React from "react";
import { NumericInput } from "design-system";

import { useOverrideCtrl, OverrideCtrl } from "./OverrideCtrl";
import { Schema } from "../../../CreateAndEdit/Schema";

const usePercentageRateCtrl = useOverrideCtrl.child(Schema.PercentageRate, {
  read(parent) {
    const rate = parent.get("rate");
    return rate?.type === "overwrite" && rate.newRate.type === "percentage"
      ? rate.newRate
      : undefined;
  },
  write: (child) => ({
    rate: {
      type: "overwrite",
      newRate: child.getUnvalidatedInputs(),
    },
  }),
});

interface Props {
  parent: OverrideCtrl;
}

export const PercentageRate: React.FC<Props> = (props) => {
  const ctrl = usePercentageRateCtrl(props.parent);

  return (
    <>
      <NumericInput
        {...ctrl.props.NumericInput("fraction", {
          name: "Adjustment",
          tooltip:
            "Composite products are priced as a percentage of some other set of products.",
          placeholder: "0.0",
          suffix: "%",
        })}
        className="w-[6rem]"
      />
    </>
  );
};
