import React from "react";
import {
  GetInvoiceMetadataQuery,
  useGetInvoiceMetadataQuery,
} from "./queries.graphql";
import Decimal from "decimal.js";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { DeprecatedFlyoverWithMetadataTable } from "components/deprecated/FlyoverWithMetadataTable";

interface Props {
  onRequestClose: () => void;
  invoiceId: string;
  invoiceName: string;
}

export const DeprecatedInvoiceFlyover: React.FC<Props> = ({
  onRequestClose,
  invoiceId,
  invoiceName,
}) => {
  const { data, loading, error } = useGetInvoiceMetadataQuery({
    variables: {
      id: invoiceId,
    },
  });
  const nonGAContractFeatures = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const includeBillableStatus =
    nonGAContractFeatures?.includes("BILLABLE_STATUS");

  const invoice = data?.invoice;

  const getNetsuiteSalesOrderId = (
    invoice: GetInvoiceMetadataQuery["invoice"],
  ): string => {
    if (!invoice) return "";
    switch (invoice.__typename) {
      case "ContractScheduledInvoice":
      case "ContractRefundInvoice":
      case "ContractPostpaidTrueupInvoice":
      case "ContractProServiceInvoice":
        return invoice.netsuite_sales_order_id ?? "";
      default:
        return "";
    }
  };

  type ResellerRoyaltyData = {
    reseller: string;
    netsuite_reseller_id: string;
    fraction: string;
    aws_account_number?: string | null;
    aws_payer_reference_id?: string | null;
    aws_offer_id?: string | null;
    gcp_account_id?: string | null;
    gcp_offer_id?: string | null;
  };

  const getResellerRoyalty = (
    invoice: GetInvoiceMetadataQuery["invoice"],
  ): ResellerRoyaltyData | null => {
    if (!invoice) return null;
    for (const li of invoice.line_items) {
      if (li.__typename === "ContractAWSRoyaltyLineItem") {
        const {
          netsuite_reseller_id,
          aws_account_number,
          aws_payer_reference_id,
          aws_offer_id,
          fraction,
        } = li.reseller_royalty;
        return {
          reseller: "AWS",
          fraction,
          netsuite_reseller_id,
          aws_account_number,
          aws_payer_reference_id,
          aws_offer_id,
        };
      }
      if (li.__typename === "ContractGCPRoyaltyLineItem") {
        const { netsuite_reseller_id, fraction, gcp_account_id, gcp_offer_id } =
          li.reseller_royalty;
        return {
          reseller: "GCP",
          fraction,
          netsuite_reseller_id,
          gcp_account_id,
          gcp_offer_id,
        };
      }
    }
    return null;
  };

  const mapInvoiceToRows = (): Map<string, string> => {
    if (!invoice) return new Map();

    const resellerRoyalty = getResellerRoyalty(invoice);

    return new Map([
      ["invoice_id", invoice.id],
      ["invoice_type", invoice.external_type ?? ""],
      ["netsuite_sales_order_id", getNetsuiteSalesOrderId(invoice) ?? ""],
      ["reseller", resellerRoyalty?.reseller ?? ""],
      [
        "reseller_royalty_percent",
        resellerRoyalty?.fraction
          ? `${new Decimal(resellerRoyalty.fraction).mul(100).toString()}%`
          : "",
      ],
      ["netsuite_reseller_id", resellerRoyalty?.netsuite_reseller_id ?? ""],
      ["aws_account_id", resellerRoyalty?.aws_account_number ?? ""],
      ["aws_payer_reference_id", resellerRoyalty?.aws_payer_reference_id ?? ""],
      ["aws_offer_id", resellerRoyalty?.aws_offer_id ?? ""],
      ["gcp_account_id", resellerRoyalty?.gcp_account_id ?? ""],
      ["gcp_offer_id", resellerRoyalty?.gcp_offer_id ?? ""],
      ...(includeBillableStatus
        ? [
            [
              "billable_status",
              "billable_status" in invoice ? invoice.billable_status : "",
            ] as [string, string],
          ]
        : []),
    ]);
  };

  return (
    <DeprecatedFlyoverWithMetadataTable
      onRequestClose={onRequestClose}
      title={`${invoiceName} Invoice`}
      instanceType="invoice"
      error={error}
      loading={loading}
      metadataRows={mapInvoiceToRows()}
      options={{ removeEmptyValueRows: true }}
    />
  );
};
