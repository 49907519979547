import React, { useContext } from "react";
import { IconButton } from "components/IconButton";
import {
  PrepaidCommitAccessScheduleController,
  CommitFlyoverController,
  PrepaidCommitTermsController,
  usePrepaidCommitAccessScheduleController,
  usePrepaidCommitAccessScheduleItemController,
  usePrepaidCommitTermsController,
} from ".";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { v4 as uuid } from "uuid";
import {
  CommitAccessScheduleItemEditability,
  CommitAccessScheduleItemRemovability,
  ExternalCommitType,
} from "types/generated-graphql/__types__";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { CreditType } from "app/types/credit-types";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";
import { DatePicker } from "components/DatePicker";
import { dayjs } from "lib/dayjs";
import { BillingSchedule } from "../../components/BillingSchedule/BillingSchedule";
import { CreditTypeContext } from "./CommitTakeover";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { CommitPriceInput } from "../../lib/CommitPriceInput";
import { Tooltip } from "components/Tooltip";
import {
  getEditabilityMessage,
  getRemovabilityMessage,
} from "../../../Edit/utils";

const PrepaidCommitAccessScheduleItem: React.FC<{
  id: string;
  parent: PrepaidCommitAccessScheduleController;
  action: React.ReactNode;
  creditType: CreditType;
  setParentCreditType?: (creditType: CreditType) => void;
}> = (props) => {
  const ctrl = usePrepaidCommitAccessScheduleItemController(
    props.parent,
    props.id,
  );

  // Default to editable for newly created segments
  const editability =
    ctrl.get("editability") ?? CommitAccessScheduleItemEditability.Editable;
  const isEditable =
    editability === CommitAccessScheduleItemEditability.Editable;

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "amount",
  );

  return (
    <div>
      <div className="flex w-full flex-row gap-[24px]">
        <div className="flex flex-1 flex-col">
          <Tooltip
            label={getEditabilityMessage(editability)}
            disabled={isEditable}
            fullWidth
          >
            <CommitPriceInput
              unitPrice={ctrl.get("amount")}
              creditType={props.creditType}
              onPriceChange={(price) => {
                ctrl.update({
                  amount: price,
                });
              }}
              onCreditTypeChange={(creditType) => {
                if (props.setParentCreditType) {
                  props.setParentCreditType(creditType);
                }
              }}
              disableChangingCreditTypes={
                props.setParentCreditType === undefined
              }
              isInvalid={!ctrl.appearsValid() && !ctrl.isValid("amount")}
              hintText={
                (!ctrl.appearsValid() && ctrl.state.fields.amount?.error) ||
                "Amount allotted to the contract"
              }
              disabled={!isEditable}
            />
          </Tooltip>
        </div>
        <div className="flex flex-none gap-lg">
          <div className="flex flex-col">
            <DatePicker
              text="Starting at"
              value={
                ctrl.get("date")
                  ? dayjs.utc(ctrl.get("date")).toDate()
                  : undefined
              }
              onDateApply={(date) => {
                ctrl.update({
                  date: date?.toISOString(),
                });
              }}
              disabled={!isEditable}
              tooltipContent={{
                label: isEditable
                  ? "Starting at"
                  : getEditabilityMessage(editability),
              }}
            />
          </div>
          <div className="flex flex-col">
            <DatePicker
              text="Ending before"
              value={
                ctrl.get("endDate")
                  ? dayjs.utc(ctrl.get("endDate")).toDate()
                  : undefined
              }
              openToDate={
                ctrl.get("endDate")
                  ? dayjs.utc(ctrl.get("endDate")).toDate()
                  : undefined
              }
              onDateApply={(date) => {
                ctrl.update({
                  endDate: date?.toISOString(),
                });
              }}
              disabled={!isEditable}
              tooltipContent={{
                label: isEditable
                  ? "Ending before (optional)"
                  : getEditabilityMessage(editability),
              }}
            />
          </div>
        </div>

        {props.action}
      </div>
    </div>
  );
};

const PrepaidCommitAccessSchedule: React.FC<{
  parent: PrepaidCommitTermsController;
  asCredit: boolean;
  isExistingCommit: boolean;
}> = (props) => {
  const creditTypes = useContext(CreditTypeContext);
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePrepaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = ctrl.get("accessSchedule") ?? [];
  const creditType = findCreditType(
    props.parent.get("accessScheduleCreditTypeId") ?? USD_CREDIT_TYPE.id,
    [...creditTypes.fiatCreditTypes, ...creditTypes.customCreditTypes],
  );
  return (
    <div className="flex flex-col gap-12">
      <p className="text-sm text-black">
        {props.asCredit ? "Credit" : "Commit"} balance
      </p>
      <div className="flex flex-col gap-4">
        {accessSchedule.map((item, i) => {
          const removability =
            item.removability ?? CommitAccessScheduleItemRemovability.Removable;
          return (
            <PrepaidCommitAccessScheduleItem
              key={i}
              id={item.id}
              parent={ctrl}
              setParentCreditType={
                i === 0
                  ? (creditType) => {
                      props.parent.update({
                        accessScheduleCreditTypeId: creditType.id,
                      });
                    }
                  : undefined
              }
              action={
                i === 0 ? (
                  <Tooltip label="Add segment">
                    <IconButton
                      onClick={() => {
                        ctrl.update({
                          accessSchedule: [
                            ...accessSchedule,
                            {
                              id: uuid(),
                            },
                          ],
                        });
                      }}
                      theme="secondary"
                      icon="plus"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    label={
                      removability ===
                      CommitAccessScheduleItemRemovability.Removable
                        ? "Delete segment"
                        : getRemovabilityMessage(removability)
                    }
                  >
                    <IconButton
                      onClick={() => {
                        ctrl.update({
                          accessSchedule: accessSchedule.filter(
                            (i) => i.id !== item.id,
                          ),
                        });
                      }}
                      disabled={
                        accessSchedule.length === 1 ||
                        removability !==
                          CommitAccessScheduleItemRemovability.Removable
                      }
                      theme="secondary"
                      icon="trash01"
                    />
                  </Tooltip>
                )
              }
              creditType={creditType}
            />
          );
        })}
      </div>
    </div>
  );
};

export const PrepaidCommitTerms: React.FC<{
  parent: CommitFlyoverController;
  level: "customer" | "contract";
  asCredit: boolean;
  contracts?: { id: string; name?: string | null }[];
  defaultCreditType: CreditType;
  isExistingCommit: boolean;
}> = (props) => {
  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const externalType = props.asCredit
    ? ExternalCommitType.Credit
    : ExternalCommitType.Commit;
  const ctrl = usePrepaidCommitTermsController(
    props.parent,
    props.level,
    externalType,
    props.defaultCreditType,
  );
  const disallowFreeCommits =
    !!nonGAContractFeaturesEnabled?.includes("BLOCK_FREE_COMMITS");
  return (
    <div className="flex flex-col gap-[24px]">
      <div>
        <PrepaidCommitAccessSchedule
          parent={ctrl}
          asCredit={props.asCredit}
          isExistingCommit={props.isExistingCommit}
        />
      </div>
      {!props.asCredit && (
        <div className="flex flex-col gap-12">
          <BillingSchedule
            parent={ctrl}
            mode="invoice"
            allowEmpty={!disallowFreeCommits}
            isExistingSchedule={props.isExistingCommit}
          />
        </div>
      )}
    </div>
  );
};
