import React from "react";
import {
  CommitFlyoverController,
  PostpaidCommitAccessScheduleController,
  PostpaidCommitBillingScheduleController,
  PostpaidCommitTermsController,
  usePostpaidCommitAccessScheduleController,
  usePostpaidCommitAccessScheduleItemController,
  usePostpaidCommitBillingScheduleController,
  usePostpaidCommitBillingScheduleItemController,
  usePostpaidCommitTermsController,
} from ".";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { USD_CREDIT_TYPE, displayCreditTypeName } from "app/lib/credits";
import {
  NumericInput,
  DateInput,
  Subtitle,
  Select,
  Tooltip,
} from "design-system";
import { DefaultTimeframe } from "../../../CreateAndEdit/lib/DefaultTimeframe";
import Decimal from "decimal.js";
import { CreditType } from "app/types/credit-types";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";
import { CommitAccessScheduleItemEditability } from "types/generated-graphql/__types__";
import { getEditabilityMessage } from "../../../Edit/utils";

const PostpaidCommitAccessScheduleItem: React.FC<{
  id: string;
  parent: PostpaidCommitAccessScheduleController;
  amount: number | undefined;
  creditType: CreditType;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitAccessScheduleItemController(
    props.parent,
    props.id,
    timeframe,
    props.amount,
  );

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "amount",
  );

  const editability =
    ctrl.get("editability") ?? CommitAccessScheduleItemEditability.Editable;
  const isEditable =
    editability === CommitAccessScheduleItemEditability.Editable;

  return (
    <>
      <Tooltip
        content={getEditabilityMessage(editability)}
        disabled={isEditable}
      >
        <div className="w-full">
          <DateInput
            {...ctrl.props.DateInput("date", {
              name: "Starting at",
              disabled: !isEditable,
            })}
          />
        </div>
      </Tooltip>
      <Tooltip
        content={getEditabilityMessage(editability)}
        disabled={isEditable}
      >
        <div className="w-full">
          <DateInput
            {...ctrl.props.DateInput("endDate", {
              name: "Ending before",
              minDate: ctrl.get("date"),
              disabled: !isEditable,
            })}
          />
        </div>
      </Tooltip>
      <div className="flex gap-12">
        <div className="w-full">
          <DeprecatedCreditInput
            {...ctrl.props.CreditInput("amount", {
              placeholder: "123.45",
              name: "Amount",
              creditType: props.creditType,
              tooltip: "Must match total amount in billing schedule.",
              disabled: true,
            })}
          />
        </div>
      </div>
    </>
  );
};

const PostpaidCommitBillingScheduleItem: React.FC<{
  id: string;
  parent: PostpaidCommitBillingScheduleController;
  accessScheduleEnd: string | null;
  creditType: CreditType;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitBillingScheduleItemController(
    props.parent,
    props.id,
    timeframe,
    props.accessScheduleEnd,
  );

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "unitPrice",
  );

  return (
    <div className="col-span-3 grid grid-cols-3 gap-12">
      <DateInput
        {...ctrl.props.DateInput("date", {
          name: "Invoice at",
          minDate: props.accessScheduleEnd ?? undefined,
          tooltip:
            "The date of the true up invoice. Must be at or after access schedule end date.",
        })}
      />
      <NumericInput
        {...ctrl.props.NumericInput("quantity", {
          name: "Quantity",
          placeholder: "1",
        })}
      />

      <div className="flex gap-12">
        <div className="grow">
          <DeprecatedCreditInput
            {...ctrl.props.CreditInput("unitPrice", {
              name: "Unit price",
              placeholder: "123.45",
              creditType: props.creditType,
              allowZeroAmounts: false,
            })}
          />
        </div>
      </div>
    </div>
  );
};

const PostpaidCommitAccessSchedule: React.FC<{
  parent: PostpaidCommitTermsController;
  fiatCreditTypes: CreditType[];
  creditType: CreditType;
  isExistingCommit: boolean;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = ctrl.get("accessSchedule");
  const billingCtrl = usePostpaidCommitBillingScheduleController(
    props.parent,
    timeframe,
  );
  const billingSchedule = billingCtrl.get("billingSchedule");
  return (
    <div className="flex flex-col gap-12">
      <Subtitle level={4}>Access schedule</Subtitle>
      <div className="grid grid-cols-3 gap-12">
        <Select
          {...props.parent.props.Select("accessScheduleCreditTypeId", {
            name: "Pricing unit",
            placeholder: "",
            options: props.fiatCreditTypes.map((ct) => ({
              label: displayCreditTypeName(ct),
              value: ct.id,
            })),
            disabled: props.isExistingCommit,
          })}
        />
      </div>
      <div className="grid grid-cols-3 gap-12">
        {accessSchedule && accessSchedule.length && (
          <PostpaidCommitAccessScheduleItem
            id={accessSchedule[0].id}
            parent={ctrl}
            amount={
              billingSchedule
                ? new Decimal(billingSchedule[0].quantity ?? 0)
                    .mul(billingSchedule[0].unitPrice ?? 0)
                    .toNumber()
                : undefined
            }
            creditType={props.creditType}
          />
        )}
      </div>
    </div>
  );
};

const PostpaidCommitBillingSchedule: React.FC<{
  parent: PostpaidCommitTermsController;
  creditType: CreditType;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitBillingScheduleController(
    props.parent,
    timeframe,
  );
  const billingSchedule = ctrl.get("billingSchedule");
  const accessCtrl = usePostpaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = accessCtrl.get("accessSchedule");
  return (
    <div className="flex flex-col gap-12">
      <Subtitle level={4}>Billing schedule</Subtitle>
      <div className="grid grid-cols-3 gap-12">
        {billingSchedule && billingSchedule.length && (
          <PostpaidCommitBillingScheduleItem
            id={billingSchedule[0].id}
            parent={ctrl}
            accessScheduleEnd={
              accessSchedule ? accessSchedule[0].endDate : null
            }
            creditType={props.creditType}
          />
        )}
      </div>
    </div>
  );
};

export const PostpaidCommitTerms: React.FC<{
  parent: CommitFlyoverController;
  level: "contract" | "customer";
  fiatCreditTypes: CreditType[];
  defaultCreditType: CreditType;
  contracts?: { id: string; name?: string | null }[];
  isExistingCommit: boolean;
}> = (props) => {
  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );
  const ctrl = usePostpaidCommitTermsController(
    props.parent,
    props.level,
    showCurrencyWork ? props.defaultCreditType : USD_CREDIT_TYPE,
  );
  const creditType =
    props.fiatCreditTypes.find(
      (ct) => ct.id === ctrl.get("accessScheduleCreditTypeId"),
    ) ?? USD_CREDIT_TYPE;
  return (
    <div className="flex flex-col gap-[32px]">
      <div>
        <PostpaidCommitAccessSchedule
          parent={ctrl}
          fiatCreditTypes={props.fiatCreditTypes}
          creditType={creditType}
          isExistingCommit={props.isExistingCommit}
        />
      </div>
      <div>
        <PostpaidCommitBillingSchedule parent={ctrl} creditType={creditType} />
      </div>
      {props.level === "customer" && (
        <Select
          {...ctrl.props.Select("invoiceContractId", {
            name: "Invoice contract",
            placeholder: "Select",
            tooltip:
              "Select the contract that will be used to issue a true up invoice for this commit.",
            loading: !props.contracts,
            options:
              props.contracts?.map((c) => ({
                value: c.id,
                label: c.name ?? c.id,
              })) ?? [],
          })}
        />
      )}
      <div className="flex flex-col gap-12">
        <Subtitle>Priority</Subtitle>
        <NumericInput
          {...ctrl.props.NumericInput("priority", {
            name: "Priority",
            tooltip:
              "Dictates the order that commits are applied. Commits with a lower priority will be applied first.",
            placeholder: "100",
            disabled: props.isExistingCommit,
          })}
        />
      </div>
      {props.level === "contract" && (
        <div className="flex flex-col gap-12">
          <Subtitle>Rollover terms</Subtitle>
          <div className="grid grid-cols-3 gap-12">
            <NumericInput
              {...ctrl.props.NumericInput("rolloverFraction", {
                name: "Rollover amount (optional)",
                tooltip:
                  "If this contract is renewed, up to this percent of the total commitment will roll over to the new contract.",
                placeholder: "0.0",
                suffix: "%",
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};
