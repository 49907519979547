/* d928ba97e129288ea8084cf3303992b1b4909710
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckCustomerHasPlanQueryVariables = Types.Exact<{
  customerId: Types.Scalars['uuid'];
}>;


export type CheckCustomerHasPlanQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, CustomerPlans: Array<{ __typename?: 'CustomerPlan', id: string }> } | null };

export type CheckCustomerHasContractQueryVariables = Types.Exact<{
  customerId: Types.Scalars['uuid'];
}>;


export type CheckCustomerHasContractQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, contracts: Array<{ __typename?: 'Contract', id: string }> } | null };


export const CheckCustomerHasPlanDocument = gql`
    query CheckCustomerHasPlan($customerId: uuid!) {
  customer: Customer_by_pk(id: $customerId) {
    id
    CustomerPlans(limit: 1) {
      id
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCheckCustomerHasPlanQuery__
 *
 * To run a query within a React component, call `useCheckCustomerHasPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCustomerHasPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCustomerHasPlanQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCheckCustomerHasPlanQuery(baseOptions: Apollo.QueryHookOptions<CheckCustomerHasPlanQuery, CheckCustomerHasPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCustomerHasPlanQuery, CheckCustomerHasPlanQueryVariables>(CheckCustomerHasPlanDocument, options);
      }
export function useCheckCustomerHasPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCustomerHasPlanQuery, CheckCustomerHasPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCustomerHasPlanQuery, CheckCustomerHasPlanQueryVariables>(CheckCustomerHasPlanDocument, options);
        }
export type CheckCustomerHasPlanQueryHookResult = ReturnType<typeof useCheckCustomerHasPlanQuery>;
export type CheckCustomerHasPlanLazyQueryHookResult = ReturnType<typeof useCheckCustomerHasPlanLazyQuery>;
export type CheckCustomerHasPlanQueryResult = Apollo.QueryResult<CheckCustomerHasPlanQuery, CheckCustomerHasPlanQueryVariables>;
export const CheckCustomerHasContractDocument = gql`
    query CheckCustomerHasContract($customerId: uuid!) {
  customer: Customer_by_pk(id: $customerId) {
    id
    contracts {
      id
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCheckCustomerHasContractQuery__
 *
 * To run a query within a React component, call `useCheckCustomerHasContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCustomerHasContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCustomerHasContractQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCheckCustomerHasContractQuery(baseOptions: Apollo.QueryHookOptions<CheckCustomerHasContractQuery, CheckCustomerHasContractQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckCustomerHasContractQuery, CheckCustomerHasContractQueryVariables>(CheckCustomerHasContractDocument, options);
      }
export function useCheckCustomerHasContractLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckCustomerHasContractQuery, CheckCustomerHasContractQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckCustomerHasContractQuery, CheckCustomerHasContractQueryVariables>(CheckCustomerHasContractDocument, options);
        }
export type CheckCustomerHasContractQueryHookResult = ReturnType<typeof useCheckCustomerHasContractQuery>;
export type CheckCustomerHasContractLazyQueryHookResult = ReturnType<typeof useCheckCustomerHasContractLazyQuery>;
export type CheckCustomerHasContractQueryResult = Apollo.QueryResult<CheckCustomerHasContractQuery, CheckCustomerHasContractQueryVariables>;