import React from "react";
import { Icon, Subtitle } from "design-system";

interface TipBoxProps {
  title: React.ReactNode;
  body: React.ReactNode;
}

export const DeprecatedTipBox: React.FC<TipBoxProps> = ({ title, body }) => {
  return (
    <div
      className="flex flex-row content-stretch rounded-xs border border-deprecated-primary-400 bg-deprecated-primary-50 px-[14px] pb-0 pt-[10px]"
      style={{ top: 0 }}
    >
      <div className="w-[40px] flex-grow-0">
        <Icon icon="bulb" className="text-deprecated-primary-500" />
      </div>
      <div className="ml-[10px]">
        <Subtitle level={2} className="mb-4 text-deprecated-primary-900">
          {title}
        </Subtitle>
        <div className="[&>p]:text-deprecated-primary-700">{body}</div>
      </div>
    </div>
  );
};
