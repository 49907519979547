import React from "react";
import { RouteObject, Navigate, Outlet, useLocation } from "react-router-dom";

import { EnvironmentRedirect } from "app/lib/environmentSwitcher/EnvironmentRedirect";

//t10 pages
import T10Overview from "app/pages/Overview";

// Legacy customer routes
import AddPlanToCustomer from "app/pages/Customer/AddPlanToCustomer";
import NewCustomer from "app/pages/Customer/NewCustomer";
import { Usage } from "app/pages/deprecated/Customer/tabs/Usage";

// Contract-based customer routes
import { CustomersList } from "./CustomersList/CustomersList";
import { CustomerAuditLogs as T10CustomerAuditLogs } from "app/pages/Customer/tabs/AuditLogs";
import {
  ContractCreatePage,
  AmendmentCreate,
  CustomerContractOverview,
  CustomerContractLifecycle,
  CustomerContractInvoices,
  CustomerContractCommits,
} from "./Customer/Contracts";
import {
  ContractCustomerPlanOverview,
  ContractCustomerPlanInvoices,
} from "./Customer/Plans";

// Other routes
import { CorrectInvoice } from "app/pages/Customer/tabs/Invoices/CorrectInvoice";
import { CustomerCommitOverview } from "./Customer/Contracts/Commits";
import { CreateProduct } from "./Pricing/CreateAndEditProductV2/CreateProduct";
import { Customer } from "app/pages/Customer";
import { CustomerInvoices } from "app/pages/Customer/tabs/Invoices";
import { Commits } from "app/pages/Customer/tabs/Commits";
import { CustomerOverview as T10CustomerOverview } from "app/pages/Customer/tabs/Overview";
import { CustomerAlerts as T10CustomerAlerts } from "app/pages/Customer/tabs/Alerts";
import { CustomerSettings as T10CustomerSettings } from "app/pages/Customer/tabs/Settings";
import { CustomerCredits as T10CustomerCredits } from "app/pages/Customer/tabs/Credits";
import { AutoTopups as T10AutoTopups } from "app/pages/Customer/tabs/AutoTopups";
import { EditContract } from "./Customer/Contracts/Edit/EditContract";

const CommitsRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/commits([\/?#]|$)/,
    "/commits-and-credits$1",
  );
  return <Navigate to={newPath} replace />;
};

const BalancesRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/balances([\/?#]|$)/,
    "/commits-and-credits$1",
  );
  return <Navigate to={newPath} replace />;
};

const ContractPricingRedirect: React.FC = () => {
  const { pathname } = useLocation();
  const newPath = pathname.replace(
    /\/contract-pricing([\/?#]|$)/,
    "/offering$1",
  );
  return <Navigate to={newPath} replace />;
};

export const ContractCustomersRoutes: RouteObject[] = [
  {
    index: true,
    element: <T10Overview />,
  },
  {
    path: "overview",
    element: <EnvironmentRedirect to="/" />,
  },
  {
    path: "alerts",
    element: <EnvironmentRedirect to="/connections/alerts" />,
  },
  {
    path: "customers",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <CustomersList />,
      },
      {
        path: "new",
        element: <NewCustomer />,
      },
      {
        path: ":customerId",
        children: [
          {
            index: true,
            element: (
              <Customer>
                <T10CustomerOverview />
              </Customer>
            ),
          },

          // CUSTOMER LEVEL STUFF
          {
            path: "commits/*",
            element: <CommitsRedirect />,
          },
          {
            path: "balances/*",
            element: <BalancesRedirect />,
          },
          {
            path: "commits-and-credits",
            children: [
              {
                index: true,
                element: (
                  <Customer>
                    <Commits />
                  </Customer>
                ),
              },
              {
                path: ":commitId",
                children: [
                  {
                    index: true,
                    element: <CustomerCommitOverview />,
                  },
                ],
              },
            ],
          },
          {
            path: "credits",
            element: (
              <Customer>
                <T10CustomerCredits />
              </Customer>
            ),
          },
          {
            path: "usage",
            element: (
              <Customer>
                <Usage />
              </Customer>
            ),
          },
          {
            path: "alerts",
            element: (
              <Customer>
                <T10CustomerAlerts />
              </Customer>
            ),
          },
          {
            path: "auto-topups",
            element: (
              <Customer>
                <T10AutoTopups />
              </Customer>
            ),
          },
          {
            path: "settings",
            element: (
              <Customer>
                <T10CustomerSettings />
              </Customer>
            ),
          },
          {
            path: "audit-logs",
            element: (
              <Customer>
                <T10CustomerAuditLogs />
              </Customer>
            ),
          },
          {
            path: "invoices",
            children: [
              {
                index: true,
                element: (
                  <Customer>
                    <CustomerInvoices />
                  </Customer>
                ),
              },
              {
                path: ":invoiceId",
                children: [
                  {
                    index: true,
                    element: (
                      <Customer>
                        <CustomerInvoices />
                      </Customer>
                    ),
                  },
                  {
                    path: "correct",
                    element: <CorrectInvoice />,
                  },
                ],
              },
            ],
          },

          {
            path: "contracts/add",
            children: [
              {
                index: true,
                element: <ContractCreatePage />,
              },
            ],
          },

          // CUSTOMER CONTRACT
          {
            path: "contracts/:contractId",
            children: [
              {
                index: true,
                element: <CustomerContractOverview />,
              },
              {
                path: "amend",
                element: <AmendmentCreate />,
              },
              {
                path: "edit",
                element: <EditContract />,
              },
              {
                path: "lifecycle",
                children: [
                  {
                    index: true,
                    element: <CustomerContractLifecycle />,
                  },
                  {
                    path: ":lifecycleId",
                    children: [
                      {
                        index: true,
                        element: <CustomerContractLifecycle />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "invoices",
                children: [
                  {
                    index: true,
                    element: <CustomerContractInvoices />,
                  },
                  {
                    path: ":invoiceId",
                    children: [
                      {
                        index: true,
                        element: <CustomerContractInvoices />,
                      },
                      {
                        path: "correct",
                        element: <CorrectInvoice />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "commits/*",
                element: <CommitsRedirect />,
              },
              {
                path: "balances/*",
                element: <BalancesRedirect />,
              },
              {
                path: "commits-and-credits",
                children: [
                  {
                    index: true,
                    element: <CustomerContractCommits />,
                  },
                  {
                    path: ":commitId",
                    element: <CustomerContractCommits />,
                  },
                ],
              },
            ],
          },

          // PLANS
          {
            path: "plans",
            children: [
              {
                index: true,
                element: <EnvironmentRedirect to="/customers" />,
              },
              {
                path: "add",
                element: <AddPlanToCustomer />,
              },
              {
                path: ":customerPlanId",
                children: [
                  {
                    index: true,
                    element: <ContractCustomerPlanOverview />,
                  },
                  {
                    path: "invoices",
                    children: [
                      {
                        index: true,
                        element: <ContractCustomerPlanInvoices />,
                      },
                      {
                        path: ":invoiceId",
                        children: [
                          {
                            index: true,
                            element: <ContractCustomerPlanInvoices />,
                          },
                          {
                            path: "correct",
                            element: <CorrectInvoice />,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "developer",
    children: [
      {
        path: "events",
        element: <EnvironmentRedirect to="/connections/events" />,
      },
      {
        path: "tokens",
        element: <EnvironmentRedirect to="/connections/api-tokens-webhooks" />,
      },
      {
        path: "webhooks",
        element: <EnvironmentRedirect to="/connections/api-tokens-webhooks" />,
      },
    ],
  },
  {
    path: "contract-pricing",
    element: <ContractPricingRedirect />,
    children: [
      {
        path: "rate-cards",
        element: <ContractPricingRedirect />,
      },
      {
        path: "rate-cards/:id",
        element: <ContractPricingRedirect />,
      },
      {
        path: "rate-cards/new",
        element: <ContractPricingRedirect />,
      },
      {
        path: "rate-cards/create",
        element: <ContractPricingRedirect />,
        children: [
          {
            path: "",
            element: <ContractPricingRedirect />,
            index: true,
          },
          {
            path: "dimensional-pricing",
            element: <ContractPricingRedirect />,
          },
          {
            path: "rate-products",
            element: <ContractPricingRedirect />,
          },
        ],
      },
      {
        path: "rate-cards/:id/edit",
        element: <ContractPricingRedirect />,
      },
      {
        path: "products",
        children: [
          {
            index: true,
            element: <ContractPricingRedirect />,
          },
          {
            path: ":id",
            element: <ContractPricingRedirect />,
          },
          {
            path: "create",
            element: <CreateProduct />,
          },
        ],
      },
    ],
  },
];
