import React from "react";
import { Modal } from "components/Modal";

import { Button } from "components/Button";
import { Form, FormController } from "app/lib/FormController";

export function useConfirmClose<S extends Form.Shape>(
  ctrl: FormController<S>,
  onConfirmedCancel: () => void,
): [confirmingCloseEl: React.ReactNode | undefined, confirmClose: () => void] {
  const snapshot = React.useMemo(() => ctrl.snapshot(), []);
  const [confirmingClose, setConfirmingClose] = React.useState(false);

  const confirmingCloseEl = confirmingClose ? (
    <Modal
      title="Discard changes?"
      isOpen
      onClose={() => setConfirmingClose(false)}
      modalButtons={[
        <Button
          key="cancel"
          onClick={() => setConfirmingClose(false)}
          text="Cancel"
          theme="primary"
        />,
        <Button
          key="confirm"
          onClick={onConfirmedCancel}
          text="Confirm"
          theme="secondary"
        />,
      ]}
    >
      <p>Are you sure you want to close this form? You have unsaved changes.</p>
    </Modal>
  ) : undefined;

  function confirmClose() {
    if (ctrl.matchesSnapshot(snapshot)) {
      onConfirmedCancel();
      return;
    }

    setConfirmingClose(true);
  }

  return [confirmingCloseEl, confirmClose];
}
