import { useApolloClient } from "@apollo/client";
import { Button } from "components/Button";
import {
  ExistingDestination,
  PreparedDestination,
  useGetDestinations,
  useUpdateDestination,
  prepareDestinationWithForm,
  prepareDestinationFromExisting,
  useDestination,
  useDestinationForm,
  useTestExistingConnection,
} from "@prequel/react";
import { useParams } from "react-router-dom";
import { AppShell } from "components/AppShell";
import {
  fetchPrequelAuthTokenForDestination,
  PREQUEL_API_URL,
  useDraftInvoicesExportEnabled,
} from "app/lib/dataExport";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useNavigate } from "app/lib/useNavigate";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSnackbar } from "components/deprecated/Snackbar";
import { reportToSentry } from "app/lib/errors/sentry";
import { LoadingSpinner, Subtitle } from "design-system";
import { ProductsAndModels } from "components/DataExport/ProductsAndModels";
import { TestConnection } from "components/DataExport/TestConnection";
import { EmptyState } from "components/EmptyState";
import { DestinationForm } from "components/DataExport/DestinationForm";

const UpdateDataExportDestination: React.FC = () => {
  const navigate = useNavigate();
  const pushMessage = useSnackbar();
  const apolloClient = useApolloClient();
  const applicationOrigin = window.location.origin;

  const existingDestinationId = useParams<{ destinationId: string }>()
    .destinationId;

  const dataExportDraftInvoicesEnabled = useDraftInvoicesExportEnabled();

  const dataExportChangesDisabled =
    useFeatureFlag<boolean>("disable-data-export-ui-changes", false) || false;

  const [connectionValid, setConnectionValid] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [showEmptyStateErrorPage, setShowEmptyStateErrorPage] =
    useState<boolean>(false);

  async function fetchPrequelAuthTokenToEditDestination(
    preparedDestination?: PreparedDestination | ExistingDestination,
  ): Promise<string> {
    try {
      const data = await fetchPrequelAuthTokenForDestination(
        apolloClient,
        applicationOrigin,
        preparedDestination,
      );

      return data.token_string;
    } catch (e) {
      setShowEmptyStateErrorPage(true);
      reportToSentry(new Error(`Failed to generate scoped auth token: ${e}`));
      throw new Error(`Failed to generate scoped auth token`);
    }
  }

  const formRef = useRef<HTMLFormElement>(null);
  const [destination, setDestination] = useDestination();

  const destinationForm = useDestinationForm(
    destination,
    process.env.PREQUEL_ORGANIZATION_ID ?? "",
    {
      includeInternalFields: false,
      host: PREQUEL_API_URL,
    },
  );

  const [currentDestination, setCurrentDestination] =
    useState<ExistingDestination>();

  const getDestinations = useGetDestinations(
    fetchPrequelAuthTokenToEditDestination,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  const updateDestination = useUpdateDestination(
    fetchPrequelAuthTokenToEditDestination,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  const testConnection = useTestExistingConnection(
    fetchPrequelAuthTokenToEditDestination,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  useEffect(() => {
    const fetchDestinations = async () => {
      const destination = (await getDestinations()).find(
        (d) => d.id === existingDestinationId,
      );

      if (!destination) {
        // destination not found, back to data export page
        navigate("/connections/data-export");
        return;
      }

      setCurrentDestination(destination);
    };

    fetchDestinations().catch((e) => {
      setShowEmptyStateErrorPage(true);
      reportToSentry(new Error(`Failed to fetch destination: ${e}`));
    });
  }, []);

  useEffect(() => {
    if (!currentDestination) {
      // not yet loaded
      return;
    }

    const converted = prepareDestinationFromExisting(currentDestination);
    setDestination(converted);
  }, [currentDestination]);

  const preparedDestination = useMemo(
    () => prepareDestinationWithForm(destination, destinationForm),
    [destination, destinationForm],
  );

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  async function testExistingConnection() {
    if (!currentDestination) {
      // this shouldn't be possible
      throw new Error("No destination found");
    }

    return await testConnection(currentDestination, preparedDestination);
  }

  async function updateExistingDestination() {
    if (!currentDestination) {
      return;
    }

    setSubmitLoading(true);
    const response = await updateDestination(
      currentDestination,
      preparedDestination,
    );
    if (response.status === "success") {
      pushMessage({
        content: "Successfully updated data export integration",
        type: "success",
      });
      navigate("/connections/data-export");
      return;
    }

    pushMessage({
      content: `Error updating data export integration, please try again`,
      type: "error",
    });

    reportToSentry(
      new Error(
        "Error in updating destination: " + response.message || "Unknown",
      ),
    );

    setSubmitLoading(false);
  }

  let content = <></>;
  if (showEmptyStateErrorPage) {
    content = (
      <EmptyState
        icon="database02"
        mainText="We ran into an issue loading this page"
        supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
      />
    );
  } else if (!destinationForm || !currentDestination) {
    content = (
      <div className="mt-32 w-auto text-center">
        <LoadingSpinner className="h-24 w-24" />
        <Subtitle level={2} className="">
          Loading your data export destination...
        </Subtitle>
      </div>
    );
  } else {
    content = (
      <div className="px-xl py-xl">
        <DestinationForm
          formRef={formRef}
          form={destinationForm}
          destination={destination}
          setDestination={setDestination}
        />
        <ProductsAndModels
          fetchPrequelAuthToken={fetchPrequelAuthTokenToEditDestination}
          destination={destination}
          setDestination={setDestination}
          draftInvoicesModelsEnabled={dataExportDraftInvoicesEnabled}
        />
        <TestConnection
          testConnection={testExistingConnection}
          validateForm={validateForm}
          onSuccess={(success) => {
            setConnectionValid(success);
          }}
        />
      </div>
    );
  }

  return (
    <AppShell
      title="Update your data export integration"
      headerProps={{
        actions: [
          <Button
            onClick={() => navigate("/connections/data-export")}
            className="mr-4"
            text="Cancel"
            theme="linkGray"
          />,
          <Button
            onClick={updateExistingDestination}
            disabled={
              !connectionValid || submitLoading || dataExportChangesDisabled
            }
            loading={submitLoading}
            text="Update"
            theme="primary"
          />,
        ],
      }}
    >
      {content}
    </AppShell>
  );
};

export default UpdateDataExportDestination;
