import React from "react";

import { RootCtrl, useRootCtrl } from "./RootCtrl";
import { CreditType } from "app/types/credit-types";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";
import { DatePicker } from "components/DatePicker";
import { dayjs } from "lib/dayjs";
import { CommitPriceInput } from "../../lib/CommitPriceInput";
import { Schema } from "../../Schema";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";

// For one-time charges, the whole schedule is just one item
const useOneTimeCtrl = useRootCtrl.child(Schema.FixedScheduleItem, {
  read(parent, timeframe: DefaultTimeframe) {
    const schedule = parent.get("schedule");

    if (!schedule || schedule.type !== "fixed" || schedule.items.length !== 1) {
      return {
        date: timeframe.startingAt,
      };
    }

    const {
      id,
      date = timeframe.startingAt,
      unitPrice,
      quantity,
    } = schedule.items[0];
    return {
      id,
      date,
      unitPrice,
      quantity,
    };
  },
  write(child) {
    return {
      schedule: {
        type: "fixed",
        items: [child.getUnvalidatedInputs()],
      },
    };
  },
});

export const OneTimeSchedule: React.FC<{
  parent: RootCtrl;
  creditType: CreditType;
  onCreditTypeChange: (creditType: CreditType) => void;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useOneTimeCtrl(props.parent, timeframe);

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "unitPrice",
  );

  return (
    <div className="mt-12 flex flex-row gap-[24px]">
      <div className="flex flex-1 flex-col">
        <p className="mb-4 min-h-[20px] text-sm text-black">Invoice amount</p>
        <CommitPriceInput
          unitPrice={ctrl.get("unitPrice")}
          creditType={props.creditType}
          onPriceChange={(price) => {
            ctrl.update({
              unitPrice: price,
            });
          }}
          onCreditTypeChange={props.onCreditTypeChange}
          isInvalid={!ctrl.isValid("unitPrice")}
          hintText={
            (!ctrl.appearsValid() && ctrl.state.fields.unitPrice?.error) || ""
          }
        />
      </div>
      <div className="flex flex-none flex-col">
        <div className="mb-4 min-h-[20px]"></div>
        <DatePicker
          text="Invoice at"
          value={
            ctrl.get("date") ? dayjs.utc(ctrl.get("date")).toDate() : undefined
          }
          onDateApply={(date) => {
            ctrl.update({
              date: date?.toISOString(),
            });
          }}
          tooltipContent={{
            label: "Invoice at",
          }}
        />
      </div>
    </div>
  );
};
