import {
  AWSMarketplaceCustomerSettings,
  AzureMarketplaceCustomerSettings,
} from "app/lib/billingProvider/billingProviderSettings";
import {
  BillingProviderEnum_Enum,
  CommitType,
} from "types/generated-graphql/__types__";
import { ContractFormController } from "./ContractContext";
import { ScheduledChargeForEditsFragment } from "./data.graphql";
import { Schema } from "./Schema";
import Decimal from "decimal.js";
import { USD_CREDIT_ID } from "app/lib/credits";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { dayjs } from "lib/dayjs";
import { CommitDetailsForEditsFragment } from "../Edit/data.graphql";

export function onConnectAzure(
  ctrl: ContractFormController,
  azureSettingsForContracts: AzureMarketplaceCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  },
) {
  ctrl.get("billingProviderConfiguration")
    ?.billing_provider_configuration_id ===
  azureSettingsForContracts.customerBillingProviderConfigurationID
    ? ctrl.update({
        billingProvider: undefined,
        billingProviderConfiguration: undefined,
      })
    : ctrl.update({
        billingProvider: BillingProviderEnum_Enum.Azure,
        billingProviderConfiguration: {
          billing_provider_configuration_id:
            azureSettingsForContracts.customerBillingProviderConfigurationID,
          configuration: {
            azure_subscription_id: azureSettingsForContracts.subscriptionId,
          },
        },
      });
}

export function onConnectAWS(
  ctrl: ContractFormController,
  awsSettingsForContracts: AWSMarketplaceCustomerSettings & {
    customerBillingProviderConfigurationID?: string;
  },
) {
  ctrl.get("billingProviderConfiguration")
    ?.billing_provider_configuration_id ===
  awsSettingsForContracts.customerBillingProviderConfigurationID
    ? ctrl.update({
        billingProvider: undefined,
        billingProviderConfiguration: undefined,
      })
    : ctrl.update({
        billingProvider: BillingProviderEnum_Enum.AwsMarketplace,
        billingProviderConfiguration: {
          billing_provider_configuration_id:
            awsSettingsForContracts.customerBillingProviderConfigurationID,
          configuration: {
            aws_customer_id: awsSettingsForContracts.customerId,
            aws_product_code: awsSettingsForContracts.productCode,
            aws_region: awsSettingsForContracts.region,
            aws_is_subscription_product:
              awsSettingsForContracts.isSubscriptionProduct,
          },
        },
      });
}

export const formatCommits = (commits: CommitDetailsForEditsFragment[]) => {
  const formattedCommits = commits.map((commit) => {
    let commitFlyoverRoot: Schema.Types.CommitFlyoverRoot["commit"] = {
      ...commit,
      level: "contract",
      accessSchedule: commit.access_schedule.schedule_items.map((item) => ({
        id: item.id,
        amount: parseFloat(item.amount),
        date: new Date(item.date).toISOString(),
        endDate: new Date(item.end_date).toISOString(),
        editability: item.editability,
        removability: item.removability,
      })),
      priority: parseFloat(commit.priority),
      accessScheduleCreditTypeId: commit.access_schedule.credit_type.id,
      rolloverFraction: commit.rollover_fraction
        ? new Decimal(commit.rollover_fraction).times(100).toNumber()
        : undefined,
      ...(commit.__typename === "PrepaidCommit"
        ? {
            type: CommitType.Prepaid,
            externalType: commit.external_type,
            // Convert recurring schedules to fixed for existing commits
            // This is because we cannot edit a recurring schedule and must
            // edit invoice schedule as a "custom" schedule
            billingSchedule: {
              type: "fixed",
              items:
                commit.invoice_schedule?.schedule_items.map((item) => {
                  return {
                    id: item.id,
                    date: dayjs(item.date).toISOString(),
                    // Manually round to 15 decimal places to avoid floating
                    // point error
                    unitPrice: new Decimal(item.unit_price)
                      .toDecimalPlaces(15)
                      .toNumber(),
                    quantity: new Decimal(item.quantity)
                      .toDecimalPlaces(15)
                      .toNumber(),
                    editability: item.editability,
                    removability: item.removability,
                  };
                }) ?? [],
            },
            billingScheduleCreditTypeId:
              Commit.getInvoiceScheduleCreditId(commit),
            billingScheduleFrequency: "custom",
          }
        : {
            billingSchedule:
              Commit.getPostpaidBillingScheduleFromInvoiceSchedule(commit),
            type: CommitType.Postpaid,
          }),
    };

    // RFs come in as decimal numbers, but the UI renders them as %
    const newCommit: Schema.Types.CommitFlyoverRoot = {
      // needed for CommitTable
      type: Commit.getCommitType(commit),
      productId: commit.product.id,
      id: commit.id,
      temporaryId: commit.id,
      commit: commitFlyoverRoot,
      netsuiteSalesOrderId: commit.netsuite_sales_order_id ?? undefined,
      rolloverFraction: commit.rollover_fraction
        ? new Decimal(commit.rollover_fraction).times(100).toString()
        : undefined,
      applicableProductIds: commit.applicable_products?.map((p) => p.id),
      applicableProductTags: commit.applicable_tags,
    };
    return newCommit;
  });
  return formattedCommits;
};

export const formatCharges = (charges: ScheduledChargeForEditsFragment[]) => {
  return charges.map((charge) => {
    let newCharge: Schema.Types.ScheduledCharge = {
      id: charge.id,
      productId: charge.product.id,
      // Convert recurring schedules to fixed for existing commits
      // This is because we cannot edit a recurring schedule and must
      // edit invoice schedule as a "custom" schedule
      billingSchedule: {
        type: "fixed",
        items:
          charge.schedule?.schedule_items.map((item) => {
            return {
              id: item.id,
              date: dayjs(item.date).toISOString(),
              // Manually round to 15 decimal places to avoid floating
              // point error
              unitPrice: new Decimal(item.unit_price)
                .toDecimalPlaces(15)
                .toNumber(),
              quantity: new Decimal(item.quantity)
                .toDecimalPlaces(15)
                .toNumber(),
              editability: item.editability,
              removability: item.removability,
            };
          }) ?? [],
      },
      billingScheduleCreditTypeId:
        charge.schedule.credit_type.id ?? USD_CREDIT_ID,
      billingScheduleFrequency: "custom",
      netsuiteSalesOrderId: charge.netsuite_sales_order_id ?? undefined,
    };
    return newCharge;
  });
};
