import React from "react";

import { useNavigate } from "app/lib/useNavigate";
import { Customer } from "app/pages/Contracts/lib/Customer";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { AppShell } from "components/AppShell";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { FormController } from "app/lib/FormController";
import {
  getUserFacingErrorMessage,
  UserFacingError,
} from "app/lib/errors/errorHandling";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";

import {
  useCreateContractMutation,
  useExistingCustomerContractsQuery,
  ContractCreate_ContractPricingFragment as Pricing,
  ContractCreate_ExistingContractFragment as ExistingContract,
  useContractPricingAndCreditTypesQuery,
} from "./data.graphql";

import { Schema } from "../CreateAndEdit/Schema";
import { ContractDetailsSection } from "./Sections/ContractDetails";
import { FooterBar } from "./components/FooterBar";
import { CommitsSection } from "./Sections/Commits";
import { RatesSection } from "./Sections/RateCard";
import { ContractBillingConfigurationSection } from "./Sections/BillingConfiguration";
import { AdditionalTermsSection } from "./Sections/AdditionalTerms";
import { useSnackbar } from "components/deprecated/Snackbar";
import { DefaultTimeframe } from "../CreateAndEdit/lib/DefaultTimeframe";
import { getCreateMutationVars } from "./convertFormToMutationVars";
import { useFlyovers, useSnapshot, Snapshot } from "./lib/SharedContext";
import { useRequiredParam } from "app/lib/routes/params";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { CreditsSection } from "./Sections/Commits/CreditsSection";
import { CreditType } from "app/types/credit-types";
import { filterAndSortCreditTypes } from "app/pages/Contracts/lib/CreditTypes";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { doContractsOverlap } from "../../../lib/Contract/Contract";
import { ContractOverlapConfirmationModal } from "./ContractOverlapConfirmationModal";
import { ContractFormV2 } from "../CreateAndEdit/components/ContractFormV2";

export type CreateContractCtrl = ReturnType<typeof useCreateContractCtrl>;
const useCreateContractCtrl = FormController.createHook(
  Schema.CreateContractInput,
  {
    init(snapshot: Snapshot, pricing: Pricing) {
      const initial = FormController.parseJsonSnapshot(
        Schema.CreateContractInput,
        snapshot.initialJson,
      );

      return initial?.rateCardId
        ? initial
        : {
            ...initial,
            rateCardId: pricing.rate_cards.at(0)?.id,
          };
    },
  },
);

const ContractCreateForm: React.FC<{
  pricing: Pricing;
  existingContracts: ExistingContract[];
  customerId: string;
  snapshot: Snapshot;
  onCancel: () => void;
  fiatCreditTypes: CreditType[];
  customCreditTypes: CreditType[];
}> = (props) => {
  const ctrl = useCreateContractCtrl(props.snapshot, props.pricing);
  React.useEffect(() => {
    props.snapshot.update(ctrl);
  }, [ctrl]);

  const [
    showContractOverlapConfirmationModal,
    setShowContractOverlapConfirmationModal,
  ] = React.useState(false);

  const [pendingCreateContractInput, setPendingCreateContractInput] =
    React.useState<Schema.Types.CreateContractInput | null>(null);

  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const customBillingCycleDateEnabled = useFeatureFlag<boolean>(
    "allow-custom-billing-cycle-anchor-date",
    false,
  );
  const scheduledChargesOnUsageInvoicesEnabled = useFeatureFlag<boolean>(
    "scheduled-charges-on-usage-invoices",
    false,
  );

  const { flyoverElement, setFlyover } = useFlyovers({
    ctrl,
    allProducts: Array.from(props.pricing.products),
    contract: {
      starting_at: ctrl.get("startingAt") ?? null,
      ending_before: ctrl.get("endingBefore") ?? null,
      multiplier_override_prioritization:
        ctrl.get("multiplierOverridePrioritization") ?? null,
    },
    options: {
      netsuiteEnabled: nonGAContractFeaturesEnabled?.includes("NETSUITE"),
      level: "contract",
    },
  });

  const navigate = useNavigate();
  const pushMessage = useSnackbar();

  const [createContractMutation, createContractMutationResult] =
    useCreateContractMutation();

  const createContract = async (
    createContractInput: Schema.Types.CreateContractInput,
  ) => {
    try {
      if (createContractMutationResult.loading) {
        return;
      }

      const result = await createContractMutation({
        variables: getCreateMutationVars(props.customerId, createContractInput),
        update(cache) {
          cache.evict({ id: `Customer:${props.customerId}` });
        },
      });
      const newContract = result.data?.create_contract;
      if (!newContract) {
        throw new UserFacingError(
          "Failed to create contract, server did not respond as expected",
        );
      }

      props.snapshot.clear();
      navigate(Contract.getRoutePath(newContract));
      pushMessage({
        type: "success",
        content: `New contract successfully created`,
      });
    } catch (e) {
      pushMessage({
        content: getUserFacingErrorMessage(e, "Failed to create contract"),
        type: "error",
      });
    }
  };

  const submitForm = FormController.useSubmitHandler(ctrl, async (valid) => {
    if (createContractMutationResult.loading) {
      return;
    }

    const overlappingContracts = props.existingContracts.filter(
      (existingContract) => {
        return doContractsOverlap(
          {
            startingAt: valid.startingAt,
            endingBefore: valid.endingBefore ?? null,
          },
          {
            startingAt: existingContract.starting_at,
            endingBefore: existingContract.ending_before,
          },
        );
      },
    );

    if (overlappingContracts.length > 0) {
      setPendingCreateContractInput(valid);
      setShowContractOverlapConfirmationModal(true);
      return;
    }

    await createContract(valid);
  });

  const allCreditTypes = [...props.fiatCreditTypes, ...props.customCreditTypes];
  const rateCardId = ctrl.get("rateCardId");

  return (
    <DefaultTimeframe.Provider
      startingAt={ctrl.get("startingAt")}
      endingBefore={ctrl.get("endingBefore")}
    >
      {flyoverElement}
      {showContractOverlapConfirmationModal &&
        pendingCreateContractInput !== null && (
          <ContractOverlapConfirmationModal
            onSave={async () => {
              setShowContractOverlapConfirmationModal(false);
              await createContract(pendingCreateContractInput);
            }}
            onClose={() => {
              setShowContractOverlapConfirmationModal(false);
            }}
          />
        )}
      <form onSubmit={submitForm} className="h-full">
        <div className="-mx-12 flex h-full flex-col overflow-hidden">
          <div className="grow overflow-auto">
            <ContractDetailsSection
              ctrl={ctrl}
              pricing={props.pricing}
              existingContracts={props.existingContracts}
              options={{
                supersedeEnabled: nonGAContractFeaturesEnabled?.includes(
                  "TRANSITION_SUPERSEDE",
                ),
                customBillingCycleDateEnabled,
              }}
            />
            <CommitsSection
              ctrl={ctrl}
              pricing={props.pricing}
              onAddCommit={() =>
                setFlyover({
                  type: "new_commit",
                  fiatCreditTypes: props.fiatCreditTypes,
                  customCreditTypes: props.customCreditTypes,
                  rateCardId,
                })
              }
              onEditCommit={(id) =>
                setFlyover({
                  type: "edit_commit",
                  fiatCreditTypes: props.fiatCreditTypes,
                  customCreditTypes: props.customCreditTypes,
                  id,
                  rateCardId,
                })
              }
              creditTypes={allCreditTypes}
            />
            <CreditsSection
              ctrl={ctrl}
              pricing={props.pricing}
              onAddCredit={() =>
                setFlyover({
                  type: "new_credit",
                  fiatCreditTypes: props.fiatCreditTypes,
                  customCreditTypes: props.customCreditTypes,
                  rateCardId,
                })
              }
              onEditCredit={(id) =>
                setFlyover({
                  type: "edit_credit",
                  fiatCreditTypes: props.fiatCreditTypes,
                  customCreditTypes: props.customCreditTypes,
                  id,
                  rateCardId,
                })
              }
              creditTypes={allCreditTypes}
            />
            <RatesSection
              ctrl={ctrl}
              pricing={props.pricing}
              allProducts={props.pricing.products}
              baseContractOverrideDescriptions={[]}
              contract={{
                starting_at: ctrl.get("startingAt") ?? null,
                ending_before: ctrl.get("endingBefore") ?? null,
              }}
              rateCardId={rateCardId}
              onAddOverride={(productId: string, creditType) =>
                setFlyover({
                  type: "new_override",
                  productId,
                  creditType,
                  rateCardId,
                })
              }
              onEditOverride={(overrideId: string, creditType) =>
                setFlyover({
                  type: "edit_override",
                  id: overrideId,
                  creditType,
                  rateCardId,
                })
              }
              onViewOverrides={() =>
                setFlyover({ type: "view_overrides", rateCardId })
              }
            />
            <AdditionalTermsSection
              ctrl={ctrl}
              pricing={props.pricing}
              fiatCreditTypes={props.fiatCreditTypes}
              onAdd={(type: Schema.Types.AdditionalTermType) => {
                setFlyover({
                  type: `new_${type}`,
                  fiatCreditTypes: props.fiatCreditTypes,
                });
              }}
              onEdit={(type: Schema.Types.AdditionalTermType, id: string) =>
                setFlyover({
                  type: `edit_${type}`,
                  id,
                  fiatCreditTypes: props.fiatCreditTypes,
                })
              }
              options={{
                resellerRoyaltiesEnabled:
                  nonGAContractFeaturesEnabled?.includes("RESELLER_ROYALTIES"),
                discountsEnabled:
                  nonGAContractFeaturesEnabled?.includes("DISCOUNTS"),
                proServicesEnabled: nonGAContractFeaturesEnabled?.includes(
                  "PROFESSIONAL_SERVICES",
                ),
              }}
            />
            <ContractBillingConfigurationSection
              ctrl={ctrl}
              customerID={props.customerId}
              options={{
                salesforceEnabled:
                  nonGAContractFeaturesEnabled?.includes("SALESFORCE"),
                netsuiteEnabled:
                  nonGAContractFeaturesEnabled?.includes("NETSUITE"),
                scheduledChargesOnUsageInvoicesEnabled,
              }}
            />
          </div>
          <FooterBar
            right={
              <>
                <Button
                  onClick={props.onCancel}
                  text="Cancel"
                  theme="linkGray"
                />
                <Button
                  loading={createContractMutationResult.loading}
                  disabled={
                    createContractMutationResult.loading || !ctrl.appearsValid()
                  }
                  onClick={submitForm}
                  text="Save"
                  theme="primary"
                />
              </>
            }
          />
        </div>
      </form>
    </DefaultTimeframe.Provider>
  );
};

export const ContractCreatePage: React.FC = () => {
  const newContractFormEnabled = useFeatureFlag<boolean>(
    "contract-create-edit-redesign",
    false,
  );
  const customerId = useRequiredParam("customerId");
  const snapshot = useSnapshot(`contract-create-snapshot:${customerId}`);
  const pricingAndCreditTypesReq = useContractPricingAndCreditTypesQuery();
  const existingContractsReq = useExistingCustomerContractsQuery({
    variables: {
      customerId,
    },
  });

  const loading =
    pricingAndCreditTypesReq.loading || existingContractsReq.loading;
  const error = pricingAndCreditTypesReq.error ?? existingContractsReq.error;
  const pricing = pricingAndCreditTypesReq.data?.contract_pricing;
  const existingContracts = (
    existingContractsReq.data?.Customer_by_pk?.contracts ?? []
  ).filter((c) => !c.archived_at);

  const navigate = useNavigate();
  const cancel = () => {
    snapshot.clear();
    navigate(Customer.getRoutePath({ id: customerId }));
  };

  const { fiatCreditTypes, customCreditTypes } = filterAndSortCreditTypes(
    pricingAndCreditTypesReq.data?.CreditType ?? [],
  );

  const action = (
    <IconButton onClick={cancel} theme="secondary" icon="xClose" />
  );

  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );
  const relevantFiatCreditTypes = showCurrencyWork
    ? fiatCreditTypes
    : [USD_CREDIT_TYPE];

  if (newContractFormEnabled) {
    return <ContractFormV2 />;
  } else {
    return (
      <AppShell
        title="Configure your contract"
        headerProps={{
          actions: action,
          breadcrumbs: Breadcrumbs.from({
            label:
              existingContractsReq.data?.Customer_by_pk?.name ?? "Loading...",
            routePath: `/customers/${customerId}`,
          }),
        }}
      >
        {error ? (
          <ErrorEmptyState error={error} title="Failed to load the form" />
        ) : loading ? (
          <DeprecatedParagraphSkeleton numLines={20} />
        ) : !pricing ? null : (
          <ContractCreateForm
            customerId={customerId}
            snapshot={snapshot}
            pricing={pricing}
            existingContracts={existingContracts}
            onCancel={cancel}
            fiatCreditTypes={relevantFiatCreditTypes}
            customCreditTypes={customCreditTypes}
          />
        )}
      </AppShell>
    );
  }
};
