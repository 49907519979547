import {
  useGetCommitRateForCustomerQuery,
  useGetCommitRateForRateCardQuery,
} from "./data.graphql";

/**
 * Returns whether the customer has commit rates for either a given rate card or for any contract.
 * If rateCardId is provided, it will check the rate card.
 * If only customerId is provided, it will check all contracts for the customer.
 */
export function useHasCommitRate({
  customerId, // Use this if you want to check the customer's contracts
  rateCardId, // Use this if you want to check a specific rate card
}: {
  customerId?: string;
  rateCardId?: string;
}) {
  const {
    data: customerData,
    loading: customerLoading,
    error: customerError,
  } = useGetCommitRateForCustomerQuery({
    variables: { id: customerId || "" },
    skip: Boolean(rateCardId),
  });
  const {
    data: rateCardData,
    loading: rateCardLoading,
    error: rateCardError,
  } = useGetCommitRateForRateCardQuery({
    variables: { id: rateCardId || "" },
    skip: !rateCardId,
  });

  if (!customerId && !rateCardId) {
    return {
      hasCommitRate: false,
      isLoading: false,
    };
  }

  if (rateCardId && !rateCardLoading) {
    const hasCommitRateOnRateCard = Boolean(
      rateCardData?.products_and_rate_cards.rate_card.rates_per_product.some(
        (rate) => rate.rates.some((rr) => rr.commit_rate !== null),
      ),
    );

    return {
      hasCommitRate: hasCommitRateOnRateCard,
      isLoading: rateCardLoading,
      error: rateCardError,
    };
  }

  if (!rateCardId && !customerLoading) {
    const contractCommitRates = Object.fromEntries(
      customerData?.customer?.contracts
        .filter((c) => !c.archived_at && c.rate_card?.id)
        .map((contract) => [
          contract.rate_card?.id,
          contract.rate_card?.rates_per_product.some((rp) =>
            rp.rates.some((r) => r.commit_rate),
          ),
        ]) ?? [],
    );

    return {
      hasCommitRate: Object.values(contractCommitRates).some(Boolean),
      isLoading: customerLoading,
      error: customerError,
    };
  }
  return {
    isLoading: rateCardId ? customerLoading : rateCardLoading,
    error: rateCardId ? customerError : rateCardError,
  };
}
