import React from "react";
import { Column, Table } from "components/Table";
import { DeprecatedTableSkeleton } from "components/deprecated/Table";
import { AvatarWithName } from "design-system";
import {
  InvoiceExternalTypeEnum,
  InvoiceFinalizationMetadataStatus,
} from "types/generated-graphql/__types__";
import { printDate, toDayjs } from "lib/date";
import { BadgeGroup } from "components/BadgeGroup";

interface InvoiceFinalizationTableProps {
  loading?: boolean;
  data: InvoiceFinalization[];
  loadMoreData: () => void;
  hasMoreData: boolean;
}

export type InvoiceFinalization = {
  id: string;
  date: Date;
  cohort: InvoiceExternalTypeEnum;
  finalizationDate: Date;
  booksClosedDate: Date;
  invoicesFinalized: number;
  billingProviderInvoiceCount: number;
  actor: {
    id: string;
    name: string;
    deprecated_at: string | null;
  };
  status: InvoiceFinalizationMetadataStatus;
  currentBillingProviderInvoiceCount?: number;
};

const invoiceTypeToLabel = (invoiceType: InvoiceExternalTypeEnum) => {
  switch (invoiceType) {
    case InvoiceExternalTypeEnum.PlanArrears:
      return "Plan Usage Statements";
    case InvoiceExternalTypeEnum.Usage:
      return "Contract Usage Statements";
    case InvoiceExternalTypeEnum.Scheduled:
      return "Postpaid Commit True-Up Invoices";
    case InvoiceExternalTypeEnum.CreditPurchase:
    case InvoiceExternalTypeEnum.Correction:
    case InvoiceExternalTypeEnum.SeatPurchase:
    case InvoiceExternalTypeEnum.Parent:
      throw new Error("Unexpected invoice type");
    default:
      invoiceType satisfies never;
  }
};

export const InvoiceFinalizationTable: React.FC<
  InvoiceFinalizationTableProps
> = ({ loading, data, loadMoreData, hasMoreData }) => {
  const columns: Column<InvoiceFinalization>[] = [
    {
      id: "1",
      accessorKey: "date",
      header: "Date",
      cell: (props) => printDate(toDayjs(props.getValue())),
      tooltipContent: "The date the user took this action",
    },
    {
      id: "2",
      header: "Invoice cohort",
      cell: (props) => props.getValue(),
      accessorFn: (row) => invoiceTypeToLabel(row.cohort),
      tooltipContent:
        "The group of invoices selected for finalization. This can be plan usage statements, contract usage statements or postpaid commit true-up invoices.",
    },
    {
      id: "3",
      accessorKey: "finalizationDate",
      header: "Finalization date",
      cell: (props) => printDate(toDayjs(props.getValue())),
      tooltipContent:
        "The finalization date selected. All invoices for the selected cohort that existed at the time of the action with end dates on or before this date were finalized.",
    },
    {
      id: "4",
      accessorKey: "booksClosedDate",
      header: "Accounting period close date",
      cell: (props) => printDate(toDayjs(props.getValue())),
      tooltipContent:
        "This is the NetSuite accounting period close date selected.",
    },
    {
      id: "5",
      header: "Total invoices finalized",
      accessorKey: "invoicesFinalized",
      cell: (props) => props.getValue(),
      tooltipContent: "The number of invoices queued for finalization.",
    },
    {
      id: "6",
      header: "Invoices processed by billing provider",
      cell: (props) => {
        const invoiceCount = props.getValue();
        const status = props.row.original.status;
        return status === InvoiceFinalizationMetadataStatus.Finalized ? (
          invoiceCount
        ) : (
          <BadgeGroup
            mainLabel={`${invoiceCount} to go`}
            badgeLabel="Processing"
            icon="refreshCw05"
          />
        );
      },
      tooltipContent:
        "The number of invoices processed by your billing provider. You won't be able to change your accounting period closed date while this process is not complete.",
      accessorFn: (row) => {
        const invoiceCount =
          row.status === InvoiceFinalizationMetadataStatus.Finalized
            ? row.billingProviderInvoiceCount
            : row.billingProviderInvoiceCount -
              (row.currentBillingProviderInvoiceCount || 0);
        return invoiceCount;
      },
    },
    {
      id: "7",
      header: "Actor",
      cell: (props) => {
        const actor = { ...props.row.original.actor };

        return <AvatarWithName {...actor} />;
      },
      tooltipContent: "The user who took this action.",
      accessorFn: (row) => row.actor.name,
    },
  ];

  return loading ? (
    <DeprecatedTableSkeleton
      numRows={15}
      columnNames={columns.map((c) => c.header ?? "")}
    />
  ) : (
    <Table
      title="Finalization log"
      defaultSort={[{ id: "1", desc: true }]}
      loading={loading}
      data={data}
      columns={columns}
      paginationOptions={{
        type: "loadMore",
        loadMoreButton: {
          onClick: loadMoreData,
          disabled: !hasMoreData,
        },
      }}
    />
  );
};
