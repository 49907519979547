/* 16838a52c1ebd4920a22072ae98b0685bef21321
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSalesforceAccountIdMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type DeleteSalesforceAccountIdMutation = { __typename?: 'Mutation', delete_customer_configs: { __typename?: 'AffectedRowsResponse', affected_rows: number } };


export const DeleteSalesforceAccountIdDocument = gql`
    mutation DeleteSalesforceAccountId($customer_id: uuid!) {
  delete_customer_configs(
    customer_id: $customer_id
    keys: ["salesforce_account_id"]
  ) {
    affected_rows
  }
}
    `;
export type DeleteSalesforceAccountIdMutationFn = Apollo.MutationFunction<DeleteSalesforceAccountIdMutation, DeleteSalesforceAccountIdMutationVariables>;

/**
 * __useDeleteSalesforceAccountIdMutation__
 *
 * To run a mutation, you first call `useDeleteSalesforceAccountIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesforceAccountIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesforceAccountIdMutation, { data, loading, error }] = useDeleteSalesforceAccountIdMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useDeleteSalesforceAccountIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesforceAccountIdMutation, DeleteSalesforceAccountIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalesforceAccountIdMutation, DeleteSalesforceAccountIdMutationVariables>(DeleteSalesforceAccountIdDocument, options);
      }
export type DeleteSalesforceAccountIdMutationHookResult = ReturnType<typeof useDeleteSalesforceAccountIdMutation>;
export type DeleteSalesforceAccountIdMutationResult = Apollo.MutationResult<DeleteSalesforceAccountIdMutation>;
export type DeleteSalesforceAccountIdMutationOptions = Apollo.BaseMutationOptions<DeleteSalesforceAccountIdMutation, DeleteSalesforceAccountIdMutationVariables>;