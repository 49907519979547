/* a4bb07baab5c56f03ff9fa6feb85d2f7ad62ef31
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillableMetricsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  types: Array<Types.BillableMetricTypeEnum> | Types.BillableMetricTypeEnum;
  limit: Types.Scalars['Int'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.BillableMetricSortingInput>;
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
  group_key_filter?: Types.InputMaybe<Types.BillableMetricGroupKeyFilterEnum>;
}>;


export type BillableMetricsQuery = { __typename?: 'Query', billable_metrics: Array<{ __typename?: 'BillableMetric', id: string, name: string, aggregate: Types.BillingMetricAggregateEnum_Enum, aggregate_keys: object | null, group_keys: object | null, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, sql: string | null, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null }> };

export type BillableMetricDetailsFragment = { __typename?: 'BillableMetric', id: string, name: string, aggregate: Types.BillingMetricAggregateEnum_Enum, aggregate_keys: object | null, group_keys: object | null, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, sql: string | null, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null };

export type SeatMetricsQueryVariables = Types.Exact<{
  types: Array<Types.BillableMetricTypeEnum> | Types.BillableMetricTypeEnum;
  limit: Types.Scalars['Int'];
  sort?: Types.InputMaybe<Types.SeatMetricSortingInput>;
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SeatMetricsQuery = { __typename?: 'Query', seat_metrics: { __typename?: 'PagedSeatMetricsResult', cursor: string | null, metrics: Array<{ __typename?: 'SeatMetric', id: string, name: string, aggregate_key: string, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } }> } };

export type SeatMetricDetailsFragment = { __typename?: 'SeatMetric', id: string, name: string, aggregate_key: string, filter: object, created_at: string, is_draft: boolean, deleted_at: string | null, active_plan_count: number, active_product_count: number, Creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } };

export const BillableMetricDetailsFragmentDoc = gql`
    fragment BillableMetricDetails on BillableMetric {
  id
  name
  aggregate
  aggregate_keys
  group_keys
  filter
  created_at
  is_draft
  deleted_at
  Creator {
    name
    id
    deprecated_at
  }
  active_plan_count
  active_product_count
  sql
  __environment_type: environment_type
}
    `;
export const SeatMetricDetailsFragmentDoc = gql`
    fragment SeatMetricDetails on SeatMetric {
  id
  name
  aggregate_key
  filter
  created_at
  is_draft
  deleted_at
  Creator {
    name
    id
    deprecated_at
  }
  active_plan_count
  active_product_count
}
    `;
export const BillableMetricsDocument = gql`
    query BillableMetrics($environment_type: EnvironmentTypeEnum_enum!, $types: [BillableMetricTypeEnum!]!, $limit: Int!, $search: String, $sort: BillableMetricSortingInput, $cursor: uuid, $group_key_filter: BillableMetricGroupKeyFilterEnum) {
  billable_metrics(
    environment_type: $environment_type
    types: $types
    limit: $limit
    search: $search
    sort: $sort
    cursor: $cursor
    group_key_filter: $group_key_filter
  ) {
    id
    ...BillableMetricDetails
    __environment_type: environment_type
  }
}
    ${BillableMetricDetailsFragmentDoc}`;

/**
 * __useBillableMetricsQuery__
 *
 * To run a query within a React component, call `useBillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillableMetricsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      types: // value for 'types'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      cursor: // value for 'cursor'
 *      group_key_filter: // value for 'group_key_filter'
 *   },
 * });
 */
export function useBillableMetricsQuery(baseOptions: Apollo.QueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(BillableMetricsDocument, options);
      }
export function useBillableMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillableMetricsQuery, BillableMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillableMetricsQuery, BillableMetricsQueryVariables>(BillableMetricsDocument, options);
        }
export type BillableMetricsQueryHookResult = ReturnType<typeof useBillableMetricsQuery>;
export type BillableMetricsLazyQueryHookResult = ReturnType<typeof useBillableMetricsLazyQuery>;
export type BillableMetricsQueryResult = Apollo.QueryResult<BillableMetricsQuery, BillableMetricsQueryVariables>;
export const SeatMetricsDocument = gql`
    query SeatMetrics($types: [BillableMetricTypeEnum!]!, $limit: Int!, $sort: SeatMetricSortingInput, $cursor: String) {
  seat_metrics(types: $types, limit: $limit, sort: $sort, cursor: $cursor) {
    cursor
    metrics {
      id
      ...SeatMetricDetails
    }
  }
}
    ${SeatMetricDetailsFragmentDoc}`;

/**
 * __useSeatMetricsQuery__
 *
 * To run a query within a React component, call `useSeatMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeatMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeatMetricsQuery({
 *   variables: {
 *      types: // value for 'types'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useSeatMetricsQuery(baseOptions: Apollo.QueryHookOptions<SeatMetricsQuery, SeatMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeatMetricsQuery, SeatMetricsQueryVariables>(SeatMetricsDocument, options);
      }
export function useSeatMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeatMetricsQuery, SeatMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeatMetricsQuery, SeatMetricsQueryVariables>(SeatMetricsDocument, options);
        }
export type SeatMetricsQueryHookResult = ReturnType<typeof useSeatMetricsQuery>;
export type SeatMetricsLazyQueryHookResult = ReturnType<typeof useSeatMetricsLazyQuery>;
export type SeatMetricsQueryResult = Apollo.QueryResult<SeatMetricsQuery, SeatMetricsQueryVariables>;