import React, { useMemo } from "react";
import { Button } from "components/Button";
import { Takeover } from "components/Takeover";
import { useNavigate } from "app/lib/useNavigate";
import { Customer } from "app/pages/Contracts/lib/Customer";
import { useRequiredParam } from "app/lib/routes/params";
import { ContractContext } from "../ContractContext";
import AzureMarketplaceSettingsModal from "app/pages/deprecated/Customer/tabs/Settings/components/AzureMarketplaceSettingsModal";
import AWSMarketplaceSettingsModal from "app/pages/deprecated/Customer/tabs/Settings/components/AWSMarketplaceSettingsModal";
import StripeSettingsModal from "app/pages/deprecated/Customer/tabs/Settings/components/StripeSettingsModal";
import { BillingProviderEnum_Enum } from "types/generated-graphql/__types__";
import { BasicDetails } from "../Sections/BasicDetails";
import NotFoundPage from "app/pages/404";
import { Terms } from "../Sections/Terms";
import { useFlyovers } from "../../Create/lib/SharedContext";
import { DefaultTimeframe } from "../lib/DefaultTimeframe";
import { toISOString } from "lib/date";

const ContractForm: React.FC = () => {
  const navigate = useNavigate();
  const customerId = useRequiredParam("customerId");
  const {
    ctrl,
    rateCards,
    isEdit,
    errors,
    clientHasDeltaStreamEnabled,
    newBillingProviderConfigOptions,
    existingCustomerBillingProviderConfigOptions,
    billingProviderSettings,
    stripeSettingsModalOpen,
    awsMarketplaceSettingsModalOpen,
    azureMarketplaceSettingsModalOpen,
    setAWSMarketplaceSettingsModalOpen,
    setAzureMarketplaceSettingsModalOpen,
    setStripeSettingsModalOpen,
    billingProviderDropdownDisabled,
    featureFlagOptions,
    existingContracts,
    loadingStates,
    contractToEdit,
    pricing,
  } = ContractContext.useContainer();

  const products = useMemo(() => {
    if (pricing?.contract_pricing?.products) {
      return pricing?.contract_pricing?.products;
    }
    return [];
  }, [pricing]);

  const { flyoverElement, setFlyover } = useFlyovers({
    ctrl,
    allProducts: products,
    contract: {
      starting_at: !isEdit ? ctrl.get("startingAt") ?? null : null,
      ending_before: !isEdit ? ctrl.get("endingBefore") ?? null : null,
      multiplier_override_prioritization: !isEdit
        ? ctrl.get("multiplierOverridePrioritization") ?? null
        : null,
    },
    options: {
      netsuiteEnabled: featureFlagOptions.netsuiteEnabled,
      level: "contract",
    },
  });

  if (errors.pricing || errors.contract || errors.existingContracts) {
    return <NotFoundPage />;
  }

  if (isEdit && loadingStates.contract) {
    return null;
  }

  return !loadingStates.pricing && !loadingStates.contract ? (
    <DefaultTimeframe.Provider
      startingAt={
        isEdit
          ? contractToEdit?.starting_at
            ? toISOString(contractToEdit.starting_at)
            : ""
          : ctrl.get("startingAt") ?? ""
      }
      endingBefore={
        isEdit
          ? contractToEdit?.ending_before
            ? toISOString(contractToEdit.ending_before)
            : ""
          : ctrl.get("endingBefore") ?? ""
      }
    >
      <Takeover
        isOpen={true}
        onClose={() => navigate(Customer.getRoutePath({ id: customerId }))}
        title={`${isEdit ? "Edit" : "Create"} Contract`}
        maxContainerWidth="max-w-[1440px]"
        headerButtons={[
          <Button
            key="docs"
            text="View docs"
            theme="primary"
            leadingIcon="linkExternal01"
            className="text gray-600"
            size="sm"
            linkTo="https://docs.metronome.com/manage-product-access/provision-customer/#create-a-contract"
            isExternalLink
          />,
        ]}
        footerLeadingButton={
          <Button
            key="back"
            text="Back"
            theme="secondary"
            size="lg"
            className="w-[177px]"
            onClick={() => navigate(Customer.getRoutePath({ id: customerId }))}
          />
        }
        footerTrailingButtons={[
          <Button
            key="createOrEdit"
            text="Create contract"
            theme="primary"
            size="lg"
            onClick={() => {}}
          />,
        ]}
      >
        {/* Terms Flyover */}
        {flyoverElement}
        {/* Setup Integration Modals */}
        {awsMarketplaceSettingsModalOpen && (
          <AWSMarketplaceSettingsModal
            onClose={(result) => {
              setAWSMarketplaceSettingsModalOpen(false);
              result?.isSuccess &&
                ctrl.update({
                  billingProvider: BillingProviderEnum_Enum.AwsMarketplace,
                });
            }}
            plansAndOrContracts="contracts_only"
            customerId={customerId}
          />
        )}

        {azureMarketplaceSettingsModalOpen && (
          <AzureMarketplaceSettingsModal
            onClose={(result) => {
              setAzureMarketplaceSettingsModalOpen(false);
              result?.isSuccess &&
                ctrl.update({
                  billingProvider: BillingProviderEnum_Enum.Azure,
                });
            }}
            plansAndOrContracts="contracts_only"
            customerID={customerId}
          />
        )}

        {stripeSettingsModalOpen && (
          <StripeSettingsModal
            onClose={(_) => {
              setStripeSettingsModalOpen(false);
            }}
            edit={false}
            customerId={customerId}
            stripeCustomerID=""
            stripeCollectionMethod=""
            hasBillingProviderOnPlan={
              !!billingProviderSettings?.hasBillingProviderOnPlan
            }
            hasStripeOnPlan={
              !!billingProviderSettings?.hasStripeBillingProviderCustomer
            }
            hasStripeOnContract={
              !!billingProviderSettings?.hasStripeBillingProviderConfiguration
            }
            hasStripeBillingProviderToken={
              !!billingProviderSettings?.hasStripeBillingProviderToken
            }
            hasStripeBillingProviderDeliveryMethod={
              !!billingProviderSettings?.hasStripeBillingProviderDeliveryMethod
            }
          />
        )}
        <div className="top-[98px] flex flex-col gap-[24px] pl-4xl pr-4xl">
          <BasicDetails
            ctrl={ctrl}
            rateCards={rateCards}
            clientHasDeltaStreamEnabled={clientHasDeltaStreamEnabled}
            newBillingProviderConfigOptions={newBillingProviderConfigOptions}
            existingCustomerBillingProviderConfigOptions={
              existingCustomerBillingProviderConfigOptions
            }
            billingProviderDropdownDisabled={billingProviderDropdownDisabled}
            featureFlagOptions={featureFlagOptions}
            existingContracts={existingContracts}
            loadingStates={loadingStates}
            isEdit={isEdit}
            contractToEdit={contractToEdit}
          />
          <Terms
            setFlyover={setFlyover}
            ctrl={ctrl}
            pricing={pricing}
            isEdit={isEdit}
            featureFlagOptions={featureFlagOptions}
            contractToEdit={contractToEdit}
          />
        </div>
      </Takeover>
    </DefaultTimeframe.Provider>
  ) : null;
};

export const ContractFormV2: React.FC = () => {
  return (
    <ContractContext.Provider>
      <ContractForm />
    </ContractContext.Provider>
  );
};
