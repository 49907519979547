import React from "react";

import { useNavigate } from "app/lib/useNavigate";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { useNow, latest, toISOString, dayjs } from "lib/date";
import { AppShell } from "components/AppShell";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { FormController } from "app/lib/FormController";
import {
  getUserFacingErrorMessage,
  UserFacingError,
} from "app/lib/errors/errorHandling";

import {
  useAmendContractMutation,
  useContractPricingAndCreditTypesQuery,
  useContractToAmendQuery,
} from "./data.graphql";

import { Schema } from "../CreateAndEdit/Schema";
import { AmendmentDetails } from "./Sections/AmendmentDetails";
import { FooterBar } from "./components/FooterBar";
import { CommitsSection } from "./Sections/Commits";
import { RatesSection } from "./Sections/RateCard";
import { AdditionalTermsSection } from "./Sections/AdditionalTerms";
import { useSnackbar } from "components/deprecated/Snackbar";
import { ContractPricing } from "app/pages/Contracts/lib/ContractPricing";
import { DefaultTimeframe } from "../CreateAndEdit/lib/DefaultTimeframe";
import { getAmendMutationVars } from "./convertFormToMutationVars";
import { useFlyovers, useSnapshot, Snapshot } from "./lib/SharedContext";
import { useRequiredParam } from "app/lib/routes/params";
import {
  ContractIntegrations,
  getIntegrationsDescription,
} from "./Sections/ContractIntegrations";
import { Section } from "./components/Section";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { describeOverride } from "app/pages/Contracts/lib/Override";
import { CreditsSection } from "./Sections/Commits/CreditsSection";
import { filterAndSortCreditTypes } from "app/pages/Contracts/lib/CreditTypes";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";
import { Breadcrumbs } from "app/lib/breadcrumbs";

export type CreateAmendmentCtrl = ReturnType<typeof useCreateAmendmentCtrl>;
const useCreateAmendmentCtrl = FormController.createHook(
  Schema.CreateAmendmentInput,
  {
    init(snapshot: Snapshot) {
      return FormController.parseJsonSnapshot(
        Schema.CreateAmendmentInput,
        snapshot.initialJson,
      );
    },
  },
);

export const AmendmentCreate: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const now = useNow();

  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );

  const contractReq = useContractToAmendQuery({
    variables: {
      contract_id: contractId,
      customer_id: customerId,
    },
  });

  const pricingAndCreditTypesReq = useContractPricingAndCreditTypesQuery();
  const snapshot = useSnapshot(
    `contract-create-snapshot:${customerId}:${contractId}`,
  );
  const ctrl = useCreateAmendmentCtrl(snapshot);
  React.useEffect(() => {
    snapshot.update(ctrl);
  }, [ctrl]);

  const { fiatCreditTypes, customCreditTypes } = filterAndSortCreditTypes(
    pricingAndCreditTypesReq.data?.CreditType ?? [],
  );

  const contractFromReq = contractReq.data?.Customer_by_pk?.contract;
  const { flyoverElement, setFlyover } = useFlyovers({
    ctrl,
    allProducts: pricingAndCreditTypesReq?.data?.contract_pricing.products
      ? pricingAndCreditTypesReq.data.contract_pricing.products
      : [],
    contract: contractFromReq || undefined,
    options: {
      netsuiteEnabled: nonGAContractFeaturesEnabled?.includes("NETSUITE"),
    },
  });

  const navigate = useNavigate();
  const pushMessage = useSnackbar();

  const [amendContractMutation, amendContractMutationResult] =
    useAmendContractMutation();

  const cancel = () => {
    snapshot.clear();
    navigate(
      Contract.getRoutePath({
        id: contractId,
        customer: { id: customerId },
      }),
    );
  };

  const loading = pricingAndCreditTypesReq.loading || contractReq.loading;
  const error = pricingAndCreditTypesReq.error;
  const pricing = pricingAndCreditTypesReq.data?.contract_pricing;
  const contract = contractReq.data?.Customer_by_pk?.contract;
  const rateCard =
    pricing && contract?.rate_card
      ? ContractPricing.getRateCard(pricing, contract.rate_card.id)
      : undefined;

  const netsuiteEnabled = nonGAContractFeaturesEnabled?.includes("NETSUITE");
  const salesforceEnabled =
    nonGAContractFeaturesEnabled?.includes("SALESFORCE");

  React.useEffect(() => {
    if (!contract) {
      return;
    }

    if (!ctrl.get("effectiveAt")) {
      // async default value for effectiveAt based on the contract start time
      ctrl.update({
        effectiveAt: latest(dayjs.utc(contract.starting_at), now.utc())
          .startOf("day")
          .toISOString(),
      });
    }
  }, [contract]);

  const submitForm = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      if (amendContractMutationResult.loading || !pricing) {
        return;
      }

      const result = await amendContractMutation({
        variables: getAmendMutationVars(customerId, contractId, valid),
        update(cache) {
          cache.evict({ id: `Customer:${customerId}` });
          cache.evict({ id: `Contract:${contractId}` });
          cache.gc();
        },
      });
      const amended = result.data?.amend_contract;
      if (!amended) {
        throw new UserFacingError(
          "Failed to amend contract, server sent an unexpected response",
        );
      }

      snapshot.clear();
      navigate(Contract.getRoutePath(amended));
      pushMessage({
        type: "success",
        content: `Contract successfully amended`,
      });
    } catch (e) {
      pushMessage({
        content: getUserFacingErrorMessage(e, "Failed to amend contract"),
        type: "error",
      });
    }
  });

  return (
    <AppShell
      title="Amend contract"
      headerProps={{
        actions: (
          <IconButton onClick={cancel} theme="secondary" icon="xClose" />
        ),
        breadcrumbs: Breadcrumbs.from(
          {
            label: contractReq.data?.Customer_by_pk?.name ?? "Loading...",
            routePath: `/customers/${customerId}`,
          },
          {
            label: contractReq.data?.Customer_by_pk?.contract
              ? Contract.getName(contractReq.data.Customer_by_pk.contract)
              : "",
            routePath: `/customers/${customerId}/contracts/${contractId}`,
          },
        ),
      }}
    >
      {error ? (
        <ErrorEmptyState error={error} title="Failed to load the form" />
      ) : loading || !pricing || !contract ? (
        <DeprecatedParagraphSkeleton numLines={20} />
      ) : (
        <DefaultTimeframe.Provider
          startingAt={
            ctrl.get("effectiveAt") ?? toISOString(contract.starting_at)
          }
          endingBefore={
            contract.ending_before
              ? toISOString(contract.ending_before)
              : undefined
          }
        >
          {flyoverElement}

          <form onSubmit={submitForm} className="h-full">
            <div className="-mx-12 flex h-full flex-col overflow-hidden">
              <div className="grow overflow-auto">
                <AmendmentDetails ctrl={ctrl} contract={contract} />
                <CommitsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  onAddCommit={() =>
                    setFlyover({
                      type: "new_commit",
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditCommit={(id) =>
                    setFlyover({
                      type: "edit_commit",
                      id,
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  creditTypes={[...fiatCreditTypes, ...customCreditTypes]}
                />
                <CreditsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  onAddCredit={() =>
                    setFlyover({
                      type: "new_credit",
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditCredit={(id) =>
                    setFlyover({
                      type: "edit_credit",
                      id,
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  creditTypes={[...fiatCreditTypes, ...customCreditTypes]}
                />
                <RatesSection
                  ctrl={ctrl}
                  rateCardId={rateCard?.id}
                  allProducts={pricing.products}
                  baseContractOverrideDescriptions={
                    contractFromReq
                      ? contractFromReq.v2_fields?.overrides?.map((o) =>
                          describeOverride(now, o),
                        ) ?? []
                      : []
                  }
                  pricing={pricing}
                  contract={contract}
                  onAddOverride={(productId, creditType) =>
                    setFlyover({
                      type: "new_override",
                      productId,
                      creditType,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditOverride={(overrideId, creditType) =>
                    setFlyover({
                      type: "edit_override",
                      id: overrideId,
                      creditType,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onViewOverrides={() =>
                    setFlyover({
                      type: "view_overrides",
                      rateCardId: rateCard?.id,
                    })
                  }
                />
                <AdditionalTermsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  fiatCreditTypes={fiatCreditTypes}
                  onAdd={(type: Schema.Types.AdditionalTermType) => {
                    setFlyover({
                      type: `new_${type}`,
                      fiatCreditTypes,
                    });
                  }}
                  onEdit={(type: Schema.Types.AdditionalTermType, id: string) =>
                    setFlyover({ type: `edit_${type}`, id, fiatCreditTypes })
                  }
                  options={{
                    discountsEnabled:
                      nonGAContractFeaturesEnabled?.includes("DISCOUNTS"),
                    resellerRoyaltiesEnabled:
                      nonGAContractFeaturesEnabled?.includes(
                        "RESELLER_ROYALTIES",
                      ),
                    proServicesEnabled: nonGAContractFeaturesEnabled?.includes(
                      "PROFESSIONAL_SERVICES",
                    ),
                  }}
                />

                {(salesforceEnabled || netsuiteEnabled) && (
                  <Section
                    title="Integration"
                    className="flex flex-col gap-24 py-12"
                    description={getIntegrationsDescription({
                      salesforceEnabled,
                      netsuiteEnabled,
                    })}
                  >
                    <ContractIntegrations
                      ctrl={ctrl}
                      options={{
                        salesforceEnabled,
                        netsuiteEnabled,
                      }}
                    />
                  </Section>
                )}
              </div>
              <FooterBar
                right={
                  <>
                    <Button onClick={cancel} text="Cancel" theme="linkGray" />
                    <Button
                      disabled={
                        amendContractMutationResult.loading ||
                        !ctrl.appearsValid()
                      }
                      onClick={submitForm}
                      text="Save"
                      theme="primary"
                    />
                  </>
                }
              />
            </div>
          </form>
        </DefaultTimeframe.Provider>
      )}
    </AppShell>
  );
};
