import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button } from "../Button";
import { Popover } from "react-tiny-popover-next";
import { Icon, IconName } from "../Icon";
import { v4 as uuid } from "uuid";
import { twMerge } from "tailwind-merge";
import { IconButton, IconButtonProps } from "../IconButton";
import { Checkbox } from "../Checkbox";
import { RadioButton } from "../RadioButton";
import { DeprecatedInternalLink } from "components/deprecated/Typography";

export const DropdownHR: React.FC = () => {
  return <hr className="border-b border-t-0 border-gray-200" />;
};

export const DropdownHeader: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="flex min-h-5xl items-center border-b border-gray-200 px-xl py-md text-sm text-gray-600">
      {text}
    </div>
  );
};

interface DropdownItemProps {
  /** Mark the item as disabled, preventing interaction */
  disabled?: boolean;
  /** Add a leading Icon to the item */
  icon?: IconName;
  /** For cases where the items in a dropdown need to be Checkboxes, for example a filter */
  isCheckbox?: boolean;
  /** For cases where the items in a dropdown need to be RadioButtons, for example a filter */
  isRadioButton?: boolean;
  /** If the route passed to the `linkTo` prop is meant to open an external URI in a new tab */
  isExternalLink?: boolean;
  /** The label of the Dropdown item */
  label: string;
  /** Subtext to display under the label */
  subtext?: string;
  /** The DropdownItem will become an anchor tag. If the URI provided is to an external location, please add `isExternalLink` as well. */
  linkTo?: string;
  /** Provide a callback here to receive the label and value of the user-selection item */
  onClick?: (meta: { label: string; value: string; selected: boolean }) => void;
  /** Default - false; Optionally truncate the DropdownItem text with an ellipsis */
  truncateItem?: boolean;
  /** Setting selected to true will render a trailing checkmark on the item */
  selected?: boolean;
  /** The value of the Dropdown item */
  value: string;
  /** Secondary action, shows up on the right */
  secondaryAction?: IconButtonProps;
}
export const DropdownItem: React.FC<DropdownItemProps> = ({
  disabled,
  icon,
  isCheckbox,
  isRadioButton,
  isExternalLink,
  label,
  linkTo,
  onClick,
  selected,
  truncateItem,
  value,
  subtext,
  secondaryAction,
}) => {
  let element;
  const commonClassnames = twMerge(
    "min-h-5xl px-sm my-sm group text-sm font-medium",
    !disabled && "cursor-pointer",
  );
  const commonChildren = (
    <div
      title={label}
      className={twMerge(
        "flex h-full min-h-5xl items-center justify-between rounded-sm px-[10px] py-[3px]",
        !disabled && "cursor-pointer group-hover:bg-gray-50",
      )}
    >
      <div className="flex w-full flex-row items-center gap-x-md">
        {icon && <Icon icon={icon} size={16} className="text-gray-950" />}
        <div className="flex flex-grow flex-col">
          <span
            className={twMerge(
              "flex-1",
              truncateItem && "truncate",
              disabled ? "text-gray-400" : "text-gray-600",
            )}
          >
            {label}
          </span>
          {subtext && (
            <span
              className={twMerge(
                "flex-1",
                truncateItem && "truncate",
                "text-sm",
                "font-normal",
                disabled ? "text-gray-400" : "text-gray-500",
              )}
            >
              {subtext}
            </span>
          )}
        </div>
        {selected && <Icon icon="check" size={16} />}
      </div>
      {secondaryAction && (
        <IconButton
          {...secondaryAction}
          className={twMerge(secondaryAction.className, "mr-[-10px]")}
        />
      )}
    </div>
  );

  if (linkTo) {
    const commonProps = {
      className: commonClassnames,
      "aria-disabled": disabled,
      children: commonChildren,
    };

    if (isExternalLink) {
      element = <a {...commonProps} target="_blank" href={linkTo} />;
    } else {
      element = <DeprecatedInternalLink {...commonProps} routePath={linkTo} />;
    }
  } else if (isCheckbox) {
    element = (
      <Checkbox
        className={twMerge(commonClassnames, "items-center px-[16px]")}
        checked={selected}
        disabled={disabled}
        label={label}
        size="sm"
        onClick={
          !disabled && onClick
            ? () =>
                onClick({ label: label, value: value, selected: !!selected })
            : undefined
        }
      />
    );
  } else if (isRadioButton) {
    element = (
      <RadioButton
        className={twMerge(commonClassnames, "items-center px-[16px]")}
        checked={selected}
        disabled={disabled}
        label={label}
        size="sm"
        onClick={
          !disabled && onClick
            ? () =>
                onClick({ label: label, value: value, selected: !!selected })
            : undefined
        }
        value={value}
      />
    );
  } else {
    element = (
      <div
        className={commonClassnames}
        onClick={
          !disabled && onClick
            ? (e) => {
                e.preventDefault();
                onClick({ label: label, value: value, selected: !!selected });
              }
            : undefined
        }
        role={!disabled && !!onClick ? "button" : undefined}
      >
        {commonChildren}
      </div>
    );
  }

  return element;
};

interface DropdownSubComponents {
  DropdownItem: typeof DropdownItem;
  DropdownHR: typeof DropdownHR;
}

export type DropdownProps = {
  /** Children of Dropdown are either DropdownHR or DropdownItems:
   *  DropdownHR - create a horizontal rule to separate "sections" in a Dropdown
   *  DropdownItem - a single item/option in a Dropdown list.
   */
  children?: React.ReactNode[];
  /**
   * You can render at the top of the Dropdown some content that could give the user
   * more context or tone-setting for the rest of the Dropdown options. A settings menu
   * could show a user avatar and profile name, for example.
   */
  headerContent?: React.ReactNode;
  /**
   * Hide the trailing Dropdown chevron that appears.
   */
  hideChevron?: boolean;
  /**
   * This will render the label of the selected option as the text for the Dropdown
   * button to give better context to the user of what they have chosen. Pass the
   * label of the selected option
   */
  selectedOption?: string;

  /** Disable the dropdown, making it unclickable */
  disabled?: boolean;

  /** Fill the parent container */
  fullWidth?: boolean;

  /** Override the button theme */
  buttonTheme?: "primary" | "secondary" | "tertiary";

  /** Default: md - controls the size of the main button */
  buttonSize?: "sm" | "md" | "lg";

  /** allows overriding the class of the main button.  This can be used in scenarios like changing rounding. */
  buttonClass?: string;

  /** Trigger a function when the dropdown is closed.  */
  onDropdownClose?: () => void;

  /** Title displayed on top of the dropdown */
  title?: string;
} & ({ label: string; icon?: IconName } | { icon: IconName; label?: never });

/**
 * Dropdowns are used to group together actions in a subview. They’re useful for hiding
 * menus or when you have multiple actions that cannot be separate buttons.
 */
export const Dropdown: React.FC<DropdownProps> & DropdownSubComponents = ({
  children,
  headerContent,
  hideChevron,
  label,
  icon,
  selectedOption,
  disabled,
  fullWidth,
  buttonTheme,
  buttonSize,
  buttonClass,
  onDropdownClose,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const buttonRef = React.useRef<HTMLDivElement | null>(null);
  const contentRef = React.useRef<HTMLDivElement | null>(null);

  const trayAlignment = useMemo(() => {
    if (!!buttonRef?.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const distanceFromRight = window.innerWidth - rect.right;

      if (distanceFromRight < 240) {
        return "end";
      } else {
        return "start";
      }
    }
    return "start";
  }, [buttonRef?.current]);

  const handleOnClick = useCallback(
    (event: MouseEvent) => {
      if (contentRef?.current) {
        const rect = contentRef.current.getBoundingClientRect();

        // Check if the click was inside the popover content
        const isClickInside =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;

        if (!isClickInside) {
          handleDropdownToggle(false);
        }
      }
    },
    [contentRef?.current],
  );

  const handleDropdownToggle = (setDropdownOpen: boolean) => {
    if (setDropdownOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      if (onDropdownClose) {
        onDropdownClose();
      }
    }
  };

  useEffect(() => {
    handleDropdownToggle(false);
  }, [selectedOption]);

  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    // Mouse move handler to track mouse position
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    // Scroll handler to check if mouse is outside dropdown
    const handleScroll = () => {
      const rect = divRef.current?.getBoundingClientRect();
      if (!rect) return;

      const { x, y } = mousePosition;

      const isMouseInside =
        x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;

      // Close the dropdown if the mouse is outside
      if (!isMouseInside) {
        setIsOpen(false);
      }
    };

    // Attach event listeners
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll, true);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, [mousePosition]);

  return (
    <>
      <Popover
        positions={["bottom", "top"]} // If there isn't enough space under the button to render, render on top
        isOpen={isOpen}
        transformMode="relative"
        transform={(state) => {
          return state.position === "bottom"
            ? {
                top: 8,
              }
            : { top: 0 };
        }}
        onClickOutside={handleOnClick}
        align={trayAlignment}
        ref={buttonRef}
        containerClassName="z-aboveBase"
        content={
          <div
            ref={contentRef}
            className="max-h-[450px] min-h-[50px] min-w-[240px] max-w-[420px] overflow-y-auto rounded-md border border-gray-200 bg-white shadow-lg"
          >
            {headerContent && (
              <div className="flex min-h-5xl items-center border-b border-gray-200 px-xl py-md text-sm text-gray-600">
                {headerContent}
              </div>
            )}
            <div
              tabIndex={0}
              ref={(el) => {
                divRef.current = el;
              }}
            >
              <div className="flex flex-col">
                {children?.map((c) => <Fragment key={uuid()}>{c}</Fragment>)}
              </div>
            </div>
          </div>
        }
      >
        <div className={twMerge("inline-block", fullWidth && "w-full")}>
          <div>
            {title && (
              <label className="mb-sm text-sm text-black" htmlFor={title}>
                {title}
              </label>
            )}
          </div>
          {label ? (
            <Button
              theme={buttonTheme ?? "secondary"}
              leadingIcon={icon}
              trailingIcon={
                hideChevron ? undefined : isOpen ? "chevronUp" : "chevronDown"
              }
              text={selectedOption ?? label}
              onClick={(e) => {
                e.preventDefault();
                handleDropdownToggle(!isOpen);
              }}
              disabled={disabled}
              showFullWidth={fullWidth}
              className={twMerge(
                fullWidth ? "justify-between" : "",
                buttonClass,
              )}
              size={buttonSize}
            />
          ) : (
            <IconButton
              theme={buttonTheme ?? "tertiary"}
              icon={icon ?? "dotsVertical"}
              onClick={(e) => {
                e.preventDefault();
                handleDropdownToggle(!isOpen);
              }}
              disabled={disabled}
              size={buttonSize}
              className={buttonClass}
            />
          )}
        </div>
      </Popover>
    </>
  );
};
Dropdown.DropdownItem = DropdownItem;
Dropdown.DropdownHR = DropdownHR;

Dropdown.displayName = "Dropdown";
DropdownItem.displayName = "Dropdown.Item";
DropdownHR.displayName = "Dropdown.HR";
