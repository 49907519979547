/* c7d188edce68f363a9da654e58d597e9c0290c78
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MinimalContractFromRouteQueryVariables = Types.Exact<{
  customerId: Types.Scalars['uuid'];
  contractId: Types.Scalars['uuid'];
}>;


export type MinimalContractFromRouteQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, name: string, contract: { __typename?: 'Contract', id: string, name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } | null } | null };

export type CustomerContractLayoutFragment = { __typename?: 'Customer', id: string, name: string, contract: { __typename?: 'Contract', id: string, name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null } | null };

export type ContractNameFragment = { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null };

export const ContractNameFragmentDoc = gql`
    fragment ContractName on Contract {
  name
  starting_at
  rate_card {
    id
    name
  }
}
    `;
export const CustomerContractLayoutFragmentDoc = gql`
    fragment CustomerContractLayout on Customer {
  id
  name
  contract(id: $contractId) {
    id
    ...ContractName
  }
  __environment_type: environment_type
}
    ${ContractNameFragmentDoc}`;
export const MinimalContractFromRouteDocument = gql`
    query MinimalContractFromRoute($customerId: uuid!, $contractId: uuid!) {
  customer: Customer_by_pk(id: $customerId) {
    ...CustomerContractLayout
    __environment_type: environment_type
  }
}
    ${CustomerContractLayoutFragmentDoc}`;

/**
 * __useMinimalContractFromRouteQuery__
 *
 * To run a query within a React component, call `useMinimalContractFromRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinimalContractFromRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinimalContractFromRouteQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      contractId: // value for 'contractId'
 *   },
 * });
 */
export function useMinimalContractFromRouteQuery(baseOptions: Apollo.QueryHookOptions<MinimalContractFromRouteQuery, MinimalContractFromRouteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MinimalContractFromRouteQuery, MinimalContractFromRouteQueryVariables>(MinimalContractFromRouteDocument, options);
      }
export function useMinimalContractFromRouteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MinimalContractFromRouteQuery, MinimalContractFromRouteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MinimalContractFromRouteQuery, MinimalContractFromRouteQueryVariables>(MinimalContractFromRouteDocument, options);
        }
export type MinimalContractFromRouteQueryHookResult = ReturnType<typeof useMinimalContractFromRouteQuery>;
export type MinimalContractFromRouteLazyQueryHookResult = ReturnType<typeof useMinimalContractFromRouteLazyQuery>;
export type MinimalContractFromRouteQueryResult = Apollo.QueryResult<MinimalContractFromRouteQuery, MinimalContractFromRouteQueryVariables>;