import { v4 as uuid } from "uuid";
import { FormController } from "app/lib/FormController";
import { CommitConsumptionRateTypeEnum } from "types/generated-graphql/__types__";
import { Schema } from "../../Schema";

export type RecurringCommitController = ReturnType<
  typeof useRecurringCommitController
>;

export const useRecurringCommitController = FormController.createHook(
  Schema.RecurringCommitRoot,
  {
    init: (edit?: Schema.Types.RecurringCommitRoot) => {
      const newCommit = {
        temporaryId: `temp-id:${uuid()}`,
        rateType: CommitConsumptionRateTypeEnum.ListRate,
      };
      return edit ?? newCommit;
    },
  },
);
