import React from "react";
import { Schema } from "../../../CreateAndEdit/Schema";

import { Button } from "components/Button";
import { Pricing } from "../types";
import { Section } from "../../components/Section";
import { CommitsTable } from ".";
import { CreditType } from "app/types/credit-types";

interface Props {
  ctrl: Schema.Types.CreateSharedCtrl;
  pricing: Pricing;
  onAddCommit(): void;
  onEditCommit(id: string): void;
  creditTypes: CreditType[];
}

export const CommitsSection: React.FC<Props> = (props) => {
  const commits = props.ctrl.get("commits") ?? [];

  return (
    <Section
      title="Commits"
      actions={
        <Button
          onClick={() => props.onAddCommit()}
          text="Add a commit"
          theme="primary"
          leadingIcon="plus"
        />
      }
      description="Add prepaid or postpaid committed spend terms."
    >
      {commits.length === 0 ? (
        <div className="flex h-[120px] items-center justify-center rounded-medium border border-deprecated-gray-100 bg-gray-50 text-sm text-gray-600">
          No commits
        </div>
      ) : (
        <CommitsTable
          title="Commits"
          rateRows={commits}
          pricing={props.pricing}
          onSelectRow={(row) => props.onEditCommit(row.id)}
          asCredit={false}
          creditTypes={props.creditTypes}
        />
      )}
    </Section>
  );
};
