import { Table } from "components/Table";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { useSearcher } from "app/lib/search/useSearcher";
import { useNavigate } from "app/lib/useNavigate";
import React, { useState } from "react";

import { dayjs, useNow } from "lib/date";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
import { useContractProductListQuery } from "app/pages/Contracts/Pricing/ContractProductList/data.graphql";
import { ContractProductFlyover } from "app/pages/Contracts/Pricing/ContractProductFlyover";
import {
  EditProductDocument,
  EditProductModal,
} from "app/pages/Contracts/Pricing/CreateAndEditProductModal";
import { useApolloResp } from "app/pages/Contracts/lib/ApolloResp";
import { AvatarWithName } from "components/Avatar";
import { Timestamp } from "components/Timestamp";
import { DeprecatedPopoverMenu } from "components/deprecated/PopoverMenu";
import { gatedAction, useAuthCheck } from "app/lib/useAuthCheck";
import { IconButton } from "components/IconButton";
import {
  ArchiveProductListItemDocument,
  useArchiveProductListItemMutation,
} from "app/pages/Contracts/Pricing/ContractProductFlyover/data.graphql";
import { Button } from "components/Button";
import { ConfirmModal } from "app/pages/Contracts/components/ConfirmModal";
import { getUserFacingErrorMessage } from "app/lib/errors/errorHandling";
import { reportToSentry } from "app/lib/errors/sentry";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useSearchParam } from "app/lib/routes/useSearchParam";
import { useUIMode } from "app/lib/useUIMode";
import { ProductsTable } from "../Plans/ProductsTable";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "../../constants";

export const ProductListContainer: React.FC = () => {
  const { mode } = useUIMode();

  if (mode === "plans-only") {
    return <ProductsTable titleButtons={[]} />;
  } else {
    return <Products />;
  }
};

const Products: React.FC = () => {
  const now = useNow();
  const req = useApolloResp(useContractProductListQuery());
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [editProductId, setEditProductId] = useState<string | undefined>();
  const [productId] = useSearchParam("product_id");
  const [archiveProductRequest, archiveProductResult] =
    useArchiveProductListItemMutation({
      update(cache) {
        cache.evict({
          fieldName: "contract_pricing",
        });
      },
    });

  const pushMessage = useSnackbar();

  const allProducts = React.useMemo(
    () =>
      req.state === "success"
        ? req.contract_pricing.products?.map((p) => ({
            ...p,
            currentName: ProductListItem.getName(p, now),
          })) ?? []
        : [],
    [req, now],
  );

  const [productToArchive, setProductToArchive] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const canEditProduct = !!useAuthCheck(EditProductDocument, true).allowed;
  const canArchiveProduct = !!useAuthCheck(ArchiveProductListItemDocument, true)
    .allowed;

  const searcher = useSearcher(allProducts, {
    keys: ["currentName"],
    shouldSort: true,
  });
  const products = searcher(searchTerm);

  if (req.state === "error") {
    return (
      <ErrorEmptyState
        title="We ran into an error loading your product list"
        error={req.error}
      />
    );
  }

  return (
    <>
      {productId && (
        <ContractProductFlyover
          productId={productId}
          onRequestClose={() =>
            navigate("/offering/products", { replace: true })
          }
          editProduct={(product: string) => setEditProductId(product)}
          showDropdownMenu={true}
        />
      )}
      {editProductId && (
        <EditProductModal
          onClose={() => setEditProductId(undefined)}
          editProductId={editProductId}
        />
      )}
      {productToArchive && (
        <ConfirmModal
          title="Archive product"
          loading={archiveProductResult.loading}
          onCancel={() => {
            setProductToArchive(null);
          }}
          onConfirm={() => {
            void archiveProductRequest({
              variables: {
                product_id: productToArchive.id,
                archived_at: new Date().toISOString(),
              },
            })
              .then(
                () => {
                  pushMessage({
                    type: "success",
                    content: "Product archived",
                  });
                },
                (error) => {
                  const msg = getUserFacingErrorMessage(error);
                  pushMessage({
                    type: "error",
                    content: `Failed to archive product: ${msg}`,
                  });
                  reportToSentry(error ?? archiveProductResult.data);
                },
              )
              .finally(() => {
                setProductToArchive(null);
              });
          }}
        >
          <p>
            Any current rate cards associated with this product will continue to
            function as normal. However, it will no longer be available as an
            option for newly created rates. Are you sure you want to archive
            this product?
          </p>
        </ConfirmModal>
      )}
      <Table
        title="Product catalog"
        data={products}
        columns={[
          {
            id: "name",
            cell: (props) => {
              return (
                <div className="flex flex-col">
                  <span className="text-sm text-core-slate">
                    {props.getValue().name}
                  </span>
                  <span className="text-xs text-gray-600">
                    {props.getValue().type}
                  </span>
                </div>
              );
            },
            header: "Name",
            enableSorting: true,
            sortingFn: (a, b) =>
              a.original.currentName.localeCompare(b.original.currentName),
            accessorFn: (row) => ({
              name: row.currentName,
              type: ProductListItem.printType(row),
            }),
          },
          {
            id: "user",
            cell: (props) => (
              <AvatarWithName
                name={props.getValue().name}
                id={props.getValue().id}
                deprecated_at={props.getValue().deprecated_at}
              />
            ),
            header: "User",
            enableSorting: false,
            accessorFn: (row) => row.initial.Creator,
          },
          {
            id: "last-edited",
            cell: (props) => <Timestamp dateTime={props.getValue()} />,
            header: "Last edited",
            accessorFn: (row) =>
              dayjs
                .utc(ProductListItem.getCurrent(row, "created_at", now))
                .toDate(),
          },
          {
            id: "billable-metric",
            cell: (props) => props.getValue(),
            header: "Associated billable metric",
            accessorFn: (row) =>
              ProductListItem.getBillableMetricName(row, now) ?? "--",
          },
          {
            id: "actions",
            cell: (props) => {
              const product = props.row.original;
              const actions = [
                {
                  content: "Manage custom fields...",
                  routePath: `/connections/custom-fields/contract-product/${product.id}`,
                  disabled: !!product.archived_at,
                },
                gatedAction(canEditProduct, {
                  content: "Edit product",
                  onClick: () => setEditProductId(product.id),
                  disabled: !!product.archived_at,
                }),
                gatedAction(canArchiveProduct, {
                  content: "Archive product",
                  onClick: () =>
                    setProductToArchive({
                      id: product.id,
                      name: product.currentName,
                    }),
                  disabled: !!product.archived_at,
                }),
              ];

              return (
                <DeprecatedPopoverMenu
                  positions={["bottom", "top"]}
                  align="end"
                  options={actions}
                >
                  {(onClick) => (
                    <IconButton
                      onClick={onClick}
                      theme="tertiary"
                      icon="dotsVertical"
                    />
                  )}
                </DeprecatedPopoverMenu>
              );
            },
            isDisplay: true,
          },
        ]}
        loading={req.state === "loading"}
        searchOptions={{ showSearch: true, onSearch: setSearchTerm }}
        topBarActions={[
          <Button
            text="Add"
            linkTo="/offering/products/create"
            leadingIcon="plus"
            theme="primary"
            key={1}
          />,
        ]}
        rowRoutePath={(row) => `/offering/products?product_id=${row.id}`}
        rowRoutePathReplace={true}
        paginationOptions={{
          type: "clientSide",
          pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
        }}
        defaultSort={searchTerm ? [] : [{ id: "name", desc: false }]}
      />
    </>
  );
};
