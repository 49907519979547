/* 9699ae129544c76fd330429140e98db63266e07e
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReleaseInvoicesForFinalizationMutationVariables = Types.Exact<{
  inputs: Types.InvoiceFinalizationInput;
  preview: Types.Scalars['Boolean'];
}>;


export type ReleaseInvoicesForFinalizationMutation = { __typename?: 'Mutation', release_invoices_for_finalization: { __typename?: 'InvoiceFinalizationMetadata', id: string, invoices_finalized: number, billing_provider_invoice_count: number, invoice_cohort: Types.InvoiceExternalTypeEnum, finalization_date: string, books_closed_date: string, created_at: string, Creator: { __typename?: 'Actor', name: string, id: string } } };

export type GetInvoiceFinalizationMetadataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInvoiceFinalizationMetadataQuery = { __typename?: 'Query', invoice_finalization_metadata: Array<{ __typename?: 'InvoiceFinalizationMetadata', id: string, invoices_finalized: number, billing_provider_invoice_count: number, invoice_cohort: Types.InvoiceExternalTypeEnum, finalization_date: string, books_closed_date: string, created_at: string, status: Types.InvoiceFinalizationMetadataStatus, Creator: { __typename?: 'Actor', id: string, name: string } } | null> };

export type GetInvoiceFinalizationProgressCountQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetInvoiceFinalizationProgressCountQuery = { __typename?: 'Query', invoice_finalization_progress_count: { __typename?: 'InvoiceFinalizationProgressCountResponse', count: number } };

export type UpdateInvoiceFinalizationMetadataStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  status: Types.InvoiceFinalizationMetadataStatus;
}>;


export type UpdateInvoiceFinalizationMetadataStatusMutation = { __typename?: 'Mutation', update_invoice_finalization_metadata_status: { __typename?: 'InvoiceFinalizationMetadata', id: string, status: Types.InvoiceFinalizationMetadataStatus } };

export type GetBooksClosedDateQueryVariables = Types.Exact<{
  environment_type?: Types.InputMaybe<Types.EnvironmentTypeEnum_Enum>;
}>;


export type GetBooksClosedDateQuery = { __typename?: 'Query', ClientConfig: Array<{ __typename?: 'ClientConfig', id: string, key: Types.ClientConfigKeyEnum_Enum, value: string }> };

export type SetBooksClosedDateMutationVariables = Types.Exact<{
  environment_type?: Types.InputMaybe<Types.EnvironmentTypeEnum_Enum>;
  value: Types.Scalars['String'];
}>;


export type SetBooksClosedDateMutation = { __typename?: 'Mutation', insert_ClientConfig_one: { __typename?: 'ClientConfig', id: string, value: string } | null };


export const ReleaseInvoicesForFinalizationDocument = gql`
    mutation ReleaseInvoicesForFinalization($inputs: InvoiceFinalizationInput!, $preview: Boolean!) {
  release_invoices_for_finalization(inputs: $inputs, preview: $preview) {
    id
    invoices_finalized
    billing_provider_invoice_count
    invoice_cohort
    Creator {
      name
      id
    }
    finalization_date
    books_closed_date
    created_at
  }
}
    `;
export type ReleaseInvoicesForFinalizationMutationFn = Apollo.MutationFunction<ReleaseInvoicesForFinalizationMutation, ReleaseInvoicesForFinalizationMutationVariables>;

/**
 * __useReleaseInvoicesForFinalizationMutation__
 *
 * To run a mutation, you first call `useReleaseInvoicesForFinalizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseInvoicesForFinalizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseInvoicesForFinalizationMutation, { data, loading, error }] = useReleaseInvoicesForFinalizationMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useReleaseInvoicesForFinalizationMutation(baseOptions?: Apollo.MutationHookOptions<ReleaseInvoicesForFinalizationMutation, ReleaseInvoicesForFinalizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReleaseInvoicesForFinalizationMutation, ReleaseInvoicesForFinalizationMutationVariables>(ReleaseInvoicesForFinalizationDocument, options);
      }
export type ReleaseInvoicesForFinalizationMutationHookResult = ReturnType<typeof useReleaseInvoicesForFinalizationMutation>;
export type ReleaseInvoicesForFinalizationMutationResult = Apollo.MutationResult<ReleaseInvoicesForFinalizationMutation>;
export type ReleaseInvoicesForFinalizationMutationOptions = Apollo.BaseMutationOptions<ReleaseInvoicesForFinalizationMutation, ReleaseInvoicesForFinalizationMutationVariables>;
export const GetInvoiceFinalizationMetadataDocument = gql`
    query GetInvoiceFinalizationMetadata {
  invoice_finalization_metadata {
    id
    invoices_finalized
    billing_provider_invoice_count
    invoice_cohort
    Creator {
      id
      name
    }
    finalization_date
    books_closed_date
    created_at
    status
  }
}
    `;

/**
 * __useGetInvoiceFinalizationMetadataQuery__
 *
 * To run a query within a React component, call `useGetInvoiceFinalizationMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceFinalizationMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceFinalizationMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoiceFinalizationMetadataQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoiceFinalizationMetadataQuery, GetInvoiceFinalizationMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceFinalizationMetadataQuery, GetInvoiceFinalizationMetadataQueryVariables>(GetInvoiceFinalizationMetadataDocument, options);
      }
export function useGetInvoiceFinalizationMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceFinalizationMetadataQuery, GetInvoiceFinalizationMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceFinalizationMetadataQuery, GetInvoiceFinalizationMetadataQueryVariables>(GetInvoiceFinalizationMetadataDocument, options);
        }
export type GetInvoiceFinalizationMetadataQueryHookResult = ReturnType<typeof useGetInvoiceFinalizationMetadataQuery>;
export type GetInvoiceFinalizationMetadataLazyQueryHookResult = ReturnType<typeof useGetInvoiceFinalizationMetadataLazyQuery>;
export type GetInvoiceFinalizationMetadataQueryResult = Apollo.QueryResult<GetInvoiceFinalizationMetadataQuery, GetInvoiceFinalizationMetadataQueryVariables>;
export const GetInvoiceFinalizationProgressCountDocument = gql`
    query GetInvoiceFinalizationProgressCount($id: uuid!) {
  invoice_finalization_progress_count(metadata_id: $id) {
    count
  }
}
    `;

/**
 * __useGetInvoiceFinalizationProgressCountQuery__
 *
 * To run a query within a React component, call `useGetInvoiceFinalizationProgressCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceFinalizationProgressCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceFinalizationProgressCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceFinalizationProgressCountQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceFinalizationProgressCountQuery, GetInvoiceFinalizationProgressCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceFinalizationProgressCountQuery, GetInvoiceFinalizationProgressCountQueryVariables>(GetInvoiceFinalizationProgressCountDocument, options);
      }
export function useGetInvoiceFinalizationProgressCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceFinalizationProgressCountQuery, GetInvoiceFinalizationProgressCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceFinalizationProgressCountQuery, GetInvoiceFinalizationProgressCountQueryVariables>(GetInvoiceFinalizationProgressCountDocument, options);
        }
export type GetInvoiceFinalizationProgressCountQueryHookResult = ReturnType<typeof useGetInvoiceFinalizationProgressCountQuery>;
export type GetInvoiceFinalizationProgressCountLazyQueryHookResult = ReturnType<typeof useGetInvoiceFinalizationProgressCountLazyQuery>;
export type GetInvoiceFinalizationProgressCountQueryResult = Apollo.QueryResult<GetInvoiceFinalizationProgressCountQuery, GetInvoiceFinalizationProgressCountQueryVariables>;
export const UpdateInvoiceFinalizationMetadataStatusDocument = gql`
    mutation UpdateInvoiceFinalizationMetadataStatus($id: uuid!, $status: InvoiceFinalizationMetadataStatus!) {
  update_invoice_finalization_metadata_status(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type UpdateInvoiceFinalizationMetadataStatusMutationFn = Apollo.MutationFunction<UpdateInvoiceFinalizationMetadataStatusMutation, UpdateInvoiceFinalizationMetadataStatusMutationVariables>;

/**
 * __useUpdateInvoiceFinalizationMetadataStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceFinalizationMetadataStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceFinalizationMetadataStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceFinalizationMetadataStatusMutation, { data, loading, error }] = useUpdateInvoiceFinalizationMetadataStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateInvoiceFinalizationMetadataStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceFinalizationMetadataStatusMutation, UpdateInvoiceFinalizationMetadataStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceFinalizationMetadataStatusMutation, UpdateInvoiceFinalizationMetadataStatusMutationVariables>(UpdateInvoiceFinalizationMetadataStatusDocument, options);
      }
export type UpdateInvoiceFinalizationMetadataStatusMutationHookResult = ReturnType<typeof useUpdateInvoiceFinalizationMetadataStatusMutation>;
export type UpdateInvoiceFinalizationMetadataStatusMutationResult = Apollo.MutationResult<UpdateInvoiceFinalizationMetadataStatusMutation>;
export type UpdateInvoiceFinalizationMetadataStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceFinalizationMetadataStatusMutation, UpdateInvoiceFinalizationMetadataStatusMutationVariables>;
export const GetBooksClosedDateDocument = gql`
    query GetBooksClosedDate($environment_type: EnvironmentTypeEnum_enum) {
  ClientConfig(
    where: {environment_type: {_eq: $environment_type}, key: {_eq: books_closed_date}}
  ) {
    id
    key
    value
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetBooksClosedDateQuery__
 *
 * To run a query within a React component, call `useGetBooksClosedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksClosedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksClosedDateQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetBooksClosedDateQuery(baseOptions?: Apollo.QueryHookOptions<GetBooksClosedDateQuery, GetBooksClosedDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBooksClosedDateQuery, GetBooksClosedDateQueryVariables>(GetBooksClosedDateDocument, options);
      }
export function useGetBooksClosedDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBooksClosedDateQuery, GetBooksClosedDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBooksClosedDateQuery, GetBooksClosedDateQueryVariables>(GetBooksClosedDateDocument, options);
        }
export type GetBooksClosedDateQueryHookResult = ReturnType<typeof useGetBooksClosedDateQuery>;
export type GetBooksClosedDateLazyQueryHookResult = ReturnType<typeof useGetBooksClosedDateLazyQuery>;
export type GetBooksClosedDateQueryResult = Apollo.QueryResult<GetBooksClosedDateQuery, GetBooksClosedDateQueryVariables>;
export const SetBooksClosedDateDocument = gql`
    mutation SetBooksClosedDate($environment_type: EnvironmentTypeEnum_enum, $value: String!) {
  insert_ClientConfig_one(
    object: {key: books_closed_date, value: $value}
    on_conflict: {constraint: ClientConfig_uniqueness, update_columns: value, where: {environment_type: {_eq: $environment_type}, key: {_eq: books_closed_date}}}
  ) {
    id
    value
    __environment_type: environment_type
  }
}
    `;
export type SetBooksClosedDateMutationFn = Apollo.MutationFunction<SetBooksClosedDateMutation, SetBooksClosedDateMutationVariables>;

/**
 * __useSetBooksClosedDateMutation__
 *
 * To run a mutation, you first call `useSetBooksClosedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBooksClosedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBooksClosedDateMutation, { data, loading, error }] = useSetBooksClosedDateMutation({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetBooksClosedDateMutation(baseOptions?: Apollo.MutationHookOptions<SetBooksClosedDateMutation, SetBooksClosedDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBooksClosedDateMutation, SetBooksClosedDateMutationVariables>(SetBooksClosedDateDocument, options);
      }
export type SetBooksClosedDateMutationHookResult = ReturnType<typeof useSetBooksClosedDateMutation>;
export type SetBooksClosedDateMutationResult = Apollo.MutationResult<SetBooksClosedDateMutation>;
export type SetBooksClosedDateMutationOptions = Apollo.BaseMutationOptions<SetBooksClosedDateMutation, SetBooksClosedDateMutationVariables>;