import React, { useMemo, useState } from "react";
import { useApolloResp } from "app/pages/Contracts/lib/ApolloResp";
import {
  InsertTokenDocument,
  TokensQuery,
  useTokensQuery,
} from "app/pages/Connections/tabs/APITokensAndWebhooks/APITokens/data/tokens.graphql";
import { CreateTokenModal } from "app/pages/Connections/tabs/APITokensAndWebhooks/APITokens/components/CreateTokenModal";
import { Table } from "components/Table";
import { ButtonGroup } from "components/ButtonGroup";
import { GatedButton, GatedIconButton } from "components/GatedButton";
import { Timestamp } from "components/Timestamp";
import { View } from "./view";
import { ArchiveTokenDocument } from "app/pages/Connections/tabs/APITokensAndWebhooks/APITokens/components/ArchiveTokenModal/queries.graphql";
import ArchiveTokenModal from "app/pages/Connections/tabs/APITokensAndWebhooks/APITokens/components/ArchiveTokenModal";
import { Toggle } from "components/Toggle";

function HiddenAPIToken({
  token_display_suffix,
}: {
  token_display_suffix: string;
}) {
  const hidden = "•".repeat(58) + token_display_suffix;
  return <div className="inline-flex items-center font-mono">{hidden}</div>;
}

type Token = TokensQuery["list_tokens"][0];
export const APITokens: React.FC<{
  setSelectedView: (view: View) => void;
}> = ({ setSelectedView }) => {
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const tokensResult = useApolloResp(
    useTokensQuery({ variables: { include_archived: includeArchived } }),
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tokenToArchive, setTokenToArchive] = useState<Token | null>(null);
  const table = useMemo(() => {
    return (
      <Table
        loading={tokensResult.state === "loading"}
        topBarActions={[
          <div className="flex flex-1 justify-between">
            <ButtonGroup
              buttons={[
                {
                  text: "API tokens",
                  onClick: () => setSelectedView("API_TOKENS"),
                  isActive: true,
                },
                {
                  text: "Webhooks",
                  onClick: () => setSelectedView("WEBHOOKS"),
                  isActive: false,
                },
              ]}
            />
            <div className="flex flex-row items-center gap-md">
              <Toggle
                size="sm"
                toggled={includeArchived}
                onChange={({ toggled }) => setIncludeArchived(toggled)}
                label="Include archived"
              />
              <GatedButton
                doc={InsertTokenDocument}
                onClick={() => setCreateModalOpen(true)}
                text="Add"
                theme="primary"
                leadingIcon="plus"
                size="sm"
              />
            </div>
          </div>,
        ]}
        columns={[
          {
            id: "name",
            header: "Name",
            cell: (props) => (
              <>
                {props.getValue()}
                {props.row.original.deprecated_at ? " (archived)" : ""}
              </>
            ),
            accessorKey: "name",
          },
          {
            id: "suffix",
            header: "Token suffix",
            cell: (props) => (
              <HiddenAPIToken token_display_suffix={props.getValue()} />
            ),
            accessorKey: "token_display_suffix",
          },
          {
            id: "created_at",
            header: "Created",
            cell: (props) => (
              <Timestamp dateTime={new Date(props.getValue())} />
            ),
            accessorKey: "created_at",
          },
          {
            id: "actions",
            header: "",
            cell: (props) => (
              <GatedIconButton
                doc={ArchiveTokenDocument}
                variables={{ id: props.getValue().id }}
                theme="tertiary"
                icon="trash03"
                size="sm"
                onClick={() => setTokenToArchive(props.getValue())}
                disabled={props.getValue().deprecated_at !== null}
              />
            ),
            accessorFn: (r) => r,
            enableSorting: false,
          },
        ]}
        data={tokensResult.state === "success" ? tokensResult.list_tokens : []}
        paginationOptions={{
          type: "clientSide",
          pageSize: 9,
        }}
        defaultSort={[{ id: "created_at", desc: true }]}
      />
    );
  }, [tokensResult]);
  return (
    <>
      {createModalOpen && (
        <CreateTokenModal onClose={() => setCreateModalOpen(false)} />
      )}
      {tokenToArchive && (
        <ArchiveTokenModal
          onClose={() => {
            setTokenToArchive(null);
          }}
          token={tokenToArchive}
        />
      )}
      {table}
    </>
  );
};
