import React, { useState } from "react";
import { Button } from "components/Button";
import { useLocation, Outlet } from "react-router-dom";
import { Takeover } from "components/Takeover";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useCreateRateCardMutation } from "./data.graphql";
import { useNavigate } from "app/lib/useNavigate";

import { reportToSentry } from "app/lib/errors/sentry";
import {
  formAliasesAsGraphqlAliases,
  formCreditTypeConversionsAsGraphqlCreditTypeConversions,
  formRatesAsGraphqlRates,
} from "./utils";
import { RateCardContext } from "./RateCardContext";
import {
  RateCardCreatedModal,
  shouldShowRateCardCreatedModal,
} from "./RateCardCreatedModal";
import {
  RateCardCreateLandingPage,
  shouldShowPreviewPage,
} from "./LandingPage";

const RateCardCreate: React.FC = () => {
  const [
    showRateCreateModalForRateCardId,
    setShowRateCreateModalForRateCardId,
  ] = React.useState<string | null>(null);
  const [showPreviewPage, setShowPreviewPage] = useState(shouldShowPreviewPage);
  const location = useLocation();

  const navigate = useNavigate();

  const {
    clearSnapshot,
    loading,
    selectedProducts,
    name,
    description,
    aliases,
    dimensionalProducts,
    rates,
    fiatCreditType,
    creditTypeConversions,
    hasValidRateCardInput,
  } = RateCardContext.useContainer();
  const urlPrefix = "/offering/rate-cards/new";
  const hasDimensionalPricingRates = dimensionalProducts.length > 0;
  const getCurrentStepName = () => {
    return location.pathname.split(`${urlPrefix}/`)[1];
  };

  const isFirstStep = !getCurrentStepName();
  const isLastStep = getCurrentStepName() === "rate-products";

  const pushMessage = useSnackbar();

  const [createRateCardMutation, createRateCardResult] =
    useCreateRateCardMutation();

  const backToRateCardList = () => {
    clearSnapshot();
    navigate("/offering/rate-cards");
  };

  const onSubmit = async () => {
    try {
      // multiply USD rates by 100 to convert to cents
      const convertedRates = [
        rates.usageRates.filter((r) => !r.isRemovedSubrate),
        rates.subscriptionRates,
        rates.compositeRates,
      ].flat();

      const fiatCreditTypeId = fiatCreditType.id;
      const result = await createRateCardMutation({
        variables: {
          name: name.trim(),
          description: description?.trim(),
          rates: formRatesAsGraphqlRates(convertedRates),
          aliases: formAliasesAsGraphqlAliases(aliases ?? []),
          fiatCreditTypeId,
          creditTypeConversions:
            formCreditTypeConversionsAsGraphqlCreditTypeConversions(
              creditTypeConversions,
              fiatCreditTypeId,
            ),
        },
        update(cache) {
          cache.evict({ fieldName: "products_and_rate_cards" });
          cache.evict({ fieldName: "contract_pricing" });
        },
      });
      const id = result.data?.create_rate_card?.id;

      if (id) {
        if (shouldShowRateCardCreatedModal()) {
          setShowRateCreateModalForRateCardId(id);
        } else {
          pushMessage({
            type: "success",
            content: "Rate card created successfully",
          });
          clearSnapshot();
          navigate(`/offering/rate-cards/${id}`);
        }
      }
    } catch (e) {
      reportToSentry(e);
      pushMessage({
        content: `Failed to create new rate card: ${e}`,
        type: "error",
      });
    }
  };

  let nextStepDisabled: boolean = loading;
  if (isLastStep) {
    nextStepDisabled =
      nextStepDisabled ||
      !hasValidRateCardInput ||
      createRateCardResult.loading;
  } else if (isFirstStep) {
    nextStepDisabled =
      nextStepDisabled || selectedProducts.length === 0 || !name;
  } else {
    nextStepDisabled =
      nextStepDisabled ||
      dimensionalProducts.some((p) =>
        p.pricingGroupKeyValues.some((k) => !k.values.length),
      );
  }

  return (
    <Takeover
      title="Create a rate card"
      maxContainerWidth="max-w-[1400px]"
      isOpen={true}
      onClose={() => backToRateCardList()}
      footerLeadingButton={
        <Button
          className="w-[128px] justify-center"
          onClick={() => {
            if (isLastStep && hasDimensionalPricingRates) {
              navigate(`${urlPrefix}/dimensional-pricing`);
            } else if (isFirstStep) {
              if (shouldShowPreviewPage()) {
                setShowPreviewPage(true);
              } else {
                backToRateCardList();
              }
            } else {
              navigate(`${urlPrefix}/`);
            }
          }}
          text="Back"
          theme="secondary"
        />
      }
      footerTrailingButtons={[
        <Button
          className="w-[128px] justify-center"
          onClick={async () => {
            if (isLastStep) {
              await onSubmit();
            } else {
              if (isFirstStep && hasDimensionalPricingRates) {
                navigate(`${urlPrefix}/dimensional-pricing`);
              } else {
                navigate(`${urlPrefix}/rate-products`);
              }
            }
          }}
          text={isLastStep ? "Save" : "Continue"}
          theme="primary"
          disabled={nextStepDisabled}
          loading={isLastStep ? createRateCardResult.loading : false}
        />,
      ]}
      children={
        <div className="pb-32">
          {/* Display the current step */}
          <Outlet />
          {showRateCreateModalForRateCardId && (
            <RateCardCreatedModal
              onClose={() => {
                pushMessage({
                  type: "success",
                  content: "Rate card created successfully",
                });
                clearSnapshot();
                navigate(
                  `/offering/rate-cards/${showRateCreateModalForRateCardId}`,
                );
                setShowRateCreateModalForRateCardId(null);
              }}
            />
          )}
          {showPreviewPage ? (
            <RateCardCreateLandingPage
              setShowPreviewPage={setShowPreviewPage}
            />
          ) : null}
        </div>
      }
    ></Takeover>
  );
};
export const RateCardCreateV2: React.FC = () => {
  return (
    <RateCardContext.Provider>
      <RateCardCreate />
    </RateCardContext.Provider>
  );
};
