/* 46902cd0dc1a09e95419cd64c9c9e7a5bb922a63
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductsForRateCardQueryVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
}>;


export type GetProductsForRateCardQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_card: { __typename?: 'RateCardMetadata', id: string, products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> }> } } };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

type ProductListItemTags_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

export type ProductListItemTagsFragment = ProductListItemTags_CompositeProductListItem_Fragment | ProductListItemTags_FixedProductListItem_Fragment | ProductListItemTags_ProServiceProductListItem_Fragment | ProductListItemTags_SubscriptionProductListItem_Fragment | ProductListItemTags_UsageProductListItem_Fragment;

export type GetProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProductsQuery = { __typename?: 'Query', products_and_rate_cards: { __typename?: 'ProductsAndRateCards', products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string, tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string, tags: Array<string> | null }> }> } };

export type CreditTypeForRateFromRateCardAndProductIdsQueryVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
  productId: Types.Scalars['uuid'];
}>;


export type CreditTypeForRateFromRateCardAndProductIdsQuery = { __typename?: 'Query', products_and_rate_cards: { __typename?: 'ProductsAndRateCards', rate_card: { __typename?: 'RateCard', id: string, rate_schedule: { __typename?: 'RateSchedule', credit_types_on_segments: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }> } } } };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type IsProratedForRateFromRateCardAndProductIdsQueryVariables = Types.Exact<{
  rateCardId: Types.Scalars['uuid'];
  productId: Types.Scalars['uuid'];
}>;


export type IsProratedForRateFromRateCardAndProductIdsQuery = { __typename?: 'Query', products_and_rate_cards: { __typename?: 'ProductsAndRateCards', rate_card: { __typename?: 'RateCard', id: string, rate_schedule: { __typename?: 'RateSchedule', scalar_segments: Array<import("types/generated-graphql/__types__").ScalarRateScheduleSegment> } } } };

export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const ProductListItemTagsFragmentDoc = gql`
    fragment ProductListItemTags on ProductListItem {
  ... on FixedProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
}
    `;
export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const GetProductsForRateCardDocument = gql`
    query GetProductsForRateCard($rateCardId: uuid!) {
  contract_pricing {
    rate_card(id: $rateCardId) {
      id
      products {
        __typename
        ...ProductListItemId
        ...ProductListItemName
        ...ProductListItemTags
      }
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ProductListItemTagsFragmentDoc}`;

/**
 * __useGetProductsForRateCardQuery__
 *
 * To run a query within a React component, call `useGetProductsForRateCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForRateCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForRateCardQuery({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *   },
 * });
 */
export function useGetProductsForRateCardQuery(baseOptions: Apollo.QueryHookOptions<GetProductsForRateCardQuery, GetProductsForRateCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForRateCardQuery, GetProductsForRateCardQueryVariables>(GetProductsForRateCardDocument, options);
      }
export function useGetProductsForRateCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForRateCardQuery, GetProductsForRateCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForRateCardQuery, GetProductsForRateCardQueryVariables>(GetProductsForRateCardDocument, options);
        }
export type GetProductsForRateCardQueryHookResult = ReturnType<typeof useGetProductsForRateCardQuery>;
export type GetProductsForRateCardLazyQueryHookResult = ReturnType<typeof useGetProductsForRateCardLazyQuery>;
export type GetProductsForRateCardQueryResult = Apollo.QueryResult<GetProductsForRateCardQuery, GetProductsForRateCardQueryVariables>;
export const GetProductsDocument = gql`
    query GetProducts {
  products_and_rate_cards {
    products {
      __typename
      ...ProductListItemId
      ...ProductListItemName
      ...ProductListItemTags
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ProductListItemTagsFragmentDoc}`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const CreditTypeForRateFromRateCardAndProductIdsDocument = gql`
    query CreditTypeForRateFromRateCardAndProductIds($rateCardId: uuid!, $productId: uuid!) {
  products_and_rate_cards {
    rate_card(id: $rateCardId) {
      id
      rate_schedule(limit: 1, selectors: [{product_id: $productId}]) {
        credit_types_on_segments {
          ...CreditType
          __environment_type: environment_type
        }
      }
    }
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useCreditTypeForRateFromRateCardAndProductIdsQuery__
 *
 * To run a query within a React component, call `useCreditTypeForRateFromRateCardAndProductIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditTypeForRateFromRateCardAndProductIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditTypeForRateFromRateCardAndProductIdsQuery({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCreditTypeForRateFromRateCardAndProductIdsQuery(baseOptions: Apollo.QueryHookOptions<CreditTypeForRateFromRateCardAndProductIdsQuery, CreditTypeForRateFromRateCardAndProductIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditTypeForRateFromRateCardAndProductIdsQuery, CreditTypeForRateFromRateCardAndProductIdsQueryVariables>(CreditTypeForRateFromRateCardAndProductIdsDocument, options);
      }
export function useCreditTypeForRateFromRateCardAndProductIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditTypeForRateFromRateCardAndProductIdsQuery, CreditTypeForRateFromRateCardAndProductIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditTypeForRateFromRateCardAndProductIdsQuery, CreditTypeForRateFromRateCardAndProductIdsQueryVariables>(CreditTypeForRateFromRateCardAndProductIdsDocument, options);
        }
export type CreditTypeForRateFromRateCardAndProductIdsQueryHookResult = ReturnType<typeof useCreditTypeForRateFromRateCardAndProductIdsQuery>;
export type CreditTypeForRateFromRateCardAndProductIdsLazyQueryHookResult = ReturnType<typeof useCreditTypeForRateFromRateCardAndProductIdsLazyQuery>;
export type CreditTypeForRateFromRateCardAndProductIdsQueryResult = Apollo.QueryResult<CreditTypeForRateFromRateCardAndProductIdsQuery, CreditTypeForRateFromRateCardAndProductIdsQueryVariables>;
export const IsProratedForRateFromRateCardAndProductIdsDocument = gql`
    query IsProratedForRateFromRateCardAndProductIds($rateCardId: uuid!, $productId: uuid!) {
  products_and_rate_cards {
    rate_card(id: $rateCardId) {
      id
      rate_schedule(limit: 1, selectors: [{product_id: $productId}]) {
        scalar_segments
      }
    }
  }
}
    `;

/**
 * __useIsProratedForRateFromRateCardAndProductIdsQuery__
 *
 * To run a query within a React component, call `useIsProratedForRateFromRateCardAndProductIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsProratedForRateFromRateCardAndProductIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsProratedForRateFromRateCardAndProductIdsQuery({
 *   variables: {
 *      rateCardId: // value for 'rateCardId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useIsProratedForRateFromRateCardAndProductIdsQuery(baseOptions: Apollo.QueryHookOptions<IsProratedForRateFromRateCardAndProductIdsQuery, IsProratedForRateFromRateCardAndProductIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsProratedForRateFromRateCardAndProductIdsQuery, IsProratedForRateFromRateCardAndProductIdsQueryVariables>(IsProratedForRateFromRateCardAndProductIdsDocument, options);
      }
export function useIsProratedForRateFromRateCardAndProductIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsProratedForRateFromRateCardAndProductIdsQuery, IsProratedForRateFromRateCardAndProductIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsProratedForRateFromRateCardAndProductIdsQuery, IsProratedForRateFromRateCardAndProductIdsQueryVariables>(IsProratedForRateFromRateCardAndProductIdsDocument, options);
        }
export type IsProratedForRateFromRateCardAndProductIdsQueryHookResult = ReturnType<typeof useIsProratedForRateFromRateCardAndProductIdsQuery>;
export type IsProratedForRateFromRateCardAndProductIdsLazyQueryHookResult = ReturnType<typeof useIsProratedForRateFromRateCardAndProductIdsLazyQuery>;
export type IsProratedForRateFromRateCardAndProductIdsQueryResult = Apollo.QueryResult<IsProratedForRateFromRateCardAndProductIdsQuery, IsProratedForRateFromRateCardAndProductIdsQueryVariables>;