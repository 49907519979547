import { Dropdown, DropdownItem } from "components/Dropdown";
import { SectionHeader } from "components/SectionHeader";
import React, { useMemo } from "react";
import { TermCards } from "../components/TermCards";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { Flyover } from "../../Create/lib/SharedContext/useFlyovers";
import { ContractFormController } from "../ContractContext";
import { filterAndSortCreditTypes } from "app/pages/Contracts/lib/CreditTypes";
import { ContractPricingAndCreditTypesQuery } from "types/generated-graphql/src/app/pages/Contracts/Customer/Contracts/Create/data.graphql";
import { TermsTables } from "./TermsTables";
import { ContractType } from "./BasicDetails";

interface TermsProps {
  setFlyover: (flyover: Flyover | undefined) => void;
  ctrl: ContractFormController;
  pricing?: ContractPricingAndCreditTypesQuery;
  isEdit: boolean;
  featureFlagOptions: Record<string, boolean | undefined>;
  contractToEdit?: ContractType;
}

export const Terms: React.FC<TermsProps> = ({
  ctrl,
  setFlyover,
  pricing,
  isEdit,
  featureFlagOptions,
  contractToEdit,
}) => {
  const { fiatCreditTypes, customCreditTypes } = filterAndSortCreditTypes(
    pricing?.CreditType ?? [],
  );

  const allCreditTypes = [...fiatCreditTypes, ...customCreditTypes];

  const thresholdBillingUIEnabled = useFeatureFlag<boolean>(
    "allow-threshold-billing-ui",
    false,
  );
  const subscriptionsUIEnabled = useFeatureFlag<boolean>(
    "allow-subscriptions-ui",
    false,
  );

  const commits = ctrl.get("commits") ?? [];
  const credits = ctrl.get("credits") ?? [];
  const proServices = ctrl.get("proServices") ?? [];
  const resellerRoyalties = ctrl.get("resellerRoyalties") ?? [];
  const scheduledCharges = ctrl.get("scheduledCharges") ?? [];
  const discounts = ctrl.get("discounts") ?? [];
  const shouldShowAdditionalTermsTable =
    [...proServices, ...resellerRoyalties, ...scheduledCharges, ...discounts]
      .length > 0;
  const shouldShowTables =
    [
      ...commits,
      ...credits,
      ...proServices,
      ...resellerRoyalties,
      ...scheduledCharges,
      ...discounts,
    ].length > 0;

  // ToDo: Add subscription and threshold billing forms when ready
  const termDetails = useMemo(
    (): ReadonlyArray<
      Readonly<{
        label: string;
        onAdd: () => void;
        onEdit?: (id: string) => void;
      }>
    > =>
      [
        {
          label: "Commits",
          onAdd: () =>
            setFlyover({
              type: "new_commit",
              fiatCreditTypes: fiatCreditTypes,
              customCreditTypes: customCreditTypes,
              rateCardId: !isEdit
                ? ctrl.get("rateCardId") ?? undefined
                : contractToEdit?.rate_card?.id ?? undefined,
            }),
          onEdit: (id: string) =>
            setFlyover({
              type: "edit_commit",
              id,
              fiatCreditTypes: fiatCreditTypes,
              customCreditTypes: customCreditTypes,
              rateCardId: !isEdit
                ? ctrl.get("rateCardId") ?? undefined
                : undefined,
            }),
        },
        {
          label: "Credits",
          onAdd: () =>
            setFlyover({
              type: "new_credit",
              fiatCreditTypes: fiatCreditTypes,
              customCreditTypes: customCreditTypes,
              rateCardId: !isEdit
                ? ctrl.get("rateCardId") ?? undefined
                : undefined,
            }),
          onEdit: (id: string) =>
            setFlyover({
              type: "edit_credit",
              fiatCreditTypes: fiatCreditTypes,
              customCreditTypes: customCreditTypes,
              id,
              rateCardId: !isEdit
                ? ctrl.get("rateCardId") ?? undefined
                : undefined,
            }),
        },
        {
          label: "Scheduled charge",
          onAdd: () =>
            setFlyover({
              type: "new_scheduled_charge",
              fiatCreditTypes: fiatCreditTypes,
            }),
          onEdit: (id: string) =>
            setFlyover({
              type: "edit_scheduled_charge",
              id,
              fiatCreditTypes: fiatCreditTypes,
            }),
        },
        {
          label: "Discount",
          onAdd: () =>
            setFlyover({
              type: "new_discount",
              fiatCreditTypes: fiatCreditTypes,
            }),
          onEdit: (id: string) => {
            setFlyover({
              type: "edit_discount",
              id,
              fiatCreditTypes: fiatCreditTypes,
            });
          },
        },
        {
          label: "Reseller royalty",
          onAdd: () =>
            setFlyover({
              type: "new_reseller_royalty",
            }),
          onEdit: (id: string) => {
            setFlyover({
              type: "edit_reseller_royalty",
              id,
            });
          },
        },
        {
          label: "Professional services",
          onAdd: () =>
            setFlyover({
              type: "new_pro_service",
            }),
          onEdit: (id: string) => {
            setFlyover({
              type: "edit_pro_service",
              id,
            });
          },
        },
      ].filter(({ label }) => {
        if (label === "Reseller royalty") {
          return featureFlagOptions.resellerRoyaltyEnabled;
        }
        if (label === "Professional services") {
          return featureFlagOptions.professionalServicesEnabled;
        }
        if (label === "Discount") {
          return featureFlagOptions.discountEnabled;
        }
        return true;
      }),
    [isEdit, featureFlagOptions, ctrl],
  );

  return (
    <div className="flex flex-col gap-[24px]">
      <SectionHeader
        title="Terms"
        subtitle="Optional billing terms, covering pre-purchased usage, discounts, usage-based triggers, and recurring charges."
        bottomBorder={true}
        actions={[
          <Dropdown label="Add" buttonTheme="primary">
            {termDetails.map(({ label, onAdd }, index) => (
              <DropdownItem
                key={index}
                label={label}
                value={label}
                onClick={() => onAdd()}
              />
            ))}
          </Dropdown>,
        ]}
      />
      {shouldShowTables && pricing ? (
        <TermsTables
          ctrl={ctrl}
          pricing={pricing.contract_pricing}
          creditTypes={allCreditTypes}
          editHandlers={termDetails.map(({ label, onEdit }) => ({
            label,
            onEdit,
          }))}
          shouldShowTables={shouldShowTables}
          fiatCreditTypes={fiatCreditTypes}
          shouldShowAdditionalTermsTable={shouldShowAdditionalTermsTable}
          isEdit={isEdit}
        />
      ) : (
        <TermCards
          subscriptionUIEnabled={subscriptionsUIEnabled}
          thresholdBillingUIEnabled={thresholdBillingUIEnabled}
        />
      )}
    </div>
  );
};
