import React from "react";
import { createContainer } from "unstated-next";
import { FormController } from "app/lib/FormController";
import { Schema } from "./Schema";
import { useSnapshot, Snapshot } from "../Create/lib/SharedContext";
import { useRequiredParam, useOptionalParam } from "app/lib/routes/params";
import {
  ContractPricingAndCreditTypesQuery,
  useContractPricingAndCreditTypesQuery,
  useExistingCustomerContractsQuery,
} from "../Create/data.graphql";
import { useMemo, useState } from "react";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import {
  useGetBillingProvidersSettingsQuery,
  useGetClientConfigQuery,
} from "app/pages/deprecated/Customer/tabs/Settings/sections/BillingProvider/queries.graphql";
import {
  CustomerIntegrationSettingsInfo,
  MultiBPCustomerSettings,
  parseIntegrationCustomerSettings,
  StripeCustomerSettings,
} from "app/lib/billingProvider/billingProviderSettings";
import {
  BillingProviderEnum_Enum,
  ExternalCommitType,
} from "types/generated-graphql/__types__";
import { stripeCanBeSetUp } from "app/pages/deprecated/Customer/tabs/Settings/sections/BillingProvider";
import {
  formatCharges,
  formatCommits,
  onConnectAWS,
  onConnectAzure,
} from "./contextUtils";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useContractToEditQuery } from "./data.graphql";

export type CreateContractCtrl = ReturnType<typeof useCreateContractCtrl>;
export const useCreateContractCtrl = FormController.createHook(
  Schema.CreateContractInput,
  {
    init(
      snapshot: Snapshot,
      pricing?: ContractPricingAndCreditTypesQuery["contract_pricing"],
    ) {
      const initial = FormController.parseJsonSnapshot(
        Schema.CreateContractInput,
        snapshot.initialJson,
      );

      return {
        ...initial,
        rateCardId: initial?.rateCardId ?? pricing?.rate_cards.at(0)?.id,
      };
    },
  },
);

export type EditContractCtrl = ReturnType<typeof useEditContractCtrl>;
const useEditContractCtrl = FormController.createHook(
  Schema.EditContractInput,
  {
    init(snapshot: Snapshot) {
      return FormController.parseJsonSnapshot(
        Schema.EditContractInput,
        snapshot.initialJson,
      );
    },
  },
);

export type RateCardMap = Map<string, { id: string; name: string }>;
// For now, we need to cast FormController to any because the types are not compatible between the two controllers.
// This will be fixed in the future when we start actually using the edit controller
// ToDo: PENG-3620 - Fix EditContractCtrl to be compatible with CreateContractCtrl
export type ContractFormController = FormController<any>;

export type NewBillingProviderConfigOptionProps = {
  label: string;
  value: BillingProviderEnum_Enum;
  onAddNew: () => void;
};

function useContractContext() {
  const customerId = useRequiredParam("customerId");
  const contractId = useOptionalParam("contractId");
  const [awsMarketplaceSettingsModalOpen, setAWSMarketplaceSettingsModalOpen] =
    useState(false);
  const [
    azureMarketplaceSettingsModalOpen,
    setAzureMarketplaceSettingsModalOpen,
  ] = useState(false);
  const [stripeSettingsModalOpen, setStripeSettingsModalOpen] = useState(false);
  const isEdit = !!contractId;
  const pricingAndCreditTypesReq = useContractPricingAndCreditTypesQuery();
  const { data: clientConfig, loading: clientConfigLoading } =
    useGetClientConfigQuery();
  const existingContractsReq = useExistingCustomerContractsQuery({
    variables: {
      customerId,
    },
    skip: isEdit,
  });

  const contractToEdit = useContractToEditQuery({
    variables: {
      contract_id: contractId ?? "",
      customer_id: customerId,
    },
    skip: !isEdit,
  });

  const existingContracts = (
    existingContractsReq.data?.Customer_by_pk?.contracts ?? []
  ).filter((c) => !c.archived_at);
  const clientHasDeltaStreamEnabled =
    clientConfig?.is_delta_stream_enabled ?? false;

  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );
  const snapshot = useSnapshot(
    isEdit
      ? `contract-edit-snapshot:${customerId}:${contractId}`
      : `contract-create-snapshot:${customerId}`,
  );

  const editCtrl: EditContractCtrl = useEditContractCtrl(snapshot);
  const createCtrl: CreateContractCtrl = useCreateContractCtrl(
    snapshot,
    pricingAndCreditTypesReq.data?.contract_pricing,
  );
  const ctrl: ContractFormController = isEdit ? editCtrl : createCtrl;

  React.useEffect(() => {
    if (pricingAndCreditTypesReq.data?.contract_pricing && !isEdit) {
      ctrl.update({
        rateCardId:
          pricingAndCreditTypesReq.data?.contract_pricing?.rate_cards.at(0)?.id,
      });
    }
  }, [pricingAndCreditTypesReq.data?.contract_pricing]);

  React.useEffect(() => {
    if (contractToEdit?.data) {
      const allCommits =
        contractToEdit.data?.Customer_by_pk?.contract?.v2_fields?.commits_union;
      const charges =
        contractToEdit.data?.Customer_by_pk?.contract?.v2_fields
          ?.scheduled_charges;
      const commits = allCommits?.filter(
        (commit) =>
          commit.__typename === "PostpaidCommit" ||
          commit.external_type === ExternalCommitType.Commit,
      );
      const credits = allCommits?.filter(
        (commit) =>
          commit.__typename === "PrepaidCommit" &&
          commit.external_type === ExternalCommitType.Credit,
      );

      ctrl.update({
        commits: commits ? formatCommits(commits) : [],
        existingCommitIds: commits?.map((commit) => commit.id) ?? [],
        scheduledCharges: charges ? formatCharges(charges) : [],
        existingScheduledChargeIds: charges?.map((sc) => sc.id) ?? [],
        credits: credits ? formatCommits(credits) : [],
        existingCreditIds: credits?.map((credit) => credit.id) ?? [],
        salesforceOpportunityId:
          contractToEdit.data?.Customer_by_pk?.contract
            ?.salesforce_opportunity_id ?? undefined,
        netsuiteSalesOrderId:
          contractToEdit.data?.Customer_by_pk?.contract
            ?.netsuite_sales_order_id ?? undefined,
      });
    }
  }, [contractToEdit?.data]);

  const clearSnapshot = () => snapshot.clear();

  const rateCards: RateCardMap = useMemo(() => {
    const map = new Map();
    if (pricingAndCreditTypesReq.data?.contract_pricing?.rate_cards) {
      pricingAndCreditTypesReq.data.contract_pricing.rate_cards.forEach(
        (rateCard) => {
          map.set(rateCard.id, rateCard);
        },
      );
    }
    return map;
  }, [pricingAndCreditTypesReq.data]);

  const { environmentType } = useEnvironment();

  const {
    data: billingProviderSettingsData,
    loading: billingProviderSettingsLoading,
  } = useGetBillingProvidersSettingsQuery({
    variables: {
      customer_id: customerId,
      environment_type: environmentType,
    },
  });

  let billingProviderSettings: CustomerIntegrationSettingsInfo | undefined;
  let stripeMultiSettings: MultiBPCustomerSettings<StripeCustomerSettings> = [];

  if (billingProviderSettingsData) {
    billingProviderSettings = parseIntegrationCustomerSettings(
      billingProviderSettingsData,
    );
    stripeMultiSettings = billingProviderSettings?.stripeSettings ?? [];
  }

  const azureMultiSettings = billingProviderSettings?.azureMultiSettings ?? [];
  const awsMultiSettings = billingProviderSettings?.awsMultiSettings ?? [];
  const stripeContractsSettings = stripeMultiSettings.filter(
    (s) => !!s.customerBillingProviderConfigurationID,
  );

  const newBillingProviderConfigOptions = useMemo(
    () =>
      [
        // AWS and Azure Marketplaces are always available to connect to new
        // contracts via multi-bp-on-contracts functionality, provided they are
        // set up at the client level
        billingProviderSettings?.clientHasAzure
          ? {
              label: `Azure`,
              value: BillingProviderEnum_Enum.Azure,
              onAddNew: () => {
                setAzureMarketplaceSettingsModalOpen(true);
              },
            }
          : undefined,
        billingProviderSettings?.clientHasAws
          ? {
              label: `AWS`,
              value: BillingProviderEnum_Enum.AwsMarketplace,
              onAddNew: () => {
                setAWSMarketplaceSettingsModalOpen(true);
              },
            }
          : undefined,
        stripeCanBeSetUp(billingProviderSettings)
          ? {
              label: "Stripe",
              value: BillingProviderEnum_Enum.Stripe,
              onAddNew: () => {
                setStripeSettingsModalOpen(true);
              },
            }
          : undefined,
      ].filter(
        (
          v: NewBillingProviderConfigOptionProps | undefined,
        ): v is NewBillingProviderConfigOptionProps => !!v,
      ),
    [billingProviderSettings],
  );

  // NOTE: if an integration is connected to a contract, there's a customerBillingProviderConfigurationID for it
  const existingCustomerBillingProviderConfigOptions = useMemo(
    () => [
      // NOTE: stripe doesn't support multiple configs per customer, but marketplaces do.
      // We're still doing this in case that changes for Stripe/for consistency
      ...azureMultiSettings.map((azureSettingsForContracts) => ({
        label: `Azure`,
        subtext: `Subscription ID: ${azureSettingsForContracts.subscriptionId}`,
        value:
          azureSettingsForContracts.customerBillingProviderConfigurationID ??
          BillingProviderEnum_Enum.Azure,
        isConnected: true,
        onConnectExisting: () => {
          onConnectAzure(ctrl, azureSettingsForContracts);
        },
      })),
      ...awsMultiSettings.map((awsSettingsForContracts) => ({
        label: `AWS`,
        subtext: `Customer ID: ${awsSettingsForContracts.customerId}, Product Code: ${awsSettingsForContracts.productCode}, Region: ${awsSettingsForContracts.region}`,
        value:
          awsSettingsForContracts.customerBillingProviderConfigurationID ??
          BillingProviderEnum_Enum.AwsMarketplace,
        isConnected: true,
        onConnectExisting: () => {
          onConnectAWS(ctrl, awsSettingsForContracts);
        },
      })),
      ...stripeContractsSettings?.map((stripeSettingsForContracts) => ({
        label: `Stripe`,
        subtext: `Customer ID: ${stripeSettingsForContracts.customerId}, Collection Method: ${stripeSettingsForContracts.collectionMethod}`,
        value:
          stripeSettingsForContracts.customerBillingProviderConfigurationID ??
          BillingProviderEnum_Enum.Stripe,
        isConnected: true,
        onConnectExisting: () => {
          ctrl.update({
            billingProvider: BillingProviderEnum_Enum.Stripe,
            billingProviderConfiguration: {
              billing_provider_configuration_id:
                stripeSettingsForContracts.customerBillingProviderConfigurationID,
              configuration: {
                stripe_customer_id: stripeSettingsForContracts.customerId,
                stripe_collection_method:
                  stripeSettingsForContracts.collectionMethod,
              },
            },
          });
        },
      })),
    ],
    [billingProviderSettings],
  );

  const billingProviderDropdownDisabled =
    [
      ...existingCustomerBillingProviderConfigOptions,
      ...newBillingProviderConfigOptions,
    ].length === 0;

  const featureFlagOptions = {
    netsuiteEnabled: nonGAContractFeaturesEnabled?.includes("NETSUITE"),
    salesforceEnabled: nonGAContractFeaturesEnabled?.includes("SALESFORCE"),
    supersedeEnabled: nonGAContractFeaturesEnabled?.includes(
      "TRANSITION_SUPERSEDE",
    ),
    professionalServicesEnabled: nonGAContractFeaturesEnabled?.includes(
      "PROFESSIONAL_SERVICES",
    ),
    resellerRoyaltiesEnabled:
      nonGAContractFeaturesEnabled?.includes("RESELLER_ROYALTIES"),
    discountsEnabled: nonGAContractFeaturesEnabled?.includes("DISCOUNTS"),
  };

  const loadingStates = {
    pricing: pricingAndCreditTypesReq.loading,
    existingContracts: existingContractsReq.loading,
    billingProviderSettings: billingProviderSettingsLoading,
    clientConfig: clientConfigLoading,
    contract: contractToEdit.loading,
  };

  const errors = {
    pricing: pricingAndCreditTypesReq.error,
    existingContracts: existingContractsReq.error,
    contract: contractToEdit.error,
  };

  return {
    ctrl,
    clearSnapshot,
    isEdit,
    errors,
    loadingStates,
    rateCards,
    clientHasDeltaStreamEnabled,
    newBillingProviderConfigOptions,
    existingCustomerBillingProviderConfigOptions,
    billingProviderSettings,
    stripeSettingsModalOpen,
    awsMarketplaceSettingsModalOpen,
    azureMarketplaceSettingsModalOpen,
    setStripeSettingsModalOpen,
    setAWSMarketplaceSettingsModalOpen,
    setAzureMarketplaceSettingsModalOpen,
    billingProviderDropdownDisabled,
    featureFlagOptions,
    existingContracts,
    contractToEdit: contractToEdit.data?.Customer_by_pk?.contract,
    pricing: pricingAndCreditTypesReq?.data,
  };
}

export const ContractContext = createContainer(useContractContext);
