import {
  Destination,
  EnumObject,
  Form,
  FormField,
  FormSection,
} from "@prequel/react";
import { reportToSentry } from "app/lib/errors/sentry";
import { ButtonGroup, ButtonProps } from "components/ButtonGroup";
import { CodeBlock } from "components/CodeBlock";
import { TextInput } from "components/Input";
import { SectionHeader } from "components/SectionHeader";
import { Toggle } from "components/Toggle";
import { TextArea } from "design-system/components/Input";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { Tooltip } from "components/Tooltip";
import React, { useEffect } from "react";

type SetDestinationHook = React.Dispatch<
  React.SetStateAction<Partial<Destination>>
>;

type DestinationFormProps = {
  formRef: React.RefObject<HTMLFormElement>;
  form: Form;

  destination: Destination;
  setDestination: SetDestinationHook;
};

export const DestinationForm: React.FC<DestinationFormProps> = ({
  form,
  formRef,
  destination,
  setDestination,
}) => {
  const sections = form.map((section) => {
    return (
      <DestinationFormSection
        key={section.id}
        destination={destination}
        setDestination={setDestination}
        section={section}
      />
    );
  });

  return <form ref={formRef}>{sections}</form>;
};

const DestinationFormSection: React.FC<{
  destination: Destination;
  setDestination: SetDestinationHook;

  section: FormSection;
}> = ({ destination, setDestination, section }) => {
  const fields = section.fields.map((field) => {
    return (
      <div className="my-12" key={field.name}>
        <DestinationFormField
          field={field}
          destination={destination}
          setDestination={setDestination}
        />
      </div>
    );
  });

  return (
    <div className="my-12">
      <SectionHeader
        title={section.title}
        subtitle={section.subtitle ?? ""}
        bottomBorder={false}
      />
      {fields}
    </div>
  );
};

const DestinationFormField: React.FC<{
  field: FormField;
  destination: Destination;
  setDestination: SetDestinationHook;
}> = ({ field, destination, setDestination }) => {
  const element = field.form_element;
  let fragment = <></>;

  switch (element) {
    case "input":
      fragment = (
        <InputField
          field={field}
          destination={destination}
          setDestination={setDestination}
        />
      );
      break;
    case "textarea":
      fragment = (
        <TextAreaField
          field={field}
          destination={destination}
          setDestination={setDestination}
        />
      );
      break;
    case "select":
      fragment = (
        <DropdownField
          field={field}
          destination={destination}
          setDestination={setDestination}
        />
      );
      break;
    case "radio":
      fragment = (
        <RadioField
          field={field}
          destination={destination}
          setDestination={setDestination}
        />
      );
      break;
    case "div":
      fragment = <ConstField field={field} />;
      break;
    default:
      element satisfies never;
      reportToSentry("Invalid Prequel form element: " + element);
  }

  return <>{fragment}</>;
};

const InputField: React.FC<{
  field: FormField & {
    form_element: "input";
    input_type: "text" | "password" | "number";
  };
  destination: Destination;
  setDestination: SetDestinationHook;
}> = ({ field, destination, setDestination }) => {
  return (
    <TextInput
      fullWidth
      key={field.name}
      type={field.input_type}
      label={field.label}
      required={field.required}
      placeholder={field.input_type != "password" ? field.placeholder : ""}
      helpText={field.description}
      value={destination[field.name]?.toString()}
      onChange={({ value }) => {
        setDestination({
          [field.name]: value,
        });
      }}
    />
  );
};

const TextAreaField: React.FC<{
  field: FormField & {
    form_element: "textarea";
  };
  destination: Destination;
  setDestination: SetDestinationHook;
}> = ({ field, destination, setDestination }) => {
  return (
    <>
      <label className="mb-sm text-sm text-black">{field.label}</label>
      <TextArea
        required={field.required}
        key={field.name}
        placeholder={field.placeholder}
        value={destination[field.name]?.toString()}
        onChange={(value) => setDestination({ [field.name]: value })}
        minRows={8}
      />
    </>
  );
};

const DropdownField: React.FC<{
  field: FormField & {
    form_element: "select";
    enum?: EnumObject[];
  };
  destination: Destination;
  setDestination: SetDestinationHook;
}> = ({ field, destination, setDestination }) => {
  useEffect(() => {
    if (!field.required || !field.enum || field.enum.length === 0) {
      // no need to set a default
      return;
    }

    if (
      !destination[field.name] ||
      !field.enum.find((item) => item.key === destination[field.name])
    ) {
      // Set a default value if not set
      // or if set and not part of the existing enum value set
      setDestination({ [field.name]: field.enum[0].key });
    }
  }, [destination]);

  return (
    <Tooltip label={field.description} disabled={!field.description}>
      <Dropdown
        fullWidth={true}
        label={field.label}
        selectedOption={
          field.enum?.find((v) => v.key === destination[field.name])?.display
        }
      >
        {field.enum?.map((item) => {
          return (
            <DropdownItem
              value={item.key.toString()}
              label={item.display}
              disabled={item.disabled}
              onClick={({ value }) => setDestination({ [field.name]: value })}
            />
          );
        }) ?? []}
      </Dropdown>
    </Tooltip>
  );
};

const RadioField: React.FC<{
  field: FormField & {
    form_element: "radio";
    enum: EnumObject[];
  };
  destination: Destination;
  setDestination: SetDestinationHook;
}> = ({ field, destination, setDestination }) => {
  useEffect(() => {
    if (field.type === "boolean") {
      // not valid on boolean fields
      return;
    }

    if (!field.required || !field.enum || field.enum.length === 0) {
      // no need to set a default
      return;
    }

    if (
      !destination[field.name] ||
      !field.enum.find((item) => item.key === destination[field.name])
    ) {
      // Set a default value if not set
      // or if set and not part of the existing enum value set
      setDestination({ [field.name]: field.enum[0].key });
    }
  }, [destination]);

  if (field.type === "boolean") {
    return (
      <Toggle
        label={field.label}
        supportingText={field.description}
        toggled={!!destination[field.name]}
        onChange={({ toggled }) => setDestination({ [field.name]: toggled })}
      />
    );
  }

  const buttons: ButtonProps[] = field.enum.map(({ key, display }) => ({
    text: display,
    isActive: destination[field.name] === key,
    onClick: () => {
      setDestination({ [field.name]: key });
    },
  }));

  return (
    <div className="flex flex-col">
      <label className="mb-sm text-sm text-black">{field.label}</label>
      <ButtonGroup
        className="[&>button]:focus-within:ring-0"
        buttons={buttons}
      />
    </div>
  );
};

const ConstField: React.FC<{
  field: FormField & {
    form_element: "div";
  };
}> = ({ field }) => {
  if (!field.const) {
    return <></>;
  }

  if (typeof field.const === "string") {
    return (
      <TextInput
        fullWidth
        disabled={true}
        type="text"
        key={field.name}
        label={field.label}
        helpText={field.description}
        value={field.const}
      />
    );
  }

  if (typeof field.const === "object") {
    return (
      <>
        <div className="text-md">{field.label}</div>
        <CodeBlock
          language="json"
          height={200}
          isReadOnly={true}
          runButtonDisabled={true}
          code={JSON.stringify(field.const, null, 2)}
        />
      </>
    );
  }

  return <></>;
};
