import React from "react";
import { NumericInput, Body } from "design-system";

import { Schema } from "../../../CreateAndEdit/Schema";
import { useOverrideCtrl } from "./OverrideCtrl";
import Decimal from "decimal.js";

const useMultiplierRateCtrl = useOverrideCtrl.child(Schema.MultiplierOverride, {
  read(parent) {
    const rate = parent.get("rate");
    return rate?.type === "multiplier" ? rate : {};
  },
  write: (child) => ({
    rate: child.getUnvalidatedInputs(),
  }),
});

const formatMultiplier = (multiplier: number) => {
  const percentage = new Decimal(multiplier).times(100);

  if (percentage.gt(100)) {
    return `${percentage.minus(100)}% Increase`;
  } else if (percentage.lt(100) && percentage.gt(0)) {
    return `${new Decimal(100).minus(percentage)}% Discount`;
  } else {
    return "";
  }
};

interface Props {
  parent: ReturnType<typeof useOverrideCtrl>;
  multiplierOverridePrioritization: string | null;
}

export const MultiplierRate: React.FC<Props> = (props) => {
  const ctrl = useMultiplierRateCtrl(props.parent);
  const multiplierValue = ctrl.get("multiplier") ?? 0;

  return (
    <div className="flex items-center gap-12">
      <NumericInput
        {...ctrl.props.NumericInput("multiplier", {
          name: "Adjustment",
          placeholder: "0.0",
          tooltip:
            "Multiplies list price of product by desired adjustment - for example, a 0.8 adjustment results in a 20% discount.",
          min: 0,
          suffix: "",
        })}
        className="w-[6rem]"
      />
      {props.multiplierOverridePrioritization === "EXPLICIT" && (
        <NumericInput
          {...ctrl.props.NumericInput("priority", {
            name: "Priority",
            placeholder: "1",
            tooltip:
              "Required for EXPLICIT multiplier prioritization scheme. Lower priority values are prioritized first. Must be >0",
            min: 0,
            suffix: "",
          })}
          className="w-[6rem]"
        />
      )}
      <Body className="mb-0 mt-[18px] text-gray-600" level={2}>
        {formatMultiplier(multiplierValue)}
      </Body>
    </div>
  );
};
