/* a0c00b253d9bcadb1725fcf5a058aa4dbb51621e
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArchiveBillableMetricMutationVariables = Types.Exact<{
  billable_metric_id: Types.Scalars['uuid'];
}>;


export type ArchiveBillableMetricMutation = { __typename?: 'Mutation', archive_billable_metric: { __typename?: 'BillableMetric', id: string, deleted_at: string | null } };

export type ArchiveSeatMetricMutationVariables = Types.Exact<{
  seat_metric_id: Types.Scalars['uuid'];
}>;


export type ArchiveSeatMetricMutation = { __typename?: 'Mutation', archive_seat_metric: { __typename?: 'SeatMetric', id: string, deleted_at: string | null } };


export const ArchiveBillableMetricDocument = gql`
    mutation ArchiveBillableMetric($billable_metric_id: uuid!) {
  archive_billable_metric(billable_metric_id: $billable_metric_id) {
    id
    deleted_at
    __environment_type: environment_type
  }
}
    `;
export type ArchiveBillableMetricMutationFn = Apollo.MutationFunction<ArchiveBillableMetricMutation, ArchiveBillableMetricMutationVariables>;

/**
 * __useArchiveBillableMetricMutation__
 *
 * To run a mutation, you first call `useArchiveBillableMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBillableMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBillableMetricMutation, { data, loading, error }] = useArchiveBillableMetricMutation({
 *   variables: {
 *      billable_metric_id: // value for 'billable_metric_id'
 *   },
 * });
 */
export function useArchiveBillableMetricMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveBillableMetricMutation, ArchiveBillableMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveBillableMetricMutation, ArchiveBillableMetricMutationVariables>(ArchiveBillableMetricDocument, options);
      }
export type ArchiveBillableMetricMutationHookResult = ReturnType<typeof useArchiveBillableMetricMutation>;
export type ArchiveBillableMetricMutationResult = Apollo.MutationResult<ArchiveBillableMetricMutation>;
export type ArchiveBillableMetricMutationOptions = Apollo.BaseMutationOptions<ArchiveBillableMetricMutation, ArchiveBillableMetricMutationVariables>;
export const ArchiveSeatMetricDocument = gql`
    mutation ArchiveSeatMetric($seat_metric_id: uuid!) {
  archive_seat_metric(seat_metric_id: $seat_metric_id) {
    id
    deleted_at
  }
}
    `;
export type ArchiveSeatMetricMutationFn = Apollo.MutationFunction<ArchiveSeatMetricMutation, ArchiveSeatMetricMutationVariables>;

/**
 * __useArchiveSeatMetricMutation__
 *
 * To run a mutation, you first call `useArchiveSeatMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSeatMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSeatMetricMutation, { data, loading, error }] = useArchiveSeatMetricMutation({
 *   variables: {
 *      seat_metric_id: // value for 'seat_metric_id'
 *   },
 * });
 */
export function useArchiveSeatMetricMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveSeatMetricMutation, ArchiveSeatMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveSeatMetricMutation, ArchiveSeatMetricMutationVariables>(ArchiveSeatMetricDocument, options);
      }
export type ArchiveSeatMetricMutationHookResult = ReturnType<typeof useArchiveSeatMetricMutation>;
export type ArchiveSeatMetricMutationResult = Apollo.MutationResult<ArchiveSeatMetricMutation>;
export type ArchiveSeatMetricMutationOptions = Apollo.BaseMutationOptions<ArchiveSeatMetricMutation, ArchiveSeatMetricMutationVariables>;