import React from "react";

import { IconButton } from "components/IconButton";
import { Button } from "components/Button";

import { v4 as uuid } from "uuid";

import { RootCtrl, useRootCtrl } from "./RootCtrl";
import { useMode } from "./mode";
import { CreditType } from "app/types/credit-types";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";

import { TextInput } from "components/Input";
import { CommitPriceInput } from "../../lib/CommitPriceInput";
import { DatePicker } from "components/DatePicker";
import { dayjs } from "lib/dayjs";
import { Schema } from "../../Schema";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { DateSequence } from "../../lib/DateSequence";
import { upsertById } from "../../lib/upsertById";
import {
  CommitInvoiceScheduleItemEditability,
  CommitInvoiceScheduleItemRemovability,
  ScheduledChargeScheduleItemEditability,
} from "types/generated-graphql/__types__";
import {
  getEditabilityMessage,
  getRemovabilityMessage,
} from "../../../Edit/utils";
import { Tooltip } from "components/Tooltip";

export const useCustomScheduleCtrl = useRootCtrl.child(Schema.FixedSchedule, {
  read: (parent) => {
    const schedule = parent.get("schedule");
    return schedule?.type === "fixed" ? schedule : { type: "fixed" };
  },
  write: (child) => ({ schedule: child.getUnvalidatedInputs() }),
  debugName: "CustomSchedule",
});

export const CustomSchedule: React.FC<{
  parent: RootCtrl;
  creditType: CreditType;
  allowEmpty: boolean;
  onCreditTypeChange: (creditType: CreditType) => void;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useCustomScheduleCtrl(props.parent);
  const items = ctrl.get("items") ?? [];
  const mode = useMode();
  const seq = DateSequence.create(
    items.map((i) => i.date),
    timeframe,
  );

  return (
    <>
      <div className="col-span-3 flex flex-col gap-12">
        {items.map((item, i) => {
          return (
            <DateSequence.Provider seq={seq} index={i} key={item.id ?? `${i}`}>
              <CustomScheduleItem
                id={item.id}
                parent={ctrl}
                creditType={props.creditType}
                onCreditTypeChange={props.onCreditTypeChange}
                onDelete={
                  items.length > (props.allowEmpty ? 0 : 1)
                    ? () =>
                        ctrl.update({
                          items: ctrl
                            .get("items")
                            ?.filter((i) => i.id !== item.id),
                        })
                    : undefined
                }
              />
            </DateSequence.Provider>
          );
        })}
      </div>
      <div>
        <Button
          onClick={() =>
            ctrl.update({
              items: [
                ...(ctrl.get("items") || []),
                {
                  id: uuid(),
                },
              ],
            })
          }
          text={
            mode === "invoice"
              ? "Add another segment"
              : "Add discount to another invoice"
          }
          theme="secondary"
          leadingIcon="plus"
        />
      </div>
    </>
  );
};

const useScheduleItemCtrl = useCustomScheduleCtrl.child(
  Schema.FixedScheduleItem,
  {
    read(parent, id: string, timeframe?: DefaultTimeframe) {
      const existing = parent.get("items")?.find((i) => i.id === id);

      return {
        ...existing,
        date: existing?.date ?? timeframe?.startingAt,
      };
    },
    write(child, parent) {
      return {
        items: upsertById(
          parent.get("items") || [],
          child.getUnvalidatedInputs(),
        ),
      };
    },
  },
);

interface ScheduleItemProps {
  parent: ReturnType<typeof useCustomScheduleCtrl>;
  id: string;
  creditType: CreditType;
  onDelete?: () => void;
  onCreditTypeChange: (creditType: CreditType) => void;
}
const CustomScheduleItem: React.FC<ScheduleItemProps> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useScheduleItemCtrl(props.parent, props.id, timeframe);

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "unitPrice",
  );

  const editability =
    ctrl.get("editability") ?? CommitInvoiceScheduleItemEditability.Editable;
  const isEditable =
    editability === CommitInvoiceScheduleItemEditability.Editable ||
    editability === ScheduledChargeScheduleItemEditability.Editable;

  const removability =
    ctrl.get("removability") ?? CommitInvoiceScheduleItemRemovability.Removable;

  return (
    <div className="mb-12 mt-12 flex flex-row gap-[24px]">
      <div className="flex flex-1 flex-col">
        <p className="mb-4 min-h-[20px] text-sm text-black">Unit price</p>
        <Tooltip
          label={getEditabilityMessage(editability)}
          disabled={isEditable}
        >
          <CommitPriceInput
            unitPrice={ctrl.get("unitPrice")}
            creditType={props.creditType}
            onPriceChange={(price) => {
              ctrl.update({
                unitPrice: price,
              });
            }}
            onCreditTypeChange={props.onCreditTypeChange}
            isInvalid={!ctrl.isValid("unitPrice")}
            hintText={
              (!ctrl.appearsValid() && ctrl.state.fields.unitPrice?.error) || ""
            }
            disabled={!isEditable}
          />
        </Tooltip>
      </div>
      <div className="flex w-[250px] flex-col">
        <p className="mb-4 text-sm text-black">Quantity</p>
        <Tooltip
          label={getEditabilityMessage(editability)}
          disabled={isEditable}
        >
          <TextInput
            placeholder="100"
            type="number"
            className="min-w-[200px]"
            disabled={!isEditable}
            value={
              ctrl.get("quantity") !== undefined
                ? (String(ctrl.get("quantity")) as string)
                : undefined
            }
            fullWidth
            onChange={({ value: quantity }) =>
              ctrl.update({
                quantity: quantity !== "" ? Number(quantity) : undefined,
              })
            }
            hintText={
              (!ctrl.appearsValid() && ctrl.state.fields.quantity?.error) || ""
            }
          />
        </Tooltip>
      </div>
      <div className="flex flex-none flex-col">
        <div className="mb-4 min-h-[20px]"></div>
        <DatePicker
          text="Invoice at"
          value={
            ctrl.get("date") ? dayjs.utc(ctrl.get("date")).toDate() : undefined
          }
          onDateApply={(date) => {
            ctrl.update({
              date: date?.toISOString(),
            });
          }}
          disabled={!isEditable}
          tooltipContent={{
            label: isEditable
              ? "Invoice at"
              : getEditabilityMessage(editability),
          }}
        />
      </div>
      <div className="flex flex-none flex-col">
        <div className="mb-4 min-h-[20px]"></div>
        <Tooltip
          label={
            removability === CommitInvoiceScheduleItemRemovability.Removable
              ? "Remove segment"
              : getRemovabilityMessage(removability)
          }
        >
          <IconButton
            onClick={props.onDelete}
            theme="secondary"
            icon="trash01"
            disabled={
              removability !== CommitInvoiceScheduleItemRemovability.Removable
            }
          />
        </Tooltip>
      </div>
    </div>
  );
};
