import { CreatePlanDataQuery } from "../../../../data/queries.graphql";
import React, { useEffect } from "react";
import { useDraftPlan } from "../../../../context";
import { getRampStartPeriod } from "app/lib/plans/ramps";
import { Minimum } from "app/lib/plans/types";
import { getInvoiceMinimumFloor } from "../../doneChecks";
import {
  DeprecatedInputSection,
  DeprecatedWizardSplitPage,
} from "components/deprecated/Wizard";
import { creditsFromInput } from "app/lib/credits";
import { getUpdateCreditTypeForInvoiceMinimum } from "../../actions";
import { DeprecatedCreditInput } from "components/deprecated/Input";
import { DeprecatedPricingUnitSelector } from "components/deprecated/PricingUnitSelector";
import { CustomCreditType, FiatCreditType } from "app/types/credit-types";

interface InvoiceMinimumPageProps {
  rampIndex: number;
  data: CreatePlanDataQuery;
}
export const InvoiceMinimumPage: React.FC<InvoiceMinimumPageProps> = (
  props,
) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const rampStartPeriod = getRampStartPeriod(
    props.rampIndex,
    draftPlan.ramps ? [...draftPlan.ramps, {}] : [{}],
  );
  const prevRampStartPeriod = getRampStartPeriod(
    props.rampIndex - 1,
    draftPlan.ramps ? [...draftPlan.ramps, {}] : [{}],
  );

  const minimum: Minimum = draftPlan.minimums?.find(
    (min) => min.startPeriod === rampStartPeriod,
  ) || {
    startPeriod: rampStartPeriod,
  };

  const creditType = minimum.creditType || undefined;
  const minimumFloor = getInvoiceMinimumFloor(
    draftPlan,
    rampStartPeriod,
    creditType,
  );

  useEffect(() => {
    if (props.rampIndex > 0) {
      // Pre-fill minimum: If needed, write previous ramp's minimum to the
      // draftPlan after this invoice minimum page is loaded
      if (!minimum.value) {
        const prevMin = draftPlan.minimums?.find(
          (min) => min.startPeriod === prevRampStartPeriod,
        );
        if (prevMin) {
          setDraftPlan({
            ...draftPlan,
            minimums: (draftPlan.minimums || [])
              .filter((min) => min.startPeriod !== rampStartPeriod)
              .concat([{ ...prevMin, startPeriod: rampStartPeriod }]),
          });
        }
      }
    }
  }, [props.rampIndex]);

  const allowedCreditTypeIds = new Set<string>(
    draftPlan.pricedProducts?.map((pp) => pp.creditType?.id ?? ""),
  );

  return (
    <DeprecatedWizardSplitPage>
      <DeprecatedInputSection
        header="Invoice minimums"
        subtitle="Invoice minimums are applied at the end of each billing period. If a customer's total spend is less than the minimum, an adjustment is added to make up the difference."
      >
        <div className="mb-0 ml-0 mr-12 mt-12 flex w-[182px] flex-col">
          <DeprecatedPricingUnitSelector
            key={`invoiceMinimum${rampStartPeriod}`}
            disabled={rampStartPeriod > 0}
            onChange={(ct) =>
              setDraftPlan(
                getUpdateCreditTypeForInvoiceMinimum(
                  ct as FiatCreditType | CustomCreditType,
                  rampStartPeriod,
                  draftPlan,
                ),
              )
            }
            selectedCreditTypeId={creditType?.id}
            allowedCreditTypeIds={allowedCreditTypeIds}
            autoFocus={creditType?.id === undefined}
          />
        </div>
        <div className="mb-[28px]">
          <DeprecatedCreditInput
            name="Invoice minimum"
            placeholder="123.45"
            success={!!minimum.value}
            creditType={
              creditType || {
                id: "",
                name: "",
                client_id: null,
                environment_type: null,
              }
            }
            initialValue={minimum.value}
            allowZeroAmounts
            onChange={(v) => {
              if (v === null) {
                return;
              }
              setDraftPlan({
                ...draftPlan,
                minimums: draftPlan.minimums
                  ?.filter((min) => min.startPeriod !== rampStartPeriod)
                  .concat(
                    minimum
                      ? [
                          {
                            ...minimum,
                            value: v.toString(),
                          },
                        ]
                      : [
                          {
                            startPeriod: rampStartPeriod,
                            value: v.toString(),
                          },
                        ],
                  ),
              });
            }}
            disabled={!creditType}
            error={
              minimum &&
              minimum.value !== undefined &&
              creditType &&
              creditsFromInput(minimum.value, creditType).lessThan(minimumFloor)
                ? "This minimum is less than the lowest possible total"
                : ""
            }
            className="w-[208px]"
          />
        </div>
      </DeprecatedInputSection>
    </DeprecatedWizardSplitPage>
  );
};
