import React, { useContext, useState } from "react";
import { v4 as uuid } from "uuid";

import { OneTimeSchedule } from "./OneTimeSchedule";
import { RecurringSchedule } from "./RecurringSchedule";
import { CustomSchedule } from "./CustomSchedule";
import { RecurringScheduleFrequency } from "types/generated-graphql/__types__";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { CreditTypeContext } from "../../Sections/Commits/CommitTakeover";
import { CreditType } from "app/types/credit-types";
import { ModeProvider } from "./mode";
import { Schema } from "../../Schema";
import { ParentCtrl, useRootCtrl } from "./RootCtrl";

function transformSchedule(
  newFreq: Schema.Types.BillingScheduleFrequency,
  prevSchedule: Schema.Types.BillingSchedule | undefined,
): Schema.Types.BillingSchedule {
  switch (newFreq) {
    case "none":
      return {
        type: "fixed",
        items: [],
      };
    case "one":
      // truncate to one fixed item
      return {
        type: "fixed",
        items:
          prevSchedule?.type === "fixed" ? prevSchedule.items.slice(0, 1) : [],
      };
    case "custom":
      return {
        type: "fixed",
        items:
          prevSchedule?.type === "fixed" && prevSchedule.items.length
            ? prevSchedule.items
            : [
                {
                  id: uuid(),
                } as Schema.Types.FixedScheduleItem,
              ],
      };
    case RecurringScheduleFrequency.Annual:
    case RecurringScheduleFrequency.Monthly:
    case RecurringScheduleFrequency.Quarterly:
    case RecurringScheduleFrequency.SemiAnnual:
      // preserve existing schedule if it's also recurring
      return {
        ...(prevSchedule?.type === "recurring" ? prevSchedule : {}),
        type: "recurring",
      } as Schema.Types.BillingSchedule;
  }
}

// Frequency value mapped to its label (excluding the None option)
const FREQUENCY_MAPPING: Record<string | RecurringScheduleFrequency, string> = {
  one: "One-time",
  [RecurringScheduleFrequency.Monthly]: "Scheduled monthly",
  [RecurringScheduleFrequency.Quarterly]: "Scheduled quarterly",
  [RecurringScheduleFrequency.SemiAnnual]: "Scheduled semi-annually",
  [RecurringScheduleFrequency.Annual]: "Scheduled annually",
  custom: "Custom",
};

interface Props {
  parent: ParentCtrl;
  mode: "invoice" | "discount";
  allowEmpty?: boolean;
  isExistingSchedule?: boolean;
}

export const BillingSchedule: React.FC<Props> = (props) => {
  const creditTypeContext = useContext(CreditTypeContext);
  const ctrl = useRootCtrl(
    props.parent,
    creditTypeContext.fiatCreditTypes[0].id,
  );
  // Set the initial selected invoice frequency based on the value set via the form controller, otherwise default to One-time
  const [selectedFrequencyLabel, setSelectedFrequencyLabel] = useState(
    FREQUENCY_MAPPING[ctrl.get("frequency") ?? "one"],
  );

  const creditType = findCreditType(
    ctrl.get("creditTypeId") ?? USD_CREDIT_TYPE.id,
    creditTypeContext.fiatCreditTypes,
  );

  function setFrequency(label: string, value: string) {
    const update = {
      frequency: value as
        | "none"
        | "one"
        | "custom"
        | RecurringScheduleFrequency,
    };
    ctrl.update({
      frequency:
        update.frequency === "none" && !props.allowEmpty
          ? "one"
          : update.frequency,
      schedule: transformSchedule(update.frequency, ctrl.get("schedule")),
    });
    setSelectedFrequencyLabel(label);
  }

  function setBillingScheduleCreditType(creditType: CreditType) {
    props.parent.update({
      billingScheduleCreditTypeId: creditType.id,
    });
    ctrl.update({
      creditTypeId: creditType.id,
    });
  }

  function generateFrequencyDropdownItemProps(label: string, value: string) {
    return {
      key: label,
      label: label,
      onClick: (meta: { label: string; value: string; selected: boolean }) =>
        setFrequency(meta.label, meta.value),
      value: value,
      selected: ctrl.get("frequency") === value,
    };
  }

  return (
    <div>
      <p className="mb-4 text-sm text-black">Invoice frequency</p>
      <Dropdown label="Select" selectedOption={selectedFrequencyLabel}>
        {props.allowEmpty && (
          <DropdownItem
            {...generateFrequencyDropdownItemProps("None (free)", "none")}
          />
        )}
        {Object.entries(FREQUENCY_MAPPING).map(([value, label]) => (
          <DropdownItem {...generateFrequencyDropdownItemProps(label, value)} />
        ))}
      </Dropdown>
      <ModeProvider value={props.mode}>
        {((): React.ReactElement | null => {
          const freq = ctrl.get("frequency");
          switch (freq) {
            case "one":
              return (
                <OneTimeSchedule
                  parent={ctrl}
                  creditType={creditType}
                  onCreditTypeChange={(creditType) =>
                    setBillingScheduleCreditType(creditType)
                  }
                />
              );
            case RecurringScheduleFrequency.Annual:
            case RecurringScheduleFrequency.Monthly:
            case RecurringScheduleFrequency.Quarterly:
            case RecurringScheduleFrequency.SemiAnnual:
              return (
                <RecurringSchedule
                  parent={ctrl}
                  frequency={freq}
                  creditType={creditType}
                  onCreditTypeChange={(creditType) =>
                    setBillingScheduleCreditType(creditType)
                  }
                />
              );
            case "custom":
              return (
                <CustomSchedule
                  parent={ctrl}
                  creditType={creditType}
                  allowEmpty={!!props.allowEmpty}
                  onCreditTypeChange={(creditType) =>
                    setBillingScheduleCreditType(creditType)
                  }
                />
              );
            case "none":
            case undefined:
              return null;
          }
        })()}
      </ModeProvider>
    </div>
  );
};
