import React from "react";

import { Headline, Icon, NumericInput, Tooltip } from "design-system";
import { IconButton } from "components/IconButton";
import Decimal from "decimal.js";
import { RoundedCurrency, USD_CREDIT_ID } from "app/lib/credits";
import { DeprecatedProductContainer } from "components/deprecated/ProductContainer";
import { DeprecatedSimpleTable } from "components/deprecated/SimpleTable";
import {
  InvoiceCorrectionItem,
  InvoiceCorrectionItemTypeEnum,
  InvoiceCorrectionLineItemPointer,
} from "types/generated-graphql/__types__";
import { DeprecatedNoSubLineItems } from "components/deprecated/Invoice/components/NoSubLineItems";
import {
  ArrearsCorrectableLineItem,
  CorrectableSubLineItem,
  CorrectionEditMode,
} from "../../types";
import { createLineItemPointer, lineItemPointerToString } from "../../pointers";
import { Quantity } from "components/Quantity";
import { EmbeddableDashboardContext } from "embeddable-dashboards/lib/embeddableDashboardContext";

export const ProductChargeLineItemCorrection: React.FC<{
  lineItem: ArrearsCorrectableLineItem;
  correctItem: (item: InvoiceCorrectionItem & { input?: number }) => void;
  removeCorrection: (pointer: InvoiceCorrectionLineItemPointer) => void;
  clearCorrections: () => void;
  corrections: Record<string, InvoiceCorrectionItem & { input?: number }>;
  correctedLineItems?: Record<string, CorrectableSubLineItem>;
  editMode: CorrectionEditMode;
  onEditModeChanged: (editMode: CorrectionEditMode) => void;
}> = ({
  lineItem,
  corrections,
  correctItem,
  removeCorrection,
  clearCorrections,
  correctedLineItems,
  editMode,
  onEditModeChanged,
}) => {
  const lineItemName =
    lineItem.__typename === "GroupedProductChargeLineItem"
      ? lineItem.product.name
      : lineItem.display_name;

  const subLineItems: (CorrectableSubLineItem & {
    pointer: InvoiceCorrectionLineItemPointer;
  })[] = lineItem.sub_line_items.map((sli) => ({
    ...sli,
    pointer: createLineItemPointer(lineItem, sli),
  }));

  const changeEditMode = (newEditMode: CorrectionEditMode) => {
    if (newEditMode !== editMode) {
      onEditModeChanged(newEditMode);
      clearCorrections();
    }
  };

  const renderSelectableColumn = (
    name: string,
    targetEditMode: CorrectionEditMode,
  ) => {
    return (
      <span className="flex items-center">
        <IconButton
          disabled={targetEditMode === editMode}
          onClick={() => changeEditMode(targetEditMode)}
          theme="tertiary"
          icon="edit05"
        />
        {name}
      </span>
    );
  };

  const renderEditableDiff = (
    targetEditMode: CorrectionEditMode,
    subLineItem: CorrectableSubLineItem & {
      pointer: InvoiceCorrectionLineItemPointer;
    },
    correctedSubLineItem: CorrectableSubLineItem | undefined,
  ) => {
    if (editMode !== targetEditMode) {
      if (!correctedSubLineItem) {
        return "--";
      }
      const value =
        targetEditMode === "newQuantity"
          ? new Decimal(subLineItem.quantity).add(
              new Decimal(correctedSubLineItem.quantity),
            )
          : targetEditMode === "newTotal"
            ? new Decimal(subLineItem.total).add(
                new Decimal(correctedSubLineItem.total),
              )
            : targetEditMode === "deltaQuantity"
              ? new Decimal(correctedSubLineItem.quantity)
              : targetEditMode === "deltaTotal"
                ? new Decimal(correctedSubLineItem.total)
                : (new Decimal(0) as typeof targetEditMode as never);

      if (targetEditMode === "newTotal" || targetEditMode === "deltaTotal") {
        return (
          <RoundedCurrency
            amount={value}
            creditType={subLineItem.credit_type}
            hideSuffix={subLineItem.credit_type.id === USD_CREDIT_ID}
          />
        );
      } else {
        const roundedValue = value.abs().lessThan(1e-10)
          ? new Decimal(0)
          : value.abs().lessThan(1)
            ? value.toSignificantDigits(2)
            : value.toDecimalPlaces(2);

        if (roundedValue.toString() !== value.toString()) {
          return (
            <Tooltip inline content={value.toString()}>
              {roundedValue.toString()}
            </Tooltip>
          );
        }
        return value.toString();
      }
    }

    if (subLineItem.__typename === "CompositeChargeLineItem") {
      return <NumericInput placeholder="Calculated total" disabled />;
    }
    if (subLineItem.pricing_factor.charge_type_enum === "FLAT") {
      return (
        <Tooltip content="Fixed charges cannot be corrected">
          <NumericInput placeholder="Fixed" disabled />
        </Tooltip>
      );
    }

    const unitPrice =
      "unit_price" in subLineItem && subLineItem.unit_price
        ? new Decimal(subLineItem.unit_price)
        : null;
    if (
      (targetEditMode === "newTotal" || targetEditMode === "deltaTotal") &&
      !unitPrice
    ) {
      // If there's no unit price, then this line item is more complex than total = quantity * unit price,
      // so the total cannot be edited. It could still be edited indirectly by changing the quantity instead.
      return <NumericInput placeholder="Complex total" disabled />;
    }

    const existingCorrection =
      corrections[lineItemPointerToString(subLineItem.pointer)]?.input;

    return (
      <NumericInput
        placeholder="123"
        value={existingCorrection}
        onChange={(v) => {
          if (v == null) {
            removeCorrection(subLineItem.pointer);
          } else {
            const newQuantity =
              editMode === "newQuantity"
                ? new Decimal(v)
                : editMode === "newTotal"
                  ? new Decimal(v).div(unitPrice ?? new Decimal(1))
                  : editMode === "deltaQuantity"
                    ? new Decimal(v).add(new Decimal(subLineItem.quantity))
                    : editMode === "deltaTotal"
                      ? new Decimal(v)
                          .div(unitPrice ?? new Decimal(1))
                          .add(new Decimal(subLineItem.quantity))
                      : (new Decimal(0) as typeof editMode as never);

            correctItem({
              line_item_pointer: subLineItem.pointer,
              value: newQuantity.toString(),
              type: InvoiceCorrectionItemTypeEnum.QuantityChange,
              input: v,
            });
          }
        }}
      />
    );
  };

  const subLineItemTable = (
    <DeprecatedSimpleTable
      noBottomBorder
      data={subLineItems}
      columns={[
        {
          header: "Description",
          render: (subLineItem) => {
            return subLineItem.display_name;
          },
        },
        {
          header: "Quantity",
          render: (subLineItem) => {
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem" ||
              subLineItem.__typename === "CompositeChargeLineItem"
            ) {
              return <Quantity quantity={subLineItem.quantity} />;
            }
            return "--";
          },
        },
        {
          header: "Unit Price",
          render: (subLineItem) => {
            if (
              subLineItem.__typename === "ChargeLineItem" ||
              subLineItem.__typename === "AdvanceChargeLineItem"
            ) {
              if (!subLineItem.unit_price) {
                return "--";
              }
              return (
                <RoundedCurrency
                  amount={new Decimal(subLineItem.unit_price)}
                  creditType={subLineItem.credit_type}
                  hideSuffix={subLineItem.credit_type.id === USD_CREDIT_ID}
                />
              );
            } else if (subLineItem.__typename === "CompositeChargeLineItem") {
              const unitPrice =
                subLineItem.quantity === "0"
                  ? new Decimal(0)
                  : new Decimal(subLineItem.total).div(subLineItem.quantity);
              return (
                <RoundedCurrency
                  amount={unitPrice}
                  creditType={subLineItem.credit_type}
                  hideSuffix={subLineItem.credit_type.id === USD_CREDIT_ID}
                />
              );
            }
            return "-";
          },
        },
        {
          header: "Total",
          render: (subLineItem) => {
            return (
              <RoundedCurrency
                amount={new Decimal(subLineItem.total)}
                creditType={subLineItem.credit_type}
                hideSuffix={subLineItem.credit_type.id === USD_CREDIT_ID}
              />
            );
          },
        },
        {
          header: "",
          render: () => {
            return <Icon icon="arrowForward" />;
          },
        },
        {
          header: renderSelectableColumn("New quantity", "newQuantity"),
          render: (subLineItem, isSubRow) => {
            if (isSubRow) {
              return;
            }
            const correctedSubLineItem =
              correctedLineItems?.[
                lineItemPointerToString(subLineItem.pointer)
              ];
            return renderEditableDiff(
              "newQuantity",
              subLineItem,
              correctedSubLineItem,
            );
          },
        },
        {
          header: renderSelectableColumn("New total", "newTotal"),
          render: (subLineItem, isSubRow) => {
            if (isSubRow) {
              return;
            }
            const correctedSubLineItem =
              correctedLineItems?.[
                lineItemPointerToString(subLineItem.pointer)
              ];
            return renderEditableDiff(
              "newTotal",
              subLineItem,
              correctedSubLineItem,
            );
          },
        },
        {
          header: "",
          render: () => {
            return <Icon icon="arrowForward" />;
          },
        },
        {
          header: renderSelectableColumn("Delta quantity", "deltaQuantity"),
          render: (subLineItem, isSubRow) => {
            // Tiers are not supported
            if (isSubRow) {
              return "--";
            }
            const correctedSubLineItem =
              correctedLineItems?.[
                lineItemPointerToString(subLineItem.pointer)
              ];
            return renderEditableDiff(
              "deltaQuantity",
              subLineItem,
              correctedSubLineItem,
            );
          },
        },
        {
          header: renderSelectableColumn("Delta total", "deltaTotal"),
          render: (subLineItem, isSubRow) => {
            // Tiers are not supported
            if (isSubRow) {
              return "--";
            }
            const correctedSubLineItem =
              correctedLineItems?.[
                lineItemPointerToString(subLineItem.pointer)
              ];
            return renderEditableDiff(
              "deltaTotal",
              subLineItem,
              correctedSubLineItem,
            );
          },
        },
      ]}
    />
  );

  const { isEmbeddableDashboard } = EmbeddableDashboardContext.useContainer();
  return (
    <DeprecatedProductContainer
      title={
        <Headline
          level={5}
          className={
            isEmbeddableDashboard
              ? "text-default-font"
              : "text-deprecated-primary-dark"
          }
        >
          {lineItemName}
        </Headline>
      }
      groupKey={
        lineItem.__typename === "GroupedProductChargeLineItem"
          ? lineItem.group_key
          : null
      }
    >
      {lineItem.__typename === "GroupedProductChargeLineItem" ? (
        <div>
          {lineItem.group_key}: <b>"{lineItem.group_value}"</b>
        </div>
      ) : null}
      <div className="-mb-12 p-12 pb-0">
        {subLineItems.length > 0 ? (
          subLineItemTable
        ) : (
          <DeprecatedNoSubLineItems showChargesWithZeroUsage={true} />
        )}
      </div>
    </DeprecatedProductContainer>
  );
};
