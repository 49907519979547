import Decimal from "decimal.js";
import { Schema } from "../../CreateAndEdit/Schema";
import { Dayjs } from "lib/dayjs";
import { Override } from "@metronome-industries/schedule-utils";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";

function describeRateChange(
  override: Schema.Types.Override,
): Override.Change.Rate | null {
  if (!override.rate) {
    return null;
  }

  switch (override.rate.type) {
    case "multiplier":
      return {
        type: "multiplier",
        multiplier: new Decimal(override.rate.multiplier),
        priority:
          override.rate.priority != null
            ? new Decimal(override.rate.priority)
            : null,
      };
    case "overwrite":
      switch (override.rate.newRate.type) {
        case "flat":
          return {
            type: "flat_overwrite",
            unitPrice: new Decimal(override.rate.newRate.unitPrice),
          };
        case "percentage":
          return {
            type: "percentage_overwrite",
            fraction: new Decimal(override.rate.newRate.fraction),
            useListPrices: false,
          };
        case "subscription":
          return {
            type: "subscription_overwrite",
            isProrated: override.rate.newRate.isProrated,
            quantity: new Decimal(override.rate.newRate.quantity),
            unitPrice: new Decimal(override.rate.newRate.unitPrice),
          };
        case "tiered":
          return {
            type: "tiered_overwrite",
            tiers: convertTiers(override.rate.newRate.tiers),
          };
      }
  }
}

const convertTiers = (tiers: Schema.Types.Tier[]) => {
  return tiers.map((tier, index) => {
    const prevLastUnit = tiers[index - 1]?.lastUnit;
    const size =
      index === 0
        ? tier.lastUnit
        : tier.lastUnit !== undefined && prevLastUnit !== undefined
          ? tier.lastUnit - prevLastUnit
          : undefined;

    return {
      size: size !== undefined ? new Decimal(size) : undefined,
      unitPrice: new Decimal(tier.unitPrice),
    };
  });
};

export function describeNewOverride(
  now: Dayjs,
  products: Array<
    ProductListItem.IdFragment &
      ProductListItem.NameFragment &
      ProductListItem.TypeFragment
  >,
  override: Schema.Types.Override,
): Override.Description {
  let appliesTo: Override.AppliesToDescription | null = null;
  if (override.productId) {
    const product = products.find((p) => p.id === override.productId);
    if (!product) {
      throw new Error(`missing product ${override.productId}`);
    }

    appliesTo = [
      {
        productId: product.id,
        commitIds: override.commitIds,
      },
    ];
  } else if (override.tags) {
    appliesTo = override.tags.map((tag) => ({
      productTags: [tag],
      commitIds: override.commitIds,
    }));
  }

  const entitlementChange: Override.Change.Entitlement | null =
    override.entitled === "inherit"
      ? null
      : { type: "entitlement", entitled: override.entitled === "enable" };

  return new Override.Description({
    id: override.id,
    startingAt: new Date(override.startingAt),
    endingBefore: override.endingBefore
      ? new Date(override.endingBefore)
      : null,
    appliesTo,
    changes: [
      entitlementChange ?? [],
      describeRateChange(override) ?? [],
    ].flat(),
    type: override.type,
    target: override.target ?? null,
  });
}
