import { SectionHeader } from "components/SectionHeader";
import {
  ContractFormController,
  NewBillingProviderConfigOptionProps,
  RateCardMap,
} from "../ContractContext";
import { ContractCreate_ExistingContractFragment as ExistingContract } from "../../Create/data.graphql";
import React, { useMemo } from "react";
import { TextInput } from "components/Input";
import { DatePicker } from "components/DatePicker";
import { InputDropdown } from "components/InputDropdown";
import { Dropdown, DropdownHeader, DropdownItem } from "components/Dropdown";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { printDate, toDayjs } from "lib/date";
import { BasicDetailsAdvanced } from "./BasicDetailsAdvanced";
import { ContractToEditQuery } from "types/generated-graphql/src/app/pages/Contracts/Customer/Contracts/CreateAndEdit/data.graphql";

export type ContractType =
  | NonNullable<ContractToEditQuery["Customer_by_pk"]>["contract"]
  | null;

type BillingConfiguration = {
  label: string;
  subtext: string;
  value: string;
  isConnected: boolean;
  onConnectExisting: () => void;
};

interface BasicDetailsProps {
  ctrl: ContractFormController;
  rateCards: RateCardMap;
  clientHasDeltaStreamEnabled: boolean;
  newBillingProviderConfigOptions: NewBillingProviderConfigOptionProps[];
  existingCustomerBillingProviderConfigOptions: BillingConfiguration[];
  billingProviderDropdownDisabled: boolean;
  featureFlagOptions: {
    [key: string]: boolean | undefined;
  };
  existingContracts: ExistingContract[];
  loadingStates: {
    [key: string]: boolean;
  };
  isEdit: boolean;
  contractToEdit?: ContractType;
}

function contractNamePlaceholder(
  ctrl: ContractFormController,
  rateCards: RateCardMap,
) {
  const rateCardId = ctrl.get("rateCardId");
  if (!rateCardId) {
    return "Enter name or choose a rate card";
  }

  const rateCard = rateCardId ? rateCards.get(rateCardId) : null;

  return Contract.makeName(
    ctrl.get("startingAt") || printDate(toDayjs(new Date())),
    null,
    rateCard,
  );
}

function handleNetPaymentTermsChange(
  meta: { value: string },
  ctrl: ContractFormController,
) {
  const trimmedValue = meta.value.trim();

  let validValue;

  if (trimmedValue === "") {
    validValue = undefined;
  } else {
    const parsedValue = Number(trimmedValue);
    if (!isNaN(parsedValue) && parsedValue > 0) {
      validValue = parsedValue;
    } else {
      validValue = trimmedValue;
    }
  }

  ctrl.update({
    netPaymentTermsDays: validValue,
  });
}

export const BasicDetails: React.FC<BasicDetailsProps> = ({
  ctrl,
  rateCards,
  clientHasDeltaStreamEnabled,
  billingProviderDropdownDisabled,
  newBillingProviderConfigOptions,
  existingCustomerBillingProviderConfigOptions,
  featureFlagOptions,
  existingContracts,
  loadingStates,
  isEdit,
  contractToEdit,
}) => {
  const rateCard = useMemo(
    () => (!isEdit ? rateCards.get(ctrl.get("rateCardId")) : undefined),
    [rateCards, ctrl, isEdit],
  );

  const getContractName = () => {
    if (isEdit) {
      return contractToEdit?.name ?? "";
    } else {
      return ctrl.get("name") ?? "";
    }
  };
  const startingAt = contractToEdit?.starting_at || ctrl.get("startingAt");
  const endingBefore = contractToEdit?.ending_before
    ? contractToEdit?.ending_before
    : !isEdit
      ? ctrl.get("endingBefore")
      : undefined;
  const netPaymentTermsDays = contractToEdit?.net_payment_terms_days
    ? contractToEdit.net_payment_terms_days ?? undefined
    : (!isEdit && ctrl.get("netPaymentTermsDays")) ?? undefined;
  const billingProviderLabel = billingProviderDropdownDisabled
    ? ""
    : (!isEdit && ctrl.get("billingProvider")) ||
      [
        ...existingCustomerBillingProviderConfigOptions,
        ...newBillingProviderConfigOptions,
      ][0]?.label;

  return (
    <div className="flex flex-col gap-[24px]">
      <SectionHeader
        title="Basic info"
        subtitle="Basic configuration inputs to connect a contract to a customer"
        bottomBorder={true}
      />
      <div className="flex flex-row items-end gap-[24px]">
        <TextInput
          label="Contract name (optional)"
          fullWidth
          helpText="The contract name will default to the rate card name and start date"
          placeholder={
            !isEdit ? contractNamePlaceholder(ctrl, rateCards) : undefined
          }
          value={getContractName()}
          onChange={(meta: { value: string }) =>
            ctrl.update({ name: meta.value })
          }
          disabled={isEdit}
        />
        <div className="flex flex-row gap-xl">
          <DatePicker
            value={startingAt}
            openToDate={startingAt}
            onDateApply={(date?: Date) => ctrl.update({ startingAt: date })}
            disabled={isEdit}
          />
          <DatePicker
            text="Ending before"
            minDate={startingAt}
            value={endingBefore}
            onDateApply={(date?: Date) => ctrl.update({ endingBefore: date })}
            disabled={isEdit} // ToDo: PENG-3711 - Enable when edit_contract mutation can support ending_before
            allowClear
          />
        </div>
      </div>
      <div className="flex items-start gap-[24px] self-stretch">
        {!isEdit && (
          <InputDropdown
            label="Rate card"
            value={rateCard?.name}
            onClearText={() => ctrl.update({ rateCardId: undefined })}
            isTypingEnabled={false}
            disabled={isEdit}
          >
            {[
              <DropdownItem
                label="None"
                key="none"
                value=""
                onClick={() => ctrl.update({ rateCardId: undefined })}
              />,
            ].concat(
              Array.from(rateCards.values()).map((rateCard) => (
                <DropdownItem
                  label={rateCard.name}
                  key={rateCard.id}
                  value={rateCard.id}
                  onClick={() => ctrl.update({ rateCardId: rateCard.id })}
                />
              )),
            )}
          </InputDropdown>
        )}
        <TextInput
          label="Net payment terms"
          value={netPaymentTermsDays ? netPaymentTermsDays.toString() : ""}
          isInvalid={!isEdit && !ctrl.isValid("netPaymentTermsDays")}
          hintText={
            (!isEdit && ctrl.state.fields.netPaymentTermsDays?.error) || ""
          }
          helpText="The amount of time a customer has to pay an invoice"
          placeholder={
            !isEdit && ctrl.get("netPaymentTermsDays") ? "" : "30 days"
          }
          onChange={(meta: { value: string }) => {
            handleNetPaymentTermsChange(meta, ctrl);
          }}
          type="number"
          disabled={isEdit}
        />
        {!clientHasDeltaStreamEnabled && (
          <Dropdown
            title="Billing provider"
            label={billingProviderLabel}
            disabled={
              isEdit ||
              billingProviderDropdownDisabled ||
              loadingStates.billingProviderSettings ||
              loadingStates.clientConfig
            }
          >
            {existingCustomerBillingProviderConfigOptions?.length ? (
              <DropdownHeader text="Connected billing provider" />
            ) : undefined}
            {existingCustomerBillingProviderConfigOptions
              .filter((config) => "isConnected" in config && config.isConnected)
              .map(({ label, value, subtext, onConnectExisting }, index) => (
                <DropdownItem
                  key={"connected-bp-" + index}
                  label={label}
                  subtext={subtext}
                  value={value}
                  selected={
                    (ctrl.get("billingProviderConfiguration")
                      ?.billing_provider_configuration_id ??
                      ctrl.get("billingProvider")) === value
                  }
                  onClick={onConnectExisting}
                />
              ))}
            {newBillingProviderConfigOptions?.length ? (
              <DropdownHeader text="Add new billing provider" />
            ) : undefined}
            {newBillingProviderConfigOptions.map(
              ({ label, value, onAddNew }, index) => (
                <DropdownItem
                  key={"new-bp-" + index}
                  label={label}
                  value={value}
                  onClick={onAddNew}
                />
              ),
            )}
          </Dropdown>
        )}
      </div>
      <BasicDetailsAdvanced
        ctrl={ctrl}
        featureFlagOptions={featureFlagOptions}
        existingContracts={existingContracts}
        isEdit={isEdit}
      />
    </div>
  );
};
