import { Dayjs } from "lib/dayjs";

import { Contract } from "app/pages/Contracts/lib/Contract";
import { ContractTerms } from "app/pages/Contracts/lib/ContractTerms";
import { AdditionalTermRow } from "app/pages/Contracts/components/AdditionalTermsTable";

import { AdditionalTermsTableFragment } from "./data.graphql";

type Term<C extends AdditionalTermsTableFragment> =
  | C["v2_fields"]["discounts"][number]
  | C["v2_fields"]["scheduled_charges"][number]
  | C["amendments"][number]["discounts"][number]
  | C["amendments"][number]["scheduled_charges"][number]
  | C["reseller_royalties"][number]
  | C["amendments"][number]["reseller_royalties"][number]
  | C["v2_fields"]["pro_services"][number];

export function getTerms<C extends AdditionalTermsTableFragment>(
  contract: C,
  now: Dayjs,
  opts: {
    includeAmendments: boolean;
  },
): Term<C>[] {
  const discounts = opts.includeAmendments
    ? contract.v2_fields?.discounts ?? []
    : contract.v2_fields?.discounts.filter((c) => !c.amendment_id);

  const scheduledCharges = opts.includeAmendments
    ? contract.v2_fields?.scheduled_charges ?? []
    : contract.v2_fields?.scheduled_charges.filter((c) => !c.amendment_id);

  const proServices = opts.includeAmendments
    ? contract.v2_fields?.pro_services ?? []
    : contract.v2_fields?.pro_services.filter((c) => !c.amendment_id);

  return [
    ...discounts,
    ...scheduledCharges,
    ...proServices,
    ...Contract.getResellerRoyaltiesStates(
      contract,
      opts.includeAmendments,
      now,
    ).values(),
  ];
}

export function getAmendmentTerms<C extends AdditionalTermsTableFragment>(
  amendment: C["amendments"][number],
): Term<C>[] {
  return [
    ...amendment.discounts,
    ...amendment.scheduled_charges,
    ...amendment.pro_services,
    ...amendment.reseller_royalties,
  ];
}

export function getRowsFromTerms<C extends AdditionalTermsTableFragment>(
  terms: Term<C>[],
  now: Dayjs,
  onTermClicked?: (term: Term<C>) => void,
) {
  return terms.map(
    (term): AdditionalTermRow => ({
      name: ContractTerms.getName(term, now),
      type: ContractTerms.getType(term),
      onClick: onTermClicked ? () => onTermClicked(term) : undefined,
      invoiceCount: ContractTerms.getInvoiceCount(term),
      startDate: ContractTerms.getStartDate(term),
      endDate: ContractTerms.getEndDate(term),
      rate: ContractTerms.getTermRateProps(term),
      archivedAt: ContractTerms.getArchivedAt(term),
    }),
  );
}
