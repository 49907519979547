import {
  CommitAccessScheduleItemEditability,
  CommitAccessScheduleItemRemovability,
  CommitInvoiceScheduleItemEditability,
  CommitInvoiceScheduleItemRemovability,
  ScheduledChargeScheduleItemEditability,
  ScheduledChargeScheduleItemRemovability,
} from "types/generated-graphql/__types__";

export const getEditabilityMessage = (editability: string) => {
  switch (editability) {
    case CommitInvoiceScheduleItemEditability.CannotEditUnsupportedCommitType:
    case CommitAccessScheduleItemEditability.CannotEditUnsupportedCommitType:
      return "Edits are not supported for this commit type";
    case CommitInvoiceScheduleItemEditability.CannotEditHasFinalizedInvoice:
    case ScheduledChargeScheduleItemEditability.CannotEditHasFinalizedInvoice:
      return "Edits are not supported for schedule items with a finalized invoice";
    case CommitInvoiceScheduleItemEditability.Editable:
    case CommitAccessScheduleItemEditability.Editable:
    case ScheduledChargeScheduleItemEditability.Editable:
      return "";
    default:
      throw new Error(`Unexpected editability: ${editability}`);
  }
};

export const getRemovabilityMessage = (removability: string) => {
  switch (removability) {
    case CommitInvoiceScheduleItemRemovability.CannotRemoveUnsupportedCommitType:
    case CommitAccessScheduleItemRemovability.CannotRemoveUnsupportedCommitType:
      return "Removal is not supported for this commit type";
    case CommitInvoiceScheduleItemRemovability.CannotRemoveHasFinalizedInvoice:
    case CommitAccessScheduleItemRemovability.CannotRemoveHasFinalizedInvoice:
    case ScheduledChargeScheduleItemRemovability.CannotRemoveHasFinalizedInvoice:
      return "Removal is not supported for schedule items with a finalized invoice";
    case CommitInvoiceScheduleItemRemovability.Removable:
    case CommitAccessScheduleItemRemovability.Removable:
    case ScheduledChargeScheduleItemRemovability.Removable:
      return "";
    default:
      throw new Error(`Unexpected removability: ${removability}`);
  }
};
