import { ApolloClient } from "@apollo/client";
import { ExistingDestination, PreparedDestination } from "@prequel/react";
import {
  GeneratePrequelAuthTokenDocument,
  GeneratePrequelAuthTokenMutation,
  GeneratePrequelAuthTokenMutationVariables,
  GeneratePrequelAuthTokenForDestinationDocument,
  GeneratePrequelAuthTokenForDestinationMutation,
  GeneratePrequelAuthTokenForDestinationMutationVariables,
} from "app/lib/dataExport/queries.graphql";
import { useFeatureFlag } from "app/lib/launchdarkly";

// LaunchDarkly config type
type DraftInvoiceConfig = {
  enabled: boolean;
  schemas?: string[];
};

const DRAFT_INVOICE_SCHEMA_TYPE = "draft_invoices";

export const PREQUEL_API_URL = "https://9ccb13.prequel.co";

export const useDraftInvoicesExportEnabled = () => {
  const dataExportDraftInvoicesConfig = useFeatureFlag<
    DraftInvoiceConfig | undefined
  >("data-export-draft-invoice-pusher", undefined);
  return (
    dataExportDraftInvoicesConfig &&
    dataExportDraftInvoicesConfig.enabled &&
    dataExportDraftInvoicesConfig.schemas &&
    dataExportDraftInvoicesConfig.schemas.includes(DRAFT_INVOICE_SCHEMA_TYPE)
  );
};

export function useDataExportEnabled() {
  const dataExportPricingAndPackagingEnabled = useFeatureFlag<
    boolean | undefined
  >("data-export-pricing-and-packaging", undefined);
  const dataExportRawEventsEnabled = useFeatureFlag<boolean | undefined>(
    "data-export-raw-events",
    undefined,
  );
  const dataExportDraftInvoicesEnabled = useDraftInvoicesExportEnabled();
  return (
    dataExportPricingAndPackagingEnabled ||
    dataExportRawEventsEnabled ||
    dataExportDraftInvoicesEnabled
  );
}

// This will fetch the auth token for a recipient (which is based on client ID)
export async function fetchPrequelAuthTokenForRecipient(
  apolloClient: ApolloClient<unknown>,
  applicationOrigin: string,
): Promise<string> {
  try {
    const data = (
      await apolloClient.mutate<
        GeneratePrequelAuthTokenMutation,
        GeneratePrequelAuthTokenMutationVariables
      >({
        mutation: GeneratePrequelAuthTokenDocument,
        variables: {
          applicationOrigin: applicationOrigin,
        },
      })
    ).data;

    if (!data) {
      throw new Error("Failed to generate scoped auth token: no data returned");
    }

    if (!data.generate_prequel_auth_token.token_string) {
      throw new Error(
        "Failed to generate scoped auth token: token_string was not returned",
      );
    }

    return data.generate_prequel_auth_token.token_string;
  } catch (e) {
    throw new Error(`Failed to generate scoped auth token: ${e}`);
  }
}

// This will fetch the auth token for a recipient (which is based on client ID)
export async function fetchPrequelAuthTokenForDestination(
  apolloClient: ApolloClient<unknown>,
  applicationOrigin: string,
  destination?: PreparedDestination | ExistingDestination,
): Promise<{
  token_string: string;
  recipient_id: string;
}> {
  try {
    const data = (
      await apolloClient.mutate<
        GeneratePrequelAuthTokenForDestinationMutation,
        GeneratePrequelAuthTokenForDestinationMutationVariables
      >({
        mutation: GeneratePrequelAuthTokenForDestinationDocument,
        variables: {
          applicationOrigin: applicationOrigin,
          destinationJson: JSON.stringify(destination),
        },
      })
    ).data;

    if (!data) {
      throw new Error("Failed to generate scoped auth token: no data returned");
    }

    return {
      token_string: data.generate_prequel_auth_token.token_string,
      recipient_id: data.generate_prequel_auth_token.recipient_id,
    };
  } catch (e) {
    throw new Error(`Failed to generate scoped auth token: ${e}`);
  }
}
