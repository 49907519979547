import React from "react";
import classnames from "classnames";
import { Subtitle, Icon, IconName } from "design-system";

import { EventDetailsQuery } from "../../queries.graphql";
import { useFeatureFlag } from "app/lib/launchdarkly";
type Event = EventDetailsQuery["mri_events"][0];

type StepState = "success" | "error" | "warning" | "neutral";

interface StepProps {
  state: StepState;
  heading: string;
  icon: IconName;
  entries: string[];
}

const Step: React.FC<StepProps> = (props) => {
  const { icon, heading } = props;

  let dotStateClass = "";
  let rowTitleStateClass = "";
  let stepBlockStateClass = "";
  switch (props.state) {
    case "success":
      dotStateClass = "!bg-deprecated-success-500";
      rowTitleStateClass = "";
      stepBlockStateClass = "";
      break;

    case "error":
      dotStateClass = "!bg-deprecated-error-500";
      rowTitleStateClass = "";
      stepBlockStateClass = "!bg-deprecated-error-500";
      break;

    case "warning":
      dotStateClass = "!bg-deprecated-warning-500";
      rowTitleStateClass = "";
      stepBlockStateClass = "!bg-deprecated-warning-500";
      break;

    case "neutral":
      dotStateClass = "";
      rowTitleStateClass = "!text-deprecated-gray-300";
      stepBlockStateClass = "";
      break;
  }

  return (
    <div className="flex flex-row">
      <div className="inline-block w-[32px] overflow-hidden">
        <div className="h-[23px] border-b border-l border-dashed border-b-deprecated-gray-100 border-l-deprecated-gray-100" />
        <div className="h-full w-[32px] flex-grow border-l border-dashed border-l-deprecated-gray-100" />
      </div>
      <span
        className={classnames(
          "mt-[18px] inline-block h-[9px] w-[9px] rounded-[50%] bg-deprecated-gray-300",
          dotStateClass,
        )}
      />
      <div className="flex flex-col pt-[15px]">
        <Subtitle
          className={classnames(
            "ml-[3px] flex flex-row items-center pl-[6px]",
            rowTitleStateClass,
          )}
          level={2}
        >
          {heading}
        </Subtitle>
        {props.entries.map((title, i) => (
          <div
            className={classnames(
              " mx-[10px] my-8 flex min-h-[20px] flex-row items-center rounded-xxs bg-gray-800 px-8 py-[6px] font-default text-xxs font-bold uppercase text-white",
              stepBlockStateClass,
            )}
            key={i}
          >
            <Icon icon={icon} className="mr-8 min-h-[20px] min-w-[20px]" />
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};

type SharedStepProps = {
  event: Event;
};

const IngestStep = () => {
  return (
    <Step
      state="success"
      heading="Event ingested"
      icon="codeWorking"
      entries={["/INGEST"]}
    />
  );
};

const BillableMetricStep: React.FC<SharedStepProps> = (props) => {
  let heading = "Billable metric not matched";
  let entries = ["NO BILLABLE METRIC MATCHED"];
  let stepState: StepState = "error";
  if (props.event?.billable_metrics?.length) {
    heading = "Billable metric matched";
    entries = props.event?.billable_metrics.map(({ name }) => name);
    stepState = "success";
  }

  return (
    <Step
      state={stepState}
      heading={heading}
      icon="pricetags"
      entries={entries}
    />
  );
};

const SeatMetricStep: React.FC<SharedStepProps> = (props) => {
  let heading = "Seat metric not matched";
  let entries = ["NO SEAT MATCHED"];
  let stepState: StepState = "error";
  if (props.event?.seat_metrics?.length) {
    heading = "Seat metric matched";
    entries = props.event?.seat_metrics.map(({ name }) => name);
    stepState = "success";
  }

  return (
    <Step
      state={stepState}
      heading={heading}
      icon="pricetags"
      entries={entries}
    />
  );
};

const CustomerStep: React.FC<SharedStepProps> = (props) => {
  const { event } = props;
  let heading = "Customer not matched";
  let entries = ["NO CUSTOMER FOUND"];
  let stepState: StepState = "error";
  if (event?.customer) {
    heading = "Customer matched";
    entries = [event.customer.name];
    stepState = "success";
  }
  return (
    <Step
      state={stepState}
      heading={heading}
      icon="personCircle"
      entries={entries}
    />
  );
};

const DuplicateStep: React.FC = () => {
  return (
    <Step
      state="error"
      heading="Event was a duplicate"
      icon="codeWorking"
      entries={[]}
    />
  );
};

type EventTimelineProps = {
  event: Event;
};

export const EventTimeline: React.FC<EventTimelineProps> = ({ event }) => {
  const seatsEnabled = useFeatureFlag("seats", false);
  return (
    <div className="ml-[6px] flex flex-col">
      <IngestStep />
      {event.is_duplicate ? (
        <DuplicateStep />
      ) : (
        <>
          <BillableMetricStep event={event} />
          {seatsEnabled ? <SeatMetricStep event={event} /> : null}
          <CustomerStep event={event} />
        </>
      )}
    </div>
  );
};
