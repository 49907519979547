/* 2b0883709db0fc92eddd36385e31f58f081dca36
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeContractIssueDateMutationVariables = Types.Exact<{
  invoice_id: Types.Scalars['uuid'];
  date: Types.Scalars['timestamptz'];
}>;


export type ChangeContractIssueDateMutation = { __typename?: 'Mutation', update_contract_invoice_date: { __typename?: 'AdHocPlanInvoice', id: string, issued_at: string } | { __typename?: 'AdhocContractUsageInvoice', id: string, issued_at: string } | { __typename?: 'AdvanceInvoice', id: string, issued_at: string } | { __typename?: 'ArrearsInvoice', id: string, issued_at: string } | { __typename?: 'ContractPostpaidTrueupInvoice', id: string, issued_at: string } | { __typename?: 'ContractProServiceInvoice', id: string, issued_at: string } | { __typename?: 'ContractRefundInvoice', id: string, issued_at: string } | { __typename?: 'ContractScheduledInvoice', id: string, issued_at: string } | { __typename?: 'ContractUsageInvoice', id: string, issued_at: string } | { __typename?: 'CorrectionInvoice', id: string, issued_at: string } | { __typename?: 'CreditPurchaseInvoice', id: string, issued_at: string } | { __typename?: 'ParentInvoice', id: string, issued_at: string } | { __typename?: 'SeatPurchaseInvoice', id: string, issued_at: string } };


export const ChangeContractIssueDateDocument = gql`
    mutation ChangeContractIssueDate($invoice_id: uuid!, $date: timestamptz!) {
  update_contract_invoice_date(invoice_id: $invoice_id, new_invoice_date: $date) {
    id
    issued_at
  }
}
    `;
export type ChangeContractIssueDateMutationFn = Apollo.MutationFunction<ChangeContractIssueDateMutation, ChangeContractIssueDateMutationVariables>;

/**
 * __useChangeContractIssueDateMutation__
 *
 * To run a mutation, you first call `useChangeContractIssueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeContractIssueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeContractIssueDateMutation, { data, loading, error }] = useChangeContractIssueDateMutation({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useChangeContractIssueDateMutation(baseOptions?: Apollo.MutationHookOptions<ChangeContractIssueDateMutation, ChangeContractIssueDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeContractIssueDateMutation, ChangeContractIssueDateMutationVariables>(ChangeContractIssueDateDocument, options);
      }
export type ChangeContractIssueDateMutationHookResult = ReturnType<typeof useChangeContractIssueDateMutation>;
export type ChangeContractIssueDateMutationResult = Apollo.MutationResult<ChangeContractIssueDateMutation>;
export type ChangeContractIssueDateMutationOptions = Apollo.BaseMutationOptions<ChangeContractIssueDateMutation, ChangeContractIssueDateMutationVariables>;