/* f5d2bf2e17c226630dd72b399be1ee56a4223407
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisableStripeMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type DisableStripeMutation = { __typename?: 'Mutation', delete_BillingProviderCustomer_by_pk: { __typename?: 'BillingProviderCustomer', billing_provider_customer_id: string } | null, delete_customer_configs: { __typename?: 'AffectedRowsResponse', affected_rows: number } };


export const DisableStripeDocument = gql`
    mutation DisableStripe($customer_id: uuid!) {
  delete_BillingProviderCustomer_by_pk(
    customer_id: $customer_id
    billing_provider: STRIPE
  ) {
    billing_provider_customer_id
  }
  delete_customer_configs(
    customer_id: $customer_id
    keys: ["stripe_collection_method"]
  ) {
    affected_rows
  }
}
    `;
export type DisableStripeMutationFn = Apollo.MutationFunction<DisableStripeMutation, DisableStripeMutationVariables>;

/**
 * __useDisableStripeMutation__
 *
 * To run a mutation, you first call `useDisableStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableStripeMutation, { data, loading, error }] = useDisableStripeMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useDisableStripeMutation(baseOptions?: Apollo.MutationHookOptions<DisableStripeMutation, DisableStripeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableStripeMutation, DisableStripeMutationVariables>(DisableStripeDocument, options);
      }
export type DisableStripeMutationHookResult = ReturnType<typeof useDisableStripeMutation>;
export type DisableStripeMutationResult = Apollo.MutationResult<DisableStripeMutation>;
export type DisableStripeMutationOptions = Apollo.BaseMutationOptions<DisableStripeMutation, DisableStripeMutationVariables>;