import React, { useState } from "react";
import { CreatePlanDataQuery } from "../../../../data/queries.graphql";
import { useDraftPlan } from "../../../../context";
import { PriceRamp } from "app/lib/plans/types";
import { CustomCreditType, FiatCreditType } from "app/types/credit-types";
import {
  DeprecatedInputSection,
  DeprecatedWizardSplitPage,
} from "components/deprecated/Wizard";
import { Toggle } from "design-system";
import { Subtitle } from "design-system";
import { DeprecatedCreditTypeConversionInput } from "components/deprecated/Input";
import {
  getUpdateCreditTypeConversionMerged,
  getRemoveRampedCreditTypeConversions,
  getAddRampedCreditTypeConversions,
  getUpdateCreditTypeConversionFiatCurrency,
} from "../../actions";
import { pricingRampTitle } from "../PriceProductTable";
import { getRampStartPeriod, getRamps } from "app/lib/plans/ramps";
import { USD_CREDIT_TYPE } from "app/lib/credits";

interface OverageRatePageProps {
  creditTypesToPrice: Array<CustomCreditType>;
  data: CreatePlanDataQuery;
}
export const OverageRatePage: React.FC<OverageRatePageProps> = (props) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const [doRampedPricing, setDoRampedPricing] = useState(
    !!draftPlan.creditTypeConversions?.some((conv) => conv.startPeriod > 0),
  );
  const [rampsToPrice, setRampsToPrice] = useState<PriceRamp[]>(
    doRampedPricing ? getRamps(draftPlan) : [{}],
  );

  const existingConversionFiatCreditType: FiatCreditType | undefined =
    draftPlan.creditTypeConversions?.find(
      (ctc) => ctc.fiatCreditType,
    )?.fiatCreditType;

  const pricedFiatCreditType: FiatCreditType | undefined =
    draftPlan.pricedProducts?.find(
      (pp) => pp.creditType && pp.creditType.client_id === null,
    )?.creditType as FiatCreditType | undefined;

  const fiatCreditType =
    existingConversionFiatCreditType ?? pricedFiatCreditType ?? USD_CREDIT_TYPE;

  return (
    <DeprecatedWizardSplitPage>
      <DeprecatedInputSection
        header="What is the overage rate for your pricing unit?"
        subtitle="The overage price is applied when a customer has insufficient credits to cover their consumption."
      >
        {getRamps(draftPlan).length > 1 && (
          <Toggle
            checked={doRampedPricing}
            label="Apply unique rates for each pricing ramp"
            onChange={() => {
              setDoRampedPricing(!doRampedPricing);
              setRampsToPrice(!doRampedPricing ? getRamps(draftPlan) : [{}]);
              if (doRampedPricing) {
                setDraftPlan(getRemoveRampedCreditTypeConversions(draftPlan));
              } else {
                setDraftPlan(
                  getAddRampedCreditTypeConversions(
                    draftPlan,
                    props.creditTypesToPrice,
                    fiatCreditType,
                  ),
                );
              }
            }}
            className="!mb-[22px]"
          />
        )}
        <div>
          {rampsToPrice.map((ramp, rampIndex) => {
            const rampStartPeriod = getRampStartPeriod(
              rampIndex,
              getRamps(draftPlan),
            );
            return (
              <div key={rampIndex} className="mb-[50px]">
                {rampsToPrice.length > 1 && (
                  <Subtitle level={4} className="mb-12">
                    {pricingRampTitle(
                      ramp.durationInPeriods,
                      rampIndex,
                      draftPlan,
                    )}
                  </Subtitle>
                )}
                {props.creditTypesToPrice.map((ct) => {
                  return (
                    <DeprecatedCreditTypeConversionInput
                      key={`creditOveragePrice-${ct.id}-ramp-${rampIndex}`}
                      className="mb-[31px]"
                      overageConversion={draftPlan.creditTypeConversions?.find(
                        (c) =>
                          c.customCreditType.id === ct.id &&
                          c.startPeriod === rampStartPeriod,
                      )}
                      customCreditType={ct}
                      fiatCreditType={fiatCreditType}
                      setFiatCreditType={(newFiatCT) => {
                        setDraftPlan(
                          getUpdateCreditTypeConversionFiatCurrency(
                            draftPlan,
                            newFiatCT,
                          ),
                        );
                      }}
                      allowedCreditTypeIds={
                        pricedFiatCreditType
                          ? new Set([pricedFiatCreditType.id])
                          : undefined
                      }
                      onChange={(v) => {
                        setDraftPlan(
                          getUpdateCreditTypeConversionMerged(
                            {
                              customCreditType: ct,
                              fiatCreditType: fiatCreditType,
                              toFiatConversionFactor: v ?? undefined,
                              startPeriod: rampStartPeriod,
                            },
                            draftPlan,
                          ),
                        );
                      }}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </DeprecatedInputSection>
    </DeprecatedWizardSplitPage>
  );
};
