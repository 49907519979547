import React, { useState } from "react";

import { useNavigate } from "app/lib/useNavigate";
import { Contract } from "app/pages/Contracts/lib/Contract";
import { useNow, toISOString, dayjs } from "lib/date";
import { IconButton } from "components/IconButton";
import { Button } from "components/Button";
import { FormController } from "app/lib/FormController";
import {
  getUserFacingErrorMessage,
  UserFacingError,
} from "app/lib/errors/errorHandling";
import { USD_CREDIT_ID } from "app/lib/credits";

import { useContractPricingAndCreditTypesQuery } from "../Create/data.graphql";
import { Schema } from "../Create/Schema";
import { FooterBar } from "../Create/components/FooterBar";
import { RatesSection } from "../Create/Sections/RateCard";
import { AdditionalTermsSection } from "../Create/Sections/AdditionalTerms";
import { ContractPricing } from "app/pages/Contracts/lib/ContractPricing";
import { DefaultTimeframe } from "../Create/lib/DefaultTimeframe";
import { getEditMutationVars } from "../Create/convertFormToMutationVars";
import {
  useFlyovers,
  useSnapshot,
  Snapshot,
} from "../Create/lib/SharedContext";
import { useRequiredParam } from "app/lib/routes/params";

import { useFeatureFlag } from "app/lib/launchdarkly";
import { describeOverride } from "app/pages/Contracts/lib/Override";
import { CreditsSection } from "../Create/Sections/Commits/CreditsSection";
import { filterAndSortCreditTypes } from "app/pages/Contracts/lib/CreditTypes";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import Decimal from "decimal.js";
import { deepEqual } from "fast-equals";
import {
  CommitType,
  ExternalCommitType,
} from "types/generated-graphql/__types__";
import { Commit } from "app/pages/Contracts/lib/Commit";
import NotFoundPage from "app/pages/404";
import { DeprecatedParagraphSkeleton } from "components/deprecated/Skeleton";
import { useSnackbar } from "components/deprecated/Snackbar";
import { AppShell } from "components/AppShell";
import { MutableCommitsSection } from "./Sections/MutableCommitsSection";
import {
  CommitDetailsForEditsFragment,
  ScheduledChargeForEditsFragment,
  useContractToEditQuery,
  useEditContractMutation,
} from "./data.graphql";

export type EditContractCtrl = ReturnType<typeof useEditContractCtrl>;
const useEditContractCtrl = FormController.createHook(
  Schema.EditContractInput,
  {
    init(snapshot: Snapshot) {
      return FormController.parseJsonSnapshot(
        Schema.EditContractInput,
        snapshot.initialJson,
      );
    },
  },
);

const formatCommits = (commits: CommitDetailsForEditsFragment[]) => {
  const formattedCommits = commits.map((commit) => {
    let commitFlyoverRoot: Schema.Types.CommitFlyoverRoot["commit"] = {
      ...commit,
      level: "contract",
      accessSchedule: commit.access_schedule.schedule_items.map((item) => ({
        id: item.id,
        amount: parseFloat(item.amount),
        date: new Date(item.date).toISOString(),
        endDate: new Date(item.end_date).toISOString(),
        editability: item.editability,
        removability: item.removability,
      })),
      priority: parseFloat(commit.priority),
      accessScheduleCreditTypeId: commit.access_schedule.credit_type.id,
      rolloverFraction: commit.rollover_fraction
        ? new Decimal(commit.rollover_fraction).times(100).toNumber()
        : undefined,
      ...(commit.__typename === "PrepaidCommit"
        ? {
            type: CommitType.Prepaid,
            externalType: commit.external_type,
            // Convert recurring schedules to fixed for existing commits
            // This is because we cannot edit a recurring schedule and must
            // edit invoice schedule as a "custom" schedule
            billingSchedule: {
              type: "fixed",
              items:
                commit.invoice_schedule?.schedule_items.map((item) => {
                  return {
                    id: item.id,
                    date: dayjs(item.date).toISOString(),
                    // Manually round to 15 decimal places to avoid floating
                    // point error
                    unitPrice: new Decimal(item.unit_price)
                      .toDecimalPlaces(15)
                      .toNumber(),
                    quantity: new Decimal(item.quantity)
                      .toDecimalPlaces(15)
                      .toNumber(),
                    editability: item.editability,
                    removability: item.removability,
                  };
                }) ?? [],
            },
            billingScheduleCreditTypeId:
              Commit.getInvoiceScheduleCreditId(commit),
            billingScheduleFrequency: "custom",
          }
        : {
            billingSchedule:
              Commit.getPostpaidBillingScheduleFromInvoiceSchedule(commit),
            type: CommitType.Postpaid,
          }),
    };

    // RFs come in as decimal numbers, but the UI renders them as %
    const newCommit: Schema.Types.CommitFlyoverRoot = {
      // needed for CommitTable
      type: Commit.getCommitType(commit),
      productId: commit.product.id,
      id: commit.id,
      temporaryId: commit.id,
      commit: commitFlyoverRoot,
      netsuiteSalesOrderId: commit.netsuite_sales_order_id ?? undefined,
      rolloverFraction: commit.rollover_fraction
        ? new Decimal(commit.rollover_fraction).times(100).toString()
        : undefined,
    };
    return newCommit;
  });
  return formattedCommits;
};

const formatCharges = (charges: ScheduledChargeForEditsFragment[]) => {
  return charges.map((charge) => {
    let newCharge: Schema.Types.ScheduledCharge = {
      id: charge.id,
      productId: charge.product.id,
      // Convert recurring schedules to fixed for existing commits
      // This is because we cannot edit a recurring schedule and must
      // edit invoice schedule as a "custom" schedule
      billingSchedule: {
        type: "fixed",
        items:
          charge.schedule?.schedule_items.map((item) => {
            return {
              id: item.id,
              date: dayjs(item.date).toISOString(),
              // Manually round to 15 decimal places to avoid floating
              // point error
              unitPrice: new Decimal(item.unit_price)
                .toDecimalPlaces(15)
                .toNumber(),
              quantity: new Decimal(item.quantity)
                .toDecimalPlaces(15)
                .toNumber(),
              editability: item.editability,
              removability: item.removability,
            };
          }) ?? [],
      },
      billingScheduleCreditTypeId:
        charge.schedule.credit_type.id ?? USD_CREDIT_ID,
      billingScheduleFrequency: "custom",
      netsuiteSalesOrderId: charge.netsuite_sales_order_id ?? undefined,
    };
    return newCharge;
  });
};

export const EditContract: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const now = useNow();
  const navigate = useNavigate();
  const pushMessage = useSnackbar();
  const pricingAndCreditTypesReq = useContractPricingAndCreditTypesQuery();

  const canEditContract = useFeatureFlag<boolean>(
    "allow-contract-editing",
    false,
  );
  const nonGAContractFeaturesEnabled = useFeatureFlag<string[]>(
    "non-ga-contract-features",
    [],
  );

  const contractReq = useContractToEditQuery({
    variables: {
      contract_id: contractId,
      customer_id: customerId,
    },
  });

  const snapshot = useSnapshot(
    `contract-create-snapshot:${customerId}:${contractId}`,
  );
  const ctrl = useEditContractCtrl(snapshot);
  const [prevCtrlFields] = useState(ctrl.state.fields);
  React.useEffect(() => {
    snapshot.update(ctrl);
  }, [ctrl]);

  React.useEffect(() => {
    if (!contractReq.loading) {
      const allCommits =
        contractReq.data?.Customer_by_pk?.contract?.v2_fields?.commits_union;
      const charges =
        contractReq.data?.Customer_by_pk?.contract?.v2_fields
          ?.scheduled_charges;
      const commits = allCommits?.filter(
        (commit) =>
          commit.__typename === "PostpaidCommit" ||
          commit.external_type === ExternalCommitType.Commit,
      );
      const credits = allCommits?.filter(
        (commit) =>
          commit.__typename === "PrepaidCommit" &&
          commit.external_type === ExternalCommitType.Credit,
      );

      ctrl.update({
        commits: commits ? formatCommits(commits) : [],
        existingCommitIds: commits?.map((commit) => commit.id) ?? [],
        scheduledCharges: charges ? formatCharges(charges) : [],
        existingScheduledChargeIds: charges?.map((sc) => sc.id) ?? [],
        credits: credits ? formatCommits(credits) : [],
        existingCreditIds: credits?.map((credit) => credit.id) ?? [],
      });
    }
  }, [contractReq]);

  const { fiatCreditTypes, customCreditTypes } = filterAndSortCreditTypes(
    pricingAndCreditTypesReq.data?.CreditType ?? [],
  );

  const contractFromReq = contractReq.data?.Customer_by_pk?.contract;
  const { flyoverElement, setFlyover } = useFlyovers({
    ctrl,
    allProducts: pricingAndCreditTypesReq?.data?.contract_pricing.products
      ? pricingAndCreditTypesReq.data.contract_pricing.products
      : [],
    contract: contractFromReq || undefined,
    options: {
      netsuiteEnabled: nonGAContractFeaturesEnabled?.includes("NETSUITE"),
    },
  });

  const [editContractMutation, editContractMutationResult] =
    useEditContractMutation();

  const cancel = () => {
    snapshot.clear();
    navigate(
      Contract.getRoutePath({
        id: contractId,
        customer: { id: customerId },
      }),
    );
  };

  const loading = pricingAndCreditTypesReq.loading || contractReq.loading;
  const error = pricingAndCreditTypesReq.error;
  const pricing = pricingAndCreditTypesReq.data?.contract_pricing;
  const contract = contractReq.data?.Customer_by_pk?.contract;
  const rateCard =
    pricing && contract?.rate_card
      ? ContractPricing.getRateCard(pricing, contract.rate_card.id)
      : undefined;

  React.useEffect(() => {
    if (!contract) {
      return;
    }
  }, [contract]);

  const submitForm = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      if (editContractMutationResult.loading || !pricing) {
        return;
      }
      const oldCommits: Record<string, CommitDetailsForEditsFragment> = (
        contractReq.data?.Customer_by_pk?.contract?.v2_fields?.commits_union ??
        []
      ).reduce(
        (acc, commit) => {
          acc[commit.id] = commit;
          return acc;
        },
        {} as Record<string, CommitDetailsForEditsFragment>,
      );
      const oldCharges: Record<string, ScheduledChargeForEditsFragment> = (
        contract?.v2_fields?.scheduled_charges ?? []
      ).reduce(
        (acc, charge) => {
          acc[charge.id] = charge;
          return acc;
        },
        {} as Record<string, ScheduledChargeForEditsFragment>,
      );
      const editInput = getEditMutationVars(
        customerId,
        contractId,
        valid,
        oldCommits,
        oldCharges,
      );
      const result = await editContractMutation({
        variables: editInput,
        update(cache) {
          cache.evict({ id: `Customer:${customerId}` });
          cache.evict({ id: `Contract:${contractId}` });
          cache.gc();
        },
      });

      const editedContract = result.data?.edit_contract;
      if (!editedContract) {
        throw new UserFacingError(
          "Failed to edit contract, server sent an unexpected response",
        );
      }

      snapshot.clear();
      navigate(Contract.getRoutePath(editedContract));
      pushMessage({
        type: "success",
        content: `Contract successfully edited`,
      });
    } catch (e) {
      pushMessage({
        content: getUserFacingErrorMessage(e, "Failed to edit contract"),
        type: "error",
      });
    }
  });

  if (!canEditContract) {
    return <NotFoundPage />;
  }
  return (
    <AppShell
      title="Edit contract"
      headerProps={{
        actions: (
          <IconButton onClick={cancel} theme="secondary" icon="xClose" />
        ),
        breadcrumbs: Breadcrumbs.from(
          {
            label: contractReq.data?.Customer_by_pk?.name ?? "Loading...",
            routePath: `/customers/${customerId}`,
          },
          {
            label: contractReq.data?.Customer_by_pk?.contract
              ? Contract.getName(contractReq.data.Customer_by_pk.contract)
              : "",
            routePath: `/customers/${customerId}/contracts/${contractId}`,
          },
        ),
      }}
    >
      {error ? (
        <ErrorEmptyState error={error} title="Failed to load the form" />
      ) : loading || !pricing || !contract ? (
        <DeprecatedParagraphSkeleton numLines={20} />
      ) : (
        <DefaultTimeframe.Provider
          startingAt={toISOString(contract.starting_at)}
          endingBefore={
            contract.ending_before
              ? toISOString(contract.ending_before)
              : undefined
          }
        >
          {flyoverElement}

          <form onSubmit={submitForm} className="h-full">
            <div className="-mx-12 flex h-full flex-col overflow-hidden">
              <div className="grow overflow-auto">
                <MutableCommitsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  onAddCommit={() =>
                    setFlyover({
                      type: "new_commit",
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditCommit={(id) =>
                    setFlyover({
                      type: "edit_commit",
                      id,
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  creditTypes={[...fiatCreditTypes, ...customCreditTypes]}
                />
                <CreditsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  onAddCredit={() =>
                    setFlyover({
                      type: "new_credit",
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditCredit={(id) =>
                    setFlyover({
                      type: "edit_credit",
                      id,
                      fiatCreditTypes: fiatCreditTypes,
                      customCreditTypes: customCreditTypes,
                      rateCardId: rateCard?.id,
                    })
                  }
                  creditTypes={[...fiatCreditTypes, ...customCreditTypes]}
                />
                <RatesSection
                  ctrl={ctrl}
                  rateCardId={rateCard?.id}
                  allProducts={pricing.products}
                  baseContractOverrideDescriptions={
                    contractFromReq
                      ? contractFromReq.v2_fields?.overrides?.map((o) =>
                          describeOverride(now, o),
                        ) ?? []
                      : []
                  }
                  pricing={pricing}
                  contract={contract}
                  onAddOverride={(productId, creditType) =>
                    setFlyover({
                      type: "new_override",
                      productId,
                      creditType,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onEditOverride={(overrideId, creditType) =>
                    setFlyover({
                      type: "edit_override",
                      id: overrideId,
                      creditType,
                      rateCardId: rateCard?.id,
                    })
                  }
                  onViewOverrides={() =>
                    setFlyover({
                      type: "view_overrides",
                      rateCardId: rateCard?.id,
                    })
                  }
                />
                <AdditionalTermsSection
                  ctrl={ctrl}
                  pricing={pricing}
                  fiatCreditTypes={fiatCreditTypes}
                  onAdd={(type: Schema.Types.AdditionalTermType) => {
                    setFlyover({
                      type: `new_${type}`,
                      fiatCreditTypes,
                    });
                  }}
                  onEdit={(type: Schema.Types.AdditionalTermType, id: string) =>
                    setFlyover({ type: `edit_${type}`, id, fiatCreditTypes })
                  }
                  options={{
                    discountsEnabled:
                      nonGAContractFeaturesEnabled?.includes("DISCOUNTS"),
                    resellerRoyaltiesEnabled:
                      nonGAContractFeaturesEnabled?.includes(
                        "RESELLER_ROYALTIES",
                      ),
                    proServicesEnabled: nonGAContractFeaturesEnabled?.includes(
                      "PROFESSIONAL_SERVICES",
                    ),
                  }}
                />
              </div>
              <FooterBar
                right={
                  <>
                    <Button onClick={cancel} text="Cancel" theme="linkGray" />
                    <Button
                      disabled={
                        editContractMutationResult.loading ||
                        !ctrl.appearsValid() ||
                        deepEqual(prevCtrlFields, ctrl.state.fields)
                      }
                      onClick={submitForm}
                      text="Save"
                      theme="primary"
                    />
                  </>
                }
              />
            </div>
          </form>
        </DefaultTimeframe.Provider>
      )}
    </AppShell>
  );
};
