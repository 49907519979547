/* 7575a86a75d2f0685a176c0f76021fdb3879cc7f
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCustomerPlanTrialMutationVariables = Types.Exact<{
  customer_plan_id: Types.Scalars['uuid'];
  trial_spec: Types.TrialSpecInput;
}>;


export type UpdateCustomerPlanTrialMutation = { __typename?: 'Mutation', set_customer_plan_trial: { __typename?: 'CustomerPlan', id: string, trial_spec: { __typename?: 'TrialSpec', id: string, length_in_days: string, TrialSpecSpendingCaps: Array<{ __typename?: 'TrialSpecSpendingCap', id: string, amount: string, CreditType: { __typename?: 'CreditType', id: string, name: string } }> } | null } };


export const UpdateCustomerPlanTrialDocument = gql`
    mutation UpdateCustomerPlanTrial($customer_plan_id: uuid!, $trial_spec: TrialSpecInput!) {
  set_customer_plan_trial(
    customer_plan_id: $customer_plan_id
    trial_spec: $trial_spec
  ) {
    id
    trial_spec {
      id
      length_in_days
      TrialSpecSpendingCaps {
        id
        amount
        CreditType {
          id
          name
          __environment_type: environment_type
        }
      }
      __environment_type: environment_type
    }
  }
}
    `;
export type UpdateCustomerPlanTrialMutationFn = Apollo.MutationFunction<UpdateCustomerPlanTrialMutation, UpdateCustomerPlanTrialMutationVariables>;

/**
 * __useUpdateCustomerPlanTrialMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerPlanTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerPlanTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerPlanTrialMutation, { data, loading, error }] = useUpdateCustomerPlanTrialMutation({
 *   variables: {
 *      customer_plan_id: // value for 'customer_plan_id'
 *      trial_spec: // value for 'trial_spec'
 *   },
 * });
 */
export function useUpdateCustomerPlanTrialMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerPlanTrialMutation, UpdateCustomerPlanTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerPlanTrialMutation, UpdateCustomerPlanTrialMutationVariables>(UpdateCustomerPlanTrialDocument, options);
      }
export type UpdateCustomerPlanTrialMutationHookResult = ReturnType<typeof useUpdateCustomerPlanTrialMutation>;
export type UpdateCustomerPlanTrialMutationResult = Apollo.MutationResult<UpdateCustomerPlanTrialMutation>;
export type UpdateCustomerPlanTrialMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerPlanTrialMutation, UpdateCustomerPlanTrialMutationVariables>;