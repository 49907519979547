/* b91a1786f3e40b9be5e87f5ffb21c8db357fb6f6
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAlertQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetAlertQuery = { __typename?: 'Query', alert: { __typename?: 'Alert', id: string, name: string, threshold: string, alert_type: Types.AlertTypeEnum_Enum, webhooks_enabled: boolean, filters: object | null, archived_at: string | null, disabled_at: string | null, customer_count: number, Customer: { __typename?: 'Customer', id: string, name: string } | null, Plan: { __typename?: 'Plan', id: string, name: string } | null, CreditType: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, Creator: { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null } | null, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null } | null };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type GetAlertCustomerAlertsQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  sort: Types.CustomerAlertsSortingInput;
  search: Types.Scalars['String'];
}>;


export type GetAlertCustomerAlertsQuery = { __typename?: 'Query', alert: { __typename?: 'Alert', id: string, customer_count: number, customer_alerts: Array<{ __typename?: 'CustomerAlertComposite', id: string | null, customer_status: Types.CustomerAlertCompositeStatusEnum | null, updated_at: string | null, customer: { __typename?: 'Customer', id: string, name: string } }> } | null };

export type ArchiveAlertMutationVariables = Types.Exact<{
  alert_id: Types.Scalars['uuid'];
}>;


export type ArchiveAlertMutation = { __typename?: 'Mutation', archive_alert: { __typename?: 'Alert', id: string } };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const GetAlertDocument = gql`
    query GetAlert($id: uuid!) {
  alert: Alert_by_pk(id: $id) {
    id
    name
    threshold
    alert_type
    webhooks_enabled
    filters
    archived_at
    disabled_at
    Customer {
      id
      name
      __environment_type: environment_type
    }
    Plan {
      id
      name
      __environment_type: environment_type
    }
    CreditType {
      ...CreditType
      __environment_type: environment_type
    }
    Creator {
      id
      name
      deprecated_at
    }
    BillableMetric {
      id
      name
      __environment_type: environment_type
    }
    customer_count(search: "")
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useGetAlertQuery__
 *
 * To run a query within a React component, call `useGetAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAlertQuery(baseOptions: Apollo.QueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, options);
      }
export function useGetAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, options);
        }
export type GetAlertQueryHookResult = ReturnType<typeof useGetAlertQuery>;
export type GetAlertLazyQueryHookResult = ReturnType<typeof useGetAlertLazyQuery>;
export type GetAlertQueryResult = Apollo.QueryResult<GetAlertQuery, GetAlertQueryVariables>;
export const GetAlertCustomerAlertsDocument = gql`
    query GetAlertCustomerAlerts($id: uuid!, $sort: CustomerAlertsSortingInput!, $search: String!) {
  alert: Alert_by_pk(id: $id) {
    id
    customer_alerts(sort: $sort) {
      id
      customer_status
      updated_at
      customer {
        id
        name
        __environment_type: environment_type
      }
    }
    customer_count(search: $search)
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetAlertCustomerAlertsQuery__
 *
 * To run a query within a React component, call `useGetAlertCustomerAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertCustomerAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertCustomerAlertsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAlertCustomerAlertsQuery(baseOptions: Apollo.QueryHookOptions<GetAlertCustomerAlertsQuery, GetAlertCustomerAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAlertCustomerAlertsQuery, GetAlertCustomerAlertsQueryVariables>(GetAlertCustomerAlertsDocument, options);
      }
export function useGetAlertCustomerAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertCustomerAlertsQuery, GetAlertCustomerAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAlertCustomerAlertsQuery, GetAlertCustomerAlertsQueryVariables>(GetAlertCustomerAlertsDocument, options);
        }
export type GetAlertCustomerAlertsQueryHookResult = ReturnType<typeof useGetAlertCustomerAlertsQuery>;
export type GetAlertCustomerAlertsLazyQueryHookResult = ReturnType<typeof useGetAlertCustomerAlertsLazyQuery>;
export type GetAlertCustomerAlertsQueryResult = Apollo.QueryResult<GetAlertCustomerAlertsQuery, GetAlertCustomerAlertsQueryVariables>;
export const ArchiveAlertDocument = gql`
    mutation ArchiveAlert($alert_id: uuid!) {
  archive_alert(alert_id: $alert_id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type ArchiveAlertMutationFn = Apollo.MutationFunction<ArchiveAlertMutation, ArchiveAlertMutationVariables>;

/**
 * __useArchiveAlertMutation__
 *
 * To run a mutation, you first call `useArchiveAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAlertMutation, { data, loading, error }] = useArchiveAlertMutation({
 *   variables: {
 *      alert_id: // value for 'alert_id'
 *   },
 * });
 */
export function useArchiveAlertMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveAlertMutation, ArchiveAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveAlertMutation, ArchiveAlertMutationVariables>(ArchiveAlertDocument, options);
      }
export type ArchiveAlertMutationHookResult = ReturnType<typeof useArchiveAlertMutation>;
export type ArchiveAlertMutationResult = Apollo.MutationResult<ArchiveAlertMutation>;
export type ArchiveAlertMutationOptions = Apollo.BaseMutationOptions<ArchiveAlertMutation, ArchiveAlertMutationVariables>;