/* 363b516c9805e4916534637ac6a4aee5f790dd8f
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Launch_Sox_Report_GeneratorMutationVariables = Types.Exact<{
  startDate: Types.Scalars['timestamptz'];
  endDate: Types.Scalars['timestamptz'];
  reportEmail: Types.Scalars['String'];
  customReportType: Types.CustomReportType;
  shouldSendToClient?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type Launch_Sox_Report_GeneratorMutation = { __typename?: 'Mutation', launch_sox_report_generator: { __typename?: 'LaunchSoxReportGeneratorMutationResponse', message_id: string } };


export const Launch_Sox_Report_GeneratorDocument = gql`
    mutation Launch_sox_report_generator($startDate: timestamptz!, $endDate: timestamptz!, $reportEmail: String!, $customReportType: CustomReportType!, $shouldSendToClient: Boolean = false) {
  launch_sox_report_generator(
    start_date: $startDate
    end_date: $endDate
    report_email: $reportEmail
    report_type: $customReportType
    should_deliver_to_client: $shouldSendToClient
  ) {
    message_id
  }
}
    `;
export type Launch_Sox_Report_GeneratorMutationFn = Apollo.MutationFunction<Launch_Sox_Report_GeneratorMutation, Launch_Sox_Report_GeneratorMutationVariables>;

/**
 * __useLaunch_Sox_Report_GeneratorMutation__
 *
 * To run a mutation, you first call `useLaunch_Sox_Report_GeneratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunch_Sox_Report_GeneratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchSoxReportGeneratorMutation, { data, loading, error }] = useLaunch_Sox_Report_GeneratorMutation({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      reportEmail: // value for 'reportEmail'
 *      customReportType: // value for 'customReportType'
 *      shouldSendToClient: // value for 'shouldSendToClient'
 *   },
 * });
 */
export function useLaunch_Sox_Report_GeneratorMutation(baseOptions?: Apollo.MutationHookOptions<Launch_Sox_Report_GeneratorMutation, Launch_Sox_Report_GeneratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Launch_Sox_Report_GeneratorMutation, Launch_Sox_Report_GeneratorMutationVariables>(Launch_Sox_Report_GeneratorDocument, options);
      }
export type Launch_Sox_Report_GeneratorMutationHookResult = ReturnType<typeof useLaunch_Sox_Report_GeneratorMutation>;
export type Launch_Sox_Report_GeneratorMutationResult = Apollo.MutationResult<Launch_Sox_Report_GeneratorMutation>;
export type Launch_Sox_Report_GeneratorMutationOptions = Apollo.BaseMutationOptions<Launch_Sox_Report_GeneratorMutation, Launch_Sox_Report_GeneratorMutationVariables>;