/* af9c8d6dfeaa6401e5826e56cc7bb8e951a37a51
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductDetailsQueryVariables = Types.Exact<{
  productId: Types.Scalars['uuid'];
}>;


export type ProductDetailsQuery = { __typename?: 'Query', Product_by_pk: { __typename?: 'Product', id: string, name: string, description: string, ProductPricingFactors: Array<{ __typename?: 'ProductPricingFactor', id: string, name: string, charge_type_enum: Types.ChargeTypeEnum_Enum, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null, seat_metric: { __typename?: 'SeatMetric', id: string, name: string } | null }>, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, Plan: { __typename?: 'Plan', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, customer_count: number, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, PricedProductPricingFactors: Array<{ __typename?: 'PricedProductPricingFactor', id: string, ProductPricingFactor: { __typename?: 'ProductPricingFactor', id: string, name: string } }>, Product: { __typename?: 'Product', id: string, name: string } }>, PricedProducts_aggregate: { __typename?: 'PricedProduct_aggregate', aggregate: { __typename?: 'PricedProduct_aggregate_fields', count: number } | null } } }> } | null };

export type PlanCardDetailsFragment = { __typename?: 'Plan', id: string, name: string, description: string, created_at: string, deprecated_at: string | null, customer_count: number, Actor: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null, PricedProducts: Array<{ __typename?: 'PricedProduct', id: string, PricedProductPricingFactors: Array<{ __typename?: 'PricedProductPricingFactor', id: string, ProductPricingFactor: { __typename?: 'ProductPricingFactor', id: string, name: string } }>, Product: { __typename?: 'Product', id: string, name: string } }>, PricedProducts_aggregate: { __typename?: 'PricedProduct_aggregate', aggregate: { __typename?: 'PricedProduct_aggregate_fields', count: number } | null } };

export const PlanCardDetailsFragmentDoc = gql`
    fragment PlanCardDetails on Plan {
  id
  name
  description
  created_at
  deprecated_at
  customer_count: customer_count(status: ACTIVE)
  Actor {
    name
    id
    deprecated_at
  }
  PricedProducts {
    id
    PricedProductPricingFactors {
      id
      ProductPricingFactor {
        id
        name
        __environment_type: environment_type
      }
    }
    Product {
      id
      name
      __environment_type: environment_type
    }
  }
  PricedProducts_aggregate(distinct_on: product_id) {
    aggregate {
      count
    }
  }
  __environment_type: environment_type
}
    `;
export const ProductDetailsDocument = gql`
    query ProductDetails($productId: uuid!) {
  Product_by_pk(id: $productId) {
    id
    name
    description
    ProductPricingFactors {
      id
      name
      charge_type_enum
      BillableMetric {
        id
        name
        __environment_type: environment_type
      }
      seat_metric {
        id
        name
      }
      __environment_type: environment_type
    }
    PricedProducts(where: {Plan: {deprecated_at: {_is_null: true}}}) {
      id
      Plan {
        id
        ...PlanCardDetails
        __environment_type: environment_type
      }
    }
    __environment_type: environment_type
  }
}
    ${PlanCardDetailsFragmentDoc}`;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useProductDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
      }
export function useProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailsQuery, ProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductDetailsQuery, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
        }
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
export type ProductDetailsQueryResult = Apollo.QueryResult<ProductDetailsQuery, ProductDetailsQueryVariables>;