import {
  ContractCommitLedgersQuery,
  PrepaidCommitLedgerEntryFragment,
  PostpaidCommitLedgerEntryFragment,
  CommitWithLedgerFragment,
  useContractCommitLedgersLazyQuery,
  useCustomerCommitLedgersLazyQuery,
  useCustomerCommitLedgersQuery,
} from "./data.graphql";
import { useRef, useState, useCallback } from "react";
import { setDifference, setSum } from "lib/set";
import { debounce } from "app/lib/debounce";

type CommitUnion = NonNullable<
  NonNullable<ContractCommitLedgersQuery["customer"]>["contract"]
>["v2_fields"]["commits_union"][number];

export type LedgerEntry =
  | PrepaidCommitLedgerEntryFragment
  | PostpaidCommitLedgerEntryFragment;

export type LedgerQuery = {
  error?: Error;
  loading?: boolean;
  data?: LedgerEntry[];
};

export type CommitLedgerFetcher = {
  /**
   * Get query state for given commit ID.
   */
  getLedgerQuery: (commitId: string) => LedgerQuery;
  /**
   * Start fetching ledger entries for the given commit IDs and any queued commit IDs immediately.
   */
  startLedgerRequests: (commitIds: string[]) => void;
  /**
   * Queue commit IDs for fetching. Batch request will trigger at the end of the debounce window.
   */
  queueLedgerRequest: (commitId: string) => void;
};

/**
 * Ledger fetcher for contract commits. Only fetches if requested.
 */
export function useLazyContractCommitLedgerFetcher(
  customerId: string,
  contractId: string,
): CommitLedgerFetcher {
  const [runGql] = useContractCommitLedgersLazyQuery();
  const fetchCommits = useCallback(
    async (commitIds: string[]) => {
      const query = await runGql({
        variables: {
          customerId,
          contractId,
          commitIds,
        },
      });
      return {
        data: query.data?.customer?.contract?.v2_fields?.commits_union,
        error: query.error,
      };
    },
    [runGql],
  );
  return useLazyCommitLedgerFetcher(fetchCommits);
}

/**
 * Ledger fetcher for customer commits. Only fetches if requested.
 */
export function useLazyCustomerCommitLedgerFetcher(
  customerId: string,
): CommitLedgerFetcher {
  const [runGql] = useCustomerCommitLedgersLazyQuery();
  const fetchCommits = useCallback(
    async (commitIds: string[]) => {
      const query = await runGql({
        variables: {
          customerId,
          commitIds,
        },
      });
      return {
        data: query.data?.customer?.commits,
        error: query.error,
      };
    },
    [runGql],
  );
  return useLazyCommitLedgerFetcher(fetchCommits);
}

/**
 * Ledger fetcher for customer commits. Fetches immediately.
 */
export function useCustomerCommitLedgerQuery(
  customerId: string,
  commitId: string,
): LedgerQuery {
  const query = useCustomerCommitLedgersQuery({
    variables: {
      customerId,
      commitIds: [commitId],
    },
  });
  return {
    data: query.data?.customer?.commits?.find((c) => c.id === commitId)?.ledger,
    error: query.error,
    loading: query.loading,
  };
}

function useLazyCommitLedgerFetcher(
  fetchCommits: (commitIds: string[]) => Promise<{
    data?: CommitWithLedgerFragment[];
    error?: Error;
  }>,
): CommitLedgerFetcher {
  const fetchedCommitsRef = useRef<Set<string>>(new Set());
  const requestedCommitRefs = useRef<Set<string>>(new Set());
  const [commitsMap, setCommitsMap] = useState<Map<string, LedgerQuery>>(
    new Map(),
  );

  /**
   * Partially update commit ID states and trigger re-render.
   */
  const updateCommitStates = (
    commitIds: string[],
    valueOrFn:
      | Partial<LedgerQuery>
      | ((commitId: string) => Partial<LedgerQuery> | undefined),
  ): void => {
    setCommitsMap((prev) => {
      for (const commitId of commitIds) {
        if (typeof valueOrFn === "function") {
          const value = valueOrFn(commitId);
          if (value) {
            insertOrMergeValue(prev, commitId, value);
          }
        } else {
          insertOrMergeValue(prev, commitId, valueOrFn);
        }
      }
      return prev;
    });
  };

  const fetchLedgers = useCallback(async () => {
    const commitIds = Array.from(
      setDifference(requestedCommitRefs.current, fetchedCommitsRef.current),
    );
    if (commitIds.length === 0) {
      return;
    }

    let query;
    try {
      query = await fetchCommits(commitIds);
    } finally {
      for (const commitId of commitIds) {
        requestedCommitRefs.current.delete(commitId);
      }
      updateCommitStates(commitIds, { loading: false });
    }

    if (query.error) {
      const error = new Error("Failed to load ledger", { cause: query.error });
      updateCommitStates(commitIds, { error });
    } else {
      for (const commitId of commitIds) {
        fetchedCommitsRef.current.add(commitId);
      }
    }

    const commitsById = new Map<string, CommitUnion>(
      (query.data ?? []).map((commit) => [commit.id, commit]),
    );
    updateCommitStates(commitIds, (commitId) => {
      const commit = commitsById.get(commitId);
      if (commit) {
        return {
          data: commit?.ledger,
        };
      }
    });
  }, [fetchCommits]);

  const debouncedFetchLedgers = useCallback(debounce(fetchLedgers, 500), [
    fetchLedgers,
  ]);

  return {
    getLedgerQuery(commitId) {
      let state = commitsMap.get(commitId);
      if (!state) {
        state = { loading: true };
        commitsMap.set(commitId, state);
      }
      return state;
    },
    startLedgerRequests(commitIds) {
      const commitIdsSet = setDifference(
        commitIds,
        setSum(requestedCommitRefs.current, fetchedCommitsRef.current),
      );
      if (commitIdsSet.size === 0) {
        return;
      }
      requestedCommitRefs.current = setSum(
        requestedCommitRefs.current,
        commitIdsSet,
      );
      updateCommitStates(commitIds, { loading: true, error: undefined });
      void fetchLedgers();
    },
    queueLedgerRequest(commitId) {
      if (
        requestedCommitRefs.current.has(commitId) ||
        fetchedCommitsRef.current.has(commitId)
      ) {
        return;
      }
      requestedCommitRefs.current.add(commitId);
      updateCommitStates([commitId], { loading: true, error: undefined });
      debouncedFetchLedgers();
    },
  };
}

function insertOrMergeValue<K, V extends object>(
  map: Map<K, V>,
  key: K,
  value: V,
): void {
  const originalValue = map.get(key);
  if (originalValue) {
    Object.assign(originalValue, value);
  } else {
    map.set(key, value);
  }
}
