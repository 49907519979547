import React, { useState } from "react";
import { z } from "zod";

import { useRootCtrl, RootCtrl } from "./RootCtrl";
import { AmountDistributionType } from "types/generated-graphql/__types__";
import { CreditType } from "app/types/credit-types";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";
import { CommitPriceInput } from "../../lib/CommitPriceInput";
import { TextInput } from "components/Input";
import { DatePicker } from "components/DatePicker";
import { dayjs } from "lib/dayjs";
import { DropdownItem } from "components/Dropdown";
import { Refine, Schema } from "../../Schema";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";

// Distibution value mapped to its label (excluding the None option)
const DISTRIBUTION_MAPPING: Record<AmountDistributionType, string> = {
  [AmountDistributionType.Each]: "Each",
  [AmountDistributionType.Divided]: "Divided",
  [AmountDistributionType.DividedRounded]: "Divided and rounded",
};

const RecurringFields = z
  .object({
    type: z.literal("recurring"),
    frequency: z.enum(Schema.RECURRING_FREQUENCY),
    startDate: z.string().datetime(),
    endDate: z.string().datetime(),
    amountDistribution: z.enum(Schema.RECURRING_DISTRIBUTION),
    quantity: z.number().default(1),
    unitPrice: z.number().nonnegative(),
  })
  .superRefine(Refine.startDateAndEndDate);

export const useRecurringCtrl = useRootCtrl.child(RecurringFields, {
  debugName: "RecurringSchedule",
  read(
    parent,
    frequency: Schema.Types.RecurringFrequency,
    timeframe: DefaultTimeframe,
  ) {
    const schedule = parent.get("schedule");

    if (!schedule || schedule.type !== "recurring") {
      return {
        startDate: timeframe.startingAt,
        endDate: timeframe.endingBefore,
        frequency,
        amountDistribution: AmountDistributionType.Divided,
      };
    }

    return {
      ...schedule,
      startDate: schedule.startDate || timeframe.startingAt,
      endDate: schedule.endDate || timeframe.endingBefore,
      frequency,
    };
  },
  write(self) {
    return { schedule: self.getUnvalidatedInputs() };
  },
});

export const RecurringSchedule: React.FC<{
  parent: RootCtrl;
  frequency: Schema.Types.RecurringFrequency;
  creditType: CreditType;
  onCreditTypeChange: (creditType: CreditType) => void;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = useRecurringCtrl(props.parent, props.frequency, timeframe);
  // Set the initial selected distribution based on the value set via the form controller, otherwise default to Each
  const [selectedDistributionLabel, setSelectedDistributionLabel] = useState(
    ctrl.get("amountDistribution")
      ? DISTRIBUTION_MAPPING[ctrl.get("amountDistribution") ?? "EACH"]
      : "Select",
  );

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "unitPrice",
  );

  return (
    <div>
      <div className="mb-12 mt-12 flex flex-row gap-[24px]">
        <div className="flex flex-1 flex-col">
          <p className="mb-4 min-h-[20px] text-sm text-black">Unit price</p>
          <CommitPriceInput
            unitPrice={ctrl.get("unitPrice")}
            creditType={props.creditType}
            onPriceChange={(price) => {
              ctrl.update({
                unitPrice: price,
              });
            }}
            onCreditTypeChange={props.onCreditTypeChange}
            isInvalid={!ctrl.isValid("unitPrice")}
            hintText={
              (!ctrl.appearsValid() && ctrl.state.fields.unitPrice?.error) || ""
            }
          />
        </div>
        <div className="flex w-[300px] flex-col">
          <p className="mb-4 text-sm text-black">Quantity</p>
          <TextInput
            placeholder="100"
            type="number"
            className="min-w-[200px]"
            disabled={false}
            value={
              ctrl.get("quantity") !== undefined
                ? (String(ctrl.get("quantity")) as string)
                : undefined
            }
            fullWidth
            onChange={({ value: quantity }) =>
              ctrl.update({
                quantity: quantity !== "" ? Number(quantity) : undefined,
              })
            }
            hintText={
              (!ctrl.appearsValid() && ctrl.state.fields.quantity?.error) || ""
            }
            variantOptions={{
              variant: "dropdown",
              dropdownOptions: {
                disabled: false,
                label: selectedDistributionLabel,
                children: Object.entries(DISTRIBUTION_MAPPING).map(
                  ([value, label]) => (
                    <DropdownItem
                      label={label}
                      value={value}
                      onClick={({ label, value }) => {
                        ctrl.update({
                          amountDistribution: value,
                        });
                        setSelectedDistributionLabel(label);
                      }}
                    />
                  ),
                ) as React.ReactNode[],
              },
            }}
          />
        </div>
        <div className="flex flex-row gap-lg">
          <div className="flex flex-col">
            <div className="mb-4 min-h-[20px]"></div>
            <DatePicker
              text="Starting at"
              value={
                ctrl.get("startDate")
                  ? dayjs.utc(ctrl.get("startDate")).toDate()
                  : undefined
              }
              onDateApply={(date) => {
                ctrl.update({
                  startDate: date?.toISOString(),
                });
              }}
              tooltipContent={{
                label: "Starting at",
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="mb-4 min-h-[20px]"></div>
            <DatePicker
              text="Ending before"
              value={
                ctrl.get("endDate")
                  ? dayjs.utc(ctrl.get("endDate")).toDate()
                  : undefined
              }
              onDateApply={(date) => {
                ctrl.update({
                  endDate: date?.toISOString(),
                });
              }}
              tooltipContent={{
                label: "Ending before",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
