/* aef7a6ad9e204fa87a6df8cd23f79a418b4dc13e
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreditGrantRevenueRecognitionQueryVariables = Types.Exact<{
  snapshot_start: Types.Scalars['timestamptz'];
  snapshot_end: Types.Scalars['timestamptz'];
  limit: Types.Scalars['Int'];
  cursor?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type CreditGrantRevenueRecognitionQuery = { __typename?: 'Query', CreditGrant: Array<{ __typename?: 'CreditGrant', id: string, name: string, reason: string | null, invoice_id: string | null, effective_at: string, expires_before: string, priority: string, amount_granted: string, amount_paid: string, cost_basis: string, Customer: { __typename?: 'Customer', id: string, name: string, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }> }, starting_deductions: { __typename?: 'CreditGrantDeduction_aggregate', aggregate: { __typename?: 'CreditGrantDeduction_aggregate_fields', sum: { __typename?: 'CreditGrantDeduction_sum_fields', amount: string | null } | null } | null }, ending_deductions: { __typename?: 'CreditGrantDeduction_aggregate', aggregate: { __typename?: 'CreditGrantDeduction_aggregate_fields', sum: { __typename?: 'CreditGrantDeduction_sum_fields', amount: string | null } | null } | null } }> };


export const CreditGrantRevenueRecognitionDocument = gql`
    query CreditGrantRevenueRecognition($snapshot_start: timestamptz!, $snapshot_end: timestamptz!, $limit: Int!, $cursor: uuid) {
  CreditGrant(
    order_by: {id: asc}
    limit: $limit
    where: {id: {_gt: $cursor}, _and: [{effective_at: {_lt: $snapshot_end}}, {expires_before: {_gt: $snapshot_start}}, {voided_at: {_is_null: true}}]}
  ) {
    id
    name
    Customer {
      id
      name
      CustomerIngestAliases {
        ingest_alias
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
    reason
    invoice_id
    effective_at
    expires_before
    priority
    amount_granted
    amount_paid
    cost_basis
    starting_deductions: CreditGrantDeductions_aggregate(
      where: {effective_before: {_lte: $snapshot_start}}
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    ending_deductions: CreditGrantDeductions_aggregate(
      where: {effective_before: {_lte: $snapshot_end}}
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCreditGrantRevenueRecognitionQuery__
 *
 * To run a query within a React component, call `useCreditGrantRevenueRecognitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditGrantRevenueRecognitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditGrantRevenueRecognitionQuery({
 *   variables: {
 *      snapshot_start: // value for 'snapshot_start'
 *      snapshot_end: // value for 'snapshot_end'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCreditGrantRevenueRecognitionQuery(baseOptions: Apollo.QueryHookOptions<CreditGrantRevenueRecognitionQuery, CreditGrantRevenueRecognitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditGrantRevenueRecognitionQuery, CreditGrantRevenueRecognitionQueryVariables>(CreditGrantRevenueRecognitionDocument, options);
      }
export function useCreditGrantRevenueRecognitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditGrantRevenueRecognitionQuery, CreditGrantRevenueRecognitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditGrantRevenueRecognitionQuery, CreditGrantRevenueRecognitionQueryVariables>(CreditGrantRevenueRecognitionDocument, options);
        }
export type CreditGrantRevenueRecognitionQueryHookResult = ReturnType<typeof useCreditGrantRevenueRecognitionQuery>;
export type CreditGrantRevenueRecognitionLazyQueryHookResult = ReturnType<typeof useCreditGrantRevenueRecognitionLazyQuery>;
export type CreditGrantRevenueRecognitionQueryResult = Apollo.QueryResult<CreditGrantRevenueRecognitionQuery, CreditGrantRevenueRecognitionQueryVariables>;