import React from "react";
import {
  DeprecatedInputSection,
  DeprecatedWizardFullPage,
} from "components/deprecated/Wizard";
import { DeprecatedToggleButtons } from "components/deprecated/ToggleButtons";
import { useDraftPlan } from "../../../../context";
import { DraftPlan } from "app/lib/plans/types";

export const isBasicInfoDone = (draftPlan: DraftPlan) => {
  return (
    draftPlan &&
    draftPlan.hasMinimums !== undefined &&
    draftPlan.hasPriceRamps !== undefined &&
    draftPlan.hasTrial !== undefined &&
    draftPlan.hasRecurringGrant !== undefined
  );
};

type BasicInfoPageProps = {
  editing: boolean;
  hasCustomers: boolean;
  hasCustomPricing?: boolean;
};

function hasModifiedTierResetFrequency(draftPlan: DraftPlan) {
  return draftPlan.pricedProducts
    ?.flatMap((pp) => pp.pricingFactors)
    ?.some((pf) => pf.tierResetFrequency !== 1);
}

export const BasicInfoPage: React.FC<BasicInfoPageProps> = ({
  editing,
  hasCustomers,
  hasCustomPricing,
}) => {
  const { draftPlan, setDraftPlan } = useDraftPlan();
  const hasTierResetFrequency = hasModifiedTierResetFrequency(draftPlan);

  return (
    <DeprecatedWizardFullPage>
      <div className="flex w-[520px] flex-col">
        <DeprecatedInputSection
          header="Does this plan have pricing ramps?"
          subtitle="Ramped pricing is used to vary pricing and minimums over time. For example, a plan may give new customers a low introductory rate that increases after the first invoice."
        >
          <DeprecatedToggleButtons
            buttonProps={[
              {
                value: "yes",
                label: "Yes",
                disabled: hasCustomPricing || hasTierResetFrequency,
                tooltipContent: hasCustomPricing
                  ? "Customers on this plan have price adjustments enabled. Plans with price adjustments enabled can not have their ramps changed"
                  : hasTierResetFrequency
                    ? "Plans with tier reset frequency enabled can not have their ramps changed"
                    : undefined,
              },
              {
                value: "no",
                label: "No",
                disabled: hasCustomPricing || hasTierResetFrequency,
                tooltipContent: hasCustomPricing
                  ? "Customers on this plan have price adjustments enabled. Plans with price adjustments enabled can not have their ramps changed"
                  : hasTierResetFrequency
                    ? "Plans with tier reset frequency enabled can not have their ramps changed"
                    : undefined,
              },
            ]}
            value={
              draftPlan.hasPriceRamps === undefined
                ? undefined
                : draftPlan.hasPriceRamps
                  ? "yes"
                  : "no"
            }
            defaultButtonProps={{
              onChange: (value) => {
                if (value === "no") {
                  const updatedPricedProducts = draftPlan.pricedProducts
                    ? draftPlan.pricedProducts.map((pp) => ({
                        ...pp,
                        pricingFactors: pp.pricingFactors.filter(
                          (pf) => pf.startPeriod === 0,
                        ),
                      }))
                    : undefined;
                  setDraftPlan({
                    ...draftPlan,
                    hasPriceRamps: false,
                    ramps: undefined,
                    pricedProducts: updatedPricedProducts,
                    minimums: draftPlan.minimums?.filter(
                      (min) => min.startPeriod === 0,
                    ),
                  });
                } else {
                  setDraftPlan({
                    ...draftPlan,
                    hasPriceRamps: value === "yes",
                    ramps: undefined, // Whenever the user changes this setting, update priceRamps so they're starting from a clean slate
                  });
                }
              },
            }}
          />
        </DeprecatedInputSection>
        <DeprecatedInputSection
          header="Does this plan have a trial period?"
          subtitle="Plans can have a default trial period. A customer's invoice for their trial period will only include usage-based charges, and they will receive a discount up to an optional spending cap. Trials can be extended or customized on a per-customer basis."
        >
          <DeprecatedToggleButtons
            buttonProps={[
              {
                value: "yes",
                label: "Yes",
              },
              {
                value: "no",
                label: "No",
              },
            ]}
            value={
              draftPlan.hasTrial === undefined
                ? undefined
                : draftPlan.hasTrial
                  ? "yes"
                  : "no"
            }
            defaultButtonProps={{
              disabled: editing && hasCustomers,
              onChange: (value) =>
                setDraftPlan({
                  ...draftPlan,
                  hasTrial: value === "yes",
                  trialSpec: value === "no" ? undefined : draftPlan.trialSpec,
                }),
            }}
          />
        </DeprecatedInputSection>
        <DeprecatedInputSection
          header="Does this plan have credit grants tied to it?"
          subtitle="Credits allow customers to hold a balance entitling them to some usage of your product. Grants allow you to automatically distribute credits on a one time or recurring basis when a customer signs up on this plan."
        >
          <DeprecatedToggleButtons
            buttonProps={[
              {
                value: "yes",
                label: "Yes",
              },
              {
                value: "no",
                label: "No",
              },
            ]}
            value={
              draftPlan.hasRecurringGrant === undefined
                ? undefined
                : draftPlan.hasRecurringGrant
                  ? "yes"
                  : "no"
            }
            defaultButtonProps={{
              disabled: editing,
              onChange: (value) =>
                setDraftPlan({
                  ...draftPlan,
                  hasRecurringGrant: value === "yes",
                  recurringGrant:
                    value === "no" ? undefined : draftPlan.recurringGrant,
                }),
            }}
          />
        </DeprecatedInputSection>
        <DeprecatedInputSection
          header="Does this plan have recurring invoice minimums?"
          subtitle="Plan minimums require a customer to spend at least a certain amount in each billing cycle."
        >
          <DeprecatedToggleButtons
            buttonProps={[
              {
                value: "yes",
                label: "Yes",
              },
              {
                value: "no",
                label: "No",
              },
            ]}
            value={
              draftPlan.hasMinimums === undefined
                ? undefined
                : draftPlan.hasMinimums
                  ? "yes"
                  : "no"
            }
            defaultButtonProps={{
              onChange: (value) =>
                setDraftPlan({
                  ...draftPlan,
                  hasMinimums: value === "yes",
                  minimums: value === "no" ? undefined : draftPlan.minimums,
                }),
            }}
          />
        </DeprecatedInputSection>
      </div>
    </DeprecatedWizardFullPage>
  );
};
