import classnames from "classnames";
import React from "react";

import { DeprecatedTextSkeleton } from "components/deprecated/Skeleton";
import { Subtitle } from "design-system";

type SelectableCard = {
  id?: string;
  content: string;
  onClick?: () => void;
  icon: React.ReactNode;
  selected?: boolean;
};

interface SelectableCardsProps {
  cards: SelectableCard[];
  clickable?: boolean;
  loading?: boolean;
}

export const DeprecatedSelectableCards: React.FC<SelectableCardsProps> = ({
  cards,
  clickable,
  loading,
}) => {
  return (
    <div className="flex flex-col">
      {cards.map((card, i) => {
        const { id, content, onClick, icon, selected } = card;
        return loading ? (
          <div>
            <DeprecatedTextSkeleton />
            <DeprecatedTextSkeleton />
            <DeprecatedTextSkeleton />
          </div>
        ) : (
          <a
            key={id ?? i}
            onClick={onClick}
            className={classnames(
              "group mb-4 flex flex-row items-center rounded-xs border border-deprecated-gray-100 bg-white px-12 py-8 text-default-font",
              {
                "!border-deprecated-primary-100 !bg-deprecated-primary-50":
                  !!selected,
                "cursor-pointer no-underline hover:bg-deprecated-primary-50":
                  !!clickable,
              },
            )}
          >
            <Subtitle
              level={4}
              className={classnames(
                "mr-auto font-default text-xs text-deprecated-gray-900",
                {
                  "text-deprecated-primary-600": !!selected,
                  "group-hover:text-deprecated-primary-600": !!clickable,
                },
              )}
            >
              {content}
            </Subtitle>
            <div className="flex">{icon}</div>
          </a>
        );
      })}
    </div>
  );
};
