import React, { useCallback, useMemo } from "react";
import { ContractFormController, EditContractCtrl } from "../ContractContext";
import { CommitsTable } from "../../Create/Sections/Commits";
import { CreditType } from "app/types/credit-types";
import { Pricing } from "../../Create/Sections/types";
import {
  AdditionalTermRow,
  AdditionalTermsTable,
} from "app/pages/Contracts/components/AdditionalTermsTable";
import { ContractPricing } from "app/pages/Contracts/lib/ContractPricing";
import { earliest, latest, maybeToDayjs, toDayjs, useNow } from "lib/date";
import Decimal from "decimal.js";
import { DefaultTimeframe } from "../lib/DefaultTimeframe";
import { Schema } from "../Schema";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { MutableCommitsTable } from "../../Edit/components/MutableCommitsTable";
import { resolveRecurringSchedule } from "../lib/recurringSchedule";

type EditHandler = {
  label: string;
  onEdit?: (id: string) => void;
};

interface TermsTablesProps {
  ctrl: ContractFormController;
  pricing: Pricing;
  creditTypes: CreditType[];
  shouldShowTables: boolean;
  editHandlers: EditHandler[];
  fiatCreditTypes?: CreditType[];
  shouldShowAdditionalTermsTable: boolean;
  isEdit: boolean;
}

function sortRows(
  a: AdditionalTermRow,
  b: AdditionalTermRow,
  primary: "startDate" | "endDate",
  fallback?: "endDate",
): number {
  const aDate = a[primary];
  const bDate = b[primary];

  if (aDate && bDate) {
    return aDate.diff(bDate) || (fallback ? sortRows(a, b, fallback) : 0);
  }

  if (!aDate && !bDate) {
    return 0;
  }

  return aDate ? -1 : 1;
}

const isTermPreExisting = (
  term: Schema.Types.ScheduledCharge,
  existingScheduledChargeIds?: string[],
) => {
  return existingScheduledChargeIds?.includes(term.id) ?? false;
};

export const TermsTables: React.FC<TermsTablesProps> = ({
  ctrl,
  pricing,
  creditTypes,
  shouldShowTables,
  editHandlers,
  fiatCreditTypes,
  shouldShowAdditionalTermsTable,
  isEdit,
}) => {
  const now = useNow();
  const timeframe = DefaultTimeframe.useFromContext();
  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );
  const commits = ctrl.get("commits") ?? [];
  const credits = ctrl.get("credits") ?? [];

  const getRowForTerm = useCallback(
    (
      type: "scheduled_charge" | "discount",
      term: Schema.Types.Discount | Schema.Types.ScheduledCharge,
    ): AdditionalTermRow => {
      const dates =
        term.billingSchedule.type === "recurring"
          ? [term.billingSchedule.startDate, term.billingSchedule.endDate]
          : term.billingSchedule.items.map((si) => si.date).sort();

      const items =
        term.billingSchedule.type === "recurring"
          ? resolveRecurringSchedule(term.billingSchedule).items
          : term.billingSchedule.items;

      const invoiceCount = items.length;
      const total = items.reduce(
        (sum, si) => sum.add(new Decimal(si.unitPrice).mul(si.quantity)),
        new Decimal(0),
      );

      const startDate = dates.at(0);
      const endDate = dates.length > 1 ? dates.at(-1) : null;

      const creditType = showCurrencyWork
        ? findCreditType(
            term.billingScheduleCreditTypeId ?? USD_CREDIT_TYPE.id,
            fiatCreditTypes ?? [USD_CREDIT_TYPE],
          )
        : USD_CREDIT_TYPE;

      return {
        name: ContractPricing.getProductName(pricing, term.productId, now),
        type: type === "scheduled_charge" ? "Scheduled charge" : "Discount",
        onClick: () => {
          const label =
            type === "scheduled_charge"
              ? "Scheduled charge"
              : type === "discount"
                ? "Discount"
                : null;
          if (label) {
            const editHandler = editHandlers.find(
              (item) => item.label.toLowerCase() === label.toLowerCase(),
            );
            if (editHandler?.onEdit) {
              editHandler.onEdit(term.id);
            }
          }
        },
        invoiceCount,
        startDate: startDate ? toDayjs(startDate) : null,
        endDate: endDate ? toDayjs(endDate) : null,
        isPreExisting:
          type === "scheduled_charge" &&
          isTermPreExisting(term, ctrl.get("existingScheduledChargeIds")),
        rate:
          term.billingSchedule.type === "fixed"
            ? {
                type: "scheduled",
                invoiceCount,
                total,
                creditType,
              }
            : {
                type: "scheduled_recurring",
                invoiceCount,
                total,
                frequency:
                  Schema.RECURRING_FREQUENCY_MAP[
                    term.billingSchedule.frequency
                  ],
                creditType,
              },
      };
    },
    [pricing, ctrl],
  );

  const royaltyNameMap = {
    aws: "AWS royalty fee",
    awsProService: "AWS (professional service) royalty fee",
    gcp: "GCP royalty fee",
    gcpProService: "GCP (professional service) royalty fee",
  };

  const rows: AdditionalTermRow[] = useMemo(
    () =>
      [
        ...(ctrl.get("scheduledCharges") ?? []).map(
          (sc: {
            id: string;
            productId: string;
            billingSchedule: Schema.Types.BillingSchedule;
            billingScheduleCreditTypeId: string;
            billingScheduleFrequency: keyof typeof Schema.RECURRING_FREQUENCY_MAP;
          }) => getRowForTerm("scheduled_charge", sc),
        ),

        ...(ctrl.get("discounts") ?? []).map(
          (d: {
            id: string;
            productId: string;
            billingSchedule: Schema.Types.BillingSchedule;
            billingScheduleCreditTypeId: string;
            billingScheduleFrequency: keyof typeof Schema.RECURRING_FREQUENCY_MAP;
          }) => getRowForTerm("discount", d),
        ),

        ...(ctrl.get("resellerRoyalties") ?? []).map(
          (rr: {
            type: keyof typeof royaltyNameMap;
            startingAt: string;
            endingBefore?: string;
            percentage: number;
          }): AdditionalTermRow => ({
            name: royaltyNameMap[rr.type],
            type: "Royalty fee",
            onClick: () => {
              const editHandler = editHandlers.find(
                (item) => item.label === "Reseller royalty",
              );
              if (editHandler && editHandler.onEdit) {
                editHandler.onEdit(rr.type);
              }
            },
            invoiceCount: null,
            startDate: toDayjs(latest(rr.startingAt, timeframe.startingAt)),
            endDate:
              maybeToDayjs(earliest(rr.endingBefore, timeframe.endingBefore)) ??
              null,
            rate: {
              type: "royalty",
              fraction: new Decimal(rr.percentage).div(100).toString(),
            },
          }),
        ),

        ...(ctrl.get("proServices") ?? []).map(
          (ps: {
            id: string;
            productId: string;
            unitPrice: number;
          }): AdditionalTermRow => ({
            name: ContractPricing.getProductName(pricing, ps.productId, now),
            type: "Professional service",
            onClick: () => {
              const editHandler = editHandlers.find(
                (item) => item.label === "Professional services",
              );
              if (editHandler && editHandler.onEdit) {
                editHandler.onEdit(ps.id);
              }
            },
            invoiceCount: null,
            startDate: null,
            endDate: null,
            rate: { type: "pro_service", unitPrice: new Decimal(ps.unitPrice) },
          }),
        ),
      ].sort((a, b) => sortRows(a, b, "startDate", "endDate")),
    [ctrl],
  );

  if (!shouldShowTables) {
    return null;
  }

  return (
    <>
      {commits.length > 0 ? (
        isEdit ? (
          <MutableCommitsTable
            title="Commits"
            rateRows={commits}
            pricing={pricing}
            onSelectRow={(row) => {
              const editHandler = editHandlers.find(
                (item) => item.label === "Commits",
              );
              if (editHandler && editHandler.onEdit) {
                editHandler.onEdit(row.id);
              }
            }}
            asCredit={false}
            creditTypes={creditTypes}
            ctrl={ctrl as EditContractCtrl}
          />
        ) : (
          <CommitsTable
            title="Commits"
            rateRows={commits}
            pricing={pricing}
            onSelectRow={(row) => {
              const editHandler = editHandlers.find(
                (item) => item.label === "Commits",
              );
              if (editHandler && editHandler.onEdit) {
                editHandler.onEdit(row.id);
              }
            }}
            asCredit={false}
            creditTypes={creditTypes}
          />
        )
      ) : null}
      {credits.length > 0 && (
        <CommitsTable
          title="Credits"
          rateRows={credits}
          pricing={pricing}
          onSelectRow={(row) => {
            const editHandler = editHandlers.find(
              (item) => item.label === "Credits",
            );
            if (editHandler && editHandler.onEdit) {
              editHandler.onEdit(row.id);
            }
          }}
          asCredit
          creditTypes={creditTypes}
        />
      )}
      {shouldShowAdditionalTermsTable && (
        <AdditionalTermsTable
          className="mt-12"
          rows={rows}
          renderT10Table={true}
        />
      )}
    </>
  );
};
