import React from "react";
import {
  InvoiceCommitMetadataFragment,
  useChangeCommitEndDateMutation,
  useCommitImpactedInvoicesQuery,
} from "./data.graphql";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { Body, DateInput } from "design-system";
import { Button } from "components/Button";
import { FormController } from "app/lib/FormController";
import { useSnackbar } from "components/deprecated/Snackbar";
import { Schema } from "./Schema";
import { dayjs } from "lib/dayjs";
import { maybeToDayjs, toDayjs } from "lib/date";
import { RoundedCurrency, USD_CREDIT_TYPE } from "app/lib/credits";
import Decimal from "decimal.js";
import pluralize from "pluralize";
import { CommitCardCommit } from "./CommitCard";

const useChangeEndDateModalController = FormController.createHook(
  Schema.ChangeEndDateInput,
);

type PrepaidCommitDetails = CommitCardCommit & {
  __typename: "PrepaidCommit";
};

function roundToMidnight(date?: dayjs.Dayjs) {
  if (!date) {
    return date;
  }

  const roundedDown = date.startOf("day");
  if (roundedDown.isSame(date)) {
    return date;
  }
  return roundedDown.add(1, "day");
}

function getValidEndDateBounds(
  commit: PrepaidCommitDetails,
  finalizedInvoices: InvoiceCommitMetadataFragment[],
) {
  const lastInvoiceWithCommitApplied = finalizedInvoices.find(
    (i) =>
      i.status === "FINALIZED" &&
      i.line_items.some(
        (li) =>
          li.__typename === "ContractAppliedCommitLineItem" &&
          li.commit_with_segment.commit_union.id === commit.id,
      ),
  );
  const lastInvoiceWithCommitCharge = finalizedInvoices.find(
    (i) =>
      i.status === "FINALIZED" &&
      i.line_items.some(
        (li) =>
          li.__typename === "ContractCommitLineItem" &&
          li.commit_union.id === commit.id,
      ),
  );

  function alignedLastInvoiceDate(
    invoice: InvoiceCommitMetadataFragment | undefined,
  ) {
    if (!invoice) {
      return undefined;
    }
    // For service period invoices, we want to allow truncating the commit at the
    // end of the service period, not the issued_at date (which includes the grace period).
    return roundToMidnight(
      toDayjs(
        "exclusive_end_date" in invoice
          ? invoice.exclusive_end_date
          : invoice.issued_at,
      ),
    )?.toDate();
  }

  return {
    minEndAccessAt: alignedLastInvoiceDate(lastInvoiceWithCommitApplied),
    minEndInvoicesAt: alignedLastInvoiceDate(lastInvoiceWithCommitCharge),
  };
}

function getAccessPreview(
  commit: PrepaidCommitDetails,
  proposedAccessEnd: dayjs.Dayjs,
) {
  let segmentsRemoved = 0;
  let segmentsRemovedValue = new Decimal(0);
  let segmentsTruncated = 0;

  for (const segment of commit.access_schedule.schedule_items) {
    const segmentStart = toDayjs(segment.date);
    const segmentEnd = toDayjs(segment.end_date);
    if (proposedAccessEnd.isSameOrBefore(segmentStart)) {
      segmentsRemoved++;
      segmentsRemovedValue = segmentsRemovedValue.add(
        new Decimal(segment.amount),
      );
    } else if (proposedAccessEnd.isSameOrBefore(segmentEnd)) {
      segmentsTruncated++;
    }
  }

  return {
    segmentsRemoved,
    segmentsRemovedValue,
    segmentsTruncated,
  };
}

function getInvoicesPreview(
  commit: PrepaidCommitDetails,
  proposedInvoicesEnd: dayjs.Dayjs,
) {
  let invoicesAffected = 0;
  let invoicesAffectedValue = new Decimal(0);

  for (const item of commit.invoice_schedule?.schedule_items ?? []) {
    if (proposedInvoicesEnd.isSameOrBefore(toDayjs(item.date))) {
      invoicesAffected++;
      invoicesAffectedValue = invoicesAffectedValue.add(
        new Decimal(item.amount),
      );
    }
  }

  return {
    invoicesAffected,
    invoicesAffectedValue,
  };
}

function getPreview(
  commit: PrepaidCommitDetails,
  proposedAccessEnd?: string,
  proposedInvoicesEnd?: string,
) {
  const access = proposedAccessEnd
    ? getAccessPreview(commit, toDayjs(proposedAccessEnd))
    : undefined;
  const invoices = proposedInvoicesEnd
    ? getInvoicesPreview(commit, toDayjs(proposedInvoicesEnd))
    : undefined;

  return {
    result: {
      access,
      invoices,
    },
  };
}

interface ChangeEndDateModalProps {
  onClose: () => void;
  commit: PrepaidCommitDetails;
}

export const ChangeEndDateModal: React.FC<ChangeEndDateModalProps> = ({
  commit,
  onClose,
}) => {
  const allCommitDates = [
    ...commit.access_schedule.schedule_items.map((s) => [s.date, s.end_date]),
    ...(commit.invoice_schedule?.schedule_items.map((s) => [s.date]) ?? []),
  ]
    .flat()
    .map((d) => toDayjs(d))
    .sort((a, b) => (a.isAfter(b) ? 1 : -1));
  const commitMinDate = allCommitDates[0]?.add(-1, "day");
  const commitMaxDate = allCommitDates[allCommitDates.length - 1]?.add(
    1,
    "day",
  );

  const pushMessage = useSnackbar();
  const { loading, data: { customer } = {} } = useCommitImpactedInvoicesQuery({
    variables: {
      customerId: commit.customer.id,
      start: commitMinDate?.toISOString(),
      end: commitMaxDate?.toISOString(),
    },
  });
  const [changeCommitEndDate, changeCommitEndDateResult] =
    useChangeCommitEndDateMutation();
  const ctrl = useChangeEndDateModalController();

  const { minEndAccessAt, minEndInvoicesAt } = getValidEndDateBounds(
    commit,
    loading ? [] : customer?.finalized.invoices ?? [],
  );
  const preview =
    !loading && customer?.finalized.invoices
      ? getPreview(commit, ctrl.get("endAccessAt"), ctrl.get("endInvoicesAt"))
      : undefined;
  const commitOrCredit = Commit.isCredit(commit) ? "credit" : "commit";

  const previewActions: React.JSX.Element[] = [];
  if (preview?.result?.access?.segmentsRemoved) {
    previewActions.push(
      <>
        Remove {preview.result.access.segmentsRemoved} access{" "}
        {pluralize("segment", preview.result.access.segmentsRemoved)} (worth{" "}
        <RoundedCurrency
          amount={preview.result.access.segmentsRemovedValue}
          creditType={USD_CREDIT_TYPE}
        />
        )
      </>,
    );
  }
  if (preview?.result?.access?.segmentsTruncated) {
    previewActions.push(
      <>
        Truncate {preview.result.access.segmentsTruncated} access{" "}
        {pluralize("segment", preview.result.access.segmentsTruncated)} to end
        on {dayjs.utc(ctrl.get("endAccessAt")).format("ddd, MMM D, YYYY")}
      </>,
    );
  }
  if (preview?.result?.invoices?.invoicesAffected) {
    previewActions.push(
      <>
        Remove {preview.result.invoices.invoicesAffected}{" "}
        {pluralize("charges", preview.result.invoices.invoicesAffected)} from
        future invoices (worth{" "}
        <RoundedCurrency
          amount={preview.result.invoices.invoicesAffectedValue}
          creditType={USD_CREDIT_TYPE}
        />
        )
      </>,
    );
  }

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      const result = await changeCommitEndDate({
        variables: {
          customerId: commit.customer.id,
          commitId: commit.id,
          contractId: commit.contract?.id,
          amendmentId: commit.amendment_id ?? undefined,
          endAccessAt: ctrl.get("endAccessAt"),
          endInvoicesAt: ctrl.get("endInvoicesAt"),
        },
        update(cache) {
          cache.evict({
            fieldName: "Customer",
          });
          cache.evict({
            fieldName: "Customer_by_pk",
          });
          cache.gc();
        },
      });

      if (result?.data?.change_commit_end_date) {
        pushMessage({
          content: "End date changed successfully",
          type: "success",
        });
      } else {
        pushMessage({
          content: "End date change failed",
          type: "error",
        });
      }
      onClose();
    } catch (e) {
      pushMessage({
        content: "End date change failed",
        type: "error",
      });
    }
  });

  return (
    <DeprecatedPopup
      isOpen
      onRequestClose={onClose}
      title={`Change ${commitOrCredit} end date`}
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={onSubmit}
            disabled={
              loading ||
              changeCommitEndDateResult.loading ||
              !ctrl.appearsValid() ||
              previewActions.length === 0 ||
              (minEndAccessAt &&
                maybeToDayjs(ctrl.get("endAccessAt"))?.isBefore(
                  minEndAccessAt,
                )) ||
              (minEndInvoicesAt &&
                maybeToDayjs(ctrl.get("endInvoicesAt"))?.isBefore(
                  minEndInvoicesAt,
                ))
            }
            text="Save"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <input type="submit" className="hidden" />
        <Body level={2}>
          {Commit.isCredit(commit)
            ? "Change the end date of this credit, removing access after that date."
            : "Change the end date of this commit, removing access and invoices after those dates."}
        </Body>
        <div className="grid gap-12">
          <DateInput
            {...ctrl.props.DateInput("endAccessAt", {
              name: "End access at",
              minDate: minEndAccessAt,
              tooltip: `The last day this ${commitOrCredit} can be drawn down.`,
            })}
          />
          {!Commit.isCredit(commit) &&
          commit.invoice_schedule?.schedule_items.length ? (
            <DateInput
              {...ctrl.props.DateInput("endInvoicesAt", {
                name: "End invoices at",
                minDate: minEndInvoicesAt,
                tooltip: `The last day this ${commitOrCredit} can generate an invoice`,
              })}
            />
          ) : (
            ""
          )}
          <div>
            <Body>
              This will result in the following changes to this {commitOrCredit}
              :
            </Body>
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              {previewActions.map((a, i) => (
                <li key={i}>{a}</li>
              ))}
              {!previewActions.length && <li>No changes</li>}
            </ul>
          </div>
          <div />
        </div>
      </form>
    </DeprecatedPopup>
  );
};
