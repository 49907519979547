/* 8aa50df2d0466d453513348487990e89a3dfcb5f
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupAwsMarketplaceCredentialsMutationVariables = Types.Exact<{
  external_id: Types.Scalars['String'];
  iam_role_arn: Types.Scalars['String'];
}>;


export type SetupAwsMarketplaceCredentialsMutation = { __typename?: 'Mutation', store_aws_marketplace_credentials: { __typename?: 'MutationResponse', success: boolean } };


export const SetupAwsMarketplaceCredentialsDocument = gql`
    mutation SetupAWSMarketplaceCredentials($external_id: String!, $iam_role_arn: String!) {
  store_aws_marketplace_credentials(
    aws_external_id: $external_id
    aws_iam_role_arn: $iam_role_arn
  ) {
    success
  }
}
    `;
export type SetupAwsMarketplaceCredentialsMutationFn = Apollo.MutationFunction<SetupAwsMarketplaceCredentialsMutation, SetupAwsMarketplaceCredentialsMutationVariables>;

/**
 * __useSetupAwsMarketplaceCredentialsMutation__
 *
 * To run a mutation, you first call `useSetupAwsMarketplaceCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupAwsMarketplaceCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupAwsMarketplaceCredentialsMutation, { data, loading, error }] = useSetupAwsMarketplaceCredentialsMutation({
 *   variables: {
 *      external_id: // value for 'external_id'
 *      iam_role_arn: // value for 'iam_role_arn'
 *   },
 * });
 */
export function useSetupAwsMarketplaceCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<SetupAwsMarketplaceCredentialsMutation, SetupAwsMarketplaceCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupAwsMarketplaceCredentialsMutation, SetupAwsMarketplaceCredentialsMutationVariables>(SetupAwsMarketplaceCredentialsDocument, options);
      }
export type SetupAwsMarketplaceCredentialsMutationHookResult = ReturnType<typeof useSetupAwsMarketplaceCredentialsMutation>;
export type SetupAwsMarketplaceCredentialsMutationResult = Apollo.MutationResult<SetupAwsMarketplaceCredentialsMutation>;
export type SetupAwsMarketplaceCredentialsMutationOptions = Apollo.BaseMutationOptions<SetupAwsMarketplaceCredentialsMutation, SetupAwsMarketplaceCredentialsMutationVariables>;