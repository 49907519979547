import { ApolloError } from "@apollo/client";
import { Caption, Headline } from "design-system";
import { IconButton } from "components/IconButton";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { DeprecatedRightPane } from "components/deprecated/Popup";
import { DeprecatedTablePanel } from "components/deprecated/TablePanel";
import { ErrorEmptyState } from "app/lib/errors/ErrorEmptyState";
import React, { useState } from "react";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { useNow } from "lib/date";
import { DeprecatedToggleButtons } from "components/deprecated/ToggleButtons";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";
import { CommitConsumptionRateTypeEnum } from "types/generated-graphql/__types__";
import { CommitCardCommit } from "./CommitCard";

type CommitMetadataTab = "applicable-products" | "applicable-tags";

interface Props {
  onRequestClose: () => void;
  commit: CommitCardCommit;
  error?: ApolloError;
  loading?: boolean;
}

export const CommitMetadataFlyover: React.FC<Props> = ({
  onRequestClose,
  commit,
  error,
  loading,
}) => {
  const now = useNow();
  const [activeTab, setActiveTab] = useState<CommitMetadataTab>(
    "applicable-products",
  );
  const hasApplicableProducts =
    commit.applicable_products && commit.applicable_products.length > 0;
  const hasApplicableTags =
    commit.applicable_tags && commit.applicable_tags.length > 0;
  const appliesToAllProducts = !hasApplicableProducts && !hasApplicableTags;
  const hasBothApplicableProductsAndTags =
    hasApplicableProducts && hasApplicableTags;
  const applicableTags =
    commit.applicable_tags?.map((tag) => ({ name: tag })) ?? [];
  return (
    <DeprecatedRightPane
      isOpen
      onRequestClose={onRequestClose}
      size="lg"
      contentClassName="!p-0"
    >
      <header className="flex items-center bg-gray-50 px-12 py-8">
        {/* left */}
        <div className="grow">
          <Headline level={6} className="grow">
            {Commit.getName(commit, now)}
          </Headline>
        </div>

        {/* right */}
        <div className="flex items-center">
          <IconButton
            className="grow-0"
            onClick={onRequestClose}
            theme="secondary"
            icon="xClose"
          />
        </div>
      </header>
      <div className="p-12">
        {error ? (
          <ErrorEmptyState
            title="We were unable to load metadata for this commit"
            error={error}
          />
        ) : (
          <div className="flex flex-col gap-12">
            <div className="flex flex-row flex-wrap gap-x-24 gap-y-12">
              <div>
                <Caption>Commit ID</Caption>
                <DeprecatedCopyableID id={commit.id} label={commit.id} />
              </div>
              <div>
                <Caption>Rate Type</Caption>
                <div>{commitRateDisplayText(commit.rate_type)}</div>
              </div>
              {commit.netsuite_sales_order_id && (
                <div>
                  <Caption>Netsuite Sales Order ID</Caption>
                  <DeprecatedCopyableID
                    id={commit.netsuite_sales_order_id}
                    label={commit.netsuite_sales_order_id}
                  />
                </div>
              )}
            </div>
            {hasBothApplicableProductsAndTags || appliesToAllProducts ? (
              <>
                <DeprecatedToggleButtons<CommitMetadataTab>
                  light
                  value={activeTab}
                  className="!mb-0"
                  buttonProps={[
                    {
                      value: "applicable-products",
                      label: "Applicable products",
                      onChange: () => setActiveTab("applicable-products"),
                    },
                    {
                      value: "applicable-tags",
                      label: "Applicable tags",
                      onChange: () => setActiveTab("applicable-tags"),
                    },
                  ]}
                />
                {activeTab === "applicable-products" ? (
                  <DeprecatedTablePanel
                    title="Product"
                    loading={loading}
                    columns={[
                      {
                        id: "id",
                        header: "Product ID",
                        render: (product) => {
                          return (
                            <DeprecatedCopyableID
                              id={product.id}
                              label={product.id}
                            />
                          );
                        },
                      },
                      {
                        id: "name",
                        header: "Name",
                        textWrappable: true,
                        render: (product) =>
                          ProductListItem.getCurrent(product, "name", now),
                      },
                    ]}
                    data={loading ? [] : commit.applicable_products ?? []}
                    emptyState={
                      <p className="p-24 text-center text-sm text-gray-500">
                        {appliesToAllProducts ? "All products" : "No products"}
                      </p>
                    }
                  />
                ) : (
                  <DeprecatedTablePanel
                    title="Tag"
                    loading={loading}
                    columns={[
                      {
                        id: "name",
                        header: "Name",
                        textWrappable: true,
                        render: (tag) => tag.name,
                      },
                    ]}
                    data={loading ? [] : applicableTags}
                    emptyState={
                      <p className="p-24 text-center text-sm text-gray-500">
                        {appliesToAllProducts ? "All tags" : "No tags"}
                      </p>
                    }
                  />
                )}
              </>
            ) : hasApplicableProducts ? (
              <DeprecatedTablePanel
                title="Applicable products"
                loading={loading}
                columns={[
                  {
                    id: "id",
                    header: "Product ID",
                    render: (product) => {
                      return (
                        <DeprecatedCopyableID
                          id={product.id}
                          label={product.id}
                        />
                      );
                    },
                  },
                  {
                    id: "name",
                    header: "Name",
                    textWrappable: true,
                    render: (product) =>
                      ProductListItem.getCurrent(product, "name", now),
                  },
                ]}
                data={loading ? [] : commit.applicable_products ?? []}
              />
            ) : (
              <DeprecatedTablePanel
                title="Applicable tags"
                loading={loading}
                columns={[
                  {
                    id: "name",
                    header: "Name",
                    textWrappable: true,
                    render: (tag) => tag.name,
                  },
                ]}
                data={loading ? [] : applicableTags}
              />
            )}
          </div>
        )}
      </div>
    </DeprecatedRightPane>
  );
};

function commitRateDisplayText(rateType: CommitConsumptionRateTypeEnum) {
  if (rateType === CommitConsumptionRateTypeEnum.CommitRate) {
    return "Commit rate";
  } else if (rateType === CommitConsumptionRateTypeEnum.ListRate) {
    return "List rate";
  } else {
    rateType satisfies never;
    return "Unknown";
  }
}
