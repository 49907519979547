import React, { useCallback } from "react";
import { ContractFormController } from "../ContractContext";
import { ContractCreate_ExistingContractFragment as ExistingContract } from "../../Create/data.graphql";
import { useEnvironment } from "app/lib/environmentSwitcher/context";
import { useGetBillableMetricsQuery } from "../data.graphql";
import { TransitionTypeEnum } from "types/generated-graphql/__types__";
import { ButtonGroup } from "components/ButtonGroup";
import { Button } from "components/Button";
import { TextInput } from "components/Input";
import { OptionGroup } from "components/OptionGroup";
import { InputDropdown } from "components/InputDropdown";
import { DropdownItem } from "components/Dropdown";
import { Checkbox } from "components/Checkbox";

interface BasicDetailsAdvancedProps {
  ctrl: ContractFormController;
  featureFlagOptions: {
    [key: string]: boolean | undefined;
  };
  existingContracts: ExistingContract[];
  isEdit: boolean;
}

export const BasicDetailsAdvanced: React.FC<BasicDetailsAdvancedProps> = ({
  ctrl,
  featureFlagOptions,
  existingContracts,
  isEdit,
}) => {
  const [advanceSectionClicked, setAdvanceSectionClicked] =
    React.useState(false);
  const [salesforceInputTouched, setSalesforceInputTouched] =
    React.useState(false);
  const [usageFilterGroupValues, setUsageFilterGroupValues] = React.useState<
    string[]
  >([]);
  const [usageFilterSelected, setUsageFilterSelected] = React.useState(
    isEdit
      ? false
      : ctrl.get("usageFilterGroupKey") || usageFilterGroupValues.length > 0
        ? true
        : false,
  );
  const [transitionSelected, setTransitionSelected] = React.useState(
    isEdit
      ? false
      : ctrl.get("transitionType") ||
          ctrl.get("transitionPreviousContractId") ||
          false,
  );

  const { environmentType } = useEnvironment();

  const { data: billableMetricsData, loading: billableMetricsLoading } =
    useGetBillableMetricsQuery({
      variables: {
        environment_type: environmentType,
      },
    });

  let groupKeys: string[] = [];
  if (billableMetricsData) {
    groupKeys = Array.from(
      new Set(
        billableMetricsData?.billable_metrics.flatMap((bm) => {
          const keys = bm.group_keys as Array<string | string[]>;
          return keys?.flat();
        }),
      ),
    ).filter((key) => key);
  }

  const handleGroupValueChange = useCallback(
    ({ value }: { value: string[] }) => {
      if (value.length > 0) {
        value = Array.from(new Set(value.map((v) => v)));
        setUsageFilterGroupValues(value);
        ctrl.update({ usageFilterGroupValues: value.join(",") });
      } else {
        setUsageFilterGroupValues([]);
        ctrl.update({ usageFilterGroupValues: undefined });
      }
    },
    [usageFilterGroupValues],
  );

  const handleUsageFilterCheckboxClick = useCallback(() => {
    if (ctrl.get("usageFilterGroupKey") && usageFilterGroupValues) {
      return;
    } else {
      setUsageFilterSelected(!usageFilterSelected);
      ctrl.update({
        usageFilterGroupKey: undefined,
        usageFilterGroupValues: undefined,
      });
    }
  }, [usageFilterGroupValues, ctrl]);

  const handleTransitionCheckboxClick = useCallback(() => {
    if (ctrl.get("transitionPreviousContractId")) {
      return;
    } else {
      setTransitionSelected(!transitionSelected);
      ctrl.update({
        transitionType: undefined,
        transitionPreviousContractId: undefined,
      });
    }
  }, [transitionSelected, ctrl]);

  const handleTransitionTypeChange = useCallback(
    (
      transitionType: TransitionTypeEnum.Renewal | TransitionTypeEnum.Supersede,
    ) => {
      ctrl.update({ transitionType });
    },
    [],
  );

  const transitionButtons = isEdit
    ? []
    : featureFlagOptions.supersedeEnabled
      ? [
          <ButtonGroup
            buttons={[
              {
                onClick: () =>
                  handleTransitionTypeChange(TransitionTypeEnum.Renewal),
                text: "Renewal",
                isActive:
                  ctrl.get("transitionType") === TransitionTypeEnum.Renewal ||
                  !ctrl.get("transitionType"),
              },
              {
                onClick: () =>
                  handleTransitionTypeChange(TransitionTypeEnum.Supersede),
                text: "Supercede",
                isActive:
                  ctrl.get("transitionType") === TransitionTypeEnum.Supersede,
              },
            ]}
          />,
        ]
      : [
          <Button
            text="Renewal"
            onClick={() =>
              handleTransitionTypeChange(TransitionTypeEnum.Renewal)
            }
            theme="tertiary"
          />,
        ];

  return (
    <div>
      <Button
        text="Advanced"
        leadingIcon={advanceSectionClicked ? "chevronDown" : "chevronRight"}
        theme="tertiary"
        size="sm"
        onClick={() => setAdvanceSectionClicked(!advanceSectionClicked)}
      />
      {advanceSectionClicked && (
        <div className="flex flex-col gap-[24px]">
          <div className="flex flex-row gap-[24px]">
            {featureFlagOptions.salesforceEnabled && (
              <TextInput
                label="Salesforce opportunity ID"
                placeholder="Enter ID"
                helpText="The Salesforce opportunity ID associated with this contract"
                className="relative w-[400px]"
                isInvalid={!ctrl.isValid("salesforceOpportunityId")}
                type="text"
                value={ctrl.get("salesforceOpportunityId")}
                hintText={
                  (salesforceInputTouched &&
                    ctrl.state.fields.salesforceOpportunityId?.error) ||
                  ""
                }
                onChange={(meta: { value: string }) =>
                  ctrl.update({ salesforceOpportunityId: meta.value })
                }
                onFocus={() => setSalesforceInputTouched(false)}
                onBlur={() => setSalesforceInputTouched(true)}
                disabled={isEdit}
              />
            )}
            {featureFlagOptions.netsuiteEnabled && (
              <TextInput
                label="NetSuite sales order ID"
                placeholder="Enter ID"
                helpText="The NetSuite sales order ID associated with this contract"
                className="w-[400px]"
                type="text"
                value={ctrl.get("netsuiteSalesOrderId")}
                onChange={(meta: { value: string }) =>
                  ctrl.update({ netsuiteSalesOrderId: meta.value })
                }
              />
            )}
          </div>
          <div>
            <OptionGroup
              className="w-full items-start items-stretch gap-[24px]"
              size="narrow"
            >
              <Checkbox
                label="Add usage filters"
                supportingText="With overlapping contracts, determine how to route usage to the correct contract."
                checked={usageFilterSelected}
                onClick={handleUsageFilterCheckboxClick}
                disabled={isEdit}
                children={[
                  <InputDropdown
                    key="group-key"
                    label="Group key"
                    placeholder="Select group key"
                    isTypingEnabled={false}
                    disabled={isEdit || billableMetricsLoading}
                    value={
                      !isEdit ? ctrl.get("usageFilterGroupKey") : undefined
                    }
                    onClearText={() =>
                      ctrl.update({ usageFilterGroupKey: undefined })
                    }
                  >
                    {[...groupKeys].map((key) => (
                      <DropdownItem
                        key={key}
                        label={key}
                        value={key}
                        onClick={() =>
                          ctrl.update({ usageFilterGroupKey: key })
                        }
                      />
                    ))}
                  </InputDropdown>,
                  <InputDropdown
                    key="group-values"
                    label="Group values"
                    placeholder="Enter group value"
                    isTypingEnabled={true}
                    fullWidth
                    disabled={
                      isEdit ||
                      billableMetricsLoading ||
                      !ctrl.get("usageFilterGroupKey")
                    }
                    tagsVariant={true}
                    value={
                      isEdit
                        ? undefined
                        : usageFilterGroupValues.length > 0
                          ? usageFilterGroupValues
                          : ctrl.get("usageFilterGroupValues")?.split(",") ?? []
                    }
                    onChangeTags={handleGroupValueChange}
                    onClearText={() =>
                      ctrl.update({ usageFilterGroupValues: undefined })
                    }
                  />,
                ]}
                childrenClassName="flex flex-row gap-[24px]"
              />
              <Checkbox
                label="Rollover from previous contract"
                supportingText="If this contract is tied to an existing contract, you can rollover items from the previous one."
                checked={transitionSelected}
                onChange={handleTransitionCheckboxClick}
                disabled={isEdit}
                children={transitionButtons.concat([
                  <InputDropdown
                    label="Previous contract"
                    placeholder="Select contract"
                    fullWidth
                    disabled={
                      !transitionSelected || existingContracts.length === 0
                    }
                    isTypingEnabled={false}
                    value={
                      existingContracts.find(
                        (contract) =>
                          ctrl.get("transitionPreviousContractId") ===
                          contract.id,
                      )?.name ?? undefined
                    }
                    onClearText={() =>
                      ctrl.update({ transitionPreviousContractId: undefined })
                    }
                  >
                    {existingContracts.map((contract) => (
                      <DropdownItem
                        key={contract.id}
                        label={contract.name ?? ""}
                        value={contract.id}
                        onClick={() =>
                          ctrl.update({
                            transitionPreviousContractId: contract.id,
                          })
                        }
                      />
                    ))}
                  </InputDropdown>,
                ])}
                childrenClassName="flex flex-row items-end gap-[24px]"
              />
            </OptionGroup>
          </div>
        </div>
      )}
    </div>
  );
};
