import React, { useState } from "react";
import { APIResponse } from "@prequel/react";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { reportToSentry } from "app/lib/errors/sentry";

type TestConnectionResult = {
  type?: "success" | "error";
  message: string;
  loading: boolean;
};

type TestConnectionProps = {
  testConnection: () => Promise<APIResponse<{ status: string }>>;
  validateForm: () => boolean;
  onSuccess: (success: boolean) => void;
};

export const TestConnection = ({
  testConnection,
  validateForm,
  onSuccess,
}: TestConnectionProps) => {
  const [testResult, setTestResult] = useState<TestConnectionResult>({
    message: "",
    loading: false,
  });

  async function testDestinationConnection() {
    if (!validateForm()) return;

    setTestResult({
      message: "",
      loading: true,
    });

    const { data, message } = await testConnection();
    if (data?.status === "success") {
      setTestResult({
        message: "Connection test successful.",
        type: "success",
        loading: false,
      });

      onSuccess(true);
    } else {
      setTestResult({
        message: message || "Connection test failed.",
        type: "error",
        loading: false,
      });

      reportToSentry(new Error(message));
    }
  }

  return (
    <Card
      wrapContents={false}
      title="Test Connection to your destination"
      loading={testResult.loading}
    >
      Testing the connection will create the specified schema if it doesn't
      already exist. Running this test is required.
      <div className="mt-16">
        <Button
          theme="primary"
          disabled={testResult.loading}
          text="Test Connection"
          onClick={testDestinationConnection}
        />
      </div>
      {testResult.message && (
        <div className="mt-8 flex items-center">
          <Icon
            icon={testResult.type === "success" ? "checkCircle" : "xCircle"}
            className="mr-4 shrink-0"
          />
          <div className="text-sm">{testResult.message}</div>
        </div>
      )}
    </Card>
  );
};
