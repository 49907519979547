import React from "react";
import { Tooltip, Icon, AvatarWithName, Body } from "design-system";

import { CustomerPlanInfo, useCustomerPlan } from "../../context";
import { DeprecatedWizardFullPage } from "components/deprecated/Wizard";
import { DeprecatedCards } from "components/deprecated/Cards";
import { renderDate } from "lib/time";
import {
  AddPlanToCustomerDataQuery,
  usePlanCustomerCountsQuery,
} from "../../queries.graphql";

export const selectingPlanIsDone = (customerPlan: CustomerPlanInfo) => {
  return !!customerPlan.Plan;
};

interface SelectPlanProps {
  plans: AddPlanToCustomerDataQuery["plans"];
}
export const SelectPlan: React.FC<SelectPlanProps> = (props) => {
  const [customerPlan, setCustomerPlan] = useCustomerPlan();
  const { data } = usePlanCustomerCountsQuery();

  const planCustomerCounts: Record<string, number> = {};
  if (data) {
    for (const plan of data.plans) {
      planCustomerCounts[plan.id] = plan.customer_count;
    }
  }

  return (
    <DeprecatedWizardFullPage>
      <DeprecatedCards
        loading={false}
        cards={props.plans.map((plan) => {
          const isSelected = customerPlan.Plan?.id === plan.id;
          return {
            onClick: () => {
              setCustomerPlan({
                ...customerPlan,
                Plan: { id: plan.id, name: plan.name },
                trialSpec: undefined,
                planPreviewed: false,
                finalPlanPreviewed: false,
                customPricing: undefined,
              });
            },
            id: plan.id,
            title: plan.name,
            subtitle: <Body level={2}>{plan.id}</Body>,
            content: plan.description,
            badge: isSelected ? (
              <Icon
                icon="checkmarkCircle"
                className="bg-deprecated-primary-50 text-deprecated-primary-600"
              />
            ) : undefined,
            statistics: [
              {
                icon: "cart",
                value: plan.PricedProducts_aggregate.aggregate?.count ?? 0,
                label: "product",
              },
              ...(planCustomerCounts[plan.id] !== undefined
                ? [
                    {
                      icon: "people" as const,
                      value: planCustomerCounts[plan.id] ?? 0,
                      label: "customer",
                    },
                  ]
                : []),
            ],
            footer:
              plan.Actor != null ? (
                <Tooltip
                  content={
                    <>
                      Created by {plan.Actor.name}
                      <br />
                      {renderDate(new Date(plan.created_at), {
                        isUtc: false,
                      })}
                    </>
                  }
                >
                  <AvatarWithName {...plan.Actor} />
                </Tooltip>
              ) : undefined,
            className: isSelected
              ? "text-deprecated-primary-600 bg-deprecated-primary-50"
              : undefined,
          };
        })}
      />
    </DeprecatedWizardFullPage>
  );
};
