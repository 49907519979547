/* f214aa3cb26cfee36fa277bc116630b4c1705feb
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneratePrequelAuthTokenMutationVariables = Types.Exact<{
  applicationOrigin: Types.Scalars['String'];
}>;


export type GeneratePrequelAuthTokenMutation = { __typename?: 'Mutation', generate_prequel_auth_token: { __typename?: 'PrequelAuthTokenResponse', token_string: string, recipient_id: string } };

export type GeneratePrequelAuthTokenForDestinationMutationVariables = Types.Exact<{
  applicationOrigin: Types.Scalars['String'];
  destinationJson?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GeneratePrequelAuthTokenForDestinationMutation = { __typename?: 'Mutation', generate_prequel_auth_token: { __typename?: 'PrequelAuthTokenResponse', token_string: string, recipient_id: string } };

export type GetClientQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string, name: string }> };


export const GeneratePrequelAuthTokenDocument = gql`
    mutation GeneratePrequelAuthToken($applicationOrigin: String!) {
  generate_prequel_auth_token: generate_prequel_auth_token_v1(
    application_origin: $applicationOrigin
  ) {
    token_string
    recipient_id
  }
}
    `;
export type GeneratePrequelAuthTokenMutationFn = Apollo.MutationFunction<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;

/**
 * __useGeneratePrequelAuthTokenMutation__
 *
 * To run a mutation, you first call `useGeneratePrequelAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePrequelAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePrequelAuthTokenMutation, { data, loading, error }] = useGeneratePrequelAuthTokenMutation({
 *   variables: {
 *      applicationOrigin: // value for 'applicationOrigin'
 *   },
 * });
 */
export function useGeneratePrequelAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>(GeneratePrequelAuthTokenDocument, options);
      }
export type GeneratePrequelAuthTokenMutationHookResult = ReturnType<typeof useGeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationResult = Apollo.MutationResult<GeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationOptions = Apollo.BaseMutationOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;
export const GeneratePrequelAuthTokenForDestinationDocument = gql`
    mutation GeneratePrequelAuthTokenForDestination($applicationOrigin: String!, $destinationJson: String) {
  generate_prequel_auth_token: generate_prequel_auth_token_v1(
    application_origin: $applicationOrigin
    destination_json: $destinationJson
  ) {
    token_string
    recipient_id
  }
}
    `;
export type GeneratePrequelAuthTokenForDestinationMutationFn = Apollo.MutationFunction<GeneratePrequelAuthTokenForDestinationMutation, GeneratePrequelAuthTokenForDestinationMutationVariables>;

/**
 * __useGeneratePrequelAuthTokenForDestinationMutation__
 *
 * To run a mutation, you first call `useGeneratePrequelAuthTokenForDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePrequelAuthTokenForDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePrequelAuthTokenForDestinationMutation, { data, loading, error }] = useGeneratePrequelAuthTokenForDestinationMutation({
 *   variables: {
 *      applicationOrigin: // value for 'applicationOrigin'
 *      destinationJson: // value for 'destinationJson'
 *   },
 * });
 */
export function useGeneratePrequelAuthTokenForDestinationMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePrequelAuthTokenForDestinationMutation, GeneratePrequelAuthTokenForDestinationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePrequelAuthTokenForDestinationMutation, GeneratePrequelAuthTokenForDestinationMutationVariables>(GeneratePrequelAuthTokenForDestinationDocument, options);
      }
export type GeneratePrequelAuthTokenForDestinationMutationHookResult = ReturnType<typeof useGeneratePrequelAuthTokenForDestinationMutation>;
export type GeneratePrequelAuthTokenForDestinationMutationResult = Apollo.MutationResult<GeneratePrequelAuthTokenForDestinationMutation>;
export type GeneratePrequelAuthTokenForDestinationMutationOptions = Apollo.BaseMutationOptions<GeneratePrequelAuthTokenForDestinationMutation, GeneratePrequelAuthTokenForDestinationMutationVariables>;
export const GetClientDocument = gql`
    query GetClient {
  Client {
    id
    name
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientQuery(baseOptions?: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;