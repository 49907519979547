/* 8fff8a7fb4513a598e63b4920b4fc5932256f315
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebhooksQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type WebhooksQuery = { __typename?: 'Query', Webhook: Array<{ __typename?: 'Webhook', id: string, name: string, url: string, created_at: string, creator: { __typename?: 'Actor', name: string, id: string, deprecated_at: string | null } | null }> };

export type InsertWebhookMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  url: Types.Scalars['String'];
}>;


export type InsertWebhookMutation = { __typename?: 'Mutation', create_webhook: { __typename?: 'Webhook', id: string, secret: string } };

export type DeleteWebhookMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DeleteWebhookMutation = { __typename?: 'Mutation', delete_Webhook: { __typename?: 'Webhook_mutation_response', affected_rows: number } | null };

export type TestWebhooksMutationVariables = Types.Exact<{
  message: Types.Scalars['String'];
}>;


export type TestWebhooksMutation = { __typename?: 'Mutation', test_webhooks: { __typename?: 'MutationResponse', success: boolean } };


export const WebhooksDocument = gql`
    query Webhooks($environment_type: EnvironmentTypeEnum_enum!) {
  Webhook(where: {environment_type: {_eq: $environment_type}}) {
    id
    name
    url
    created_at
    creator {
      name
      id
      deprecated_at
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useWebhooksQuery__
 *
 * To run a query within a React component, call `useWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhooksQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useWebhooksQuery(baseOptions: Apollo.QueryHookOptions<WebhooksQuery, WebhooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebhooksQuery, WebhooksQueryVariables>(WebhooksDocument, options);
      }
export function useWebhooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebhooksQuery, WebhooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebhooksQuery, WebhooksQueryVariables>(WebhooksDocument, options);
        }
export type WebhooksQueryHookResult = ReturnType<typeof useWebhooksQuery>;
export type WebhooksLazyQueryHookResult = ReturnType<typeof useWebhooksLazyQuery>;
export type WebhooksQueryResult = Apollo.QueryResult<WebhooksQuery, WebhooksQueryVariables>;
export const InsertWebhookDocument = gql`
    mutation InsertWebhook($name: String!, $url: String!) {
  create_webhook(name: $name, url: $url) {
    id
    secret
    __environment_type: environment_type
  }
}
    `;
export type InsertWebhookMutationFn = Apollo.MutationFunction<InsertWebhookMutation, InsertWebhookMutationVariables>;

/**
 * __useInsertWebhookMutation__
 *
 * To run a mutation, you first call `useInsertWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWebhookMutation, { data, loading, error }] = useInsertWebhookMutation({
 *   variables: {
 *      name: // value for 'name'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInsertWebhookMutation(baseOptions?: Apollo.MutationHookOptions<InsertWebhookMutation, InsertWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertWebhookMutation, InsertWebhookMutationVariables>(InsertWebhookDocument, options);
      }
export type InsertWebhookMutationHookResult = ReturnType<typeof useInsertWebhookMutation>;
export type InsertWebhookMutationResult = Apollo.MutationResult<InsertWebhookMutation>;
export type InsertWebhookMutationOptions = Apollo.BaseMutationOptions<InsertWebhookMutation, InsertWebhookMutationVariables>;
export const DeleteWebhookDocument = gql`
    mutation DeleteWebhook($id: String) {
  delete_Webhook(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteWebhookMutationFn = Apollo.MutationFunction<DeleteWebhookMutation, DeleteWebhookMutationVariables>;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWebhookMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWebhookMutation, DeleteWebhookMutationVariables>(DeleteWebhookDocument, options);
      }
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>;
export type DeleteWebhookMutationResult = Apollo.MutationResult<DeleteWebhookMutation>;
export type DeleteWebhookMutationOptions = Apollo.BaseMutationOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>;
export const TestWebhooksDocument = gql`
    mutation TestWebhooks($message: String!) {
  test_webhooks(message: $message) {
    success
  }
}
    `;
export type TestWebhooksMutationFn = Apollo.MutationFunction<TestWebhooksMutation, TestWebhooksMutationVariables>;

/**
 * __useTestWebhooksMutation__
 *
 * To run a mutation, you first call `useTestWebhooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestWebhooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testWebhooksMutation, { data, loading, error }] = useTestWebhooksMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useTestWebhooksMutation(baseOptions?: Apollo.MutationHookOptions<TestWebhooksMutation, TestWebhooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestWebhooksMutation, TestWebhooksMutationVariables>(TestWebhooksDocument, options);
      }
export type TestWebhooksMutationHookResult = ReturnType<typeof useTestWebhooksMutation>;
export type TestWebhooksMutationResult = Apollo.MutationResult<TestWebhooksMutation>;
export type TestWebhooksMutationOptions = Apollo.BaseMutationOptions<TestWebhooksMutation, TestWebhooksMutationVariables>;