import {
  CommitType,
  ExternalCommitType,
} from "types/generated-graphql/__types__";
import { Schema } from "../../../CreateAndEdit/Schema";
import { upsertById } from "../../lib/upsertById";
import { useCommitFlyoverController } from ".";
import { DefaultTimeframe } from "../../../CreateAndEdit/lib/DefaultTimeframe";
import { v4 as uuid } from "uuid";
import { z } from "zod";
import { CreditType } from "app/types/credit-types";

export type PrepaidCommitTermsController = ReturnType<
  typeof usePrepaidCommitTermsController
>;
export const usePrepaidCommitTermsController = useCommitFlyoverController.child(
  Schema.PrepaidCommit,
  {
    read(
      parent,
      level: "customer" | "contract",
      externalType: ExternalCommitType,
      defaultCreditType: CreditType,
    ) {
      const commit = parent.get("commit");
      return {
        ...(commit?.type === CommitType.Prepaid
          ? {
              ...commit,
              ...(externalType === ExternalCommitType.Credit
                ? {
                    billingScheduleFrequency: "none",
                    billingSchedule: {
                      type: "fixed",
                      items: [],
                    },
                    billingScheduleCreditTypeId:
                      commit.accessScheduleCreditTypeId ?? defaultCreditType.id,
                  }
                : {}),
              accessScheduleCreditTypeId:
                commit.accessScheduleCreditTypeId ?? defaultCreditType.id,
            }
          : {}),
        level,
        externalType,
      };
    },
    write(child) {
      return {
        commit: child.getUnvalidatedInputs(),
      };
    },
  },
);

export type PrepaidCommitAccessScheduleController = ReturnType<
  typeof usePrepaidCommitAccessScheduleController
>;

export const usePrepaidCommitAccessScheduleController =
  usePrepaidCommitTermsController.child(
    z.object({
      accessSchedule: z.array(Schema.PrepaidCommitAccessScheduleItem),
    }),
    {
      read(parent, timeframe: DefaultTimeframe) {
        const accessSchedule = parent.get("accessSchedule") ?? [];
        return {
          accessSchedule:
            accessSchedule.length > 0
              ? accessSchedule
              : [
                  {
                    id: uuid(),
                    date: timeframe.startingAt,
                    endDate: timeframe.endingBefore,
                  },
                ],
        };
      },
      write(child) {
        return child.getUnvalidatedInputs();
      },
    },
  );

export const usePrepaidCommitAccessScheduleItemController =
  usePrepaidCommitAccessScheduleController.child(
    Schema.PrepaidCommitAccessScheduleItem,
    {
      read(parent, id: string, timeframe: DefaultTimeframe) {
        const item = parent
          .get("accessSchedule")
          ?.find((item) => item.id === id);
        return {
          ...item,
          date: item?.date ?? timeframe.startingAt,
          endDate: item?.endDate ?? timeframe.endingBefore,
        };
      },
      write(child, parent) {
        return {
          accessSchedule: upsertById(
            parent.get("accessSchedule") ?? [],
            child.getUnvalidatedInputs(),
          ),
        };
      },
    },
  );
