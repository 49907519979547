import React, { useState } from "react";

import { DeprecatedPopup } from "components/deprecated/Popup";
import { useUpdateCommitScheduleMutation } from "./data.graphql";
import { Body, DateInput } from "design-system";
import { Button } from "components/Button";
import { FormController } from "app/lib/FormController";
import { Schema } from "./Schema";
import { useSnackbar } from "components/deprecated/Snackbar";
import { CommitCardCommit } from "./CommitCard";

type PostpaidCommitDetails = CommitCardCommit & {
  __typename: "PostpaidCommit";
};

interface UpdatePostpaidEndModalProps {
  onClose: () => void;
  commit: PostpaidCommitDetails;
}

const useUpdatePostpaidEndModalController = FormController.createHook(
  Schema.ChangeEndDateInput.superRefine((obj, ctx) => {
    if (obj.endAccessAt && obj.endInvoicesAt) {
      if (obj.endInvoicesAt < obj.endAccessAt) {
        ctx.addIssue({
          code: "invalid_date",
          message: "Cannot invoice a postpaid commit before it ends",
          path: ["endInvoicesAt"],
          fatal: true,
        });
      }
    }
  }),
  {
    init(commit: PostpaidCommitDetails) {
      return {
        endAccessAt: new Date(
          commit.access_schedule.schedule_items[0].end_date,
        ).toISOString(),
        endInvoicesAt: new Date(
          commit.postpaid_commit_invoice_schedule_id.schedule_items[0].date,
        ).toISOString(),
      };
    },
  },
);

export const UpdatePostpaidEndModal: React.FC<UpdatePostpaidEndModalProps> = ({
  onClose,
  commit,
}) => {
  const pushMessage = useSnackbar();
  const ctrl = useUpdatePostpaidEndModalController(commit);
  const [updateCommitSchedule, updateResult] =
    useUpdateCommitScheduleMutation();
  const [accessError, setAccessError] = useState<string | undefined>();
  const [invoiceError, setInvoiceError] = useState<string | undefined>();

  const onSubmit = FormController.useSubmitHandler(ctrl, async (valid) => {
    try {
      setInvoiceError(undefined);
      setAccessError(undefined);

      const update = {
        invoice_schedule: ctrl.get("endInvoicesAt")
          ? {
              update: [
                {
                  id: commit.postpaid_commit_invoice_schedule_id
                    .schedule_items[0].id,
                  date: ctrl.get("endInvoicesAt"),
                },
              ],
            }
          : undefined,
        access_schedule: ctrl.get("endAccessAt")
          ? {
              update: [
                {
                  id: commit.access_schedule.schedule_items[0].id,
                  end_date: ctrl.get("endAccessAt"),
                },
              ],
            }
          : undefined,
      };

      const previewResult = await updateCommitSchedule({
        variables: {
          commitID: commit.id,
          customerID: commit.customer.id,
          contractID: commit.contract?.id,
          amendmentID: commit.amendment_id ?? undefined,
          update,
          preview: true,
        },
      });

      const preview = previewResult.data?.update_commit_schedule;
      if (!preview) {
        pushMessage({
          content: "An unknown error occurred",
          type: "error",
        });
        return;
      }

      if (preview.finalized_usage_invoices?.length) {
        setAccessError(
          "Invalid update to commit's access end date because it would impact an already finalized invoice",
        );
        return;
      }
      if (
        preview.finalized_postpaid_trueup_invoices?.length ||
        preview.finalized_scheduled_invoices?.length
      ) {
        setInvoiceError(
          "Cannot change this commit's invoice date because it is already finalized",
        );
        return;
      }

      const result = await updateCommitSchedule({
        variables: {
          commitID: commit.id,
          customerID: commit.customer.id,
          contractID: commit.contract?.id,
          amendmentID: commit.amendment_id ?? undefined,
          update,
          preview: false,
        },
        update(cache) {
          cache.evict({
            fieldName: "Customer",
          });
          cache.evict({
            fieldName: "Customer_by_pk",
          });
          cache.gc();
        },
      });

      if (!result.data?.update_commit_schedule.success) {
        pushMessage({
          content: "An unknown error occurred",
          type: "error",
        });
        return;
      }

      pushMessage({
        content: "Commit updated successfully",
        type: "success",
      });
      onClose();
    } catch (e) {
      pushMessage({
        content: "Commit update failed",
        type: "error",
      });
    }
  });

  return (
    <DeprecatedPopup
      isOpen
      onRequestClose={onClose}
      title="Change postpaid commit end date"
      actions={
        <>
          <Button onClick={onClose} text="Cancel" theme="linkGray" />
          <Button
            onClick={onSubmit}
            disabled={updateResult.loading || !ctrl.appearsValid()}
            text="Save"
            theme="primary"
            type="submit"
          />
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <input type="submit" className="hidden" />
        <Body level={2}>
          Update the access and invoice end for this postpaid commit.
        </Body>
        <div className="grid gap-12">
          <DateInput
            {...ctrl.props.DateInput("endAccessAt", {
              name: "End usage at",
              minDate: commit.access_schedule.schedule_items[0].date,
              tooltip: `The last day for usage on this postpaid commit.`,
            })}
            {...(accessError && { error: accessError })}
          />
          <DateInput
            {...ctrl.props.DateInput("endInvoicesAt", {
              name: "Create invoice at",
              minDate: ctrl.get("endAccessAt"),
              tooltip: `The date to create the true-up invoice for this postpaid commit.`,
            })}
            {...(invoiceError && { error: invoiceError })}
          />
        </div>
      </form>
    </DeprecatedPopup>
  );
};
