import React from "react";
import { useRequiredParam } from "app/lib/routes/params";
import { useFetchCustomerAlertsQuery } from "./queries.graphql";
import { EmptyState } from "components/EmptyState";
import { AlertsTable } from "components/AlertsTable";
import { GatedButton } from "components/GatedButton";
import { CreateAlertDocument } from "app/pages/Connections/tabs/Alerts/NewAlert/queries.graphql";
import { Button } from "components/Button";
import { Badge } from "components/Badge";
import { CustomerAlertCompositeStatusEnum } from "types/generated-graphql/__types__";

export const CustomerAlerts: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const { data, loading } = useFetchCustomerAlertsQuery({
    variables: {
      customer_id: customerId,
    },
  });

  const emptyState = (
    <EmptyState
      icon="bell03"
      mainText="This customer doesn't have any alerts yet"
      supportingText="Use alerts to manage product access, gate customer spend, set usage thresholds, and monitor commitment drawdowns."
      actions={[
        <Button
          linkTo="https://docs.metronome.com/manage-product-access/create-manage-alerts/"
          isExternalLink
          text="Learn more"
          theme="tertiary"
          leadingIcon="linkExternal01"
        />,
        <GatedButton
          linkTo="/connections/alerts/new"
          doc={CreateAlertDocument}
          text="Create alert"
          theme="primary"
          leadingIcon="plus"
        />,
      ]}
    />
  );

  return (
    <AlertsTable
      loading={loading}
      emptyState={emptyState}
      alertsData={
        data?.Customer_by_pk?.alerts.map((a) => ({
          customer_status: a.customer_status ?? undefined,
          ...a.alert,
        })) ?? []
      }
      additionalColumns={[
        {
          id: "customer_status",
          header: "Alert status",
          cell: (props) => {
            const customerStatus = props.getValue();
            return (
              <Badge
                theme={
                  customerStatus === CustomerAlertCompositeStatusEnum.InAlarm
                    ? "error"
                    : "azure-blue"
                }
                label={
                  customerStatus ?? CustomerAlertCompositeStatusEnum.Evaluating
                }
              />
            );
          },
          accessorFn: (row) => {
            return row.customer_status;
          },
        },
      ]}
    />
  );
};
