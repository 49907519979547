/* c0bf366334d834e792e7ab98b44f586c63b67fc9
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchCustomerAlertsQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
}>;


export type FetchCustomerAlertsQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, alerts: Array<{ __typename?: 'CustomerAlertComposite', id: string | null, customer_status: Types.CustomerAlertCompositeStatusEnum | null, alert: { __typename?: 'Alert', id: string, name: string, threshold: string, alert_type: Types.AlertTypeEnum_Enum, filters: object | null, Customer: { __typename?: 'Customer', id: string, name: string } | null, Plan: { __typename?: 'Plan', id: string, name: string } | null, CreditType: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }, BillableMetric: { __typename?: 'BillableMetric', id: string, name: string } | null } }> } | null };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type FetchAlertCustomerCountQueryVariables = Types.Exact<{
  alert_id: Types.Scalars['uuid'];
}>;


export type FetchAlertCustomerCountQuery = { __typename?: 'Query', Alert_by_pk: { __typename?: 'Alert', id: string, customer_count: number, Plan: { __typename?: 'Plan', id: string, name: string } | null, Customer: { __typename?: 'Customer', id: string, name: string } | null } | null };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const FetchCustomerAlertsDocument = gql`
    query FetchCustomerAlerts($customer_id: uuid!) {
  Customer_by_pk(id: $customer_id) {
    id
    alerts(types: ACTIVE) {
      id
      customer_status
      alert {
        id
        name
        threshold
        alert_type
        filters
        Customer {
          id
          name
          __environment_type: environment_type
        }
        Plan {
          id
          name
          __environment_type: environment_type
        }
        CreditType {
          ...CreditType
          __environment_type: environment_type
        }
        BillableMetric {
          id
          name
          __environment_type: environment_type
        }
        __environment_type: environment_type
      }
    }
    __environment_type: environment_type
  }
}
    ${CreditTypeFragmentDoc}`;

/**
 * __useFetchCustomerAlertsQuery__
 *
 * To run a query within a React component, call `useFetchCustomerAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCustomerAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCustomerAlertsQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useFetchCustomerAlertsQuery(baseOptions: Apollo.QueryHookOptions<FetchCustomerAlertsQuery, FetchCustomerAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCustomerAlertsQuery, FetchCustomerAlertsQueryVariables>(FetchCustomerAlertsDocument, options);
      }
export function useFetchCustomerAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCustomerAlertsQuery, FetchCustomerAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCustomerAlertsQuery, FetchCustomerAlertsQueryVariables>(FetchCustomerAlertsDocument, options);
        }
export type FetchCustomerAlertsQueryHookResult = ReturnType<typeof useFetchCustomerAlertsQuery>;
export type FetchCustomerAlertsLazyQueryHookResult = ReturnType<typeof useFetchCustomerAlertsLazyQuery>;
export type FetchCustomerAlertsQueryResult = Apollo.QueryResult<FetchCustomerAlertsQuery, FetchCustomerAlertsQueryVariables>;
export const FetchAlertCustomerCountDocument = gql`
    query FetchAlertCustomerCount($alert_id: uuid!) {
  Alert_by_pk(id: $alert_id) {
    id
    customer_count
    Plan {
      id
      name
      __environment_type: environment_type
    }
    Customer {
      id
      name
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useFetchAlertCustomerCountQuery__
 *
 * To run a query within a React component, call `useFetchAlertCustomerCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAlertCustomerCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAlertCustomerCountQuery({
 *   variables: {
 *      alert_id: // value for 'alert_id'
 *   },
 * });
 */
export function useFetchAlertCustomerCountQuery(baseOptions: Apollo.QueryHookOptions<FetchAlertCustomerCountQuery, FetchAlertCustomerCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAlertCustomerCountQuery, FetchAlertCustomerCountQueryVariables>(FetchAlertCustomerCountDocument, options);
      }
export function useFetchAlertCustomerCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAlertCustomerCountQuery, FetchAlertCustomerCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAlertCustomerCountQuery, FetchAlertCustomerCountQueryVariables>(FetchAlertCustomerCountDocument, options);
        }
export type FetchAlertCustomerCountQueryHookResult = ReturnType<typeof useFetchAlertCustomerCountQuery>;
export type FetchAlertCustomerCountLazyQueryHookResult = ReturnType<typeof useFetchAlertCustomerCountLazyQuery>;
export type FetchAlertCustomerCountQueryResult = Apollo.QueryResult<FetchAlertCustomerCountQuery, FetchAlertCustomerCountQueryVariables>;