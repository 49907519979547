import React, { useContext, useEffect } from "react";

import { useNavigate } from "app/lib/useNavigate";
import { useOptionalParam } from "app/lib/routes/params";
import { useSearchParam } from "app/lib/routes/useSearchParam";
import { useRequiredParam } from "app/lib/routes/params";

import { CustomerContractLayout } from "../CustomerContractLayout";
import {
  CommitCard,
  CommitCardCommit,
  CommitTab,
  parseCommitTab,
} from "./CommitCard";
import { EmptyState } from "components/EmptyState";
import { AsyncCommitUsage, useAsyncCommitUsage } from "../Usage";
import { useApolloResp, ApolloResp } from "app/pages/Contracts/lib/ApolloResp";
import { ContractCommitsQuery, useContractCommitsQuery } from "./data.graphql";
import { getAllCommits } from "./findCommit";
import { AppShell, AppShellContext } from "components/AppShell";
import { Breadcrumbs } from "app/lib/breadcrumbs";
import { ContractOrPlan } from "app/pages/Contracts/lib/ContractOrPlan";
import { Commit } from "app/pages/Contracts/lib/Commit";
import { dayjs } from "lib/dayjs";
import { IntersectionObserver } from "components/IntersectionObserver";
import {
  useLazyContractCommitLedgerFetcher,
  CommitLedgerFetcher,
} from "hooks/useLazyCommitLedgerFetcher";

export const CustomerContractCommits: React.FC = () => {
  const customerId = useRequiredParam("customerId");
  const contractId = useRequiredParam("contractId");
  const commitId = useOptionalParam("commitId");

  const ledgerFetcher = useLazyContractCommitLedgerFetcher(
    customerId,
    contractId,
  );
  const [activeTabs, setActiveTabs] = React.useState<Record<string, CommitTab>>(
    {},
  );
  const activeTabParam = parseCommitTab(useSearchParam("tab")[0]);
  const req = useApolloResp(
    useContractCommitsQuery({
      variables: {
        customerId,
        contractId,
      },
    }),
  );
  const asyncUsage = useAsyncCommitUsage({
    contractId,
    customerId,
  });

  useEffect(() => {
    if (commitId && activeTabParam) {
      setActiveTabs((prevTabs) => ({
        ...prevTabs,
        [commitId]: activeTabParam,
      }));
    }
  }, [commitId, activeTabParam]);

  useEffect(() => {
    if (commitId) {
      ledgerFetcher.startLedgerRequests([commitId]);
    }
  }, [commitId]);

  if (!commitId) {
    return (
      <LegacyDesignCustomerContractCommits
        customerId={customerId}
        contractId={contractId}
        commitId={commitId}
        req={req}
        asyncUsage={asyncUsage}
        activeTabs={activeTabs}
        setActiveTabs={setActiveTabs}
        ledgerFetcher={ledgerFetcher}
      />
    );
  }

  if (req.state === "loading") {
    return (
      <AppShell
        title={{ state: "loading" }}
        headerProps={{
          breadcrumbs: Breadcrumbs.from(
            { label: "Customers", routePath: "/customers" },
            { type: "loading", label: "" },
            { label: "Contracts" },
            { type: "loading", label: "" },
            {
              label: "Balances",
              routePath: `/customers/${customerId}/contracts/${contractId}/commits-and-credits`,
            },
          ),
        }}
      />
    );
  }

  const allCommits =
    req.state === "success" && req.customer.contract
      ? getAllCommits(req.customer.contract)
      : [];
  const commit = commitId
    ? allCommits.find((c) => c.id === commitId)
    : undefined;

  const content = commit ? (
    <CommitCard
      key={commit.id}
      commit={commit}
      activeTab={activeTabs[commit.id] || "ledger"}
      asyncUsage={asyncUsage}
      onTabChange={(tab) => {
        setActiveTabs((prevTabs) => ({
          ...prevTabs,
          [commit.id]: tab,
        }));
      }}
      ledgerQuery={ledgerFetcher.getLedgerQuery(commit.id)}
    />
  ) : (
    <EmptyState
      icon="bankNote03"
      mainText="Commit not found"
      supportingText="The requested commit does not exist."
    />
  );

  return (
    <AppShell
      title={commit ? Commit.getName(commit, dayjs()) : "Balances"}
      headerProps={{
        breadcrumbs:
          req.state === "success" && req.customer.contract
            ? Breadcrumbs.from(
                { label: "Customers", routePath: "/customers" },
                {
                  label: req.customer.name ?? "",
                  routePath: `/customers/${customerId}`,
                },
                { label: "Contracts" },
                {
                  label: ContractOrPlan.getName(req.customer.contract),
                  routePath: `/customers/${customerId}/contracts/${contractId}`,
                },
                {
                  label: "Balances",
                  routePath: `/customers/${customerId}/contracts/${contractId}/commits-and-credits`,
                },
              )
            : undefined,
      }}
    >
      {content}
    </AppShell>
  );
};

type LegacyDesignCustomerContract = ContractCommitsQuery & {
  customer: NonNullable<ContractCommitsQuery["customer"]>;
};

const LegacyDesignCustomerContractCommits: React.FC<{
  customerId: string;
  contractId: string;
  commitId?: string;
  req: ApolloResp<LegacyDesignCustomerContract>;
  asyncUsage: any; // Replace with proper type
  activeTabs: Record<string, CommitTab>;
  setActiveTabs: React.Dispatch<
    React.SetStateAction<Record<string, CommitTab>>
  >;
  ledgerFetcher: CommitLedgerFetcher;
}> = ({
  customerId,
  contractId,
  commitId,
  req,
  asyncUsage,
  activeTabs,
  setActiveTabs,
  ledgerFetcher,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      commitId &&
      req.state === "success" &&
      req.customer &&
      req.customer.contract &&
      !getAllCommits(req.customer.contract).some((c) => c.id === commitId)
    ) {
      navigate(
        `/customers/${customerId}/contracts/${contractId}/commits-and-credits`,
        { replace: true },
      );
    }
  }, [commitId, req, customerId, contractId, navigate]);

  useEffect(() => {
    if (req.state === "success" && req.customer && req.customer.contract) {
      const allCommits = getAllCommits(req.customer.contract);
      // Optimistically fetch the ledger for the first 20 commits. 20 was arbitrarily chosen.
      ledgerFetcher.startLedgerRequests(
        allCommits.slice(0, 20).map((c) => c.id),
      );
    }
  }, [req, ledgerFetcher]);

  return (
    <CustomerContractLayout
      rootReq={req}
      disableContainerScroll={({ contract }) =>
        getAllCommits(contract).length === 0
      }
      content={({ contract }) => {
        const allCommits = getAllCommits(contract);
        return !allCommits?.length ? (
          <EmptyState
            icon="bankNote03"
            mainText="No commits"
            supportingText="This contract has not been issued a commit."
          />
        ) : (
          allCommits.map((commit) => (
            <CommitCardWrapper
              key={commit.id}
              commit={commit}
              activeTab={activeTabs[commit.id] || "ledger"}
              asyncUsage={asyncUsage}
              ledgerFetcher={ledgerFetcher}
              commitId={commitId}
              onTabChange={(tab) => {
                setActiveTabs((prevTabs) => ({
                  ...prevTabs,
                  [commit.id]: tab,
                }));
              }}
            />
          ))
        );
      }}
    />
  );
};

const CommitCardWrapper: React.FC<{
  commitId?: string;
  commit: CommitCardCommit;
  activeTab: CommitTab;
  asyncUsage?: AsyncCommitUsage;
  ledgerFetcher: CommitLedgerFetcher;
  onTabChange: (tab: CommitTab) => void;
}> = ({
  commit,
  activeTab,
  ledgerFetcher,
  commitId,
  asyncUsage,
  onTabChange,
}) => {
  const { scrollContainerRef } = useContext(AppShellContext);
  return (
    <IntersectionObserver<HTMLDivElement>
      key={commit.id}
      root={scrollContainerRef.current}
      // Load the ledger for commits outside the viewport.
      rootMargin="400% 0px 400% 0px"
      onIntersectChange={(isIntersecting) => {
        if (isIntersecting) {
          ledgerFetcher.queueLedgerRequest(commit.id);
        }
      }}
    >
      {(ref) => (
        <CommitCard
          ref={ref}
          commit={commit}
          focus={commit.id === commitId}
          activeTab={activeTab}
          asyncUsage={asyncUsage}
          ledgerQuery={ledgerFetcher.getLedgerQuery(commit.id)}
          onTabChange={onTabChange}
        />
      )}
    </IntersectionObserver>
  );
};
