import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "twMerge";
import { IconName, Icon } from "../Icon";
import { Tooltip } from "../Tooltip";
import { Button, ButtonProps } from "../Button";
import { IconButton, IconButtonProps } from "../IconButton";
import { DropdownProps, Dropdown } from "../Dropdown";

const SIZE_VARIANTS = {
  sm: "h-[36px] py-sm, px-lg",
  md: "h-[40px] py-md px-lg",
  lg: "h-[44px] py-[10px] px-[14px]",
};

type IconInput = {
  variant: "icon";
  /** Add an icon from the library to lead next on the Input */
  icon: IconName;
};

/**
 * Partial implementation of DropdownInput.
 * This probably could use some more styling consistency.
 */
type DropdownInput = {
  variant: "dropdown";
  /** Provide a trailing dropdown for the Input that will add more context for a field */
  dropdownOptions: DropdownProps;
  /** Add an icon from the library to lead next on the Input */
  leadingIcon?: IconName;
};

type ButtonInput<T> = {
  variant: "button";
  /**
   * Button will render at the end of the input, appearing to be one whole field.
   */
  button: Omit<ButtonProps, "onClick"> & {
    onClick: (value: T) => void;
  };
};

type IconButtonInput<T> = {
  variant: "iconButton";
  /**
   * Button will render at the end of the input, appearing to be one whole field.
   */
  button: Omit<IconButtonProps, "onClick"> & {
    onClick: (value: T) => void;
  };
  /** Add an icon from the library to lead next on the Input */
  leadingIcon?: IconName;
};

type VariantOptions<T> =
  | IconInput
  | ButtonInput<T>
  | IconButtonInput<T>
  | DropdownInput;

export type DefaultInput<T> = {
  /** Indicate that the TextInput should receive browser focus upon mounting */
  autofocus?: boolean;
  /** Customize the component with additional Tailwind classes */
  className?: string;
  /**
   * When a user provides incorrect or incomplete information in this input, set this to true.
   * Consider updating the hintText as well with context as to why the input failed.
   * */
  isInvalid?: boolean;
  /**
   * Disable the input from user interactions
   */
  disabled?: boolean;
  /** Disable the clear button for the input */
  disableClear?: boolean;
  /**
   * Help text will render a help icon on the end of the input, with a tooltip.
   * Provide help text when you need to give user additional context, and may not fit
   * as `hintText` below the Input.
   * */
  helpText?: string;
  /**
   * Hint text is a short piece of copy to render below the input as string.
   * If there is an error, provide error message here.
   */
  hintText?: string;
  /** Set to true if part of the InputDropdown, to render a dropdown icon */
  dropdownState?: "open" | "closed" | undefined;
  /** Providing this will render a label element associated with the Input */
  label?: string;
  /** Set a maximum length that the user can type into the input */
  maxlength?: number;
  /** Provide a callback to receive input changes if the input field has lost focus. */
  onBlur?: (meta: { value: T }) => void;
  /** Provide a callback to receive debounced input changes if the text is being updated by the user. */
  onChange?: (meta: { value: T }) => void;
  /** Provide a callback to receive input changes if user clicks on the Input. */
  onClick?: (meta: { value: T }) => void;
  /** Provide a callback to receive input changes if the input field has gained focus. */
  onFocus?: (meta: { value: T }) => void;
  /** Provide a callback to receive key stroke and input value when a user interacts with the input field */
  onKeyDown?: (meta: { key: string; value: T }) => void;
  /** Placeholder for the input. Add text to give a user guidance on what to add for the input. */
  placeholder?: string;
  /** Default: md - sm: 36px, md: 40px, lg: 44px */
  size?: "sm" | "md" | "lg";
  /** Pass a value as a prop to the input */
  value?: T;
  /**
   * Enhancements for the Input component:
   * Icon - renders a leading icon inside the Input
   * Button - renders a trailing button outside the Input
   * */
  variantOptions?: VariantOptions<T>;
  /** Set the input to full width */
  fullWidth?: boolean;
  onClear?: () => void;

  /**
   * required - will mark the HTML input as required and allow utilizing
   * of browser native validation
   */
  required?: boolean;
};

export type TextInputProps = DefaultInput<string> & {
  /** Input type, which may result in different aria-roles and input options */
  type?: "password" | "search" | "text" | "number";
};

/**
 * PARTIAL IMPLEMENTATION !!
 * - Numbers will be handled by a separate component
 * - Dropdown has not been implemented yet.
 *
 * Input fields allow users to enter text into a UI. They typically appear in forms and dialogs.
 * This input also provides options for adding a button to the end, tags inside,
 * or an icon at the start.
 * */
export const TextInput = forwardRef(
  (
    {
      autofocus,
      className,
      isInvalid,
      placeholder,
      helpText,
      hintText,
      disabled,
      disableClear,
      dropdownState,
      label,
      size = "md",
      type,
      maxlength,
      variantOptions,
      onBlur,
      onChange,
      onFocus,
      onClick,
      onKeyDown,
      value: valueProp,
      fullWidth = false,
      onClear,
      required,
    }: TextInputProps,
    outerRef,
  ) => {
    const [inputValue, setInputValue] = useState<string>(valueProp ?? "");
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(outerRef, () => inputRef.current, []);

    const handleInputChange = useCallback(
      (newValue: string) => {
        setInputValue(newValue);
        if (onChange) {
          onChange({ value: newValue });
        }
      },
      [onChange],
    );

    useEffect(() => {
      if (valueProp !== inputValue) {
        setInputValue(valueProp ?? "");
      }
    }, [valueProp]);

    const handleOnBlur = useCallback(
      (value: string) => {
        if (onBlur) {
          onBlur({ value });
        }
      },
      [onBlur, inputValue],
    );

    const classnames = twMerge(
      "border border-gray-200 bg-white rounded-md text-md shadow-xs flex items-center flex-1",
      SIZE_VARIANTS[size],
      disabled && "bg-gray-50",
      (variantOptions?.variant === "button" ||
        variantOptions?.variant === "dropdown" ||
        variantOptions?.variant === "iconButton") &&
        "rounded-r-none",
      className,
    );

    return (
      <div className={fullWidth ? "w-full" : ""}>
        {label && (
          <label className="mb-sm text-sm text-black" htmlFor={label}>
            {label}
          </label>
        )}
        <div
          className={twMerge(
            "flex focus-within:rounded-md focus-within:ring-4 focus-within:ring-core-jade-green/[.24]",
            isInvalid && "focus-within:ring-error-600/[.24]",
            fullWidth ? "w-full" : "max-w-[400px]",
          )}
        >
          <div
            onClick={() => inputRef?.current?.focus()}
            className={classnames}
          >
            {variantOptions?.variant === "icon" && variantOptions?.icon && (
              <Icon
                className="mr-lg flex-shrink-0"
                icon={variantOptions.icon}
                size={20}
              />
            )}
            {(variantOptions?.variant === "dropdown" ||
              variantOptions?.variant === "iconButton") &&
              variantOptions?.leadingIcon && (
                <Icon
                  className="mr-lg flex-shrink-0 text-core-slate"
                  icon={variantOptions.leadingIcon}
                  size={16}
                />
              )}
            <div className="grid-cols-[0 min-content] inline-grid flex-auto">
              <input
                autoFocus={autofocus}
                maxLength={maxlength}
                id={label}
                ref={inputRef}
                className={twMerge(
                  "h-full w-full text-black placeholder:text-gray-500 focus-within:outline-none disabled:bg-gray-50",
                  type === "search" && "searchInput",
                )}
                placeholder={placeholder}
                disabled={disabled}
                value={inputValue}
                onBlur={(e) => handleOnBlur(e.currentTarget.value)}
                onClick={() => onClick?.({ value: inputValue })}
                onChange={(e) => handleInputChange(e.currentTarget.value)}
                onFocus={(e) => onFocus?.({ value: e.currentTarget.value })}
                onKeyDown={(e) =>
                  onKeyDown?.({ key: e.key, value: e.currentTarget.value })
                }
                type={type}
                required={required}
              />
            </div>
            {inputValue && !disabled && !disableClear && (
              <IconButton
                icon="xCircle"
                theme="tertiary"
                size="sm"
                onClick={() => {
                  handleInputChange("");
                  // This will remove the focus from the input
                  setTimeout(() => {
                    if (inputRef.current) {
                      inputRef.current.blur();
                    }
                  }, 0); // Delay the blur to ensure it executes after state update
                  onClear && onClear();
                }}
                className="h-[32px] w-[32px]"
              />
            )}
            {helpText && (
              <Tooltip label={helpText}>
                <Icon icon="helpCircle" size={15} />
              </Tooltip>
            )}
            {dropdownState && (
              <Icon
                className="pointer-events-none"
                size={16}
                icon={dropdownState === "open" ? "chevronUp" : "chevronDown"}
              />
            )}
          </div>
          {variantOptions?.variant === "button" && (
            <div className="inline-flex">
              <Button
                {...variantOptions?.button}
                onClick={() => variantOptions?.button.onClick(inputValue)}
                className="rounded-l-none border-l-0"
                disabled={disabled}
              />
            </div>
          )}
          {variantOptions?.variant === "iconButton" && (
            <div className="inline-flex">
              <IconButton
                {...variantOptions?.button}
                onClick={() => variantOptions?.button.onClick(inputValue)}
                className="rounded-l-none border-l-0"
                disabled={disabled}
              />
            </div>
          )}
          {variantOptions?.variant === "dropdown" && (
            <Dropdown
              {...variantOptions.dropdownOptions}
              buttonClass="rounded-l-none border-l-0"
              disabled={disabled || variantOptions.dropdownOptions.disabled}
            />
          )}
        </div>
        {hintText && (
          <div
            className={twMerge(
              "mt-sm text-sm text-gray-600",
              isInvalid && "text-error-600",
            )}
          >
            {hintText}
          </div>
        )}
      </div>
    );
  },
);
