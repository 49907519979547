import React, { useContext } from "react";
import Decimal from "decimal.js";
import { RecurringSchedule } from "@metronome-industries/schedule-utils";

import { RoundedCurrency, USD_CREDIT_TYPE } from "app/lib/credits";
import { printDate, toDayjs } from "lib/date";
import { CreditType } from "app/types/credit-types";
import { Quantity } from "components/Quantity";
import { Schema } from "../../Schema";
import {
  ExternalCommitType,
  RecurringScheduleFrequency,
} from "types/generated-graphql/__types__";
import { usePrepaidCommitTermsController } from "./PrepaidCommitTermsControllers";
import { CommitFlyoverController, CreditTypeContext } from ".";
import { useRootCtrl } from "../../components/BillingSchedule/RootCtrl";
import { useCustomScheduleCtrl } from "../../components/BillingSchedule/CustomSchedule";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { useRecurringCtrl } from "../../components/BillingSchedule/RecurringSchedule";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import { Table } from "components/Table";

type Action = { type: "UPDATE"; value?: Schema.Types.BillingSchedule };
type State = {
  invalid: boolean;
  items?: Array<{
    date: Date;
    unitPrice: Decimal;
    quantity: Decimal;
    amount: Decimal;
  }>;
};
const initialState: State = { invalid: false };

function Reducer(state: State, action: Action): State {
  switch (action.type) {
    case "UPDATE":
      if (!action.value) {
        return { invalid: true, items: state.items };
      }

      if (action.value.type === "fixed") {
        return {
          invalid: false,
          items: action.value.items.map((item) => {
            const quantity = new Decimal(item.quantity);
            const unitPrice = new Decimal(item.unitPrice);
            return {
              date: new Date(item.date),
              quantity,
              unitPrice,
              amount: unitPrice.times(quantity),
            };
          }),
        };
      }

      try {
        return {
          invalid: false,
          items: RecurringSchedule.resolve({
            startDate: new Date(action.value.startDate),
            endDate: new Date(action.value.endDate),
            frequency: action.value.frequency,
            amountDistribution: action.value.amountDistribution,
            quantity: new Decimal(action.value.quantity),
            unitPrice: new Decimal(action.value.unitPrice),
          }).items,
        };
      } catch {
        return { invalid: true, items: state.items };
      }
  }
}

export const InvoicePreview: React.FC<{
  schedule?: Schema.Types.BillingSchedule;
  creditType: CreditType;
}> = ({ schedule, creditType }) => {
  const [{ invalid, items }, dispatch] = React.useReducer(
    Reducer,
    initialState,
  );

  React.useEffect(() => {
    dispatch({ type: "UPDATE", value: schedule });
  }, [schedule]);

  if (!items) {
    return null;
  }

  return !invalid ? (
    <Table
      title="Invoice schedule"
      columns={[
        {
          id: "date",
          accessorKey: "date",
          header: "Date",
          cell: (props) => printDate(toDayjs(props.getValue())),
        },
        {
          id: "quantity",
          accessorKey: "quantity",
          header: "Quantity",
          cell: (props) => <Quantity quantity={props.getValue()} />,
        },
        {
          id: "unitPrice",
          accessorKey: "unitPrice",
          header: "Unit price",
          cell: (props) => (
            <RoundedCurrency
              amount={props.getValue()}
              creditType={creditType}
            />
          ),
        },
        {
          id: "amount",
          accessorKey: "amount",
          header: "Amount",
          cell: (props) => (
            <RoundedCurrency
              amount={props.getValue()}
              creditType={creditType}
            />
          ),
        },
      ]}
      data={items.map((obj, index) => ({
        ...obj,
        id: index.toString(),
      }))}
    />
  ) : null;
};

export const RecurringInvoicePreview: React.FC<{
  rootCtrl: ReturnType<typeof useRootCtrl>;
  frequency: RecurringScheduleFrequency;
  creditType: CreditType;
}> = ({ rootCtrl, frequency, creditType }) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const recurringCtrl = useRecurringCtrl(rootCtrl, frequency, timeframe);

  const valid = recurringCtrl.getValid();

  return <InvoicePreview schedule={valid} creditType={creditType} />;
};

export const CustomInvoicePreview: React.FC<{
  rootCtrl: ReturnType<typeof useRootCtrl>;
  creditType: CreditType;
}> = ({ rootCtrl, creditType }) => {
  const customCtrl = useCustomScheduleCtrl(rootCtrl);

  const valid = customCtrl.getValid();

  return <InvoicePreview schedule={valid} creditType={creditType} />;
};

export const MultipleInvoicePreview: React.FC<{
  parent: CommitFlyoverController;
  level: "customer" | "contract";
  defaultCreditType: CreditType;
}> = ({ parent, level, defaultCreditType }) => {
  const creditTypeContext = useContext(CreditTypeContext);

  // Multiple invoices are only supported for prepaid commits
  const commitCtrl = usePrepaidCommitTermsController(
    parent,
    level,
    ExternalCommitType.Commit,
    defaultCreditType,
  );
  const billingScheduleCtrl = useRootCtrl(
    commitCtrl,
    creditTypeContext.fiatCreditTypes[0].id,
  );

  const creditType = findCreditType(
    billingScheduleCtrl.get("creditTypeId") ?? USD_CREDIT_TYPE.id,
    creditTypeContext.fiatCreditTypes,
  );

  const frequency = billingScheduleCtrl.get("frequency");

  switch (frequency) {
    case RecurringScheduleFrequency.Annual:
    case RecurringScheduleFrequency.Monthly:
    case RecurringScheduleFrequency.Quarterly:
    case RecurringScheduleFrequency.SemiAnnual:
      return (
        <RecurringInvoicePreview
          rootCtrl={billingScheduleCtrl}
          frequency={frequency}
          creditType={creditType}
        />
      );
    case "custom":
      return (
        <CustomInvoicePreview
          rootCtrl={billingScheduleCtrl}
          creditType={creditType}
        />
      );
    default:
      return null;
  }
};
