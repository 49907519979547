import { ButtonGroup, ButtonProps } from "components/ButtonGroup";
import { Table } from "components/Table";
import React, { useMemo, useState } from "react";
import { useNavigate } from "app/lib/useNavigate";
import { useEnvironment } from "app/lib/environmentSwitcher/context";

import CustomerCountCell from "app/pages/Offering/tabs/Plans/components/CustomerCountCell";
import pluralize from "pluralize";
import {
  DraftPlansQuery,
  PlansQuery,
  useActiveProductsQuery,
  useDraftPlansQuery,
  usePlansQuery,
} from "./queries.graphql";
import { PlanTypeEnum } from "types/generated-graphql/__types__";
import { Input, Tooltip } from "design-system";
import { Badge } from "components/Badge";
import { renderDate } from "lib/time";
import StatusCell from "app/pages/Offering/tabs/Plans/components/StatusCell";
import {
  ActionMenu,
  DraftPlanActionMenu,
} from "app/pages/Offering/tabs/Plans/components/ActionMenu";
import DeleteDraftPlanModal from "app/pages/Offering/tabs/Plans/components/DeletePlanModal";
import ArchivePlanModal from "app/pages/Offering/tabs/Plans/components/ArchivePlanModal";
import DeprecatedCopyPlanModal from "components/deprecated/CopyAssetToEnvironmentModal/CopyPlanModal";
import { DeprecatedFilter, OptionType } from "components/deprecated/Filter";
import {
  PLAN_OPTIONS,
  PLAN_OPTIONS_DEFAULT,
} from "app/pages/Offering/tabs/Plans/filters";
import { useSearchParam } from "app/lib/routes/useSearchParam";
import useDebounce from "app/lib/debounce";
import Fuse from "fuse.js";
import { GatedButton } from "components/GatedButton";
import { NewPlanDocument } from "app/pages/Offering/tabs/Plans/PlanWizards/data/queries.graphql";
import { EmptyState } from "components/EmptyState";
import { NewProductDocument } from "app/pages/Offering/tabs/Products/NewProduct/queries.graphql";
import { DeprecatedSearchTooltip } from "components/deprecated/SearchTooltip";
import { Timestamp } from "components/Timestamp";
import { dayjs } from "lib/date";
import { AvatarWithName } from "components/Avatar";
import { DocsLink } from "components/DocsLink";
import { useUIMode } from "app/lib/useUIMode";
import { OFFERING_PAGES_TABLE_PAGE_SIZE } from "../../constants";

interface PlansTableProps extends React.PropsWithChildren {
  titleButtons: ButtonProps[];
}
type Plan = PlansQuery["plans"][0];
type DraftPlan = DraftPlansQuery["DraftPlan"][0];

type DraftPlanData = {
  name?: string;
  description?: string;
  selectedProductIds?: string[];
};

function isDraftPlan(plan: Plan | DraftPlan): plan is DraftPlan {
  return plan.__typename === "DraftPlan";
}

export const PlansTable: React.FC<PlansTableProps> = (props) => {
  const { environmentType } = useEnvironment();
  const navigate = useNavigate();
  const [planToCopy, setPlanToCopy] = useState<Plan | null>(null);
  const [planToDelete, setPlanToDelete] = useState<DraftPlan | null>(null);
  const [planToArchive, setPlanToArchive] = useState<Plan | null>(null);
  const [planFilters, setPlanFilters] =
    useState<OptionType[]>(PLAN_OPTIONS_DEFAULT);

  const [searchQuery, setSearchQuery] = useSearchParam("q");
  const debouncedSearchQuery = useDebounce(searchQuery.trim(), 400);
  const isPlanFilterSelected = (value: "active" | "draft" | "archived") =>
    planFilters.some((f) => f.group === "plan_status" && f.value === value);
  const { mode } = useUIMode();

  const {
    data: activePlans,
    loading: activePlansLoading,
    error: activePlansError,
  } = usePlansQuery({
    variables: {
      environment_type: environmentType,
      plan_type: PlanTypeEnum.ActiveOnly,
    },
    skip: false,
  });
  const {
    data: archivedPlans,
    loading: archivedPlansLoading,
    error: archivedPlansError,
  } = usePlansQuery({
    variables: {
      environment_type: environmentType,
      plan_type: PlanTypeEnum.ArchivedOnly,
    },
    skip: !isPlanFilterSelected("archived"),
  });
  const {
    data: draftPlansData,
    loading: draftPlansLoading,
    error: draftPlansError,
  } = useDraftPlansQuery({
    variables: { environment_type: environmentType },
  });
  const {
    data: activeProducts,
    loading: activeProductsLoading,
    error: activeProductsError,
  } = useActiveProductsQuery({
    variables: { environment_type: environmentType },
  });

  // Even if we aren't showing active and/or draft plans at the moment, we block
  // on them to show the page because we want to show the correct empty state
  // (no plans match filter vs no plans at all). However, we only block on the
  // archived plans query returning if we're going to show them.
  const loading =
    activePlansLoading ||
    draftPlansLoading ||
    activeProductsLoading ||
    (isPlanFilterSelected("archived") && archivedPlansLoading);

  const error =
    activePlansError ||
    draftPlansError ||
    activeProductsError ||
    archivedPlansError;

  const plans: Plan[] = [];
  if (isPlanFilterSelected("active") && activePlans) {
    plans.push(...activePlans?.plans);
  }
  if (isPlanFilterSelected("archived") && archivedPlans) {
    plans.push(...archivedPlans?.plans);
  }

  const planFuse = useMemo(() => {
    return new Fuse(plans ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "name", weight: 1.0 },
        { name: "description", weight: 0.7 },
        {
          name: "PricedProducts.PricedProductPricingFactors.ProductPricingFactor.name",
          weight: 0.4,
        },
        { name: "PricedProducts.Product.name", weight: 0.4 },
      ],
      threshold: 0.35,
    });
  }, [plans]);

  const draftPlans = isPlanFilterSelected("draft")
    ? draftPlansData?.DraftPlan ?? []
    : [];

  const draftPlanFuse = useMemo(() => {
    return new Fuse(draftPlans ?? [], {
      ignoreLocation: true,
      includeScore: true,
      useExtendedSearch: true,
      keys: [
        { name: "id", weight: 1.0 },
        { name: "data.name", weight: 1.0 },
        { name: "data.description", weight: 0.8 },
      ],
      threshold: 0.35,
    });
  }, [draftPlans]);

  const filteredPlans = [
    ...(debouncedSearchQuery
      ? planFuse.search(debouncedSearchQuery).map((match) => {
          return { ...match.item, score: match.score || 0 };
        })
      : plans.map((plan) => {
          return { ...plan, score: 0 };
        })),
  ];

  const filteredDraftPlans = [
    ...(debouncedSearchQuery
      ? draftPlanFuse.search(debouncedSearchQuery).map((match) => {
          return { ...match.item, score: match.score || 0 };
        })
      : draftPlans.map((draftPlan) => {
          return { ...draftPlan, score: 0 };
        })),
  ];

  const hasProducts =
    activeProducts?.products && activeProducts.products.length > 0;

  const addPlanButton = (
    <GatedButton
      doc={NewPlanDocument}
      className="ml-12"
      onClick={() =>
        hasProducts ? navigate("/offering/plans/new") : undefined
      }
      disabled={!hasProducts}
      text="Add"
      theme="primary"
      leadingIcon="plus"
      size="sm"
    />
  );

  const deleteDraftPlanModal = planToDelete && (
    <DeleteDraftPlanModal
      onClose={() => {
        setPlanToDelete(null);
      }}
      plan={planToDelete}
    />
  );

  const archivePlanModal = planToArchive && (
    <ArchivePlanModal
      onClose={() => {
        setPlanToArchive(null);
      }}
      plan={planToArchive}
    />
  );

  const copyPlanModal = planToCopy && (
    <DeprecatedCopyPlanModal
      onClose={() => {
        setPlanToCopy(null);
      }}
      planId={planToCopy.id}
      planName={planToCopy.name}
    />
  );
  const emptyState = () => {
    if (error) {
      return (
        <EmptyState
          mainText="We ran into an issue loading your plans"
          supportingText="Don't worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
          icon="shoppingCart01"
        />
      );
    } else if (!loading) {
      if (plans.length === 0 && draftPlansData?.DraftPlan.length === 0) {
        return (
          <EmptyState
            mainText="You don't have any plans."
            supportingText={
              hasProducts
                ? undefined
                : "Once you create a product, then you can build plans."
            }
            icon="file05"
            actions={[
              <DocsLink plansPath="pricing-and-packaging/how-pricing-and-packaging-works/" />,
              hasProducts ? (
                <GatedButton
                  doc={NewPlanDocument}
                  theme="primary"
                  onClick={() => navigate("/offering/plans/new")}
                  text="Create a plan"
                />
              ) : (
                <GatedButton
                  theme="primary"
                  doc={NewProductDocument}
                  onClick={() => navigate("/offering/plans/products/new")}
                  text="Create a product"
                />
              ),
            ]}
          />
        );
      } else if (
        filteredPlans.length === 0 &&
        filteredDraftPlans.length === 0
      ) {
        return (
          <EmptyState
            mainText="No matching plans found."
            supportingText={`Try a different ${
              searchQuery.length ? "search term" : "filter"
            }.`}
            icon="file05"
          />
        );
      }
    }
  };

  const table = (
    <Table
      title={
        mode === "plans-only" ? (
          "Plan catalog"
        ) : (
          <ButtonGroup buttons={props.titleButtons} />
        )
      }
      loading={loading}
      rowRoutePath={(row) =>
        row.original.__typename === "Plan"
          ? `/offering/plans/${row.id}`
          : `/offering/plans/new/${row.id}`
      }
      emptyState={emptyState()}
      columns={[
        {
          id: "name",
          header: "Name",
          cell: (props) => props.getValue(),
          supportingText: (plan) =>
            plan.__typename === "Plan"
              ? plan.description
              : plan.__typename === "DraftPlan"
                ? (plan.data as DraftPlanData).description ?? ""
                : "",
          accessorFn: (plan) =>
            plan.__typename === "Plan"
              ? plan.name
              : plan.__typename === "DraftPlan"
                ? (plan.data as DraftPlanData).name
                : "",
        },
        {
          id: "customer-count",
          header: "Customer count",
          cell: (props) => props.getValue(),
          accessorFn: (plan) => {
            if (isDraftPlan(plan)) {
              return <span>0 customers</span>;
            }
            return <CustomerCountCell planId={plan.id} />;
          },
          enableSorting: false,
        },
        {
          id: "product-count",
          header: "Product count",
          cell: (props) => {
            const productCount = props.getValue();
            return (
              <span>{`${productCount} ${pluralize("product", productCount)}`}</span>
            );
          },
          accessorFn: (plan) => {
            const productCount = isDraftPlan(plan)
              ? (plan.data as DraftPlanData).selectedProductIds?.length ?? 0
              : plan.PricedProducts_aggregate.aggregate?.count ?? 0;
            return productCount;
          },
        },
        {
          id: "user",
          header: "User",
          cell: (props) => {
            const plan = props.row.original;
            const creator = isDraftPlan(plan) ? plan.Creator : plan.Actor;
            return creator ? (
              <Tooltip
                content={
                  <>
                    Created by {creator.name}
                    <br />
                    {renderDate(new Date(plan.created_at), {
                      isUtc: false,
                    })}
                  </>
                }
              >
                <AvatarWithName {...creator} />
              </Tooltip>
            ) : null;
          },
          accessorFn: (plan) => {
            // Only return the name here for sorting purposes
            const creator = isDraftPlan(plan) ? plan.Creator : plan.Actor;
            return creator?.name || "";
          },
        },
        {
          id: "last_edited",
          header: "Last edited",
          cell: (props) => <Timestamp dateTime={props.getValue()} />,
          accessorFn: (plan) => {
            const availableDate = isDraftPlan(plan)
              ? plan.created_at
              : plan.updated_at;
            return dayjs.utc(availableDate).toDate();
          },
        },
        {
          id: "status",
          header: "Status",
          cell: (props) => props.getValue(),
          accessorFn: (plan) => {
            if (isDraftPlan(plan)) {
              return <Badge label="Draft" theme="gray" />;
            } else if (plan.deprecated_at !== null) {
              return <Badge label="Archived" theme="gray" />;
            } else {
              return <StatusCell planId={plan.id} />;
            }
          },
          enableSorting: false,
        },
        {
          id: "actions",
          header: "",
          cell: (props) => {
            return isDraftPlan(props.getValue()) ? (
              <DraftPlanActionMenu
                plan={props.getValue()}
                setPlanToDelete={setPlanToDelete}
              />
            ) : (
              <ActionMenu
                plan={props.getValue()}
                setPlanToArchive={setPlanToArchive}
                setPlanToCopy={setPlanToCopy}
              />
            );
          },
          accessorFn: (r) => r,
        },
      ]}
      data={[...filteredPlans, ...filteredDraftPlans]}
      paginationOptions={{
        type: "clientSide",
        pageSize: OFFERING_PAGES_TABLE_PAGE_SIZE,
      }}
      topBarActions={[
        hasProducts ? (
          <div className="flex flex-row items-center">
            {!loading && (plans.length || draftPlansData?.DraftPlan.length) ? (
              <>
                <DeprecatedSearchTooltip searchText="plans">
                  <Input
                    type="search"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={setSearchQuery}
                    leftIcon="search"
                    className="w-[208px]"
                  />
                </DeprecatedSearchTooltip>
                <DeprecatedFilter
                  value={planFilters}
                  options={PLAN_OPTIONS}
                  onChange={setPlanFilters}
                  onReset={() => setPlanFilters(PLAN_OPTIONS_DEFAULT)}
                />
              </>
            ) : null}
            {addPlanButton}
          </div>
        ) : (
          <Tooltip content="Once you create a product, then you can build plans.">
            {addPlanButton}
          </Tooltip>
        ),
      ]}
    />
  );

  return (
    <>
      {deleteDraftPlanModal}
      {archivePlanModal}
      {copyPlanModal}
      {table}
    </>
  );
};
