import React, { useState } from "react";
import { Input, Select } from "design-system";

import { FormController } from "app/lib/FormController";
import { useNow } from "lib/date";
import { ProductListItem } from "app/pages/Contracts/lib/ProductListItem";

import { Schema } from "../../../CreateAndEdit/Schema";
import { BillingSchedule } from "../../components/BillingSchedule";

import { AdditionalTermFlyover } from "./AdditionalTermFlyover";
import { useGetFixedProductsQuery } from "./data.graphql";
import { CreditType } from "app/types/credit-types";
import { CreateProductModal } from "app/pages/Contracts/Pricing/CreateAndEditProductModal";
import { Button } from "components/Button";

const useScheduledChargeCtrl = FormController.createHook(
  Schema.ScheduledCharge,
  {
    init(props: Props) {
      return props.edit ?? {};
    },
  },
);

interface Props {
  edit?: Schema.Types.ScheduledCharge;
  onSave: (override: Schema.Types.ScheduledCharge) => void;
  onCancel: () => void;
  onDelete?: () => void;
  fiatCreditTypes: CreditType[];
  isExistingScheduledCharge?: boolean;
}

export const ScheduledChargeFlyover: React.FC<Props> = (props) => {
  const ctrl = useScheduledChargeCtrl(props);
  const now = useNow();
  const products = useGetFixedProductsQuery();
  const [showNewProductModal, setShowNewProductModal] = useState(false);

  return (
    <>
      {showNewProductModal && (
        <CreateProductModal
          enforceType="fixed"
          onClose={(newProductId) => {
            setShowNewProductModal(false);
            ctrl.update({
              productId: newProductId,
            });
          }}
        />
      )}
      <AdditionalTermFlyover
        {...props}
        ctrl={ctrl}
        title={props.edit ? "Edit scheduled charge" : "Add a scheduled charge"}
      >
        <Select
          {...ctrl.props.Select("productId", {
            name: "Product",
            disabled: props.isExistingScheduledCharge,
            placeholder: "Select a product",
            options: [
              {
                // Dummy UUID so error doesn't show while creating a new product
                value: "0ab465e4-cf01-4b70-ba31-7a377db38299",
                label: (
                  <Button
                    onClick={() => setShowNewProductModal(true)}
                    text="Add a product"
                    theme="linkGray"
                    leadingIcon="plus"
                  />
                ),
              },
              ...(products.data?.contract_pricing.products.map((product) => ({
                label: ProductListItem.getName(product, now),
                value: product.id,
              })) ?? []),
            ],
          })}
        />

        {ctrl.get("productId") && (
          <BillingSchedule
            parent={ctrl}
            mode="invoice"
            fiatCreditTypes={props.fiatCreditTypes}
            isExistingSchedule={props.isExistingScheduledCharge}
          />
        )}

        {props.isExistingScheduledCharge && (
          <div className="grid grid-cols-3 gap-12">
            <Input
              {...ctrl.props.Input("netsuiteSalesOrderId", {
                name: "NetSuite Sales Order ID (optional)",
                placeholder: "Enter ID",
              })}
            />
          </div>
        )}
      </AdditionalTermFlyover>
    </>
  );
};
