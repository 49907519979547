/* e41e273bd2dcb83ca09631508f22047e915301f3
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBillableMetricsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetBillableMetricsQuery = { __typename?: 'Query', billable_metrics: Array<{ __typename?: 'BillableMetric', id: string, group_keys: object | null }> };


export const GetBillableMetricsDocument = gql`
    query GetBillableMetrics($environment_type: EnvironmentTypeEnum_enum!) {
  billable_metrics(environment_type: $environment_type, types: [ACTIVE]) {
    id
    group_keys
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetBillableMetricsQuery__
 *
 * To run a query within a React component, call `useGetBillableMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillableMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillableMetricsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetBillableMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetBillableMetricsQuery, GetBillableMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillableMetricsQuery, GetBillableMetricsQueryVariables>(GetBillableMetricsDocument, options);
      }
export function useGetBillableMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillableMetricsQuery, GetBillableMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillableMetricsQuery, GetBillableMetricsQueryVariables>(GetBillableMetricsDocument, options);
        }
export type GetBillableMetricsQueryHookResult = ReturnType<typeof useGetBillableMetricsQuery>;
export type GetBillableMetricsLazyQueryHookResult = ReturnType<typeof useGetBillableMetricsLazyQuery>;
export type GetBillableMetricsQueryResult = Apollo.QueryResult<GetBillableMetricsQuery, GetBillableMetricsQueryVariables>;