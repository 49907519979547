import Decimal from "decimal.js";
import pluralize from "pluralize";
import { CreditType } from "app/types/credit-types";
import {
  AlertTypeEnum_Enum,
  InvoiceExternalTypeEnum,
  ManagedEntityEnum_Enum,
} from "types/generated-graphql/__types__";
import {
  displayCreditsInCurrencyWithoutRounding,
  displayCreditTypeName,
} from "app/lib/credits";
import { useFeatureFlag } from "../launchdarkly";
import { groupBy } from "app/lib/util";
import { CustomFieldFilter } from "app/pages/Connections/tabs/Alerts/NewAlert/components/AdvancedFilters";

const AUTHORIZED_ALERT_TYPES_FEATURE_FLAG_NAME = "authorized-alert-types";
const ALL_CUSTOMER_ALERT_EVALUATION_FEATURE_FLAG_NAME =
  "allow-evaluating-alert-on-creation-for-all-customers";

export const ALL_PLAN_ALERTS = new Set([
  AlertTypeEnum_Enum.LowCreditBalanceReached,
  AlertTypeEnum_Enum.SpendThresholdReached,
  AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached,
  AlertTypeEnum_Enum.LowRemainingDaysInPlanReached,
  AlertTypeEnum_Enum.LowRemainingDaysOfCreditReached,
  AlertTypeEnum_Enum.LowRemainingCreditPercentageReached,
  AlertTypeEnum_Enum.UsageThresholdReached,
  AlertTypeEnum_Enum.InvoiceTotalReached,
]);

export const ALL_CONTRACT_ALERTS = new Set([
  AlertTypeEnum_Enum.SpendThresholdReached,
  AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached,
  AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached,
  AlertTypeEnum_Enum.LowRemainingCommitBalanceReached,
  AlertTypeEnum_Enum.LowRemainingCommitPercentageReached,
  AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached,
  AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached,
  AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached,
  AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached,
  AlertTypeEnum_Enum.UsageThresholdReached,
  AlertTypeEnum_Enum.InvoiceTotalReached,
]);

export const ALL_ALERTS = new Set([...ALL_PLAN_ALERTS, ...ALL_CONTRACT_ALERTS]);

const getCreditGrantFiltersFromAlertFilters = (filters: object | null) => {
  const alertFilters = filters as AlertFilters;
  return alertFilters && alertFilters.credit_grant_type
    ? alertFilters.credit_grant_type
    : undefined;
};

const getContractCustomFieldFiltersFromAlertFilters = (
  filters: object | null,
) => {
  const alertFilters = filters as AlertFilters;
  return alertFilters && alertFilters.custom_fields
    ? alertFilters.custom_fields
    : undefined;
};

function getInvoiceTypesFromAlertFilters(filters: object | null) {
  const alertFilters = filters as AlertFilters;
  return alertFilters && alertFilters.invoice_types?.length
    ? alertFilters.invoice_types
    : undefined;
}

type AlertFilters = {
  credit_grant_type?: string[];
  custom_fields?: CustomFieldFilter[];
  invoice_types?: InvoiceExternalTypeEnum[];
};

export const filterFieldNames: Record<
  ManagedEntityEnum_Enum | "Contract",
  string
> = {
  [ManagedEntityEnum_Enum.BillableMetric]: "billable metric",
  [ManagedEntityEnum_Enum.Charge]: "charge",
  [ManagedEntityEnum_Enum.Commit]: "Commitment",
  [ManagedEntityEnum_Enum.Contract]: "contract",
  [ManagedEntityEnum_Enum.ContractProduct]: "contract product",
  [ManagedEntityEnum_Enum.CreditGrant]: "credit grant",
  [ManagedEntityEnum_Enum.Customer]: "customer",
  [ManagedEntityEnum_Enum.CustomerPlan]: "customer plan",
  [ManagedEntityEnum_Enum.Plan]: "plan",
  [ManagedEntityEnum_Enum.Product]: "product",
  [ManagedEntityEnum_Enum.RateCard]: "rate card",
  [ManagedEntityEnum_Enum.Alert]: "alert",
  [ManagedEntityEnum_Enum.Invoice]: "invoice",
  [ManagedEntityEnum_Enum.ContractCredit]: "Contract credit",
  [ManagedEntityEnum_Enum.ScheduledCharge]: "scheduled charge",
  [ManagedEntityEnum_Enum.ProService]: "professional service",
  [ManagedEntityEnum_Enum.Discount]: "discount",
};

export const generateAlertFilterStrings = (
  filters: object | null,
  includeEntityName?: boolean,
): string[] => {
  let strings: string[] = [];
  const credit_grant_type_filters =
    getCreditGrantFiltersFromAlertFilters(filters);
  const custom_field_filters =
    getContractCustomFieldFiltersFromAlertFilters(filters);

  if (credit_grant_type_filters) {
    strings = strings.concat(
      credit_grant_type_filters.map((filter) => {
        return `Credit grant type = ${filter}`;
      }),
    );
  }

  const groupKeyFilterString = generateAlertPolicyGroupKeyFilterString(filters);
  if (groupKeyFilterString) {
    strings.push(groupKeyFilterString);
  }

  if (custom_field_filters) {
    if (includeEntityName) {
      const customFieldsByEntity = groupBy(
        custom_field_filters,
        (filter) => filter.entity,
      );
      strings = strings.concat(
        Object.entries(customFieldsByEntity).map(([entity, filters]) => {
          const filterStrings = filters.map(
            (filter) => `${filter.key ?? "--"} = ${filter.value ?? "--"}`,
          );
          return `${filterFieldNames[entity as ManagedEntityEnum_Enum]}: ${filterStrings.join(" & ")}`;
        }),
      );
    } else {
      strings = strings.concat(
        custom_field_filters.map((filter, index) => {
          return `Filter ${index + 1}: ${filter.key ?? "--"} = ${filter.value ?? "--"}`;
        }),
      );
    }
  }
  const invoiceTypesFilterString =
    generateAlertPolicyInvoiceTypesFilterString(filters);
  if (invoiceTypesFilterString) {
    strings.push(invoiceTypesFilterString);
  }

  return strings;
};

export function mapInvoiceExternalTypeToAlertFilterLabel(
  invType: InvoiceExternalTypeEnum,
): string {
  switch (invType) {
    case InvoiceExternalTypeEnum.PlanArrears:
      return "Plan usage invoices";
    case InvoiceExternalTypeEnum.Scheduled:
      return "Scheduled invoices";
    case InvoiceExternalTypeEnum.CreditPurchase:
      return "Credit purchase invoices";
    case InvoiceExternalTypeEnum.Usage:
      return "Contract usage invoices";
    case InvoiceExternalTypeEnum.Correction:
      return "Correction invoices";
    case InvoiceExternalTypeEnum.Parent:
      return "Parent invoices (unsupported)";
    case InvoiceExternalTypeEnum.SeatPurchase:
      return "Seat purchase invoices";
    default:
      invType satisfies never;
      return "";
  }
}
export function generateAlertPolicyInvoiceTypesFilterString(
  filters: object | null,
): string | undefined {
  const invoice_types_filters = getInvoiceTypesFromAlertFilters(filters);
  if (invoice_types_filters?.length) {
    return `Invoice type is one of: (${invoice_types_filters.map(mapInvoiceExternalTypeToAlertFilterLabel).join(", ")})`;
  }
  return undefined;
}

export const generateAlertPolicyGroupKeyFilterString = (
  filters: object | null,
) => {
  if (filters) {
    const groupKeyFilter = extractGroupKeyFilter(filters);
    if (groupKeyFilter) {
      return `${groupKeyFilter.key} = ${groupKeyFilter.value}`;
    }
  }
  return undefined;
};

export const generateAlertPolicyString = (
  threshold: string | number | null,
  creditType: CreditType | undefined,
  alertType: AlertTypeEnum_Enum,
  billableMetric: string | undefined,
) => {
  if (alertType === AlertTypeEnum_Enum.LowRemainingDaysInPlanReached) {
    if (threshold === null || threshold === "") {
      return `Customer plan ends in -- days`;
    }
    return `Customer plan ends in ${pluralize(
      "day",
      new Decimal(threshold).toNumber(),
      true,
    )}`;
  }
  if (alertType === AlertTypeEnum_Enum.LowRemainingCreditPercentageReached) {
    const creditPercentage = threshold ? `${threshold}%` : "--%";
    const creditName = !!creditType ? displayCreditTypeName(creditType) : "--";
    return `Customer has less than ${creditPercentage} of ${creditName} credit available`;
  }
  if (alertType === AlertTypeEnum_Enum.UsageThresholdReached) {
    const bmText = billableMetric ?? "--";
    const thresholdText = !!threshold ? threshold : "--";
    return `${bmText} reaches ${thresholdText} in a billing period`;
  }
  let creditThreshold = "--";
  if (threshold !== null && threshold !== "" && creditType) {
    creditThreshold = displayCreditsInCurrencyWithoutRounding(
      new Decimal(threshold),
      creditType,
    );
  } else if (creditType) {
    creditThreshold = `-- ${displayCreditTypeName(creditType)}`;
  }
  if (alertType === AlertTypeEnum_Enum.SpendThresholdReached) {
    return `Spend reaches ${creditThreshold} in a billing period`;
  }
  if (
    alertType === AlertTypeEnum_Enum.MonthlyInvoiceTotalSpendThresholdReached
  ) {
    return `Monthly spend reaches ${creditThreshold} in a calendar month`;
  }
  if (alertType === AlertTypeEnum_Enum.LowRemainingCommitBalanceReached) {
    let thresholdText =
      threshold === null || threshold === "" ? "--" : new Decimal(threshold);

    if (creditType) {
      thresholdText =
        threshold === null || threshold === ""
          ? `-- ${displayCreditTypeName(creditType)}`
          : displayCreditsInCurrencyWithoutRounding(
              new Decimal(threshold),
              creditType,
            );
    }

    return `Commitment balance reaches ${thresholdText}`;
  }
  if (
    alertType === AlertTypeEnum_Enum.LowRemainingContractCreditBalanceReached
  ) {
    let thresholdText =
      threshold === null || threshold === "" ? "--" : new Decimal(threshold);

    if (creditType) {
      thresholdText =
        threshold === null || threshold === ""
          ? `-- ${displayCreditTypeName(creditType)}`
          : displayCreditsInCurrencyWithoutRounding(
              new Decimal(threshold),
              creditType,
            );
    }

    return `Contract credit balance reaches ${thresholdText}`;
  }
  if (
    alertType ===
    AlertTypeEnum_Enum.LowRemainingContractCreditAndCommitBalanceReached
  ) {
    let thresholdText =
      threshold === null || threshold === "" ? "--" : new Decimal(threshold);

    if (creditType) {
      thresholdText =
        threshold === null || threshold === ""
          ? `-- ${displayCreditTypeName(creditType)}`
          : displayCreditsInCurrencyWithoutRounding(
              new Decimal(threshold),
              creditType,
            );
    }

    return `Contract credit and commit balance reaches ${thresholdText}`;
  }
  if (alertType === AlertTypeEnum_Enum.LowRemainingCommitPercentageReached) {
    const thresholdPercentage = threshold ? `${threshold}%` : "--%";
    const creditName = !!creditType ? displayCreditTypeName(creditType) : "--";

    return `Commitment balance reaches ${thresholdPercentage} of total ${creditName} credits`;
  }
  if (
    alertType === AlertTypeEnum_Enum.LowRemainingContractCreditPercentageReached
  ) {
    const thresholdPercentage = threshold ? `${threshold}%` : "--%";
    const creditName = !!creditType ? displayCreditTypeName(creditType) : "--";
    return `Contract credit balance reaches ${thresholdPercentage} of total ${creditName} credit available`;
  }
  if (
    alertType === AlertTypeEnum_Enum.LowRemainingDaysForCommitSegmentReached
  ) {
    const thresholdText =
      threshold === null || threshold === ""
        ? "-- days"
        : pluralize("day", new Decimal(threshold).toNumber(), true);

    return `Commitment will expire in ${thresholdText}`;
  }
  if (
    alertType ===
    AlertTypeEnum_Enum.LowRemainingDaysForContractCreditSegmentReached
  ) {
    const thresholdText =
      threshold === null || threshold === ""
        ? "-- days"
        : pluralize("day", new Decimal(threshold).toNumber(), true);

    return `Contract credit will expire in ${thresholdText}`;
  }

  if (alertType === AlertTypeEnum_Enum.InvoiceTotalReached) {
    return `Invoice total reaches ${creditThreshold}`;
  }

  return `Credit balance reaches ${creditThreshold}`;
};

const useGetAuthorizedAlertTypesForClient = () => {
  return useFeatureFlag<AlertTypeEnum_Enum[]>(
    AUTHORIZED_ALERT_TYPES_FEATURE_FLAG_NAME,
    [],
  );
};

export const useCanUseAlertType = (alertType: AlertTypeEnum_Enum) => {
  const authorizedAlertTypes = useGetAuthorizedAlertTypesForClient();
  if (authorizedAlertTypes === undefined) {
    return undefined;
  }
  return authorizedAlertTypes.some(
    (authorizedAlertType) => authorizedAlertType === alertType,
  );
};

// Will return true if client has access to at least one alert type
export const useHasAccessToAlerts = () => {
  const authorizedAlertTypes = useGetAuthorizedAlertTypesForClient();
  if (authorizedAlertTypes === undefined) {
    return undefined;
  }
  return authorizedAlertTypes.some((authorizedAlertType) =>
    ALL_ALERTS.has(authorizedAlertType),
  );
};

export const useCanEvaluateAlertForAllCustomers = () => {
  return useFeatureFlag<boolean>(
    ALL_CUSTOMER_ALERT_EVALUATION_FEATURE_FLAG_NAME,
    true,
  );
};

function extractGroupKeyFilter(
  filters: object,
): { key: string; value: string } | undefined {
  const groupKeyFilter = (filters as any).group_key_filter;
  if (groupKeyFilter && typeof groupKeyFilter === "object") {
    const key = groupKeyFilter.key;
    const value = groupKeyFilter.value;
    if (typeof key === "string" && typeof value === "string") {
      return { key, value };
    }
  }
  return undefined;
}
