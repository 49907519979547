import { CommitType } from "types/generated-graphql/__types__";
import { Schema } from "../../Schema";
import { upsertById } from "../../lib/upsertById";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { v4 as uuid } from "uuid";
import { z } from "zod";
import { dayjs } from "lib/dayjs";
import { CreditType } from "app/types/credit-types";
import { useCommitFlyoverController } from "./CommitFlyoverController";

export type PostpaidCommitTermsController = ReturnType<
  typeof usePostpaidCommitTermsController
>;
export const usePostpaidCommitTermsController =
  useCommitFlyoverController.child(Schema.PostpaidCommit, {
    read(
      parent,
      level: "customer" | "contract",
      defaultCreditType: CreditType,
    ) {
      const commit = parent.get("commit");
      return {
        ...(commit?.type === CommitType.Postpaid
          ? {
              ...commit,
              accessScheduleCreditTypeId:
                commit.accessScheduleCreditTypeId ?? defaultCreditType.id,
            }
          : {}),
        level,
      };
    },
    write(child) {
      return {
        commit: child.getUnvalidatedInputs(),
      };
    },
  });

export type PostpaidCommitAccessScheduleController = ReturnType<
  typeof usePostpaidCommitAccessScheduleController
>;

export const usePostpaidCommitAccessScheduleController =
  usePostpaidCommitTermsController.child(
    z.object({
      accessSchedule: z.array(Schema.PostpaidCommitAccessScheduleItem),
    }),
    {
      read(parent, timeframe: DefaultTimeframe) {
        const accessSchedule = parent.get("accessSchedule") ?? [];
        return {
          accessSchedule:
            accessSchedule.length > 0
              ? accessSchedule
              : [
                  {
                    id: uuid(),
                    date: timeframe.startingAt,
                    endDate: timeframe.endingBefore,
                  },
                ],
        };
      },
      write(child) {
        return child.getUnvalidatedInputs();
      },
    },
  );

export const usePostpaidCommitAccessScheduleItemController =
  usePostpaidCommitAccessScheduleController.child(
    Schema.PostpaidCommitAccessScheduleItem,
    {
      read(
        parent,
        id: string,
        timeframe: DefaultTimeframe,
        amount: number | undefined,
      ) {
        const item = parent
          .get("accessSchedule")
          ?.find((item) => item.id === id);

        return {
          ...item,
          date: item?.date ?? timeframe.startingAt,
          endDate: item?.endDate ?? timeframe.endingBefore,
          amount: amount,
        };
      },
      write(child, parent) {
        return {
          accessSchedule: upsertById(
            parent.get("accessSchedule") ?? [],
            child.getUnvalidatedInputs(),
          ),
        };
      },
    },
  );

export type PostpaidCommitBillingScheduleController = ReturnType<
  typeof usePostpaidCommitBillingScheduleController
>;

export const usePostpaidCommitBillingScheduleController =
  usePostpaidCommitTermsController.child(
    z.object({
      billingSchedule: z.array(Schema.PostpaidCommitBillingScheduleItem),
    }),
    {
      read(parent, timeframe: DefaultTimeframe) {
        const billingSchedule = parent.get("billingSchedule") ?? [];
        return {
          billingSchedule:
            billingSchedule.length > 0
              ? billingSchedule
              : [
                  {
                    id: uuid(),
                    date: timeframe.endingBefore,
                  },
                ],
        };
      },
      write(child) {
        return child.getUnvalidatedInputs();
      },
    },
  );

export const usePostpaidCommitBillingScheduleItemController =
  usePostpaidCommitBillingScheduleController.child(
    Schema.PostpaidCommitBillingScheduleItem,
    {
      read(
        parent,
        id: string,
        timeframe: DefaultTimeframe,
        accessScheduleEnd: string | null,
      ) {
        const item = parent
          .get("billingSchedule")
          ?.find((item) => item.id === id);

        let date = item?.date ?? accessScheduleEnd ?? timeframe.endingBefore;
        if (date && accessScheduleEnd) {
          date = dayjs(date).isBefore(accessScheduleEnd)
            ? accessScheduleEnd
            : date;
        }
        return {
          ...item,
          date: date,
        };
      },
      write(child, parent) {
        return {
          billingSchedule: upsertById(
            parent.get("billingSchedule") ?? [],
            child.getUnvalidatedInputs(),
          ),
        };
      },
    },
  );
