import { useEffect } from "react";
import {
  useIntersectionObserver,
  UseIntersectionObserverOptions,
} from "hooks/useIntersectionObserver";

export type IntersectionObserverProps<T extends Element> =
  UseIntersectionObserverOptions & {
    onIntersectChange: (isIntersecting: boolean) => void;
    children: (
      /** Ref to component that will be observed. */
      ref: React.RefObject<T>,
    ) => React.ReactNode;
  };

/**
 * Component interface to the browser's IntersectionObserver API.
 */
export const IntersectionObserver = <T extends Element>({
  onIntersectChange,
  children,
  ...useIntersectionObserverOptions
}: IntersectionObserverProps<T>) => {
  const [ref, isIntersecting] = useIntersectionObserver<T>(
    useIntersectionObserverOptions,
  );

  useEffect(() => {
    onIntersectChange(isIntersecting);
  }, [isIntersecting]);

  return children(ref);
};
