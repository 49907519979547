import React, { useState } from "react";
import {
  DeleteManagedFieldOnChargeDocument,
  DeleteManagedFieldOnCommitDocument,
  DeleteManagedFieldOnContractDocument,
  DeleteManagedFieldOnContractProductDocument,
  DeleteManagedFieldOnCreditGrantDocument,
  DeleteManagedFieldOnCustomerPlanDocument,
  DeleteManagedFieldOnPlanDocument,
  DeleteManagedFieldOnProductDocument,
  DeleteManagedFieldOnRateCardDocument,
  DeleteManagedFieldOnAlertDocument,
  DeleteManagedFieldOnInvoiceDocument,
  DeleteManagedFieldOnContractCreditDocument,
  DeleteManagedFieldOnScheduledChargeDocument,
  DeleteManagedFieldOnProServiceDocument,
  DeleteManagedFieldOnBillableMetricDocument,
  DeleteManagedFieldOnCustomerDocument,
} from "../../customFields.graphql";
import { CustomField } from "../../index";
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { Tooltip } from "components/Tooltip";
import { GatedIconButton } from "components/GatedButton";

interface RemoveValueButtonProps {
  customFieldToRemove: CustomField;
  deleteRow: (c: CustomField) => Promise<void>;
}

export const RemoveValueButton: React.FC<RemoveValueButtonProps> = ({
  customFieldToRemove,
  deleteRow,
}) => {
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  return (
    <div>
      <Modal
        title={`Are you sure you want to remove value ${customFieldToRemove.value?.value} on custom field ${customFieldToRemove.key.value}?`}
        supportingText="This action cannot be undone."
        icon="trash01"
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        modalButtons={[
          <Button
            text="Remove value"
            onClick={async () => {
              await deleteRow(customFieldToRemove);
              setRemoveModalOpen(false);
            }}
            theme="linkDestructive"
          />,
          <Button
            text="Cancel"
            onClick={() => setRemoveModalOpen(false)}
            theme="tertiary"
          />,
        ]}
      />
      <Tooltip label="Remove value" disabled={!customFieldToRemove.value}>
        <GatedIconButton
          onClick={async () => setRemoveModalOpen(true)}
          disabled={!customFieldToRemove.value}
          doc={
            {
              Charge: DeleteManagedFieldOnChargeDocument,
              Commit: DeleteManagedFieldOnCommitDocument,
              Contract: DeleteManagedFieldOnContractDocument,
              ContractProduct: DeleteManagedFieldOnContractProductDocument,
              CreditGrant: DeleteManagedFieldOnCreditGrantDocument,
              Customer: DeleteManagedFieldOnCustomerDocument,
              CustomerPlan: DeleteManagedFieldOnCustomerPlanDocument,
              Plan: DeleteManagedFieldOnPlanDocument,
              Product: DeleteManagedFieldOnProductDocument,
              RateCard: DeleteManagedFieldOnRateCardDocument,
              Alert: DeleteManagedFieldOnAlertDocument,
              Invoice: DeleteManagedFieldOnInvoiceDocument,
              ContractCredit: DeleteManagedFieldOnContractCreditDocument,
              ScheduledCharge: DeleteManagedFieldOnScheduledChargeDocument,
              ProService: DeleteManagedFieldOnProServiceDocument,
              BillableMetric: DeleteManagedFieldOnBillableMetricDocument,
            }[customFieldToRemove.entity]
          }
          theme="tertiary"
          icon="trash03"
          size="sm"
        />
      </Tooltip>
    </div>
  );
};
