/**
 * A - B = {x | x ∈ A and x ∉ B}
 */
export function setDifference<A, B extends A>(
  setA: Iterable<A>,
  setB: Iterable<B>,
): Set<A> {
  const difference = new Set<A>(setA);
  for (const elem of setB) {
    difference.delete(elem);
  }
  return difference;
}

/**
 * A + B = {x | x ∈ A or x ∈ B}
 */
export function setSum<A, B extends A>(
  setA: Iterable<A>,
  setB: Iterable<B>,
): Set<A | B> {
  const sum = new Set<A | B>(setA);
  for (const elem of setB) {
    sum.add(elem);
  }
  return sum;
}
