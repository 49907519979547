/* 9928031e32f9562df35828d0278720ed6d03f482
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommitRateOnRateCardFragment = { __typename?: 'RateCard', rates_per_product: Array<{ __typename?: 'RatesPerProduct', rates: Array<{ __typename?: 'RateCardEntry', id: string, commit_rate: { __typename?: 'CustomRate', value: object, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'FlatRate', unit_price: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'PercentageRate', fraction: string, use_list_prices: boolean } | { __typename?: 'SubscriptionRate', unit_price: string, quantity: string, is_prorated: boolean, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'TieredRate', tiers: Array<{ __typename?: 'Tier', size: string | null, unit_price: string }>, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | null }> }> };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type GetCommitRateForCustomerQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetCommitRateForCustomerQuery = { __typename?: 'Query', customer: { __typename?: 'Customer', id: string, contracts: Array<{ __typename?: 'Contract', id: string, archived_at: string | null, rate_card: { __typename?: 'RateCard', id: string, rates_per_product: Array<{ __typename?: 'RatesPerProduct', rates: Array<{ __typename?: 'RateCardEntry', id: string, commit_rate: { __typename?: 'CustomRate', value: object, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'FlatRate', unit_price: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'PercentageRate', fraction: string, use_list_prices: boolean } | { __typename?: 'SubscriptionRate', unit_price: string, quantity: string, is_prorated: boolean, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'TieredRate', tiers: Array<{ __typename?: 'Tier', size: string | null, unit_price: string }>, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | null }> }> } | null }> } | null };

export type GetCommitRateForRateCardQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetCommitRateForRateCardQuery = { __typename?: 'Query', products_and_rate_cards: { __typename?: 'ProductsAndRateCards', rate_card: { __typename?: 'RateCard', id: string, rates_per_product: Array<{ __typename?: 'RatesPerProduct', rates: Array<{ __typename?: 'RateCardEntry', id: string, commit_rate: { __typename?: 'CustomRate', value: object, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'FlatRate', unit_price: string, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'PercentageRate', fraction: string, use_list_prices: boolean } | { __typename?: 'SubscriptionRate', unit_price: string, quantity: string, is_prorated: boolean, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | { __typename?: 'TieredRate', tiers: Array<{ __typename?: 'Tier', size: string | null, unit_price: string }>, credit_type: { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null } } | null }> }> } } };

export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const CommitRateOnRateCardFragmentDoc = gql`
    fragment CommitRateOnRateCard on RateCard {
  rates_per_product {
    rates {
      id
      commit_rate {
        ... on FlatRate {
          unit_price
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
        }
        ... on PercentageRate {
          fraction
          use_list_prices
        }
        ... on SubscriptionRate {
          unit_price
          quantity
          is_prorated
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
        }
        ... on CustomRate {
          value
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
        }
        ... on TieredRate {
          tiers {
            size
            unit_price
          }
          credit_type {
            ...CreditType
            __environment_type: environment_type
          }
        }
      }
    }
  }
}
    ${CreditTypeFragmentDoc}`;
export const GetCommitRateForCustomerDocument = gql`
    query GetCommitRateForCustomer($id: uuid!) {
  customer: Customer_by_pk(id: $id) {
    id
    contracts {
      id
      rate_card {
        id
        ...CommitRateOnRateCard
      }
      archived_at
    }
    __environment_type: environment_type
  }
}
    ${CommitRateOnRateCardFragmentDoc}`;

/**
 * __useGetCommitRateForCustomerQuery__
 *
 * To run a query within a React component, call `useGetCommitRateForCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommitRateForCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommitRateForCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommitRateForCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>(GetCommitRateForCustomerDocument, options);
      }
export function useGetCommitRateForCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>(GetCommitRateForCustomerDocument, options);
        }
export type GetCommitRateForCustomerQueryHookResult = ReturnType<typeof useGetCommitRateForCustomerQuery>;
export type GetCommitRateForCustomerLazyQueryHookResult = ReturnType<typeof useGetCommitRateForCustomerLazyQuery>;
export type GetCommitRateForCustomerQueryResult = Apollo.QueryResult<GetCommitRateForCustomerQuery, GetCommitRateForCustomerQueryVariables>;
export const GetCommitRateForRateCardDocument = gql`
    query GetCommitRateForRateCard($id: uuid!) {
  products_and_rate_cards {
    rate_card(id: $id) {
      id
      ...CommitRateOnRateCard
    }
  }
}
    ${CommitRateOnRateCardFragmentDoc}`;

/**
 * __useGetCommitRateForRateCardQuery__
 *
 * To run a query within a React component, call `useGetCommitRateForRateCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommitRateForRateCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommitRateForRateCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommitRateForRateCardQuery(baseOptions: Apollo.QueryHookOptions<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>(GetCommitRateForRateCardDocument, options);
      }
export function useGetCommitRateForRateCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>(GetCommitRateForRateCardDocument, options);
        }
export type GetCommitRateForRateCardQueryHookResult = ReturnType<typeof useGetCommitRateForRateCardQuery>;
export type GetCommitRateForRateCardLazyQueryHookResult = ReturnType<typeof useGetCommitRateForRateCardLazyQuery>;
export type GetCommitRateForRateCardQueryResult = Apollo.QueryResult<GetCommitRateForRateCardQuery, GetCommitRateForRateCardQueryVariables>;