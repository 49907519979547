/* c5315099a9abf56e750f784040cdc2bc72bf50b7
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MigrateCreditGrantsMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  free_credits_product_id: Types.Scalars['uuid'];
  prepaid_credits_product_id: Types.Scalars['uuid'];
  contract_credit_priority_override?: Types.InputMaybe<Types.Scalars['numeric']>;
  expire_rolled_over_credits_after: Types.Scalars['timestamptz'];
}>;


export type MigrateCreditGrantsMutation = { __typename?: 'Mutation', migrate_plan_credits_to_contract_commits: { __typename?: 'MutationResponse', success: boolean, error: string | null } };


export const MigrateCreditGrantsDocument = gql`
    mutation MigrateCreditGrants($customer_id: uuid!, $free_credits_product_id: uuid!, $prepaid_credits_product_id: uuid!, $contract_credit_priority_override: numeric, $expire_rolled_over_credits_after: timestamptz!) {
  migrate_plan_credits_to_contract_commits(
    customer_id: $customer_id
    free_credits_product_id: $free_credits_product_id
    prepaid_credits_product_id: $prepaid_credits_product_id
    contract_credit_priority_override: $contract_credit_priority_override
    expire_rolled_over_credits_after: $expire_rolled_over_credits_after
  ) {
    success
    error
  }
}
    `;
export type MigrateCreditGrantsMutationFn = Apollo.MutationFunction<MigrateCreditGrantsMutation, MigrateCreditGrantsMutationVariables>;

/**
 * __useMigrateCreditGrantsMutation__
 *
 * To run a mutation, you first call `useMigrateCreditGrantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateCreditGrantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateCreditGrantsMutation, { data, loading, error }] = useMigrateCreditGrantsMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      free_credits_product_id: // value for 'free_credits_product_id'
 *      prepaid_credits_product_id: // value for 'prepaid_credits_product_id'
 *      contract_credit_priority_override: // value for 'contract_credit_priority_override'
 *      expire_rolled_over_credits_after: // value for 'expire_rolled_over_credits_after'
 *   },
 * });
 */
export function useMigrateCreditGrantsMutation(baseOptions?: Apollo.MutationHookOptions<MigrateCreditGrantsMutation, MigrateCreditGrantsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MigrateCreditGrantsMutation, MigrateCreditGrantsMutationVariables>(MigrateCreditGrantsDocument, options);
      }
export type MigrateCreditGrantsMutationHookResult = ReturnType<typeof useMigrateCreditGrantsMutation>;
export type MigrateCreditGrantsMutationResult = Apollo.MutationResult<MigrateCreditGrantsMutation>;
export type MigrateCreditGrantsMutationOptions = Apollo.BaseMutationOptions<MigrateCreditGrantsMutation, MigrateCreditGrantsMutationVariables>;