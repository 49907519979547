import React, { useContext } from "react";
import { RecurringCommitController } from ".";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { CreditType } from "app/types/credit-types";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";
import { CreditTypeContext } from "./CommitTakeover";
import { CommitPriceInput } from "../../lib/CommitPriceInput";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import {
  RecurringCommitTermsController,
  useRecurringCommitAccessAmountController,
  useRecurringCommitInvoiceAmountController,
  useRecurringCommitTermsController,
} from "./RecurringCommitTermsControllers";
import { Dropdown, DropdownItem } from "components/Dropdown";
import {
  ContractUsageInvoiceScheduleFrequencyEnum,
  ExternalCommitType,
  RecurringCommitProration,
} from "types/generated-graphql/__types__";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { dayjs } from "lib/dayjs";

// Maps proration mapping with user friendly strings
export const PRORATION_MAPPING: Record<RecurringCommitProration, string> = {
  [RecurringCommitProration.First]: "Prorate first",
  [RecurringCommitProration.FirstAndLast]: "Prorate first and last",
  [RecurringCommitProration.Last]: "Prorate last",
  [RecurringCommitProration.None]: "Prorate none",
};

// Maps frequency to user friendly strings
const FREQUENCY_MAPPING: Record<
  ContractUsageInvoiceScheduleFrequencyEnum,
  string
> = {
  [ContractUsageInvoiceScheduleFrequencyEnum.Monthly]: "monthly",
  [ContractUsageInvoiceScheduleFrequencyEnum.Quarterly]: "quarterly",
  [ContractUsageInvoiceScheduleFrequencyEnum.Annual]: "annually",
  [ContractUsageInvoiceScheduleFrequencyEnum.Weekly]: "weekly",
};

const RecurringCommitAccessAmount: React.FC<{
  parent: RecurringCommitTermsController;
  frequency?: ContractUsageInvoiceScheduleFrequencyEnum;
  startingAt?: string;
  endingBefore?: string;
  asCredit?: boolean;
}> = (props) => {
  const ctrl = useRecurringCommitAccessAmountController(props.parent);
  const creditTypeContext = useContext(CreditTypeContext);
  const creditType = findCreditType(
    ctrl.get("creditTypeId") ?? USD_CREDIT_TYPE.id,
    [
      ...creditTypeContext.fiatCreditTypes,
      ...creditTypeContext.customCreditTypes,
    ],
  );
  const frequency = props.frequency
    ? FREQUENCY_MAPPING[props.frequency]
    : "on usage invoice frequency";
  const startingAt = props.startingAt
    ? dayjs.utc(props.startingAt).format("MMM DD, YYYY")
    : "-";
  const endingBefore = props.endingBefore
    ? dayjs.utc(props.endingBefore).format("MMM DD, YYYY")
    : "onwards";

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    creditType,
    "unitPrice",
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="mb-4 flex w-full items-center">
          <p className="text-sm text-black">{`${props.asCredit ? "Credit" : "Commit"} balance`}</p>
        </div>
        <div className="flex w-full flex-row gap-4">
          <div className="flex flex-1 flex-col">
            <CommitPriceInput
              unitPrice={ctrl.get("unitPrice")}
              creditType={creditType}
              onPriceChange={(price) => {
                ctrl.update({
                  unitPrice: price,
                });
              }}
              onCreditTypeChange={(creditType) => {
                ctrl.update({ creditTypeId: creditType.id });
              }}
              isInvalid={!ctrl.appearsValid() && !ctrl.isValid("unitPrice")}
              hintText={
                (!ctrl.appearsValid() && ctrl.state.fields.unitPrice?.error) ||
                `Issued ${frequency} at the 1st of the month from ${startingAt} - ${endingBefore}`
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

const RecurringCommitInvoiceAmount: React.FC<{
  parent: RecurringCommitTermsController;
  frequency?: ContractUsageInvoiceScheduleFrequencyEnum;
  startingAt?: string;
  endingBefore?: string;
  asCredit?: boolean;
}> = (props) => {
  const ctrl = useRecurringCommitInvoiceAmountController(props.parent);
  const creditTypeContext = useContext(CreditTypeContext);
  const creditType = findCreditType(
    ctrl.get("creditTypeId") ?? USD_CREDIT_TYPE.id,
    [
      ...creditTypeContext.fiatCreditTypes,
      ...creditTypeContext.customCreditTypes,
    ],
  );

  const frequency = props.frequency
    ? FREQUENCY_MAPPING[props.frequency]
    : "on usage invoice frequency";
  const startingAt = props.startingAt
    ? dayjs.utc(props.startingAt).format("MMM DD, YYYY")
    : "-";
  const endingBefore = props.endingBefore
    ? dayjs.utc(props.endingBefore).format("MMM DD, YYYY")
    : "onwards";

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    creditType,
    "unitPrice",
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="mb-4 flex w-full items-center">
          <p className="text-sm text-black">Invoice amount</p>
        </div>
        <div className="flex w-full flex-row gap-4">
          <div className="flex flex-1 flex-col">
            <CommitPriceInput
              unitPrice={ctrl.get("unitPrice")}
              creditType={creditType}
              onPriceChange={(price) => {
                ctrl.update({
                  unitPrice: price,
                });
              }}
              onCreditTypeChange={(creditType) => {
                ctrl.update({ creditTypeId: creditType.id });
              }}
              isInvalid={!ctrl.appearsValid() && !ctrl.isValid("unitPrice")}
              hintText={
                (!ctrl.appearsValid() && ctrl.state.fields.unitPrice?.error) ||
                `Issued ${frequency} at the 1st of the month from ${startingAt} - ${endingBefore}`
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const RecurringCommitTerms: React.FC<{
  parent: RecurringCommitController;
  asCredit?: boolean;
  defaultCreditType: CreditType;
}> = (props) => {
  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );

  const timeframe = DefaultTimeframe.useFromContext();
  const externalType = props.asCredit
    ? ExternalCommitType.Credit
    : ExternalCommitType.Commit;
  const ctrl = useRecurringCommitTermsController(
    props.parent,
    externalType,
    timeframe,
    showCurrencyWork ? props.defaultCreditType : USD_CREDIT_TYPE,
  );
  const selectedProration = ctrl.get("proration")
    ? PRORATION_MAPPING[ctrl.get("proration") ?? RecurringCommitProration.None]
    : undefined;

  const frequency = ctrl.get("recurrenceFrequency");
  const startingAt = ctrl.get("startingAt");
  const endingBefore = ctrl.get("endingBefore");
  return (
    <div className="flex flex-col gap-[24px]">
      <div>
        <RecurringCommitAccessAmount
          parent={ctrl}
          frequency={frequency}
          startingAt={startingAt}
          endingBefore={endingBefore}
          asCredit={props.asCredit}
        />
      </div>
      {!props.asCredit && (
        <div>
          <RecurringCommitInvoiceAmount
            parent={ctrl}
            frequency={frequency}
            startingAt={startingAt}
            endingBefore={endingBefore}
            asCredit={props.asCredit}
          />
        </div>
      )}
      <div className="flex flex-1">
        <Dropdown
          key="proration"
          label="Select proration"
          selectedOption={selectedProration}
        >
          {[
            ...Object.entries(PRORATION_MAPPING).map(([value, label]) => (
              <DropdownItem
                label={label}
                key={label}
                selected={ctrl.get("proration") === value}
                value={value}
                onClick={({ value }) => {
                  ctrl.update({ proration: value });
                }}
              />
            )),
          ]}
          ,
        </Dropdown>
      </div>
    </div>
  );
};
