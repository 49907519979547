import React from "react";
import { ProductListItem } from "../ProductListItem";
import Decimal from "decimal.js";
import { ContractRecurringCommitsFragment } from "./fragments.graphql";
import { dayjs } from "lib/dayjs";
import { Table } from "components/Table";
import { ExternalCommitType } from "types/generated-graphql/__types__";
import { RoundedCurrency } from "app/lib/credits";

interface Props {
  contract: ContractRecurringCommitsFragment;
}
export const ContractRecurringCommits: React.FC<Props> = ({ contract }) => {
  const now = dayjs.utc();
  return (
    <div>
      <Table
        title="Recurring commits"
        columns={[
          {
            id: "name",
            header: "Name",
            cell: (props) => props.getValue(),
            accessorFn: (row) =>
              row.name ?? ProductListItem.getName(row.product, now),
          },
          {
            id: "frequency",
            header: "Frequency",
            accessorFn: (row) =>
              row.recurrence_frequency ??
              contract.usage_invoice_schedule.frequency,
            cell: (props) => (
              <span className="capitalize">
                {props.getValue().toLowerCase()}
              </span>
            ),
            enableSorting: false,
          },
          {
            id: "invoice_amount",
            header: "Invoice Amount",
            accessorFn: (row) => {
              const invoiceAmount = row.invoice_amount;
              if (invoiceAmount) {
                return new Decimal(invoiceAmount.unit_price).mul(
                  invoiceAmount.quantity,
                );
              } else {
                return undefined;
              }
            },
            cell: (props) => {
              const invoiceAmount = props.row.original.invoice_amount;
              if (invoiceAmount) {
                const amount = props.getValue();
                return (
                  <>
                    <RoundedCurrency
                      amount={amount}
                      creditType={invoiceAmount.fiat_credit_type}
                    />
                  </>
                );
              } else {
                return null;
              }
            },
          },
          {
            id: "access_amount",
            header: "Access amount",
            accessorFn: (row) => {
              return new Decimal(row.access_amount.unit_price).mul(
                row.access_amount.quantity,
              );
            },
            cell: (props) => {
              const accessAmount = props.row.original.access_amount;
              return (
                <>
                  <RoundedCurrency
                    amount={props.getValue()}
                    creditType={accessAmount.credit_type}
                  />
                </>
              );
            },
          },
          {
            id: "commits_created",
            header: "Credits/Commits created",
            accessorFn: (row) => {
              const commits = contract.v2_fields.commits_union.filter(
                (c) =>
                  c.__typename === "PrepaidCommit" &&
                  c.recurring_commit?.id === row.id,
              );
              return commits.length;
            },
            cell: (props) => {
              return `${props.getValue()} ${props.row.original.external_type === ExternalCommitType.Commit ? "commits" : "credits"}`;
            },
          },
        ]}
        data={contract.recurring_commits}
      />
    </div>
  );
};
