import billedRevenueSummary from "./reportTypes/billedRevenueSummary";
import agentSyncRevRecReport from "./reportTypes/agentsyncRevRec";
import billingProviderInvoicesReport from "./reportTypes/billingProviderInvoices";
import {
  soxComplianceInvoiceReport,
  soxAuditLogReport,
  usageBasedBillingFinalizationReport,
  userAndSystemAccessReport,
  customerContractRateCardAndConfigurationReport,
} from "./reportTypes/soxReports";
import netsuiteInvoiceDelivery from "./reportTypes/netsuiteInvoiceDelivery";
import creditGrantIssuanceReport from "./reportTypes/creditGrantIssuance";
import creditGrantRevenueReport from "./reportTypes/creditGrantUsage";
import { ReportConfig, SOXReport } from "./reportTypes/reportConfig";

export const emailValidation = /^.+@.+\..+$/;

export const REPORTS: (ReportConfig<any> | SOXReport)[] = [
  billedRevenueSummary,
  agentSyncRevRecReport,
  billingProviderInvoicesReport,
  creditGrantIssuanceReport,
  creditGrantRevenueReport,
  netsuiteInvoiceDelivery,
  soxComplianceInvoiceReport,
  soxAuditLogReport,
  usageBasedBillingFinalizationReport,
  userAndSystemAccessReport,
  customerContractRateCardAndConfigurationReport,
].sort((a, b) => a.name.localeCompare(b.name));

export { downloadCSV } from "./downloadCSV";
