import { useApolloClient } from "@apollo/client";
import { LoadingSpinner } from "design-system";
import { Button } from "components/Button";
import {
  useCreateDestination,
  useDestinationForm,
  prepareDestinationWithForm,
  useDestination,
  ExistingDestination,
  PreparedDestination,
  useGetDestinations,
  useTestConnection,
  Destination,
} from "@prequel/react";
import {
  fetchPrequelAuthTokenForDestination,
  PREQUEL_API_URL,
  useDraftInvoicesExportEnabled,
} from "app/lib/dataExport";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useNavigate } from "app/lib/useNavigate";
import NotFoundPage from "app/pages/404";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSnackbar } from "components/deprecated/Snackbar";
import { useGetClientQuery } from "app/lib/dataExport/queries.graphql";
import { TestConnection } from "components/DataExport/TestConnection";
import { reportToSentry } from "app/lib/errors/sentry";
import { EmptyState } from "components/EmptyState";
import { AppShell } from "components/AppShell";
import { DestinationForm } from "components/DataExport/DestinationForm";
import { ProductsAndModels } from "components/DataExport/ProductsAndModels";
import { capitalizeFirstLetter } from "app/lib/utils";

const V1_PRODUCT = "v1";
const EVENTS_PRODUCT = "events_v1";
const DRAFT_INVOICES_PRODUCT = "draft_invoices_v1";
const DEFAULT_PRODUCTS = [V1_PRODUCT, EVENTS_PRODUCT, DRAFT_INVOICES_PRODUCT];

const NewDataExportDestination: React.FC = () => {
  const navigate = useNavigate();
  const pushMessage = useSnackbar();

  const apolloClient = useApolloClient();
  const applicationOrigin = window.location.origin;
  const formRef = useRef<HTMLFormElement>(null);

  const [showEmptyStateErrorPage, setShowEmptyStateErrorPage] =
    useState<boolean>(false);

  const {
    data: clientData,
    loading: clientDataLoading,
    error: clientDataError,
  } = useGetClientQuery();
  const client = clientData?.Client[0];

  useEffect(() => {
    if (clientDataError || !(client || clientDataLoading)) {
      setShowEmptyStateErrorPage(true);
    }
  }, [clientDataError, client]);

  const [connectionValid, setConnectionValid] = useState<boolean>(false);
  const [alreadyConnected, setAlreadyConnected] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [recipientId, setRecipientId] = useState<string | undefined>();

  const [dataExportDestinations, setDataExportDestinations] = useState<
    ExistingDestination[] | undefined
  >();

  async function fetchPrequelAuthTokenToCreateDestination(
    preparedDestination?: PreparedDestination | ExistingDestination,
  ): Promise<string> {
    try {
      const data = await fetchPrequelAuthTokenForDestination(
        apolloClient,
        applicationOrigin,
        preparedDestination,
      );

      setRecipientId(data.recipient_id);
      return data.token_string;
    } catch (e) {
      setShowEmptyStateErrorPage(true);
      reportToSentry(new Error(`Failed to generate scoped auth token: ${e}`));
      throw new Error(`Failed to generate scoped auth token`);
    }
  }

  const prequelTestConnection = useTestConnection(
    fetchPrequelAuthTokenToCreateDestination,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  const maxPrequelDestinations = useFeatureFlag<number>(
    "max-prequel-destinations",
    1,
  );
  const dataExportPricingAndPackagingEnabled = useFeatureFlag<
    boolean | undefined
  >("data-export-pricing-and-packaging", undefined);
  const dataExportRawEventsEnabled = useFeatureFlag<boolean | undefined>(
    "data-export-raw-events",
    undefined,
  );
  const dataExportDraftInvoicesEnabled = useDraftInvoicesExportEnabled();

  const getDestinations = useGetDestinations(
    fetchPrequelAuthTokenToCreateDestination,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  // we want to wrap this so that if this function is called, we also
  // reset the connection valid state
  const [destination, updateDestination] = useDestination({
    vendor: "snowflake",
    products: DEFAULT_PRODUCTS,
  });

  const setDestination: React.Dispatch<
    React.SetStateAction<Partial<Destination>>
  > = (value) => {
    setConnectionValid(false);
    updateDestination(value);
  };

  useEffect(() => {
    if (client && client.name && destination.vendor.length > 0) {
      setDestination({
        // Set a readable name, such as "OpenAI Databricks"
        name: client.name + " " + capitalizeFirstLetter(destination.vendor),
      });
    }
  }, [client, destination.vendor]);

  useEffect(() => {
    if (recipientId) {
      setDestination({
        recipient_id: recipientId,
      });
    }
  }, [recipientId]);

  useEffect(() => {
    getDestinations()
      .then((destinations) => {
        setDataExportDestinations(destinations);
      })
      .catch((e) => {
        reportToSentry(new Error(`Failed to fetch destinations: ${e}`));
      });
  }, []);

  const createDestination = useCreateDestination(
    fetchPrequelAuthTokenToCreateDestination,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  const destinationForm = useDestinationForm(
    destination,
    process.env.PREQUEL_ORGANIZATION_ID ?? "",
    {
      includeInternalFields: false,
      host: PREQUEL_API_URL,
    },
  );

  const preparedDestination = useMemo(
    () => prepareDestinationWithForm(destination, destinationForm),
    [destination, destinationForm],
  );

  // N.B. In most cases we are allowing only 1 vendor destination for clients to export data to.
  // see max-prequel-destinations on LaunchDarkly or follow up at #data-export for more details.
  useEffect(() => {
    if (dataExportDestinations && dataExportDestinations.length > 0) {
      if (
        (!maxPrequelDestinations && dataExportDestinations.length > 0) ||
        (maxPrequelDestinations &&
          dataExportDestinations.length >= maxPrequelDestinations)
      ) {
        setAlreadyConnected(true);
      } else if (dataExportDestinations.length === 0) {
        setAlreadyConnected(false);
      }
    }
  }, [dataExportDestinations, maxPrequelDestinations]);

  // check flags enabled for data export
  if (
    dataExportPricingAndPackagingEnabled === false &&
    dataExportRawEventsEnabled === false &&
    dataExportDraftInvoicesEnabled === false
  ) {
    return <NotFoundPage />;
  }

  const validateForm = () => {
    return formRef.current ? formRef.current.reportValidity() : false;
  };

  async function testConnection() {
    return await prequelTestConnection(preparedDestination);
  }

  async function saveDestination() {
    if (!connectionValid) {
      return;
    }

    setSubmitLoading(true);
    const response = await createDestination(preparedDestination);
    setSubmitLoading(false);
    if (response.status === "success") {
      const name = response.data?.destination.name ?? "";
      pushMessage({
        content: `Destination '${name}' created successfully.`,
        type: "success",
      });
      navigate("/connections/data-export");
    } else {
      pushMessage({
        content: `Destination creation failed: ${response.message}`,
        type: "error",
      });

      reportToSentry(
        new Error(
          "Error in testing connection: " + response.message || "Unknown",
        ),
      );
    }
  }

  if (!destinationForm) {
    return (
      <div className="d-flex justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  let content = <LoadingSpinner />;
  if (showEmptyStateErrorPage) {
    content = (
      <EmptyState
        icon="database02"
        mainText="We ran into an issue loading this page"
        supportingText="Don’t worry! All of your data is safe, just try refreshing the page. If this problem persists, please contact us for support."
      />
    );
  } else if (alreadyConnected) {
    content = (
      <EmptyState
        icon="database02"
        mainText="Your account is already connected"
        supportingText="This account has already established a connection for data export."
      />
    );
  } else if (client && !clientDataLoading) {
    content = (
      <div className="px-xl py-xl">
        <DestinationForm
          formRef={formRef}
          form={destinationForm}
          destination={destination}
          setDestination={setDestination}
        />
        <ProductsAndModels
          fetchPrequelAuthToken={fetchPrequelAuthTokenToCreateDestination}
          destination={destination}
          setDestination={setDestination}
          draftInvoicesModelsEnabled={dataExportDraftInvoicesEnabled}
        />
        <TestConnection
          testConnection={testConnection}
          validateForm={validateForm}
          onSuccess={(success) => {
            setConnectionValid(success);
          }}
        />
      </div>
    );
  }

  return (
    <AppShell
      title="New Data Export Destination"
      headerProps={{
        actions: [
          <Button
            onClick={() => navigate("/connections/data-export")}
            className="mr-4"
            text="Cancel"
            theme="linkGray"
          />,
          <Button
            onClick={saveDestination}
            disabled={!connectionValid || submitLoading}
            loading={submitLoading}
            text="Save"
            theme="primary"
          />,
        ],
      }}
    >
      {content}
    </AppShell>
  );
};

export default NewDataExportDestination;
