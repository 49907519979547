import { GatedButton } from "components/GatedButton";
import { useRequiredParam } from "app/lib/routes/params";
import { formatCustomFields } from "app/pages/Connections/tabs/CustomFields";
import {
  useDeleteManagedFieldOnCustomerMutation,
  useGetCustomerCustomFieldsQuery,
  useSetManagedFieldOnCustomerMutation,
} from "app/pages/Connections/tabs/CustomFields/customFields.graphql";
import {
  UpdateIngestAliasesDocument,
  useGetCustomerSettingsInfoQuery,
} from "app/pages/deprecated/Customer/tabs/Settings/sections/Identifiers/queries.graphql";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "components/Button";
import { CopyableID } from "components/CopyableID";
import { Dropdown, DropdownItem } from "components/Dropdown";
import { Icon } from "components/Icon";
import { IconButton } from "components/IconButton";
import { TextInput } from "components/Input";
import { LoadingBlob } from "components/LoadingBlob";
import { Modal } from "components/Modal";
import { Integrations } from "./components/Integrations";
import { EditCustomerNameButton } from "components/EditButton/EditCustomerButton";
import { DeprecatedDeleteIngestAliasButton } from "components/deprecated/IngestAliases";
import { SetIngestAliasesModal } from "./components/SetIngestAliasesModal";

const CUSTOM_FIELD_MODAL_COPY = {
  editLabel: "Edit the value to a custom field",
  editSupportingText:
    "Change the value of this Metronome customer's custom field key.",
  newLabel: "Add custom field",
  newSupportingText:
    "Add customizable information to core entities. This data can enhance integrations making it possible to set, update, and fetch custom field values for more tailored data handling.",
};

type CustomFieldContent = {
  keyId: string;
  valueId?: string;
  value?: string;
  label: string;
  supportingText: string;
};

type MetadataProps = {
  allowCustomerNameEditing?: boolean;
  hideIntegrations?: boolean;
};

export const Metadata: React.FC<MetadataProps> = ({
  allowCustomerNameEditing,
  hideIntegrations,
}) => {
  const [selectedCustomFieldKey, setSelectedCustomFieldKey] =
    useState<string>();
  const [showAllCustomFields, setShowAllCustomFields] = useState(false);
  const [ingestAliasModalOpen, setIngestAliasModalOpen] = useState(false);
  const [customFieldModalContent, setCustomFieldModalContent] =
    useState<CustomFieldContent | null>(null);
  const [fieldValue, setFieldValue] = useState<string>();
  const [setCustomerCustomField, { loading: setCustomerCustomFieldsLoading }] =
    useSetManagedFieldOnCustomerMutation();
  const [
    deleteCustomerCustomFieldValue,
    { loading: deleteCustomerCustomFieldsLoading },
  ] = useDeleteManagedFieldOnCustomerMutation();
  const customerId = useRequiredParam("customerId");
  const {
    data: customerSettingsInfoData,
    loading: customerSettingsInfoLoading,
  } = useGetCustomerSettingsInfoQuery({
    variables: {
      customer_id: customerId,
    },
  });

  const {
    data: customerCustomFieldsData,
    loading: customerCustomFieldsLoading,
  } = useGetCustomerCustomFieldsQuery({
    variables: {
      customer_id: customerId,
    },
  });

  const customFields = useMemo(() => {
    if (!customerCustomFieldsLoading && !!customerCustomFieldsData) {
      return formatCustomFields(
        customerCustomFieldsData?.ManagedFieldKey ?? [],
      );
    }
    return [];
  }, [customerCustomFieldsLoading, customerCustomFieldsData]);

  let ingestAliasBlock: React.ReactElement;
  if (customerSettingsInfoLoading) {
    ingestAliasBlock = <LoadingBlob />;
  } else {
    ingestAliasBlock = (
      <ul className="flex flex-col gap-y-lg">
        {customerSettingsInfoData?.Customer?.CustomerIngestAliases.map(
          ({ ingest_alias }, _, all) => (
            <li key={ingest_alias}>
              <CopyableID id={ingest_alias} />
              <DeprecatedDeleteIngestAliasButton
                customerId={customerId}
                ingestAlias={ingest_alias}
                currentAliases={all.map(({ ingest_alias }) => ingest_alias)}
              />
            </li>
          ),
        )}
      </ul>
    );
  }

  let customFieldsBlock: React.ReactElement;
  const collapsedCustomFieldsListThreshold = 5;
  if (customerCustomFieldsLoading) {
    customFieldsBlock = <LoadingBlob />;
  } else {
    customFieldsBlock = (
      <>
        <ul className="flex flex-col gap-y-md text-xs text-gray-600">
          {customFields
            ?.slice(
              0,
              showAllCustomFields
                ? customFields.length + 1
                : collapsedCustomFieldsListThreshold,
            )
            .map(({ key, value }, _) => (
              <li className="flex flex-col gap-y-sm text-sm" key={key.id}>
                {key.value}
                <div className="flex items-center gap-x-md">
                  {value && <CopyableID id={value?.value} />}
                  <IconButton
                    icon="pencil01"
                    size="sm"
                    theme="linkGray"
                    onClick={() => {
                      if (value?.value) {
                        setFieldValue(value.value);
                      }
                      setSelectedCustomFieldKey(key.id);
                      setCustomFieldModalContent({
                        keyId: key.id,
                        valueId: value?.id,
                        value: value?.value,
                        label: value?.value
                          ? CUSTOM_FIELD_MODAL_COPY.editLabel
                          : CUSTOM_FIELD_MODAL_COPY.newLabel,
                        supportingText: value?.value
                          ? CUSTOM_FIELD_MODAL_COPY.editSupportingText
                          : CUSTOM_FIELD_MODAL_COPY.newSupportingText,
                      });
                    }}
                  />
                </div>
              </li>
            ))}
        </ul>
        {!!customFields &&
          customFields.length > collapsedCustomFieldsListThreshold && (
            <Button
              leadingIcon={showAllCustomFields ? "chevronUp" : "chevronDown"}
              theme="linkGray"
              onClick={(_) => setShowAllCustomFields(!showAllCustomFields)}
              text={
                showAllCustomFields
                  ? "Collapse"
                  : `${customFields.length - collapsedCustomFieldsListThreshold} more`
              }
            />
          )}
      </>
    );
  }

  const currentAliases =
    customerSettingsInfoData?.Customer?.CustomerIngestAliases.map(
      ({ ingest_alias }) => ingest_alias,
    ) ?? [];

  return (
    <div className="flex flex-col gap-y-3xl">
      {allowCustomerNameEditing ? (
        <div>
          <div className="flex flex-col gap-y-lg">
            <h3 className="text-md font-semibold text-gray-800">Name</h3>
            <div className="flex flex-row items-center">
              <h3 className="mb-xs text-sm text-gray-600">
                {customerSettingsInfoData?.Customer?.name}
              </h3>
              <EditCustomerNameButton
                customerId={customerId}
                currentName={customerSettingsInfoData?.Customer?.name || ""}
              />
            </div>
          </div>

          <div className="flex flex-col gap-y-lg">
            <h3 className="text-md font-semibold text-gray-800">ID</h3>
            <div className="flex flex-row items-center">
              <CopyableID id={customerId} className="text-xs" />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="mb-xs text-md font-semibold text-gray-800">
            {customerSettingsInfoData?.Customer?.name}
          </h3>
          <CopyableID id={customerId} className="text-xs" />
        </div>
      )}

      <div className="flex flex-col gap-y-lg">
        <h3 className="text-md font-semibold text-gray-800">Ingest aliases</h3>
        {ingestAliasBlock}
        <GatedButton
          onClick={() => setIngestAliasModalOpen(true)}
          doc={UpdateIngestAliasesDocument}
          text="Add"
          theme="secondary"
          leadingIcon="plus"
          size="sm"
        />
      </div>

      <div>
        <Link
          to={`/connections/custom-fields/customer/${customerId}`}
          className="mb-lg flex items-center"
        >
          <Icon icon="arrowNarrowUpRight" size={20} className="mr-sm" />
          <h3 className="text-md font-semibold text-gray-800">Custom fields</h3>
        </Link>
        {customFieldsBlock}
      </div>

      {!hideIntegrations && <Integrations customerId={customerId} />}

      {ingestAliasModalOpen && (
        <SetIngestAliasesModal
          existingAliases={currentAliases}
          onClose={() => setIngestAliasModalOpen(false)}
          customerId={customerId}
        />
      )}

      {customFieldModalContent && (
        <Modal
          isOpen={!!customFieldModalContent}
          onClose={() => {
            setFieldValue(undefined);
            setCustomFieldModalContent(null);
          }}
          icon="checkCircle"
          title={customFieldModalContent?.label}
          supportingText={customFieldModalContent?.supportingText}
          modalButtons={[
            <Button
              size="lg"
              showFullWidth={true}
              disabled={
                setCustomerCustomFieldsLoading &&
                deleteCustomerCustomFieldsLoading
              }
              text={customFieldModalContent?.value ? "Save" : "Add"}
              onClick={async () => {
                if (fieldValue && selectedCustomFieldKey) {
                  await setCustomerCustomField({
                    variables: {
                      customer_id: customerId,
                      key_id: selectedCustomFieldKey,
                      value: fieldValue,
                    },
                    update(cache) {
                      cache.evict({
                        fieldName: "ManagedFieldKey",
                      });
                    },
                  }).finally(() => {
                    setFieldValue(undefined);
                    setCustomFieldModalContent(null);
                  });
                } else if (customFieldModalContent.valueId && !fieldValue) {
                  // delete custom field value
                  await deleteCustomerCustomFieldValue({
                    variables: {
                      id: customFieldModalContent.valueId,
                    },
                    update(cache) {
                      cache.evict({
                        fieldName: "ManagedFieldKey",
                      });
                    },
                  }).finally(() => setCustomFieldModalContent(null));
                }
              }}
            />,
            <Button
              size="lg"
              showFullWidth={true}
              text="Cancel"
              onClick={() => {
                setFieldValue(undefined);
                setCustomFieldModalContent(null);
              }}
              theme="secondary"
            />,
          ]}
        >
          <div className="flex flex-col gap-y-xl">
            <Dropdown
              label="Field Key"
              disabled={!!customFieldModalContent?.value}
              selectedOption={
                customFields.find((f) => f.key.id === selectedCustomFieldKey)
                  ?.key.value
              }
              fullWidth={true}
            >
              {customFields.map((field, index) => (
                <DropdownItem
                  key={index}
                  label={field.key.value}
                  value={field.key.id}
                  onClick={(meta) => setSelectedCustomFieldKey(meta.value)}
                />
              ))}
            </Dropdown>
            <TextInput
              label="Field value"
              placeholder="1234-5678"
              value={fieldValue}
              onChange={(meta) => setFieldValue(meta.value)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
