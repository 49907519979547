import React, { useContext } from "react";
import {
  CommitFlyoverController,
  PostpaidCommitAccessScheduleController,
  PostpaidCommitBillingScheduleController,
  PostpaidCommitTermsController,
  usePostpaidCommitAccessScheduleController,
  usePostpaidCommitAccessScheduleItemController,
  usePostpaidCommitBillingScheduleController,
  usePostpaidCommitBillingScheduleItemController,
  usePostpaidCommitTermsController,
} from ".";
import { USD_CREDIT_TYPE } from "app/lib/credits";
import { Select } from "design-system";
import Decimal from "decimal.js";
import { CreditType } from "app/types/credit-types";
import { useFeatureFlag } from "app/lib/launchdarkly";
import { useEffectToClearInputIfPricingUnitDropdownChanges } from "app/lib/pricingUnitDropdown";
import { TextInput } from "components/Input";
import { DatePicker } from "components/DatePicker";
import { dayjs } from "lib/dayjs";
import { CreditTypeContext } from "./CommitTakeover";
import { Tooltip } from "components/Tooltip";
import { Icon } from "components/Icon";
import { CommitPriceInput } from "../../lib/CommitPriceInput";
import { findCreditType } from "app/pages/Contracts/lib/CreditTypes";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import { CommitAccessScheduleItemEditability } from "types/generated-graphql/__types__";
import { getEditabilityMessage } from "../../../Edit/utils";

const PostpaidCommitAccessScheduleItem: React.FC<{
  id: string;
  parent: PostpaidCommitAccessScheduleController;
  amount: number | undefined;
  creditType: CreditType;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitAccessScheduleItemController(
    props.parent,
    props.id,
    timeframe,
    props.amount,
  );

  const editability =
    ctrl.get("editability") ?? CommitAccessScheduleItemEditability.Editable;
  const isEditable =
    editability === CommitAccessScheduleItemEditability.Editable;

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "amount",
  );

  return (
    <>
      <div className="flex w-full flex-row gap-[24px]">
        <div className="flex flex-1 flex-col">
          <CommitPriceInput
            unitPrice={ctrl.get("amount")}
            creditType={props.creditType}
            onPriceChange={(price) => {
              ctrl.update({
                amount: price,
              });
            }}
            onCreditTypeChange={() => {}}
            isInvalid={!ctrl.appearsValid() && !ctrl.isValid("amount")}
            hintText={
              (!ctrl.appearsValid() && ctrl.state.fields.amount?.error) ||
              "Amount allotted to the contract"
            }
            disableChangingCreditTypes={true}
            disabled={true}
          />
        </div>
        <div className="flex flex-row gap-lg">
          <div className="flex flex-col">
            <DatePicker
              text="Starting at"
              value={
                ctrl.get("date")
                  ? dayjs.utc(ctrl.get("date")).toDate()
                  : undefined
              }
              onDateApply={(date) => {
                ctrl.update({
                  date: date?.toISOString(),
                });
              }}
              disabled={!isEditable}
              tooltipContent={{
                label: isEditable
                  ? "Starting at"
                  : getEditabilityMessage(editability),
              }}
            />
          </div>
          <div className="flex flex-col">
            <DatePicker
              text="Ending before"
              value={
                ctrl.get("endDate")
                  ? dayjs.utc(ctrl.get("endDate")).toDate()
                  : undefined
              }
              openToDate={
                ctrl.get("endDate")
                  ? dayjs.utc(ctrl.get("endDate")).toDate()
                  : undefined
              }
              onDateApply={(date) => {
                ctrl.update({
                  endDate: date?.toISOString(),
                });
              }}
              disabled={!isEditable}
              tooltipContent={{
                label: isEditable
                  ? "Ending before"
                  : getEditabilityMessage(editability),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PostpaidCommitBillingScheduleItem: React.FC<{
  id: string;
  parent: PostpaidCommitBillingScheduleController;
  accessScheduleEnd: string | null;
  creditType: CreditType;
  setParentCreditType?: (creditType: CreditType) => void;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitBillingScheduleItemController(
    props.parent,
    props.id,
    timeframe,
    props.accessScheduleEnd,
  );

  useEffectToClearInputIfPricingUnitDropdownChanges(
    ctrl,
    props.creditType,
    "unitPrice",
  );

  return (
    <>
      <div className="mt-12 flex flex-row gap-[24px]">
        <div className="flex flex-1 flex-col">
          <p className="mb-4 min-h-[20px] text-sm text-black">Invoice amount</p>
          <CommitPriceInput
            unitPrice={ctrl.get("unitPrice")}
            creditType={props.creditType}
            onPriceChange={(price) => {
              ctrl.update({
                unitPrice: price,
              });
            }}
            onCreditTypeChange={(creditType) => {
              if (props.setParentCreditType) {
                props.setParentCreditType(creditType);
              }
            }}
            disableChangingCreditTypes={props.setParentCreditType === undefined}
            isInvalid={!ctrl.isValid("unitPrice")}
            hintText={
              (!ctrl.appearsValid() && ctrl.state.fields.unitPrice?.error) || ""
            }
          />
        </div>
        <div className="flex w-[250px] flex-col">
          <p className="mb-4 text-sm text-black">Quantity</p>
          <TextInput
            placeholder="100"
            type="number"
            disabled={false}
            value={
              ctrl.get("quantity") !== undefined
                ? (String(ctrl.get("quantity")) as string)
                : undefined
            }
            fullWidth
            onChange={({ value: quantity }) =>
              ctrl.update({
                quantity: quantity !== "" ? Number(quantity) : undefined,
              })
            }
          />
        </div>
        <div className="flex flex-none flex-col">
          <div className="mb-4 min-h-[20px]"></div>
          <DatePicker
            text="Invoice at"
            value={
              ctrl.get("date")
                ? dayjs.utc(ctrl.get("date")).toDate()
                : undefined
            }
            onDateApply={(date) => {
              ctrl.update({
                date: date?.toISOString(),
              });
            }}
            tooltipContent={{
              label: "Invoice at",
            }}
          />
        </div>
      </div>
    </>
  );
};

const PostpaidCommitAccessSchedule: React.FC<{
  parent: PostpaidCommitTermsController;
  creditType: CreditType;
  isExistingCommit: boolean;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = ctrl.get("accessSchedule");
  const billingCtrl = usePostpaidCommitBillingScheduleController(
    props.parent,
    timeframe,
  );
  const billingSchedule = billingCtrl.get("billingSchedule");
  return (
    <div className="flex flex-col gap-12">
      {accessSchedule && accessSchedule.length && (
        <div className="flex flex-col gap-4">
          <div className="mb-4 flex w-full items-center">
            <p className="text-sm text-black">Commit balance</p>
            <Tooltip label="Must match total amount in billing schedule.">
              <Icon
                className="ml-xs inline-block"
                icon="helpCircle"
                size={14}
              />
            </Tooltip>
          </div>
          <PostpaidCommitAccessScheduleItem
            id={accessSchedule[0].id}
            parent={ctrl}
            amount={
              billingSchedule
                ? new Decimal(billingSchedule[0].quantity ?? 0)
                    .mul(billingSchedule[0].unitPrice ?? 0)
                    .toNumber()
                : undefined
            }
            creditType={props.creditType}
          />
        </div>
      )}
    </div>
  );
};

const PostpaidCommitBillingSchedule: React.FC<{
  parent: PostpaidCommitTermsController;
  creditType: CreditType;
}> = (props) => {
  const timeframe = DefaultTimeframe.useFromContext();
  const ctrl = usePostpaidCommitBillingScheduleController(
    props.parent,
    timeframe,
  );
  const billingSchedule = ctrl.get("billingSchedule");
  const accessCtrl = usePostpaidCommitAccessScheduleController(
    props.parent,
    timeframe,
  );
  const accessSchedule = accessCtrl.get("accessSchedule");
  return (
    <div className="flex flex-col gap-12">
      {billingSchedule && billingSchedule.length && (
        <PostpaidCommitBillingScheduleItem
          id={billingSchedule[0].id}
          parent={ctrl}
          accessScheduleEnd={accessSchedule ? accessSchedule[0].endDate : null}
          creditType={props.creditType}
          setParentCreditType={(creditType) => {
            props.parent.update({
              // Frontend enforces having the same credit type between postpaid access and invoice schedules.
              // The access schedule credit type ID is passed into the mutation as the credit type for billing schedules.
              accessScheduleCreditTypeId: creditType.id,
            });
          }}
        />
      )}
    </div>
  );
};

export const PostpaidCommitTerms: React.FC<{
  parent: CommitFlyoverController;
  level: "contract" | "customer";
  defaultCreditType: CreditType;
  contracts?: { id: string; name?: string | null }[];
  isExistingCommit: boolean;
}> = (props) => {
  const showCurrencyWork = useFeatureFlag<boolean>(
    "contract-currencies",
    false,
  );
  const ctrl = usePostpaidCommitTermsController(
    props.parent,
    props.level,
    showCurrencyWork ? props.defaultCreditType : USD_CREDIT_TYPE,
  );

  const creditTypeContext = useContext(CreditTypeContext);
  const creditType = findCreditType(
    ctrl.get("accessScheduleCreditTypeId") ?? USD_CREDIT_TYPE.id,
    [
      ...creditTypeContext.fiatCreditTypes,
      ...creditTypeContext.customCreditTypes,
    ],
  );
  return (
    <div className="flex flex-col gap-[24px]">
      <div>
        <PostpaidCommitAccessSchedule
          parent={ctrl}
          creditType={creditType}
          isExistingCommit={props.isExistingCommit}
        />
      </div>
      <div>
        <PostpaidCommitBillingSchedule parent={ctrl} creditType={creditType} />
      </div>
      {props.level === "customer" && (
        <Select
          {...ctrl.props.Select("invoiceContractId", {
            name: "Invoice contract",
            placeholder: "Select",
            tooltip:
              "Select the contract that will be used to issue a true up invoice for this commit.",
            loading: !props.contracts,
            options:
              props.contracts?.map((c) => ({
                value: c.id,
                label: c.name ?? c.id,
              })) ?? [],
          })}
        />
      )}
    </div>
  );
};
