/* 7281dc14cc406831167c495d2044112df7fbe3d8
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GeneratePrequelAuthTokenMutationVariables = Types.Exact<{
  applicationOrigin: Types.Scalars['String'];
}>;


export type GeneratePrequelAuthTokenMutation = { __typename?: 'Mutation', generate_prequel_auth_token: { __typename?: 'PrequelAuthTokenResponse', token_string: string } };


export const GeneratePrequelAuthTokenDocument = gql`
    mutation GeneratePrequelAuthToken($applicationOrigin: String!) {
  generate_prequel_auth_token: generate_prequel_auth_token_v1(
    application_origin: $applicationOrigin
  ) {
    token_string
  }
}
    `;
export type GeneratePrequelAuthTokenMutationFn = Apollo.MutationFunction<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;

/**
 * __useGeneratePrequelAuthTokenMutation__
 *
 * To run a mutation, you first call `useGeneratePrequelAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePrequelAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePrequelAuthTokenMutation, { data, loading, error }] = useGeneratePrequelAuthTokenMutation({
 *   variables: {
 *      applicationOrigin: // value for 'applicationOrigin'
 *   },
 * });
 */
export function useGeneratePrequelAuthTokenMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>(GeneratePrequelAuthTokenDocument, options);
      }
export type GeneratePrequelAuthTokenMutationHookResult = ReturnType<typeof useGeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationResult = Apollo.MutationResult<GeneratePrequelAuthTokenMutation>;
export type GeneratePrequelAuthTokenMutationOptions = Apollo.BaseMutationOptions<GeneratePrequelAuthTokenMutation, GeneratePrequelAuthTokenMutationVariables>;