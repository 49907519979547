/* e1473788715b9e5810595b545657e66cde9e0fec
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type CustomerByIdQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, name: string, CustomerIngestAliases: Array<{ __typename?: 'CustomerIngestAlias', ingest_alias: string }> } | null };

export type EventDetailsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  starting_after: Types.Scalars['timestamptz'];
  ending_before: Types.Scalars['timestamptz'];
  transaction_ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
  ingest_aliases?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  billable_metric_ids?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
  duplicates?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EventDetailsQuery = { __typename?: 'Query', mri_events: Array<{ __typename?: 'MRI_Event', id: string, customer_id: string, event_type: string, properties: object, timestamp: string, transaction_id: string, is_duplicate: boolean, processedAt: string | null, billable_metrics: Array<{ __typename?: 'BillableMetric', id: string, name: string, is_draft: boolean }>, seat_metrics: Array<{ __typename?: 'SeatMetric', id: string, name: string, is_draft: boolean }>, customer: { __typename?: 'Customer', id: string, name: string } | null }> };

export type EventCountsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  starting_after: Types.Scalars['timestamptz'];
  ending_before: Types.Scalars['timestamptz'];
  ingest_aliases?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  billable_metric_ids?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
}>;


export type EventCountsQuery = { __typename?: 'Query', duplicates: Array<{ __typename?: 'MRI_EventCountWindow', starting_on: string, count: string }>, non_duplicates: Array<{ __typename?: 'MRI_EventCountWindow', starting_on: string, count: string }> };

export type EventsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
  starting_after: Types.Scalars['timestamptz'];
  ending_before: Types.Scalars['timestamptz'];
  limit: Types.Scalars['Int'];
  ingest_aliases?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  transaction_ids?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  billable_metric_ids?: Types.InputMaybe<Array<Types.Scalars['uuid']> | Types.Scalars['uuid']>;
  duplicates?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EventsQuery = { __typename?: 'Query', mri_events: Array<{ __typename?: 'MRI_Event', id: string, transaction_id: string, customer_id: string, event_type: string, timestamp: string, properties: object, is_duplicate: boolean, processedAt: string | null }> };


export const CustomerByIdDocument = gql`
    query CustomerByID($id: uuid!) {
  Customer_by_pk(id: $id) {
    id
    name
    CustomerIngestAliases {
      ingest_alias
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCustomerByIdQuery__
 *
 * To run a query within a React component, call `useCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerByIdQuery(baseOptions: Apollo.QueryHookOptions<CustomerByIdQuery, CustomerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerByIdQuery, CustomerByIdQueryVariables>(CustomerByIdDocument, options);
      }
export function useCustomerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerByIdQuery, CustomerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerByIdQuery, CustomerByIdQueryVariables>(CustomerByIdDocument, options);
        }
export type CustomerByIdQueryHookResult = ReturnType<typeof useCustomerByIdQuery>;
export type CustomerByIdLazyQueryHookResult = ReturnType<typeof useCustomerByIdLazyQuery>;
export type CustomerByIdQueryResult = Apollo.QueryResult<CustomerByIdQuery, CustomerByIdQueryVariables>;
export const EventDetailsDocument = gql`
    query EventDetails($environment_type: EnvironmentTypeEnum_enum!, $starting_after: timestamptz!, $ending_before: timestamptz!, $transaction_ids: [String!]!, $ingest_aliases: [String!], $billable_metric_ids: [uuid!], $duplicates: Boolean) {
  mri_events(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    limit: 100
    ingest_aliases: $ingest_aliases
    transaction_ids: $transaction_ids
    duplicates: $duplicates
    billable_metric_ids: $billable_metric_ids
  ) {
    id
    customer_id
    event_type
    properties
    timestamp
    transaction_id
    is_duplicate
    processedAt
    billable_metrics(types: [ACTIVE]) {
      id
      name
      is_draft
      __environment_type: environment_type
    }
    seat_metrics(types: [ACTIVE]) {
      id
      name
      is_draft
    }
    customer {
      id
      name
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useEventDetailsQuery__
 *
 * To run a query within a React component, call `useEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *      transaction_ids: // value for 'transaction_ids'
 *      ingest_aliases: // value for 'ingest_aliases'
 *      billable_metric_ids: // value for 'billable_metric_ids'
 *      duplicates: // value for 'duplicates'
 *   },
 * });
 */
export function useEventDetailsQuery(baseOptions: Apollo.QueryHookOptions<EventDetailsQuery, EventDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventDetailsQuery, EventDetailsQueryVariables>(EventDetailsDocument, options);
      }
export function useEventDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventDetailsQuery, EventDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventDetailsQuery, EventDetailsQueryVariables>(EventDetailsDocument, options);
        }
export type EventDetailsQueryHookResult = ReturnType<typeof useEventDetailsQuery>;
export type EventDetailsLazyQueryHookResult = ReturnType<typeof useEventDetailsLazyQuery>;
export type EventDetailsQueryResult = Apollo.QueryResult<EventDetailsQuery, EventDetailsQueryVariables>;
export const EventCountsDocument = gql`
    query EventCounts($environment_type: EnvironmentTypeEnum_enum!, $starting_after: timestamptz!, $ending_before: timestamptz!, $ingest_aliases: [String!], $billable_metric_ids: [uuid!]) {
  duplicates: mri_event_count(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    ingest_aliases: $ingest_aliases
    billable_metric_ids: $billable_metric_ids
    duplicates: true
  ) {
    starting_on
    count
  }
  non_duplicates: mri_event_count(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    ingest_aliases: $ingest_aliases
    billable_metric_ids: $billable_metric_ids
    duplicates: false
  ) {
    starting_on
    count
  }
}
    `;

/**
 * __useEventCountsQuery__
 *
 * To run a query within a React component, call `useEventCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventCountsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *      ingest_aliases: // value for 'ingest_aliases'
 *      billable_metric_ids: // value for 'billable_metric_ids'
 *   },
 * });
 */
export function useEventCountsQuery(baseOptions: Apollo.QueryHookOptions<EventCountsQuery, EventCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventCountsQuery, EventCountsQueryVariables>(EventCountsDocument, options);
      }
export function useEventCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventCountsQuery, EventCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventCountsQuery, EventCountsQueryVariables>(EventCountsDocument, options);
        }
export type EventCountsQueryHookResult = ReturnType<typeof useEventCountsQuery>;
export type EventCountsLazyQueryHookResult = ReturnType<typeof useEventCountsLazyQuery>;
export type EventCountsQueryResult = Apollo.QueryResult<EventCountsQuery, EventCountsQueryVariables>;
export const EventsDocument = gql`
    query Events($environment_type: EnvironmentTypeEnum_enum!, $starting_after: timestamptz!, $ending_before: timestamptz!, $limit: Int!, $ingest_aliases: [String!], $transaction_ids: [String!], $billable_metric_ids: [uuid!], $duplicates: Boolean) {
  mri_events(
    environment_type: $environment_type
    starting_after: $starting_after
    ending_before: $ending_before
    limit: $limit
    ingest_aliases: $ingest_aliases
    transaction_ids: $transaction_ids
    billable_metric_ids: $billable_metric_ids
    duplicates: $duplicates
  ) {
    id
    transaction_id
    customer_id
    event_type
    timestamp
    properties
    is_duplicate
    processedAt
    __environment_type: environment_type
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *      starting_after: // value for 'starting_after'
 *      ending_before: // value for 'ending_before'
 *      limit: // value for 'limit'
 *      ingest_aliases: // value for 'ingest_aliases'
 *      transaction_ids: // value for 'transaction_ids'
 *      billable_metric_ids: // value for 'billable_metric_ids'
 *      duplicates: // value for 'duplicates'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;