import React, { Fragment, useEffect, useState } from "react";
import { Popover } from "react-tiny-popover-next";
import { IconName } from "../Icon";
import { v4 as uuid } from "uuid";
import { DefaultInput, TextInput, TextInputProps } from "../Input";
import { DropdownItem } from "../Dropdown";
import { TagsInput, TagsInputProps } from "../Input/TagsInput";

type PartialInputProps = Pick<
  DefaultInput<string>,
  | "disabled"
  | "hintText"
  | "fullWidth"
  | "label"
  | "placeholder"
  | "size"
  | "helpText"
>;

interface InputDropdownSubComponents {
  DropdownItem: typeof DropdownItem;
}

type InputDropdownProps = PartialInputProps & {
  /** Children of InputDropdown are always DropdownItems:
   *  DropdownItem - a single item/option in a Dropdown list.
   */
  children?: React.ReactNode[];
  /**
   * You can render at the top of the Dropdown some content that could give the user
   * more context or tone-setting for the rest of the Dropdown options. A settings menu
   * could show a user avatar and profile name, for example.
   */
  headerContent?: React.ReactNode;
  /** An Icon that rests to the left of the Input */
  leadingIcon?: IconName;
  /** Use a TagsInput instead */
  tagsVariant?: boolean;
  /** The value to set for the Input. For TagsInput, this should be an array */
  value?: string | string[];
  /** Callback given to TagInput dropdown  */
  onChangeTags?: TagsInputProps["onChange"];
  onUnenteredTagChange?: TagsInputProps["onUnenteredInputChange"];
  /** Callback given to TextInput dropdown  */
  onChangeText?: TextInputProps["onChange"];
  /** Callback when the user clears the TextInput dropdown  */
  onClearText?: () => void;
  isTypingEnabled?: boolean;
};

/**
 * Input dropdowns are used to group together actions in a subview. They’re useful for allowing
 * users to select from a large number of options, or to even search for options within the
 * input field before selecting.
 */
export const InputDropdown: React.FC<InputDropdownProps> &
  InputDropdownSubComponents = ({
  children,
  headerContent,
  leadingIcon,
  tagsVariant,
  value,
  onChangeTags,
  onUnenteredTagChange,
  onChangeText,
  onClearText,
  isTypingEnabled = true,
  ...inputProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const containerWidth =
    containerRef.current?.getBoundingClientRect().width ?? 240;
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    // Mouse move handler to track mouse position
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };

    // Scroll handler to check if mouse is outside dropdown
    const handleScroll = () => {
      const rect = divRef.current?.getBoundingClientRect();
      if (!rect) return;

      const { x, y } = mousePosition;

      const isMouseInside =
        x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;

      // Close the dropdown if the mouse is outside
      if (!isMouseInside) {
        setIsOpen(false);
      }
    };

    // Attach event listeners
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("scroll", handleScroll, true);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, [mousePosition]);

  const handleTextInputChange = (meta: { value: string }) => {
    if (onChangeText) {
      if (meta.value.length > 0) {
        setIsOpen(true);
      }
      if (value) {
        setIsOpen(false);
      }
      onChangeText(meta);
    }
  };

  return (
    <>
      <Popover
        positions={["bottom"]}
        isOpen={isOpen}
        transformMode="relative"
        transform={inputProps.hintText ? { top: -25 } : undefined}
        onClickOutside={() => setIsOpen(false)}
        content={
          <div
            className="mt-[5px] max-h-[450px] min-h-[50px] w-[240px] overflow-y-scroll rounded-md border border-gray-200 bg-white shadow-lg"
            style={{ width: containerWidth }}
          >
            {headerContent && (
              <div className="mb-sm flex min-h-5xl items-center border-b border-gray-200 px-xl py-md">
                {headerContent}
              </div>
            )}
            <div
              tabIndex={0}
              ref={(el) => {
                divRef.current = el;
              }}
            >
              <div className="flex flex-col overflow-y-auto">
                {children?.map((c) => <Fragment key={uuid()}>{c}</Fragment>)}
              </div>
            </div>
          </div>
        }
      >
        <div className={inputProps.fullWidth ? "w-full" : "inline-block"}>
          <div ref={containerRef}>
            {tagsVariant && value?.constructor === Array ? (
              <TagsInput
                {...inputProps}
                dropdownState={isOpen ? "open" : "closed"}
                onClick={() =>
                  !inputProps.disabled ? setIsOpen((prev) => !prev) : null
                }
                onChange={onChangeTags}
                value={value}
                leadingIcon={leadingIcon}
                isTypingEnabled={isTypingEnabled}
                onUnenteredInputChange={onUnenteredTagChange}
                skipChangeOnBlur={true}
              />
            ) : (
              <TextInput
                {...inputProps}
                dropdownState={isOpen ? "open" : "closed"}
                onClick={() => setIsOpen((prev) => !prev)}
                variantOptions={
                  leadingIcon
                    ? { variant: "icon", icon: leadingIcon }
                    : undefined
                }
                onChange={handleTextInputChange}
                onClear={onClearText}
                value={value?.constructor === String ? value : undefined}
              />
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};

InputDropdown.DropdownItem = DropdownItem;

InputDropdown.displayName = "InputDropdown";
