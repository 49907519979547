import { CreditType } from "app/types/credit-types";
import { Schema } from "../../Schema";
import { useRecurringCommitController } from "./RecurringCommitController";
import { DefaultTimeframe } from "../../lib/DefaultTimeframe";
import {
  ContractUsageInvoiceScheduleFrequencyEnum,
  ExternalCommitType,
} from "types/generated-graphql/__types__";

export type RecurringCommitTermsController = ReturnType<
  typeof useRecurringCommitTermsController
>;

export const useRecurringCommitTermsController =
  useRecurringCommitController.child(Schema.RecurringCommit, {
    read(
      parent,
      externalType: ExternalCommitType,
      timeframe: DefaultTimeframe,
      defaultCreditType: CreditType,
    ) {
      const commit = parent.get("commit");
      return {
        ...commit,
        accessAmount: {
          ...commit?.accessAmount,
          creditTypeId:
            commit?.accessAmount.creditTypeId ?? defaultCreditType.id,
        },
        invoiceAmount:
          externalType === ExternalCommitType.Credit
            ? undefined
            : {
                ...commit?.invoiceAmount,
                creditTypeId:
                  commit?.invoiceAmount?.creditTypeId ?? defaultCreditType.id,
              },
        startingAt: commit?.startingAt ?? timeframe.startingAt,
        endingBefore: commit?.endingBefore,
        recurrenceFrequency:
          commit?.recurrenceFrequency ??
          ContractUsageInvoiceScheduleFrequencyEnum.Monthly,
        externalType,
      };
    },
    write(child) {
      return {
        commit: child.getUnvalidatedInputs(),
      };
    },
  });

export const useRecurringCommitAccessAmountController =
  useRecurringCommitTermsController.child(Schema.RecurringCommitAccessAmount, {
    read(parent) {
      return parent.get("accessAmount");
    },
    write(child) {
      return {
        accessAmount: child.getUnvalidatedInputs(),
      };
    },
  });

export const useRecurringCommitInvoiceAmountController =
  useRecurringCommitTermsController.child(Schema.RecurringCommitInvoiceAmount, {
    read(parent) {
      return parent.get("invoiceAmount");
    },
    write(child) {
      return {
        invoiceAmount: child.getUnvalidatedInputs(),
      };
    },
  });
