/* 8d0b6bd3b730fdfc4bba2fd0ae83dff7fe87fd53
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckForConnectedAccountsQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type CheckForConnectedAccountsQuery = { __typename?: 'Query', stripeTokens: Array<{ __typename?: 'BillingProviderToken', account_id: string, token_string: string | null, created_at: string }>, awsMarketplaceTokens: Array<{ __typename?: 'BillingProviderToken', account_id: string, token_string: string | null, created_at: string }>, azureMarketplaceTokens: Array<{ __typename?: 'BillingProviderToken', account_id: string, token_string: string | null, created_at: string }> };

export type GetClientQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetClientQuery = { __typename?: 'Query', Client: Array<{ __typename?: 'Client', id: string }> };

export type GetCustomFieldKeysQueryVariables = Types.Exact<{
  environment_type: Types.EnvironmentTypeEnum_Enum;
}>;


export type GetCustomFieldKeysQuery = { __typename?: 'Query', ManagedFieldKey: Array<{ __typename?: 'ManagedFieldKey', id: string, entity: Types.ManagedEntityEnum_Enum, key: string, created_by: string, created_at: string, enforce_uniqueness: boolean, Creator: { __typename?: 'Actor', id: string, deprecated_at: string | null, name: string } | null }> };

export type AddCustomFieldKeysMutationVariables = Types.Exact<{
  objects: Array<Types.ManagedFieldKey_Insert_Input> | Types.ManagedFieldKey_Insert_Input;
}>;


export type AddCustomFieldKeysMutation = { __typename?: 'Mutation', insert_ManagedFieldKey: { __typename?: 'ManagedFieldKey_mutation_response', returning: Array<{ __typename?: 'ManagedFieldKey', id: string, entity: Types.ManagedEntityEnum_Enum, key: string, created_by: string, created_at: string }> } | null };

export type UpdateCustomFieldKeysMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  entity: Types.ManagedEntityEnum_Enum;
  key: Types.Scalars['String'];
}>;


export type UpdateCustomFieldKeysMutation = { __typename?: 'Mutation', update_ManagedFieldKey: { __typename?: 'ManagedFieldKey_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'ManagedFieldKey', id: string, entity: Types.ManagedEntityEnum_Enum, key: string, updated_at: string, updated_by: string }> } | null };

export type DeleteCustomFieldKeyMutationVariables = Types.Exact<{
  keyId: Types.Scalars['uuid'];
}>;


export type DeleteCustomFieldKeyMutation = { __typename?: 'Mutation', disable_managed_field_key: { __typename?: 'ManagedFieldKey', id: string } };

export type GetActiveManagedFieldKeyCountQueryVariables = Types.Exact<{
  entity: Types.ManagedEntityEnum_Enum;
}>;


export type GetActiveManagedFieldKeyCountQuery = { __typename?: 'Query', active_key_count: { __typename?: 'ManagedFieldKey_aggregate', aggregate: { __typename?: 'ManagedFieldKey_aggregate_fields', count: number } | null } };

export type GetVendorEntityMappingsQueryVariables = Types.Exact<{
  vendors?: Types.InputMaybe<Array<Types.ManagedVendorEnum_Enum> | Types.ManagedVendorEnum_Enum>;
}>;


export type GetVendorEntityMappingsQuery = { __typename?: 'Query', vendorMappings: Array<{ __typename?: 'ManagedFieldVendorMapping', id: string, entity: Types.ManagedEntityEnum_Enum, vendor: Types.ManagedVendorEnum_Enum, vendor_entity: string, vendor_field: string, ManagedFieldKey: { __typename?: 'ManagedFieldKey', id: string, key: string } }> };

export type SaveVendorEntityMappingsMutationVariables = Types.Exact<{
  objects: Array<Types.SaveVendorEntityMappingInput> | Types.SaveVendorEntityMappingInput;
}>;


export type SaveVendorEntityMappingsMutation = { __typename?: 'Mutation', save_vendor_entity_mappings: { __typename?: 'SaveVendorEntityMappingsResponse', affected_rows: number, returning: Array<{ __typename?: 'ManagedFieldVendorMapping', id: string, entity: Types.ManagedEntityEnum_Enum, vendor: Types.ManagedVendorEnum_Enum, vendor_entity: string, vendor_field: string, ManagedFieldKey: { __typename?: 'ManagedFieldKey', id: string, key: string } }> } };

export type DeleteVendorEntityMappingMutationVariables = Types.Exact<{
  mapping_id: Types.Scalars['uuid'];
}>;


export type DeleteVendorEntityMappingMutation = { __typename?: 'Mutation', delete_ManagedFieldVendorMapping_by_pk: { __typename?: 'ManagedFieldVendorMapping', id: string, entity: Types.ManagedEntityEnum_Enum, vendor: Types.ManagedVendorEnum_Enum, vendor_entity: string, vendor_field: string, ManagedFieldKey: { __typename?: 'ManagedFieldKey', id: string, key: string } } | null };


export const CheckForConnectedAccountsDocument = gql`
    query CheckForConnectedAccounts($environment_type: EnvironmentTypeEnum_enum!) {
  stripeTokens: BillingProviderToken(
    where: {billing_provider: {_eq: STRIPE}, environment_type: {_eq: $environment_type}}
  ) {
    account_id
    token_string
    created_at
    __environment_type: environment_type
  }
  awsMarketplaceTokens: BillingProviderToken(
    where: {billing_provider: {_eq: AWS_MARKETPLACE}, environment_type: {_eq: $environment_type}}
  ) {
    account_id
    token_string
    created_at
    __environment_type: environment_type
  }
  azureMarketplaceTokens: BillingProviderToken(
    where: {billing_provider: {_eq: AZURE}, environment_type: {_eq: $environment_type}}
  ) {
    account_id
    token_string
    created_at
    __environment_type: environment_type
  }
}
    `;

/**
 * __useCheckForConnectedAccountsQuery__
 *
 * To run a query within a React component, call `useCheckForConnectedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForConnectedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForConnectedAccountsQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useCheckForConnectedAccountsQuery(baseOptions: Apollo.QueryHookOptions<CheckForConnectedAccountsQuery, CheckForConnectedAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckForConnectedAccountsQuery, CheckForConnectedAccountsQueryVariables>(CheckForConnectedAccountsDocument, options);
      }
export function useCheckForConnectedAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForConnectedAccountsQuery, CheckForConnectedAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckForConnectedAccountsQuery, CheckForConnectedAccountsQueryVariables>(CheckForConnectedAccountsDocument, options);
        }
export type CheckForConnectedAccountsQueryHookResult = ReturnType<typeof useCheckForConnectedAccountsQuery>;
export type CheckForConnectedAccountsLazyQueryHookResult = ReturnType<typeof useCheckForConnectedAccountsLazyQuery>;
export type CheckForConnectedAccountsQueryResult = Apollo.QueryResult<CheckForConnectedAccountsQuery, CheckForConnectedAccountsQueryVariables>;
export const GetClientDocument = gql`
    query GetClient {
  Client {
    id
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientQuery(baseOptions?: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const GetCustomFieldKeysDocument = gql`
    query GetCustomFieldKeys($environment_type: EnvironmentTypeEnum_enum!) {
  ManagedFieldKey(
    where: {environment_type: {_eq: $environment_type}, disabled_at: {_is_null: true}, is_active: {_eq: true}}
  ) {
    id
    entity
    key
    created_by
    created_at
    enforce_uniqueness
    Creator {
      id
      deprecated_at
      name
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetCustomFieldKeysQuery__
 *
 * To run a query within a React component, call `useGetCustomFieldKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomFieldKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomFieldKeysQuery({
 *   variables: {
 *      environment_type: // value for 'environment_type'
 *   },
 * });
 */
export function useGetCustomFieldKeysQuery(baseOptions: Apollo.QueryHookOptions<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>(GetCustomFieldKeysDocument, options);
      }
export function useGetCustomFieldKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>(GetCustomFieldKeysDocument, options);
        }
export type GetCustomFieldKeysQueryHookResult = ReturnType<typeof useGetCustomFieldKeysQuery>;
export type GetCustomFieldKeysLazyQueryHookResult = ReturnType<typeof useGetCustomFieldKeysLazyQuery>;
export type GetCustomFieldKeysQueryResult = Apollo.QueryResult<GetCustomFieldKeysQuery, GetCustomFieldKeysQueryVariables>;
export const AddCustomFieldKeysDocument = gql`
    mutation AddCustomFieldKeys($objects: [ManagedFieldKey_insert_input!]!) {
  insert_ManagedFieldKey(objects: $objects) {
    returning {
      id
      entity
      key
      created_by
      created_at
      __environment_type: environment_type
    }
  }
}
    `;
export type AddCustomFieldKeysMutationFn = Apollo.MutationFunction<AddCustomFieldKeysMutation, AddCustomFieldKeysMutationVariables>;

/**
 * __useAddCustomFieldKeysMutation__
 *
 * To run a mutation, you first call `useAddCustomFieldKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomFieldKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomFieldKeysMutation, { data, loading, error }] = useAddCustomFieldKeysMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useAddCustomFieldKeysMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomFieldKeysMutation, AddCustomFieldKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomFieldKeysMutation, AddCustomFieldKeysMutationVariables>(AddCustomFieldKeysDocument, options);
      }
export type AddCustomFieldKeysMutationHookResult = ReturnType<typeof useAddCustomFieldKeysMutation>;
export type AddCustomFieldKeysMutationResult = Apollo.MutationResult<AddCustomFieldKeysMutation>;
export type AddCustomFieldKeysMutationOptions = Apollo.BaseMutationOptions<AddCustomFieldKeysMutation, AddCustomFieldKeysMutationVariables>;
export const UpdateCustomFieldKeysDocument = gql`
    mutation UpdateCustomFieldKeys($id: uuid!, $entity: ManagedEntityEnum_enum!, $key: String!) {
  update_ManagedFieldKey(
    where: {id: {_eq: $id}}
    _set: {entity: $entity, key: $key}
  ) {
    affected_rows
    returning {
      id
      entity
      key
      updated_at
      updated_by
      __environment_type: environment_type
    }
  }
}
    `;
export type UpdateCustomFieldKeysMutationFn = Apollo.MutationFunction<UpdateCustomFieldKeysMutation, UpdateCustomFieldKeysMutationVariables>;

/**
 * __useUpdateCustomFieldKeysMutation__
 *
 * To run a mutation, you first call `useUpdateCustomFieldKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomFieldKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomFieldKeysMutation, { data, loading, error }] = useUpdateCustomFieldKeysMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entity: // value for 'entity'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useUpdateCustomFieldKeysMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomFieldKeysMutation, UpdateCustomFieldKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomFieldKeysMutation, UpdateCustomFieldKeysMutationVariables>(UpdateCustomFieldKeysDocument, options);
      }
export type UpdateCustomFieldKeysMutationHookResult = ReturnType<typeof useUpdateCustomFieldKeysMutation>;
export type UpdateCustomFieldKeysMutationResult = Apollo.MutationResult<UpdateCustomFieldKeysMutation>;
export type UpdateCustomFieldKeysMutationOptions = Apollo.BaseMutationOptions<UpdateCustomFieldKeysMutation, UpdateCustomFieldKeysMutationVariables>;
export const DeleteCustomFieldKeyDocument = gql`
    mutation DeleteCustomFieldKey($keyId: uuid!) {
  disable_managed_field_key(key_id: $keyId) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteCustomFieldKeyMutationFn = Apollo.MutationFunction<DeleteCustomFieldKeyMutation, DeleteCustomFieldKeyMutationVariables>;

/**
 * __useDeleteCustomFieldKeyMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFieldKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFieldKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFieldKeyMutation, { data, loading, error }] = useDeleteCustomFieldKeyMutation({
 *   variables: {
 *      keyId: // value for 'keyId'
 *   },
 * });
 */
export function useDeleteCustomFieldKeyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFieldKeyMutation, DeleteCustomFieldKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomFieldKeyMutation, DeleteCustomFieldKeyMutationVariables>(DeleteCustomFieldKeyDocument, options);
      }
export type DeleteCustomFieldKeyMutationHookResult = ReturnType<typeof useDeleteCustomFieldKeyMutation>;
export type DeleteCustomFieldKeyMutationResult = Apollo.MutationResult<DeleteCustomFieldKeyMutation>;
export type DeleteCustomFieldKeyMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFieldKeyMutation, DeleteCustomFieldKeyMutationVariables>;
export const GetActiveManagedFieldKeyCountDocument = gql`
    query GetActiveManagedFieldKeyCount($entity: ManagedEntityEnum_enum!) {
  active_key_count: ManagedFieldKey_aggregate(
    where: {entity: {_eq: $entity}, is_active: {_eq: true}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetActiveManagedFieldKeyCountQuery__
 *
 * To run a query within a React component, call `useGetActiveManagedFieldKeyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveManagedFieldKeyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveManagedFieldKeyCountQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useGetActiveManagedFieldKeyCountQuery(baseOptions: Apollo.QueryHookOptions<GetActiveManagedFieldKeyCountQuery, GetActiveManagedFieldKeyCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveManagedFieldKeyCountQuery, GetActiveManagedFieldKeyCountQueryVariables>(GetActiveManagedFieldKeyCountDocument, options);
      }
export function useGetActiveManagedFieldKeyCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveManagedFieldKeyCountQuery, GetActiveManagedFieldKeyCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveManagedFieldKeyCountQuery, GetActiveManagedFieldKeyCountQueryVariables>(GetActiveManagedFieldKeyCountDocument, options);
        }
export type GetActiveManagedFieldKeyCountQueryHookResult = ReturnType<typeof useGetActiveManagedFieldKeyCountQuery>;
export type GetActiveManagedFieldKeyCountLazyQueryHookResult = ReturnType<typeof useGetActiveManagedFieldKeyCountLazyQuery>;
export type GetActiveManagedFieldKeyCountQueryResult = Apollo.QueryResult<GetActiveManagedFieldKeyCountQuery, GetActiveManagedFieldKeyCountQueryVariables>;
export const GetVendorEntityMappingsDocument = gql`
    query GetVendorEntityMappings($vendors: [ManagedVendorEnum_enum!]) {
  vendorMappings: ManagedFieldVendorMapping(where: {vendor: {_in: $vendors}}) {
    id
    entity
    vendor
    vendor_entity
    vendor_field
    ManagedFieldKey {
      id
      key
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useGetVendorEntityMappingsQuery__
 *
 * To run a query within a React component, call `useGetVendorEntityMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorEntityMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorEntityMappingsQuery({
 *   variables: {
 *      vendors: // value for 'vendors'
 *   },
 * });
 */
export function useGetVendorEntityMappingsQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorEntityMappingsQuery, GetVendorEntityMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorEntityMappingsQuery, GetVendorEntityMappingsQueryVariables>(GetVendorEntityMappingsDocument, options);
      }
export function useGetVendorEntityMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorEntityMappingsQuery, GetVendorEntityMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorEntityMappingsQuery, GetVendorEntityMappingsQueryVariables>(GetVendorEntityMappingsDocument, options);
        }
export type GetVendorEntityMappingsQueryHookResult = ReturnType<typeof useGetVendorEntityMappingsQuery>;
export type GetVendorEntityMappingsLazyQueryHookResult = ReturnType<typeof useGetVendorEntityMappingsLazyQuery>;
export type GetVendorEntityMappingsQueryResult = Apollo.QueryResult<GetVendorEntityMappingsQuery, GetVendorEntityMappingsQueryVariables>;
export const SaveVendorEntityMappingsDocument = gql`
    mutation SaveVendorEntityMappings($objects: [SaveVendorEntityMappingInput!]!) {
  save_vendor_entity_mappings(objects: $objects) {
    affected_rows
    returning {
      id
      entity
      vendor
      vendor_entity
      vendor_field
      ManagedFieldKey {
        id
        key
        __environment_type: environment_type
      }
      __environment_type: environment_type
    }
  }
}
    `;
export type SaveVendorEntityMappingsMutationFn = Apollo.MutationFunction<SaveVendorEntityMappingsMutation, SaveVendorEntityMappingsMutationVariables>;

/**
 * __useSaveVendorEntityMappingsMutation__
 *
 * To run a mutation, you first call `useSaveVendorEntityMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVendorEntityMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVendorEntityMappingsMutation, { data, loading, error }] = useSaveVendorEntityMappingsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useSaveVendorEntityMappingsMutation(baseOptions?: Apollo.MutationHookOptions<SaveVendorEntityMappingsMutation, SaveVendorEntityMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveVendorEntityMappingsMutation, SaveVendorEntityMappingsMutationVariables>(SaveVendorEntityMappingsDocument, options);
      }
export type SaveVendorEntityMappingsMutationHookResult = ReturnType<typeof useSaveVendorEntityMappingsMutation>;
export type SaveVendorEntityMappingsMutationResult = Apollo.MutationResult<SaveVendorEntityMappingsMutation>;
export type SaveVendorEntityMappingsMutationOptions = Apollo.BaseMutationOptions<SaveVendorEntityMappingsMutation, SaveVendorEntityMappingsMutationVariables>;
export const DeleteVendorEntityMappingDocument = gql`
    mutation DeleteVendorEntityMapping($mapping_id: uuid!) {
  delete_ManagedFieldVendorMapping_by_pk(id: $mapping_id) {
    id
    entity
    vendor
    vendor_entity
    vendor_field
    ManagedFieldKey {
      id
      key
      __environment_type: environment_type
    }
    __environment_type: environment_type
  }
}
    `;
export type DeleteVendorEntityMappingMutationFn = Apollo.MutationFunction<DeleteVendorEntityMappingMutation, DeleteVendorEntityMappingMutationVariables>;

/**
 * __useDeleteVendorEntityMappingMutation__
 *
 * To run a mutation, you first call `useDeleteVendorEntityMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorEntityMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorEntityMappingMutation, { data, loading, error }] = useDeleteVendorEntityMappingMutation({
 *   variables: {
 *      mapping_id: // value for 'mapping_id'
 *   },
 * });
 */
export function useDeleteVendorEntityMappingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVendorEntityMappingMutation, DeleteVendorEntityMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVendorEntityMappingMutation, DeleteVendorEntityMappingMutationVariables>(DeleteVendorEntityMappingDocument, options);
      }
export type DeleteVendorEntityMappingMutationHookResult = ReturnType<typeof useDeleteVendorEntityMappingMutation>;
export type DeleteVendorEntityMappingMutationResult = Apollo.MutationResult<DeleteVendorEntityMappingMutation>;
export type DeleteVendorEntityMappingMutationOptions = Apollo.BaseMutationOptions<DeleteVendorEntityMappingMutation, DeleteVendorEntityMappingMutationVariables>;