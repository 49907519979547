import { USD_CREDIT_ID } from "app/lib/credits";
import { Schema } from "app/pages/Contracts/Pricing/Schema";

import {
  RateCardEntryInput,
  RateCardAliasInput,
  RateCardCreditTypeConversionInput,
  RateInput,
  ContractUsageInvoiceScheduleFrequencyEnum,
} from "types/generated-graphql/__types__";
import Decimal from "decimal.js";

const PRICE_TYPE_TO_RATE_TYPE = {
  flat: "FLAT",
  subscription: "SUBSCRIPTION",
  percentage: "PERCENTAGE",
  tiered: "TIERED",
  custom: "CUSTOM",
};

function convertToGraphqlRateInput(
  rate: Schema.Types.Rate,
  newSubscription?: boolean,
): RateInput {
  const price = rate.price;
  if (!price) {
    throw new Error("A valid price is required");
  }
  let rateType = PRICE_TYPE_TO_RATE_TYPE[price.type];
  // For new subscriptions, rateType must be FLAT to add billing frequency
  // TODO: Remove once old subscriptions are depricated
  if (rateType === "SUBSCRIPTION" && newSubscription) {
    rateType = "FLAT";
    price.type = "flat";
  }
  const multiplier: Decimal =
    rate.creditType?.id === USD_CREDIT_ID ? new Decimal(100) : new Decimal(1);
  return {
    type: rateType,
    flat_rate:
      price.type === "flat"
        ? {
            unit_price: new Decimal(price.price ?? 0)
              .mul(multiplier)
              .toString(),
            credit_type_id: rate.creditType?.id,
          }
        : undefined,
    subscription_rate:
      price.type === "subscription"
        ? {
            unit_price: new Decimal(price.price ?? 0)
              .mul(multiplier)
              .toString(),
            quantity: String(price.quantity),
            is_prorated: price.isProrated,
            credit_type_id: rate.creditType?.id,
          }
        : undefined,
    percentage_rate:
      price.type === "percentage"
        ? {
            fraction: new Decimal(price.fraction ?? 0)
              .div(new Decimal(100))
              .toString(),
            use_list_prices: price.useListPrices,
          }
        : undefined,
    tiered_rate:
      price.type === "tiered"
        ? {
            tiers: convertToGraphqlTiers(price.tiers, multiplier),
            credit_type_id: rate.creditType?.id,
          }
        : undefined,
  };
}

export function formRatesAsGraphqlRates(
  rates: Schema.Types.Rate[],
  newSubscriptions: boolean,
): RateCardEntryInput[] {
  const graphqlRates: RateCardEntryInput[] = [];
  rates.forEach((r, idx) => {
    if (!r.isCommitPrice) {
      let commit_rate: RateInput | undefined;
      if (r.hasCommitRate && rates[idx + 1]?.isCommitPrice) {
        commit_rate = convertToGraphqlRateInput(
          rates[idx + 1],
          newSubscriptions,
        );
      }

      graphqlRates.push({
        product_list_item_id: r.productId,
        effective_at: r.startingAt,
        ending_before: r.endingBefore,
        entitled: r.entitled === "enable" ? true : false,
        pricing_groups: r.pricingGroupValues,
        rate: convertToGraphqlRateInput(r, newSubscriptions),
        billing_frequency:
          r.billingFrequency as ContractUsageInvoiceScheduleFrequencyEnum,
        commit_rate,
      });
    }
  });

  return graphqlRates;
}

// For the first tier, size is equal to lastUnit
// For subsequent tiers, size is lastUnit - previous tier's lastUnit
const convertToGraphqlTiers = (
  tiers: Schema.Types.Tier[],
  multiplier: Decimal,
) => {
  return tiers.map((t, index) => {
    let size: number | undefined;
    if (index === 0) {
      size = t.lastUnit;
    } else {
      const prevLastUnit = tiers[index - 1].lastUnit;
      size =
        t.lastUnit !== undefined && prevLastUnit !== undefined
          ? t.lastUnit - prevLastUnit
          : undefined;
    }

    return {
      size: size !== undefined ? String(size) : undefined,
      unit_price: new Decimal(t.unitPrice ?? 0).mul(multiplier).toString(),
    };
  });
};

export function formAliasesAsGraphqlAliases(
  aliases: Schema.Types.RateCardAlias[],
): RateCardAliasInput[] {
  return aliases.map((a) => ({
    name: a.name,
    starting_at: a.startingAt,
    ending_before: a.endingBefore,
  }));
}

export function formCreditTypeConversionsAsGraphqlCreditTypeConversions(
  creditTypeConversions: Schema.Types.CreditTypeConversion[],
  fiatCreditTypeId: string,
): RateCardCreditTypeConversionInput[] {
  const multiplier: Decimal =
    fiatCreditTypeId === USD_CREDIT_ID ? new Decimal(100) : new Decimal(1);
  return creditTypeConversions.map((c) => ({
    custom_credit_type_id: c.custom_credit_type_id,
    fiat_per_custom_credit: new Decimal(c.fiat_per_custom_credit)
      .mul(multiplier)
      .toString(),
  }));
}

export const getBadgeTheme = (badgeLabel: string) => {
  switch (badgeLabel) {
    case "Subscription":
      return "indigo";
    case "Usage":
      return "gray";
    case "Composite":
      return "vibrant-magenta";
    default:
      return undefined;
  }
};

export const getBadgeLabel = (productType: string | undefined) => {
  switch (productType) {
    case "CompositeProductListItem":
      return "Composite";
    case "FixedProductListItem":
      return "Fixed";
    case "ProServiceProductListItem":
      return "Professional Service";
    case "SubscriptionProductListItem":
      return "Subscription";
    case "UsageProductListItem":
      return "Usage";
    default:
      return "";
  }
};
