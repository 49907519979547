/* 31ac9133d641e4fea28577993f3fc8d226e1d8ab
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateContractMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
  customer_id: Types.Scalars['uuid'];
  starting_at: Types.Scalars['timestamptz'];
  ending_before?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  rate_card_id?: Types.InputMaybe<Types.Scalars['uuid']>;
  overrides?: Types.InputMaybe<Array<Types.RateCardEntryOverrideInput> | Types.RateCardEntryOverrideInput>;
  scheduled_charges?: Types.InputMaybe<Array<Types.ScheduledChargeInput> | Types.ScheduledChargeInput>;
  scheduled_charges_on_usage_invoices?: Types.InputMaybe<Types.ContractScheduledChargesOnUsageInvoices>;
  discounts?: Types.InputMaybe<Array<Types.DiscountInput> | Types.DiscountInput>;
  pro_services?: Types.InputMaybe<Array<Types.ProServiceInput> | Types.ProServiceInput>;
  commits: Array<Types.CommitInput> | Types.CommitInput;
  recurring_commits: Array<Types.RecurringCommitInput> | Types.RecurringCommitInput;
  reseller_royalties?: Types.InputMaybe<Array<Types.ResellerRoyaltyInput> | Types.ResellerRoyaltyInput>;
  net_payment_terms_days?: Types.InputMaybe<Types.Scalars['Int']>;
  salesforce_opportunity_id?: Types.InputMaybe<Types.Scalars['String']>;
  netsuite_sales_order_id?: Types.InputMaybe<Types.Scalars['String']>;
  usage_filter?: Types.InputMaybe<Types.CreateContractUsageFilterInput>;
  transition?: Types.InputMaybe<Types.TransitionInput>;
  usage_invoice_frequency: Types.ContractUsageInvoiceScheduleFrequencyEnum;
  billing_cycle_anchor: Types.ContractUsageInvoiceBillingCycleAnchorEnum;
  billing_cycle_anchor_date?: Types.InputMaybe<Types.Scalars['timestamptz']>;
  billing_provider_configuration?: Types.InputMaybe<Types.BillingProviderConfigurationInput>;
  multiplier_override_prioritization?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateContractMutation = { __typename?: 'Mutation', create_contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } } };

export type ContractRoutePathFragment = { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } };

export type AmendContractMutationVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  contract_id: Types.Scalars['uuid'];
  effective_at: Types.Scalars['timestamptz'];
  overrides?: Types.InputMaybe<Array<Types.RateCardEntryOverrideInput> | Types.RateCardEntryOverrideInput>;
  scheduled_charges?: Types.InputMaybe<Array<Types.ScheduledChargeInput> | Types.ScheduledChargeInput>;
  discounts?: Types.InputMaybe<Array<Types.DiscountInput> | Types.DiscountInput>;
  commits: Array<Types.CommitInput> | Types.CommitInput;
  salesforce_opportunity_id?: Types.InputMaybe<Types.Scalars['String']>;
  netsuite_sales_order_id?: Types.InputMaybe<Types.Scalars['String']>;
  reseller_royalties?: Types.InputMaybe<Array<Types.ResellerRoyaltyOrUpdateInput> | Types.ResellerRoyaltyOrUpdateInput>;
  pro_services?: Types.InputMaybe<Array<Types.ProServiceInput> | Types.ProServiceInput>;
}>;


export type AmendContractMutation = { __typename?: 'Mutation', amend_contract: { __typename?: 'Contract', id: string, customer: { __typename?: 'Customer', id: string } } };

export type ContractToAmendQueryVariables = Types.Exact<{
  customer_id: Types.Scalars['uuid'];
  contract_id: Types.Scalars['uuid'];
}>;


export type ContractToAmendQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, name: string, contract: { __typename?: 'Contract', starting_at: string, ending_before: string | null, multiplier_override_prioritization: string | null, name: string | null, id: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null, customer: { __typename?: 'Customer', id: string }, v2_fields: { __typename?: 'ContractV2Fields', overrides: Array<{ __typename?: 'RateCardEntryOverride', id: string, amendment_id: string | null, starting_at: string, ending_before: string | null, type: Types.OverrideType | null, override_target: Types.OverrideTarget | null, entitled: boolean | null, override_specifiers: Array<{ __typename?: 'OverrideSpecifier', product_id: string | null, product_tags: Array<string> | null, commit_ids: Array<string> | null, pricing_group_values: Array<{ __typename?: 'PricingGroupValue', name: string, value: string }> | null, presentation_group_values: Array<{ __typename?: 'PresentationGroupValue', name: string, value: string | null }> | null }>, change: { __typename: 'MultiplierOverride', multiplier: string, priority: string | null } | { __typename: 'OverwriteOverride', new_rate: { __typename: 'CustomRate', value: object } | { __typename: 'FlatRate', unit_price: string } | { __typename: 'PercentageRate', fraction: string, use_list_prices: boolean } | { __typename: 'SubscriptionRate', is_prorated: boolean, unit_price: string, quantity: string } | { __typename: 'TieredRate', tiers: Array<{ __typename?: 'Tier', size: string | null, unit_price: string }> } } | { __typename: 'TieredOverride', tiered_override_priority: string, tiers: Array<{ __typename?: 'TieredOverrideTiers', size: string | null, multiplier: string }> } | null }> } } | null } | null };

export type ContractNameFragment = { __typename?: 'Contract', name: string | null, starting_at: string, rate_card: { __typename?: 'RateCard', id: string, name: string } | null };

export type RateScheduleTable__ContractOverrideInfoFragment = { __typename?: 'Contract', v2_fields: { __typename?: 'ContractV2Fields', overrides: Array<{ __typename?: 'RateCardEntryOverride', id: string, amendment_id: string | null, starting_at: string, ending_before: string | null, type: Types.OverrideType | null, override_target: Types.OverrideTarget | null, entitled: boolean | null, override_specifiers: Array<{ __typename?: 'OverrideSpecifier', product_id: string | null, product_tags: Array<string> | null, commit_ids: Array<string> | null, pricing_group_values: Array<{ __typename?: 'PricingGroupValue', name: string, value: string }> | null, presentation_group_values: Array<{ __typename?: 'PresentationGroupValue', name: string, value: string | null }> | null }>, change: { __typename: 'MultiplierOverride', multiplier: string, priority: string | null } | { __typename: 'OverwriteOverride', new_rate: { __typename: 'CustomRate', value: object } | { __typename: 'FlatRate', unit_price: string } | { __typename: 'PercentageRate', fraction: string, use_list_prices: boolean } | { __typename: 'SubscriptionRate', is_prorated: boolean, unit_price: string, quantity: string } | { __typename: 'TieredRate', tiers: Array<{ __typename?: 'Tier', size: string | null, unit_price: string }> } } | { __typename: 'TieredOverride', tiered_override_priority: string, tiers: Array<{ __typename?: 'TieredOverrideTiers', size: string | null, multiplier: string }> } | null }> } };

export type Override__DescribeFragment = { __typename?: 'RateCardEntryOverride', id: string, amendment_id: string | null, starting_at: string, ending_before: string | null, type: Types.OverrideType | null, override_target: Types.OverrideTarget | null, entitled: boolean | null, override_specifiers: Array<{ __typename?: 'OverrideSpecifier', product_id: string | null, product_tags: Array<string> | null, commit_ids: Array<string> | null, pricing_group_values: Array<{ __typename?: 'PricingGroupValue', name: string, value: string }> | null, presentation_group_values: Array<{ __typename?: 'PresentationGroupValue', name: string, value: string | null }> | null }>, change: { __typename: 'MultiplierOverride', multiplier: string, priority: string | null } | { __typename: 'OverwriteOverride', new_rate: { __typename: 'CustomRate', value: object } | { __typename: 'FlatRate', unit_price: string } | { __typename: 'PercentageRate', fraction: string, use_list_prices: boolean } | { __typename: 'SubscriptionRate', is_prorated: boolean, unit_price: string, quantity: string } | { __typename: 'TieredRate', tiers: Array<{ __typename?: 'Tier', size: string | null, unit_price: string }> } } | { __typename: 'TieredOverride', tiered_override_priority: string, tiers: Array<{ __typename?: 'TieredOverrideTiers', size: string | null, multiplier: string }> } | null };

export type ContractCreate_ContractPricingFragment = { __typename?: 'ContractPricing', rate_cards: Array<{ __typename?: 'RateCardMetadata', name: string, id: string }>, products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> }> };

export type ContractPricing_RateCardNamesFragment = { __typename?: 'ContractPricing', rate_cards: Array<{ __typename?: 'RateCardMetadata', name: string, id: string }> };

export type ContractPricing_RateCardIdsFragment = { __typename?: 'ContractPricing', rate_cards: Array<{ __typename?: 'RateCardMetadata', id: string }> };

export type ContractPricing_ProductIdsFragment = { __typename?: 'ContractPricing', products: Array<{ __typename?: 'CompositeProductListItem', id: string } | { __typename?: 'FixedProductListItem', id: string } | { __typename?: 'ProServiceProductListItem', id: string } | { __typename?: 'SubscriptionProductListItem', id: string } | { __typename?: 'UsageProductListItem', id: string }> };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

export type ContractPricing_ProductTypesFragment = { __typename?: 'ContractPricing', products: Array<{ __typename: 'CompositeProductListItem' } | { __typename: 'FixedProductListItem' } | { __typename: 'ProServiceProductListItem' } | { __typename: 'SubscriptionProductListItem' } | { __typename: 'UsageProductListItem' }> };

type ProductListItemType_CompositeProductListItem_Fragment = { __typename: 'CompositeProductListItem' };

type ProductListItemType_FixedProductListItem_Fragment = { __typename: 'FixedProductListItem' };

type ProductListItemType_ProServiceProductListItem_Fragment = { __typename: 'ProServiceProductListItem' };

type ProductListItemType_SubscriptionProductListItem_Fragment = { __typename: 'SubscriptionProductListItem' };

type ProductListItemType_UsageProductListItem_Fragment = { __typename: 'UsageProductListItem' };

export type ProductListItemTypeFragment = ProductListItemType_CompositeProductListItem_Fragment | ProductListItemType_FixedProductListItem_Fragment | ProductListItemType_ProServiceProductListItem_Fragment | ProductListItemType_SubscriptionProductListItem_Fragment | ProductListItemType_UsageProductListItem_Fragment;

export type ContractPricing_ProductTagsFragment = { __typename?: 'ContractPricing', products: Array<{ __typename?: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> } | { __typename?: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> } | { __typename?: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> } | { __typename?: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> } | { __typename?: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> }> };

type ProductListItemTags_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

type ProductListItemTags_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string }> };

export type ProductListItemTagsFragment = ProductListItemTags_CompositeProductListItem_Fragment | ProductListItemTags_FixedProductListItem_Fragment | ProductListItemTags_ProServiceProductListItem_Fragment | ProductListItemTags_SubscriptionProductListItem_Fragment | ProductListItemTags_UsageProductListItem_Fragment;

export type ContractPricing_ProductNamesFragment = { __typename?: 'ContractPricing', products: Array<{ __typename?: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> }> };

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

export type ContractPricingAndCreditTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContractPricingAndCreditTypesQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_cards: Array<{ __typename?: 'RateCardMetadata', name: string, id: string }>, products: Array<{ __typename: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> } | { __typename: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', tags: Array<string>, created_at: string, name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', tags: Array<string> | null, effective_at: string, name: string | null }> }> }, CreditType: Array<{ __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null }> };

export type CreditTypeFragment = { __typename?: 'CreditType', id: string, name: string, client_id: string | null, environment_type: Types.EnvironmentTypeEnum_Enum | null };

export type ContractCreate_ExistingContractFragment = { __typename?: 'Contract', id: string, starting_at: string, ending_before: string | null, archived_at: string | null, name: string | null, rate_card: { __typename?: 'RateCard', id: string, name: string } | null };

export type ExistingCustomerContractsQueryVariables = Types.Exact<{
  customerId: Types.Scalars['uuid'];
}>;


export type ExistingCustomerContractsQuery = { __typename?: 'Query', Customer_by_pk: { __typename?: 'Customer', id: string, name: string, contracts: Array<{ __typename?: 'Contract', id: string, starting_at: string, ending_before: string | null, archived_at: string | null, name: string | null, rate_card: { __typename?: 'RateCard', id: string, name: string } | null }> } | null };

export const ContractRoutePathFragmentDoc = gql`
    fragment ContractRoutePath on Contract {
  id
  customer {
    id
    __environment_type: environment_type
  }
}
    `;
export const Override__DescribeFragmentDoc = gql`
    fragment Override__Describe on RateCardEntryOverride {
  id
  amendment_id
  starting_at
  ending_before
  type
  override_target
  override_specifiers {
    pricing_group_values {
      name
      value
    }
    presentation_group_values {
      name
      value
    }
    product_id
    product_tags
    commit_ids
  }
  entitled
  change: rate {
    __typename
    ... on MultiplierOverride {
      multiplier
      priority
    }
    ... on OverwriteOverride {
      new_rate {
        __typename
        ... on FlatRate {
          unit_price
        }
        ... on PercentageRate {
          fraction
          use_list_prices
        }
        ... on SubscriptionRate {
          is_prorated
          unit_price
          quantity
        }
        ... on TieredRate {
          tiers {
            size
            unit_price
          }
        }
        ... on CustomRate {
          value
        }
      }
    }
    ... on TieredOverride {
      tiers {
        size
        multiplier
      }
      tiered_override_priority: priority
    }
  }
}
    `;
export const RateScheduleTable__ContractOverrideInfoFragmentDoc = gql`
    fragment RateScheduleTable__ContractOverrideInfo on Contract {
  v2_fields {
    overrides {
      ...Override__Describe
    }
  }
}
    ${Override__DescribeFragmentDoc}`;
export const ContractPricing_RateCardIdsFragmentDoc = gql`
    fragment ContractPricing_RateCardIds on ContractPricing {
  rate_cards {
    id
  }
}
    `;
export const ContractPricing_RateCardNamesFragmentDoc = gql`
    fragment ContractPricing_RateCardNames on ContractPricing {
  ...ContractPricing_RateCardIds
  rate_cards {
    name
  }
}
    ${ContractPricing_RateCardIdsFragmentDoc}`;
export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ContractPricing_ProductIdsFragmentDoc = gql`
    fragment ContractPricing_ProductIds on ContractPricing {
  products {
    ...ProductListItemId
  }
}
    ${ProductListItemIdFragmentDoc}`;
export const ProductListItemTypeFragmentDoc = gql`
    fragment ProductListItemType on ProductListItem {
  __typename
}
    `;
export const ContractPricing_ProductTypesFragmentDoc = gql`
    fragment ContractPricing_ProductTypes on ContractPricing {
  products {
    ...ProductListItemType
  }
}
    ${ProductListItemTypeFragmentDoc}`;
export const ProductListItemTagsFragmentDoc = gql`
    fragment ProductListItemTags on ProductListItem {
  ... on FixedProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      tags
      created_at
    }
    updates {
      tags
      effective_at
    }
  }
}
    `;
export const ContractPricing_ProductTagsFragmentDoc = gql`
    fragment ContractPricing_ProductTags on ContractPricing {
  ...ContractPricing_ProductIds
  products {
    ...ProductListItemTags
  }
}
    ${ContractPricing_ProductIdsFragmentDoc}
${ProductListItemTagsFragmentDoc}`;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const ContractPricing_ProductNamesFragmentDoc = gql`
    fragment ContractPricing_ProductNames on ContractPricing {
  ...ContractPricing_ProductIds
  products {
    ...ProductListItemName
  }
}
    ${ContractPricing_ProductIdsFragmentDoc}
${ProductListItemNameFragmentDoc}`;
export const ContractCreate_ContractPricingFragmentDoc = gql`
    fragment ContractCreate_ContractPricing on ContractPricing {
  ...ContractPricing_RateCardNames
  ...ContractPricing_ProductIds
  ...ContractPricing_ProductTypes
  ...ContractPricing_ProductTags
  ...ContractPricing_ProductNames
}
    ${ContractPricing_RateCardNamesFragmentDoc}
${ContractPricing_ProductIdsFragmentDoc}
${ContractPricing_ProductTypesFragmentDoc}
${ContractPricing_ProductTagsFragmentDoc}
${ContractPricing_ProductNamesFragmentDoc}`;
export const CreditTypeFragmentDoc = gql`
    fragment CreditType on CreditType {
  id
  name
  client_id
  environment_type
  __environment_type: environment_type
}
    `;
export const ContractNameFragmentDoc = gql`
    fragment ContractName on Contract {
  name
  starting_at
  rate_card {
    id
    name
  }
}
    `;
export const ContractCreate_ExistingContractFragmentDoc = gql`
    fragment ContractCreate_ExistingContract on Contract {
  id
  starting_at
  ending_before
  archived_at
  ...ContractName
}
    ${ContractNameFragmentDoc}`;
export const CreateContractDocument = gql`
    mutation CreateContract($name: String, $customer_id: uuid!, $starting_at: timestamptz!, $ending_before: timestamptz, $rate_card_id: uuid, $overrides: [RateCardEntryOverrideInput!], $scheduled_charges: [ScheduledChargeInput!], $scheduled_charges_on_usage_invoices: ContractScheduledChargesOnUsageInvoices, $discounts: [DiscountInput!], $pro_services: [ProServiceInput!], $commits: [CommitInput!]!, $recurring_commits: [RecurringCommitInput!]!, $reseller_royalties: [ResellerRoyaltyInput!], $net_payment_terms_days: Int, $salesforce_opportunity_id: String, $netsuite_sales_order_id: String, $usage_filter: CreateContractUsageFilterInput, $transition: TransitionInput, $usage_invoice_frequency: ContractUsageInvoiceScheduleFrequencyEnum!, $billing_cycle_anchor: ContractUsageInvoiceBillingCycleAnchorEnum!, $billing_cycle_anchor_date: timestamptz, $billing_provider_configuration: BillingProviderConfigurationInput, $multiplier_override_prioritization: String) {
  create_contract(
    name: $name
    customer_id: $customer_id
    starting_at: $starting_at
    ending_before: $ending_before
    rate_card_id: $rate_card_id
    overrides: $overrides
    commits: $commits
    recurring_commits: $recurring_commits
    reseller_royalties: $reseller_royalties
    net_payment_terms_days: $net_payment_terms_days
    salesforce_opportunity_id: $salesforce_opportunity_id
    netsuite_sales_order_id: $netsuite_sales_order_id
    usage_filter: $usage_filter
    scheduled_charges: $scheduled_charges
    scheduled_charges_on_usage_invoices: $scheduled_charges_on_usage_invoices
    discounts: $discounts
    pro_services: $pro_services
    transition: $transition
    usage_invoice_schedule: {frequency: $usage_invoice_frequency, billing_cycle_anchor: $billing_cycle_anchor, billing_cycle_anchor_date: $billing_cycle_anchor_date}
    multiplier_override_prioritization: $multiplier_override_prioritization
    billing_provider_configuration: $billing_provider_configuration
  ) {
    ...ContractRoutePath
  }
}
    ${ContractRoutePathFragmentDoc}`;
export type CreateContractMutationFn = Apollo.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      name: // value for 'name'
 *      customer_id: // value for 'customer_id'
 *      starting_at: // value for 'starting_at'
 *      ending_before: // value for 'ending_before'
 *      rate_card_id: // value for 'rate_card_id'
 *      overrides: // value for 'overrides'
 *      scheduled_charges: // value for 'scheduled_charges'
 *      scheduled_charges_on_usage_invoices: // value for 'scheduled_charges_on_usage_invoices'
 *      discounts: // value for 'discounts'
 *      pro_services: // value for 'pro_services'
 *      commits: // value for 'commits'
 *      recurring_commits: // value for 'recurring_commits'
 *      reseller_royalties: // value for 'reseller_royalties'
 *      net_payment_terms_days: // value for 'net_payment_terms_days'
 *      salesforce_opportunity_id: // value for 'salesforce_opportunity_id'
 *      netsuite_sales_order_id: // value for 'netsuite_sales_order_id'
 *      usage_filter: // value for 'usage_filter'
 *      transition: // value for 'transition'
 *      usage_invoice_frequency: // value for 'usage_invoice_frequency'
 *      billing_cycle_anchor: // value for 'billing_cycle_anchor'
 *      billing_cycle_anchor_date: // value for 'billing_cycle_anchor_date'
 *      billing_provider_configuration: // value for 'billing_provider_configuration'
 *      multiplier_override_prioritization: // value for 'multiplier_override_prioritization'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: Apollo.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, options);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const AmendContractDocument = gql`
    mutation AmendContract($customer_id: uuid!, $contract_id: uuid!, $effective_at: timestamptz!, $overrides: [RateCardEntryOverrideInput!], $scheduled_charges: [ScheduledChargeInput!], $discounts: [DiscountInput!], $commits: [CommitInput!]!, $salesforce_opportunity_id: String, $netsuite_sales_order_id: String, $reseller_royalties: [ResellerRoyaltyOrUpdateInput!], $pro_services: [ProServiceInput!]) {
  amend_contract(
    customer_id: $customer_id
    contract_id: $contract_id
    effective_at: $effective_at
    overrides: $overrides
    scheduled_charges: $scheduled_charges
    discounts: $discounts
    commits: $commits
    salesforce_opportunity_id: $salesforce_opportunity_id
    netsuite_sales_order_id: $netsuite_sales_order_id
    reseller_royalties: $reseller_royalties
    pro_services: $pro_services
  ) {
    ...ContractRoutePath
  }
}
    ${ContractRoutePathFragmentDoc}`;
export type AmendContractMutationFn = Apollo.MutationFunction<AmendContractMutation, AmendContractMutationVariables>;

/**
 * __useAmendContractMutation__
 *
 * To run a mutation, you first call `useAmendContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAmendContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [amendContractMutation, { data, loading, error }] = useAmendContractMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      contract_id: // value for 'contract_id'
 *      effective_at: // value for 'effective_at'
 *      overrides: // value for 'overrides'
 *      scheduled_charges: // value for 'scheduled_charges'
 *      discounts: // value for 'discounts'
 *      commits: // value for 'commits'
 *      salesforce_opportunity_id: // value for 'salesforce_opportunity_id'
 *      netsuite_sales_order_id: // value for 'netsuite_sales_order_id'
 *      reseller_royalties: // value for 'reseller_royalties'
 *      pro_services: // value for 'pro_services'
 *   },
 * });
 */
export function useAmendContractMutation(baseOptions?: Apollo.MutationHookOptions<AmendContractMutation, AmendContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AmendContractMutation, AmendContractMutationVariables>(AmendContractDocument, options);
      }
export type AmendContractMutationHookResult = ReturnType<typeof useAmendContractMutation>;
export type AmendContractMutationResult = Apollo.MutationResult<AmendContractMutation>;
export type AmendContractMutationOptions = Apollo.BaseMutationOptions<AmendContractMutation, AmendContractMutationVariables>;
export const ContractToAmendDocument = gql`
    query ContractToAmend($customer_id: uuid!, $contract_id: uuid!) {
  Customer_by_pk(id: $customer_id) {
    id
    name
    contract(id: $contract_id) {
      ...ContractName
      ...ContractRoutePath
      ...RateScheduleTable__ContractOverrideInfo
      starting_at
      ending_before
      rate_card {
        id
      }
      multiplier_override_prioritization
    }
    __environment_type: environment_type
  }
}
    ${ContractNameFragmentDoc}
${ContractRoutePathFragmentDoc}
${RateScheduleTable__ContractOverrideInfoFragmentDoc}`;

/**
 * __useContractToAmendQuery__
 *
 * To run a query within a React component, call `useContractToAmendQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractToAmendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractToAmendQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      contract_id: // value for 'contract_id'
 *   },
 * });
 */
export function useContractToAmendQuery(baseOptions: Apollo.QueryHookOptions<ContractToAmendQuery, ContractToAmendQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractToAmendQuery, ContractToAmendQueryVariables>(ContractToAmendDocument, options);
      }
export function useContractToAmendLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractToAmendQuery, ContractToAmendQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractToAmendQuery, ContractToAmendQueryVariables>(ContractToAmendDocument, options);
        }
export type ContractToAmendQueryHookResult = ReturnType<typeof useContractToAmendQuery>;
export type ContractToAmendLazyQueryHookResult = ReturnType<typeof useContractToAmendLazyQuery>;
export type ContractToAmendQueryResult = Apollo.QueryResult<ContractToAmendQuery, ContractToAmendQueryVariables>;
export const ContractPricingAndCreditTypesDocument = gql`
    query ContractPricingAndCreditTypes {
  contract_pricing {
    ...ContractCreate_ContractPricing
  }
  CreditType(where: {archived_at: {_is_null: true}}) {
    ...CreditType
    __environment_type: environment_type
  }
}
    ${ContractCreate_ContractPricingFragmentDoc}
${CreditTypeFragmentDoc}`;

/**
 * __useContractPricingAndCreditTypesQuery__
 *
 * To run a query within a React component, call `useContractPricingAndCreditTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractPricingAndCreditTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractPricingAndCreditTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractPricingAndCreditTypesQuery(baseOptions?: Apollo.QueryHookOptions<ContractPricingAndCreditTypesQuery, ContractPricingAndCreditTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractPricingAndCreditTypesQuery, ContractPricingAndCreditTypesQueryVariables>(ContractPricingAndCreditTypesDocument, options);
      }
export function useContractPricingAndCreditTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractPricingAndCreditTypesQuery, ContractPricingAndCreditTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractPricingAndCreditTypesQuery, ContractPricingAndCreditTypesQueryVariables>(ContractPricingAndCreditTypesDocument, options);
        }
export type ContractPricingAndCreditTypesQueryHookResult = ReturnType<typeof useContractPricingAndCreditTypesQuery>;
export type ContractPricingAndCreditTypesLazyQueryHookResult = ReturnType<typeof useContractPricingAndCreditTypesLazyQuery>;
export type ContractPricingAndCreditTypesQueryResult = Apollo.QueryResult<ContractPricingAndCreditTypesQuery, ContractPricingAndCreditTypesQueryVariables>;
export const ExistingCustomerContractsDocument = gql`
    query ExistingCustomerContracts($customerId: uuid!) {
  Customer_by_pk(id: $customerId) {
    id
    name
    contracts {
      ...ContractCreate_ExistingContract
    }
    __environment_type: environment_type
  }
}
    ${ContractCreate_ExistingContractFragmentDoc}`;

/**
 * __useExistingCustomerContractsQuery__
 *
 * To run a query within a React component, call `useExistingCustomerContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingCustomerContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingCustomerContractsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useExistingCustomerContractsQuery(baseOptions: Apollo.QueryHookOptions<ExistingCustomerContractsQuery, ExistingCustomerContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistingCustomerContractsQuery, ExistingCustomerContractsQueryVariables>(ExistingCustomerContractsDocument, options);
      }
export function useExistingCustomerContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistingCustomerContractsQuery, ExistingCustomerContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistingCustomerContractsQuery, ExistingCustomerContractsQueryVariables>(ExistingCustomerContractsDocument, options);
        }
export type ExistingCustomerContractsQueryHookResult = ReturnType<typeof useExistingCustomerContractsQuery>;
export type ExistingCustomerContractsLazyQueryHookResult = ReturnType<typeof useExistingCustomerContractsLazyQuery>;
export type ExistingCustomerContractsQueryResult = Apollo.QueryResult<ExistingCustomerContractsQuery, ExistingCustomerContractsQueryVariables>;