import { Body, Select, Option, Toggle } from "design-system";
import { Button } from "components/Button";
import { DeprecatedPopup } from "components/deprecated/Popup";
import { useSnackbar } from "components/deprecated/Snackbar";
import { Maybe } from "graphql/jsutils/Maybe";
import { getUserFacingErrorMessage } from "app/lib/errors/errorHandling";
import {
  useAddCustomFieldKeysMutation,
  useUpdateCustomFieldKeysMutation,
  useGetActiveManagedFieldKeyCountQuery,
} from "app/pages/deprecated/GeneralSettings/queries.graphql";
import React from "react";
import { useState } from "react";
import {
  ManagedEntityEnum_Enum,
  ManagedFieldKey,
} from "types/generated-graphql/__types__";
import { useUIMode } from "app/lib/useUIMode";

const ENTITY_TO_NAME: Record<ManagedEntityEnum_Enum, string> = {
  [ManagedEntityEnum_Enum.Customer]: "Customer",
  [ManagedEntityEnum_Enum.CreditGrant]: "Credit Grant",
  [ManagedEntityEnum_Enum.Product]: "Product",
  [ManagedEntityEnum_Enum.Charge]: "Charge",
  [ManagedEntityEnum_Enum.CustomerPlan]: "Customer Plan",
  [ManagedEntityEnum_Enum.Plan]: "Plan",
  [ManagedEntityEnum_Enum.BillableMetric]: "Billable Metric",
  [ManagedEntityEnum_Enum.Contract]: "Contract",
  [ManagedEntityEnum_Enum.ContractProduct]: "Product (Contracts)",
  [ManagedEntityEnum_Enum.RateCard]: "Rate Card",
  [ManagedEntityEnum_Enum.Commit]: "Commit",
  [ManagedEntityEnum_Enum.Alert]: "Alert",
  [ManagedEntityEnum_Enum.Invoice]: "Invoice",
  [ManagedEntityEnum_Enum.ContractCredit]: "Credit (Contracts)",
  [ManagedEntityEnum_Enum.ScheduledCharge]: "Scheduled Charge",
  [ManagedEntityEnum_Enum.ProService]: "Professional Service",
  [ManagedEntityEnum_Enum.Discount]: "Discount",
};

const PLAN_ONLY_ENTITIES: ManagedEntityEnum_Enum[] = [
  ManagedEntityEnum_Enum.CreditGrant,
  ManagedEntityEnum_Enum.Product,
  ManagedEntityEnum_Enum.Charge,
  ManagedEntityEnum_Enum.Plan,
];

const entityToOption = (e: ManagedEntityEnum_Enum) => ({
  label: ENTITY_TO_NAME[e],
  value: e,
});

export const SUPPORTED_ENTITIES: Option[] = Object.keys(ENTITY_TO_NAME)
  .sort()
  .map((e) => entityToOption(e as ManagedEntityEnum_Enum));

const TEXT = {
  create: {
    title: "Create a new custom field key",
    descriptionEntityField: `Most Metronome entities have a custom field parameter.
You can use this parameter to attach key-value data to Metronome entities such as customers, products, credit grants, and charges.`,
  },
  edit: {
    title: "Edit custom field key",
    descriptionEntityField: `Edit the field name. This change will not interrupt any integration mappings tied to this managed field.
The name cannot match an existing field with the same entity. `,
  },
};

const MAX_CUSTOM_FIELD_KEY_LENGTH = 100;
export const MAX_CUSTOM_FIELD_VALUE_LENGTH = 200;
const MAX_CUSTOM_FIELD_KEYS_PER_ENTITY = 50;

const FIELD_KEY_LENGTH_VALIDATION_ERROR = new Error(
  `Key name cannot be longer than ${MAX_CUSTOM_FIELD_KEY_LENGTH} characters`,
);
const FIELD_KEY_CHARS_VALIDATION_ERROR = new Error(
  "Key name can only contain letters, numbers, hyphens or underscores",
);
const MAX_ENTITY_FIELD_KEYS_ERROR = new Error(
  `Cannot have more than ${MAX_CUSTOM_FIELD_KEYS_PER_ENTITY} keys per entity`,
);

type EditableCustomFieldKey = Pick<
  ManagedFieldKey,
  "id" | "entity" | "key" | "updated_at" | "enforce_uniqueness"
>;

interface CreateOrEditCustomFieldModalProps {
  customFieldKey?: EditableCustomFieldKey;
  mode: "create" | "edit";
  onClose: () => void;
}
export const CreateOrEditCustomFieldModal: React.FC<
  CreateOrEditCustomFieldModalProps
> = ({ customFieldKey, mode, onClose }) => {
  const { mode: uiMode } = useUIMode();
  const FILTERED_SUPPORTED_ENTITIES: Option[] = SUPPORTED_ENTITIES.filter(
    (e) => {
      return uiMode === "contracts-only"
        ? !PLAN_ONLY_ENTITIES.includes(e.value as ManagedEntityEnum_Enum)
        : true;
    },
  );
  const [entityName, setEntityName] = useState<Maybe<ManagedEntityEnum_Enum>>(
    customFieldKey?.entity ?? null,
  );
  const [entityFields, setEntityFields] = useState<string[]>(
    customFieldKey?.key ? [customFieldKey?.key] : [],
  );
  const [uniqueValuesRequired, setUniqueValuesRequired] = useState<boolean>(
    customFieldKey?.enforce_uniqueness ?? false,
  );
  const [addCustomFieldKeysMutation, { loading: addCustomFieldKeysLoading }] =
    useAddCustomFieldKeysMutation({
      update(cache) {
        cache.evict({
          fieldName: "ManagedFieldKey",
        });
      },
    });
  const [
    updateCustomFieldKeysMutation,
    { loading: updateCustomFieldKeysLoading },
  ] = useUpdateCustomFieldKeysMutation({
    update(cache) {
      cache.evict({
        fieldName: "ManagedFieldKey",
      });
    },
  });
  const [addFieldError, setFieldError] = useState<Error | null>(null);
  const loading = addCustomFieldKeysLoading || updateCustomFieldKeysLoading;
  const pushMessage = useSnackbar();
  const { data: customFieldKeys } = useGetActiveManagedFieldKeyCountQuery({
    variables: {
      entity: entityName as ManagedEntityEnum_Enum,
    },
  });
  const activeKeyCount = customFieldKeys?.active_key_count?.aggregate?.count;

  const actionButtons = (
    <>
      <Button onClick={onClose} text="Cancel" theme="linkGray" />
      <Button
        onClick={() => {
          if (activeKeyCount === undefined) {
            pushMessage({
              type: "error",
              content: `Failed to get count of active custom fields keys on entity ${entityName}`,
            });
            return;
          } else if (activeKeyCount >= MAX_CUSTOM_FIELD_KEYS_PER_ENTITY) {
            setFieldError(MAX_ENTITY_FIELD_KEYS_ERROR);
            return;
          }
          const addOrEditMutation =
            mode === "create"
              ? async () => {
                  if (entityName && entityFields?.length) {
                    await addCustomFieldKeysMutation({
                      variables: {
                        objects: entityFields.map((field) => ({
                          entity: entityName,
                          key: field,
                          enforce_uniqueness: uniqueValuesRequired,
                        })),
                      },
                    });
                  }
                }
              : async () => {
                  if (customFieldKey && entityName && entityFields?.length) {
                    await updateCustomFieldKeysMutation({
                      variables: {
                        id: customFieldKey?.id as string,
                        entity: entityName as ManagedEntityEnum_Enum,
                        key: entityFields[0],
                      },
                    });
                  }
                };
          addOrEditMutation().then(onClose).catch(setFieldError);
        }}
        loading={loading}
        disabled={!!addFieldError || !entityName || !entityFields?.length}
        text="Save"
        theme="primary"
      />
    </>
  );

  const [fieldSearchResultValue, setFieldSearchResultValue] = useState("");

  const toOptions = (vals: string[]) =>
    vals.filter((v) => !!v).map((v) => ({ label: v, value: v }));
  return (
    <DeprecatedPopup
      actions={actionButtons}
      isOpen={true}
      onRequestClose={onClose}
      title={TEXT[mode].title}
      className="!w-[498px]"
    >
      <Body level={2}>{TEXT[mode].descriptionEntityField}</Body>
      <div className="flex flex-row py-12">
        <Select
          name="Entity"
          placeholder="Customer"
          options={FILTERED_SUPPORTED_ENTITIES}
          disabled={mode === "edit"}
          value={entityName ?? ""}
          onChange={(v: string) => {
            setEntityName(v as ManagedEntityEnum_Enum);
          }}
          className="w-1/2"
        />

        {mode === "create" ? (
          <Select
            name="Key"
            placeholder="Enter one or more keys"
            options={toOptions([...entityFields, fieldSearchResultValue])}
            multiSelect={true}
            value={entityFields}
            className="ml-12 !w-[300px]"
            onChange={(values: string[]) => {
              for (var value of values) {
                const { valid, error } = validateFieldKey(value);
                if (!valid) {
                  setFieldError(error as Error);
                  return;
                }
              }
              setEntityFields(values);
            }}
            onSearch={(value) => {
              setFieldSearchResultValue(value);
              setFieldError(null);
            }}
            error={addFieldError ? getErrorMessage(addFieldError) : undefined}
            __internalComponentOverrides={{
              DropdownIndicator: () => null,
            }}
          />
        ) : (
          <Select
            name="Key"
            placeholder="Rename key"
            options={toOptions([fieldSearchResultValue, ...entityFields])}
            value={entityFields[0]}
            className="ml-12 !w-[300px]"
            onChange={(value: string) => {
              const { valid, error } = validateFieldKey(value);
              if (!valid) {
                setFieldError(error as Error);
              } else {
                setEntityFields([value]);
              }
            }}
            onSearch={(value: string) => {
              setFieldSearchResultValue(value);
              setFieldError(null);
            }}
            error={addFieldError ? getErrorMessage(addFieldError) : undefined}
            __internalComponentOverrides={{
              DropdownIndicator: () => null,
            }}
          />
        )}
      </div>
      <Body level={2}>
        Set a key's value as unique for all instances of this entity. Changing
        this value after a key has been created is not supported.
      </Body>
      <Toggle
        className="w-max"
        disabled={mode === "edit"}
        checked={uniqueValuesRequired}
        onChange={(checked: boolean) => setUniqueValuesRequired(checked)}
        label="Unique values required"
      ></Toggle>
    </DeprecatedPopup>
  );
};

const KNOWN_ERROR_MSGS = [
  FIELD_KEY_CHARS_VALIDATION_ERROR,
  FIELD_KEY_LENGTH_VALIDATION_ERROR,
  MAX_ENTITY_FIELD_KEYS_ERROR,
].map((e) => e.message);
function getErrorMessage(e: any): string {
  const isDuplicateKeyError = getUserFacingErrorMessage(e).includes(
    "Uniqueness violation",
  );

  if (isDuplicateKeyError) {
    // special case because the error from resolvers isn't user-friendly
    return "Custom field key with this name already exists";
  } else if (
    // if this is a known error, return the message
    KNOWN_ERROR_MSGS.includes(e.message)
  ) {
    return e.message;
  } else {
    return "Invalid custom field key, try again with a different name";
  }
}

function validateFieldKey(value: string): {
  valid: boolean;
  error?: Error;
} {
  if (value.length > MAX_CUSTOM_FIELD_KEY_LENGTH) {
    return { valid: false, error: FIELD_KEY_LENGTH_VALIDATION_ERROR };
  }
  if (value.match(/[a-zA-Z0-9_-]+/)?.[0] != value) {
    return { valid: false, error: FIELD_KEY_CHARS_VALIDATION_ERROR };
  }
  return { valid: true };
}
