import React from "react";
import { Destination, ProductConfig, useProducts } from "@prequel/react";
import { PREQUEL_API_URL } from "app/lib/dataExport";
import { SectionHeader } from "components/SectionHeader";
import { Checkbox } from "components/Checkbox";

const V1_PRODUCT = "v1";
const EVENTS_PRODUCT = "events_v1";
const DRAFT_INVOICES_PRODUCT = "draft_invoices_v1";
const applicationOrigin = window.location.origin;

type ProductsAndModelsProps = {
  fetchPrequelAuthToken: () => Promise<string>;

  destination: Destination;
  setDestination: React.Dispatch<React.SetStateAction<Partial<Destination>>>;
  draftInvoicesModelsEnabled?: boolean;
};

export const ProductsAndModels = ({
  fetchPrequelAuthToken,
  destination,
  setDestination,
  draftInvoicesModelsEnabled,
}: ProductsAndModelsProps) => {
  const selectedModels = destination.enabled_models || [];
  const setSelectedModels = (models: string[]) => {
    setDestination({ enabled_models: models });
  };

  const productConfigs = useProducts(
    fetchPrequelAuthToken,
    applicationOrigin,
    PREQUEL_API_URL,
  );

  return (
    <div className="my-12">
      <SectionHeader
        title="Select the Metronome data to sync to your destination"
        bottomBorder={false}
        subtitle=""
      />
      <div>
        {destination.products?.includes(V1_PRODUCT) ? (
          <ProductColumn
            product="Billing data"
            config={productConfigs?.find((p) => p.product_name === V1_PRODUCT)}
            selected={selectedModels}
            setSelected={setSelectedModels}
          />
        ) : null}
        {destination.products?.includes(EVENTS_PRODUCT) ? (
          <ProductColumn
            product="Events data"
            config={productConfigs?.find(
              (p) => p.product_name === EVENTS_PRODUCT,
            )}
            selected={selectedModels}
            setSelected={setSelectedModels}
          />
        ) : null}
        {destination.products?.includes(DRAFT_INVOICES_PRODUCT) &&
        draftInvoicesModelsEnabled ? (
          <ProductColumn
            product="Draft invoice data"
            config={productConfigs?.find(
              (p) => p.product_name === DRAFT_INVOICES_PRODUCT,
            )}
            selected={selectedModels}
            setSelected={setSelectedModels}
          />
        ) : null}
      </div>
    </div>
  );
};

const ProductColumn: React.FC<{
  product: string;
  config: ProductConfig | undefined;
  selected: string[];
  setSelected: (models: string[]) => void;
}> = ({ product, config, selected, setSelected }) => {
  if (!config) {
    return <></>;
  }

  const models = config.models.sort();

  return (
    <div className="my-12">
      <label className="mb-sm text-md text-black">{product}</label>
      {models?.map((modelName) => (
        <Checkbox
          key={modelName}
          size="sm"
          label={modelName}
          checked={selected.includes(modelName)}
          onChange={() => {
            if (selected.includes(modelName)) {
              setSelected(selected.filter((m) => m !== modelName));
            } else {
              setSelected([...selected, modelName]);
            }
          }}
        />
      ))}
    </div>
  );
};
