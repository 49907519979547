/* add220193b5346acfe71b42ed3459493b07aaa73
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ContractRateCardsListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ContractRateCardsListQuery = { __typename?: 'Query', contract_pricing: { __typename?: 'ContractPricing', rate_cards: Array<{ __typename?: 'RateCardMetadata', id: string, name: string, updated_at: string, products: Array<{ __typename?: 'CompositeProductListItem', id: string, initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'FixedProductListItem', id: string, initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'ProServiceProductListItem', id: string, initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'SubscriptionProductListItem', id: string, initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> } | { __typename?: 'UsageProductListItem', id: string, initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> }>, Creator: { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null } }> } };

type ProductListItemId_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', id: string };

type ProductListItemId_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', id: string };

type ProductListItemId_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', id: string };

type ProductListItemId_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', id: string };

type ProductListItemId_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', id: string };

export type ProductListItemIdFragment = ProductListItemId_CompositeProductListItem_Fragment | ProductListItemId_FixedProductListItem_Fragment | ProductListItemId_ProServiceProductListItem_Fragment | ProductListItemId_SubscriptionProductListItem_Fragment | ProductListItemId_UsageProductListItem_Fragment;

type ProductListItemName_CompositeProductListItem_Fragment = { __typename?: 'CompositeProductListItem', initial: { __typename?: 'CompositeProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_FixedProductListItem_Fragment = { __typename?: 'FixedProductListItem', initial: { __typename?: 'FixedProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_ProServiceProductListItem_Fragment = { __typename?: 'ProServiceProductListItem', initial: { __typename?: 'ProServiceProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_SubscriptionProductListItem_Fragment = { __typename?: 'SubscriptionProductListItem', initial: { __typename?: 'SubscriptionProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

type ProductListItemName_UsageProductListItem_Fragment = { __typename?: 'UsageProductListItem', initial: { __typename?: 'UsageProductListItemState', name: string }, updates: Array<{ __typename?: 'ProductListItemUpdate', name: string | null, effective_at: string }> };

export type ProductListItemNameFragment = ProductListItemName_CompositeProductListItem_Fragment | ProductListItemName_FixedProductListItem_Fragment | ProductListItemName_ProServiceProductListItem_Fragment | ProductListItemName_SubscriptionProductListItem_Fragment | ProductListItemName_UsageProductListItem_Fragment;

export type ActorFragment = { __typename?: 'Actor', id: string, name: string, deprecated_at: string | null };

export const ProductListItemIdFragmentDoc = gql`
    fragment ProductListItemId on ProductListItem {
  ... on FixedProductListItem {
    id
  }
  ... on ProServiceProductListItem {
    id
  }
  ... on UsageProductListItem {
    id
  }
  ... on SubscriptionProductListItem {
    id
  }
  ... on CompositeProductListItem {
    id
  }
}
    `;
export const ProductListItemNameFragmentDoc = gql`
    fragment ProductListItemName on ProductListItem {
  ... on FixedProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on ProServiceProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on UsageProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on SubscriptionProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
  ... on CompositeProductListItem {
    initial {
      name
    }
    updates {
      name
      effective_at
    }
  }
}
    `;
export const ActorFragmentDoc = gql`
    fragment Actor on Actor {
  id
  name
  deprecated_at
}
    `;
export const ContractRateCardsListDocument = gql`
    query ContractRateCardsList {
  contract_pricing {
    rate_cards {
      id
      name
      products {
        ...ProductListItemId
        ...ProductListItemName
      }
      Creator {
        ...Actor
      }
      updated_at
    }
  }
}
    ${ProductListItemIdFragmentDoc}
${ProductListItemNameFragmentDoc}
${ActorFragmentDoc}`;

/**
 * __useContractRateCardsListQuery__
 *
 * To run a query within a React component, call `useContractRateCardsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractRateCardsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractRateCardsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useContractRateCardsListQuery(baseOptions?: Apollo.QueryHookOptions<ContractRateCardsListQuery, ContractRateCardsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractRateCardsListQuery, ContractRateCardsListQueryVariables>(ContractRateCardsListDocument, options);
      }
export function useContractRateCardsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractRateCardsListQuery, ContractRateCardsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractRateCardsListQuery, ContractRateCardsListQueryVariables>(ContractRateCardsListDocument, options);
        }
export type ContractRateCardsListQueryHookResult = ReturnType<typeof useContractRateCardsListQuery>;
export type ContractRateCardsListLazyQueryHookResult = ReturnType<typeof useContractRateCardsListLazyQuery>;
export type ContractRateCardsListQueryResult = Apollo.QueryResult<ContractRateCardsListQuery, ContractRateCardsListQueryVariables>;