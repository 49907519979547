import React from "react";

import { DeprecatedPanel } from "components/deprecated/Panel";
import { DeprecatedCopyableID } from "components/deprecated/CopyableID";
import { DeprecatedDefinitionList } from "components/deprecated/DefinitionList";

import { useNow, toDayjs, printDateRange } from "lib/date";
import { TopLevelDetailsFragment } from "./data.graphql";

import { ContractStatusBadge } from "../components/ContractStatusBadge";
import { Icon } from "design-system";
import { DeprecatedInternalLink } from "components/deprecated/Typography";
import { Contract } from "../../../lib/Contract";
import { ContractUsageFilterHistoryTable } from "../components/ContractUsageFilterHistoryTable";
import { formatBillingProvider } from "app/lib/billingProvider/formatBillingProvider";
import { ContractScheduledChargesOnUsageInvoices } from "types/generated-graphql/__types__";
import { useFeatureFlag } from "app/lib/launchdarkly";

interface Props {
  contract: TopLevelDetailsFragment;
}

const BILLING_PROVIDER_LABEL = "Billing provider";

const formatFrequency = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const TopLevelDetails: React.FC<Props> = ({ contract }) => {
  const now = useNow();
  const scheduledChargesOnUsageInvoicesEnabled = useFeatureFlag<boolean>(
    "scheduled-charges-on-usage-invoices",
    false,
  );

  const transition = Contract.getTransition(contract);

  const billingProvider =
    contract.customer_billing_provider_configuration?.billing_provider;

  return (
    <DeprecatedPanel title="Contract details">
      <div className="flex gap-32 p-12">
        <DeprecatedDefinitionList
          list={[
            {
              label: "Contract ID",
              value: (
                <DeprecatedCopyableID id={contract.id} label="contract ID" />
              ),
            },

            {
              label: "Contract length",
              value: printDateRange(
                now,
                toDayjs(contract.starting_at),
                contract.ending_before
                  ? toDayjs(contract.ending_before)
                  : undefined,
              ),
              badge: <ContractStatusBadge contract={contract} />,
            },

            ...(!!transition
              ? [
                  {
                    label: "Next contract",
                    value: (
                      <DeprecatedInternalLink
                        routePath={Contract.getRoutePath(
                          transition.to_contract,
                        )}
                      >
                        {Contract.getName(transition.to_contract)}
                        <Icon
                          icon="open"
                          className="ml-4 text-deprecated-primary-500"
                        />
                      </DeprecatedInternalLink>
                    ),
                  },
                ]
              : []),
          ]}
        />
      </div>
      <div className="flex gap-32 p-12">
        <DeprecatedDefinitionList
          list={[
            {
              label: "Usage statement frequency",
              value: formatFrequency(contract.usage_invoice_schedule.frequency),
            },
            {
              label: "Net payment terms",
              value:
                contract.net_payment_terms_days != null
                  ? `${contract.net_payment_terms_days} days`
                  : "--",
            },
            ...(!!contract.usage_filter
              ? [
                  {
                    label: "Usage filter",
                    value: (
                      <ContractUsageFilterHistoryTable
                        usageFilter={contract.usage_filter}
                      />
                    ),
                  },
                ]
              : []),
            {
              label: BILLING_PROVIDER_LABEL,
              value: formatBillingProvider(billingProvider),
            },
            ...(scheduledChargesOnUsageInvoicesEnabled
              ? [
                  {
                    label: "Consolidate scheduled and usage charges",
                    value:
                      contract.scheduled_charges_on_usage_invoices ===
                      ContractScheduledChargesOnUsageInvoices.All
                        ? "Yes"
                        : "No",
                  },
                ]
              : []),
          ]}
        />
      </div>
    </DeprecatedPanel>
  );
};
