/* 7ed5c0a43aec9f170f30e270bcff3f238197be8c
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDraftPlanMutationVariables = Types.Exact<{
  draft_plan_id: Types.Scalars['uuid'];
}>;


export type DeleteDraftPlanMutation = { __typename?: 'Mutation', delete_DraftPlan_by_pk: { __typename?: 'DraftPlan', id: string } | null };


export const DeleteDraftPlanDocument = gql`
    mutation DeleteDraftPlan($draft_plan_id: uuid!) {
  delete_DraftPlan_by_pk(id: $draft_plan_id) {
    id
    __environment_type: environment_type
  }
}
    `;
export type DeleteDraftPlanMutationFn = Apollo.MutationFunction<DeleteDraftPlanMutation, DeleteDraftPlanMutationVariables>;

/**
 * __useDeleteDraftPlanMutation__
 *
 * To run a mutation, you first call `useDeleteDraftPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftPlanMutation, { data, loading, error }] = useDeleteDraftPlanMutation({
 *   variables: {
 *      draft_plan_id: // value for 'draft_plan_id'
 *   },
 * });
 */
export function useDeleteDraftPlanMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftPlanMutation, DeleteDraftPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftPlanMutation, DeleteDraftPlanMutationVariables>(DeleteDraftPlanDocument, options);
      }
export type DeleteDraftPlanMutationHookResult = ReturnType<typeof useDeleteDraftPlanMutation>;
export type DeleteDraftPlanMutationResult = Apollo.MutationResult<DeleteDraftPlanMutation>;
export type DeleteDraftPlanMutationOptions = Apollo.BaseMutationOptions<DeleteDraftPlanMutation, DeleteDraftPlanMutationVariables>;