import { Card } from "components/Card";
import { Icon } from "components/Icon";
import React from "react";

const cardContent = [
  {
    title: "Commits",
    subTitle: "Agreement to spend a dollar amount.",
    icon: (
      <Icon
        icon="coinsHandFeature"
        viewBox="0 0 36 36"
        className="h-[32px] w-[32px]"
      />
    ),
  },
  {
    title: "Credits",
    subTitle: "Give customers a dollar amount of free usage.",
    icon: (
      <Icon
        icon="creditCardPlusFeature"
        viewBox="0 0 36 36"
        className="h-[32px] w-[32px]"
      />
    ),
  },
  {
    title: "Threshold billing",
    subTitle: "Spend thresholds and credit minimums.",
    icon: (
      <Icon
        icon="bellPlusFeature"
        viewBox="0 0 36 36"
        className="h-[32px] w-[32px]"
      />
    ),
  },
  {
    title: "Subscriptions",
    subTitle: "Recurring charges on a set schedule.",
    icon: (
      <Icon
        icon="usersPlusFeature"
        viewBox="0 0 36 36"
        className="h-[32px] w-[32px]"
      />
    ),
  },
  {
    title: "Scheduled charges",
    subTitle: "Additional charges that are invoiced separetely.",
    icon: (
      <Icon
        icon="calendarPlusFeature"
        viewBox="0 0 36 36"
        className="h-[32px] w-[32px]"
      />
    ),
  },
];

interface TermCardsProps {
  subscriptionUIEnabled?: boolean;
  thresholdBillingUIEnabled?: boolean;
}

export const TermCards: React.FC<TermCardsProps> = ({
  subscriptionUIEnabled,
  thresholdBillingUIEnabled,
}) => {
  return (
    <div className="flex h-full items-stretch justify-start gap-xl">
      {cardContent
        .filter((content) => {
          const conditions: Record<string, boolean> = {
            Subscriptions: !subscriptionUIEnabled,
            "Threshold billing": !thresholdBillingUIEnabled,
          };

          return !conditions[content.title];
        })
        .map((content, index) => (
          <Card
            key={index}
            className="flex-1 flex-shrink-0 basis-0 flex-col"
            fullHeight={true}
          >
            <div className="flex h-full items-start gap-md self-stretch pl-md pr-md">
              {content.icon}
              <div className="flex h-full flex-1 flex-col self-start">
                <div className="text-gray-700 font-medium leading-2">
                  {content.title}
                </div>
                <div className="text-xs font-normal leading-2">
                  {content.subTitle}
                </div>
              </div>
            </div>
          </Card>
        ))}
    </div>
  );
};
